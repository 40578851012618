import { Box, CircularProgress, Divider, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useContext, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import ErrorsAlert from "../../components/Common/ErrorsAlert";
import IssuerIdentity from "../../components/Issuer/IssuerIdentity";
import { UserContext } from "../../context/UserProvider";
import usePromise from "../../hooks/usePromise";
import IssuersService from "../../services/IssuersService";

const Identity = () => {
  const params = useParams();
  const [data, errors, loading, setPromise] = usePromise();
  const { issuer } = useContext(UserContext);

  useEffect(() => {
    params.hash &&
      setPromise(IssuersService.validateIdentityHash(issuer, params.hash));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.hash]);

  return (
    <>
      <Box mb={1} display="flex" flexDirection="row" alignItems="center">
        <Typography variant="h6">
          <FormattedMessage
            id="routes.protected.settings.identity"
            defaultMessage="Identity"
            description="Issuer identity link and breadcrumbs title"
          />
        </Typography>
      </Box>
      <Divider />
      <Box pt={2}>
        {errors && (
          <Box mb={2}>
            <ErrorsAlert errors={errors} />
          </Box>
        )}
        {data && data.status === "validated" && (
          <Box mb={2}>
            <Alert>
              <FormattedMessage
                id="issuer-form.email-validated-text"
                defaultMessage="Email {email} was validated successfully!"
                values={{ email: data.email }}
              />
            </Alert>
          </Box>
        )}
        {loading ? (
          <Box display="flex" justifyContent="center" mb={2}>
            <CircularProgress />
          </Box>
        ) : (
          <IssuerIdentity />
        )}
      </Box>
    </>
  );
};

export default Identity;
