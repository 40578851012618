import API from "../utils/API";
import { errors } from "../config/errors";
import history from "../utils/history";

class AuthService {
  queries = {
    registerUser: (...params) =>
      `mutation{registerUser(input:{email:"${params[0]}",password:"${params[1]}",username:"${params[0]}",firstName:"${params[2]}",lastName:"${params[3]}"}){ user { email }}}`,
    validateUser: (...params) =>
      `mutation{confirmUser(input:{token:"${params[0]}"}){ user { email, enabled }}}`,
    resetPasswordRequest: (...params) =>
      `mutation{resettingRequestUser(input:{email:"${params[0]}"}){ user { email }}}`,
    resetPassword: (...params) =>
      `mutation{resettingUser(input:{token:"${params[0]}",password:"${params[1]}"}){ user { email }}}`,
  };

  login = (username, password) => {
    return API.login(username, password)
      .then((success) => {
        if (success) {
          return this.getUser().then((user) => {
            return { success: true, user: user };
          });
        } else {
          return { success: false, error: errors.AuthBadCredentials };
        }
      })
      .catch((err) => {
        return { success: false, error: errors.AuthConnectionError };
      });
  };

  logout = () => {
    localStorage.removeItem("issuer");
    API.logout();
    history.push("/");
  };

  /**
   * Returns user info based on current access_token
   * @returns {Object} User info
   * @throws string on error
   */
  getUser = () => {
    return API.callGraphql(
      `query { currentUser { email }, byUserQueryUserIssuers { collection { issuer { uuid, name, validatedIdentities }, admin } } }`
    ).then((response) => {
      if (response.errors && response.errors.length) {
        throw Error(response.errors[0].message);
      }

      if (!response.data || !response.data.currentUser) throw Error("Error");

      return this._getUserFromResponse(response.data);
    });
  };

  _getUserFromResponse = (responseData) => {
    let user = responseData.currentUser;

    user.userIssuers =
      responseData.byUserQueryUserIssuers &&
      responseData.byUserQueryUserIssuers.collection
        ? responseData.byUserQueryUserIssuers.collection.filter(
            (x) => typeof x !== undefined
          )
        : [];

    //Check if on-storage issuer is valid
    const onStorageIssuer = this.getCurrentIssuer();
    let currentUserIssuer =
      onStorageIssuer && onStorageIssuer.uuid
        ? this._getUserIssuerByIssuerUuid(
            user.userIssuers,
            onStorageIssuer.uuid
          )
        : false;
    //Clean issuer storage
    localStorage.removeItem("issuer");

    if (!currentUserIssuer) {
      currentUserIssuer = user.userIssuers[0];
    }

    //Set new issuer in storage
    currentUserIssuer &&
      currentUserIssuer.issuer &&
      this.setCurrentIssuer(currentUserIssuer.issuer);

    //Set Admin property
    user.admin =
      currentUserIssuer && currentUserIssuer.issuer
        ? currentUserIssuer.admin
        : false;

    return user;
  };

  _getUserIssuerByIssuerUuid = (userIssuers, uuid) => {
    return userIssuers.length
      ? userIssuers.find((iss) => iss.issuer.uuid === uuid)
      : undefined;
  };

  getCurrentIssuer = () => {
    return JSON.parse(localStorage.getItem("issuer"));
  };

  setCurrentIssuer = (issuer) => {
    localStorage.setItem("issuer", JSON.stringify(issuer));
  };

  register = (formData) => {
    return API.callGraphql(
      this.queries.registerUser(
        formData["email"],
        formData["password"],
        formData["name"],
        formData["lastname"]
      )
    ).then((response) => {
      if (response.errors && response.errors.length) {
        throw Error(response.errors[0].message);
      }

      if (!response.data || !response.data.registerUser) throw Error("Error");

      return response.data.registerUser.user;
    });
  };

  validateRegistration = (validationHash) => {
    return API.callGraphql(this.queries.validateUser(validationHash)).then(
      (response) => {
        if (response.errors && response.errors.length) {
          throw Error(response.errors[0].message);
        }

        if (
          !response.data ||
          !response.data.confirmUser ||
          !response.data.confirmUser.user
        )
          throw Error("Error");

        return { validated: response.data.confirmUser.user.enabled };
      }
    );
  };

  resetPasswordRequest = (email) => {
    return API.callGraphql(this.queries.resetPasswordRequest(email)).then(
      (response) => {
        if (response.errors && response.errors.length) {
          throw Error(response.errors[0].message);
        }

        if (!response.data || !response.data.resettingRequestUser)
          throw Error("Error");

        if (!response.data.resettingRequestUser.user)
          throw Error("Email not found");

        return response.data.resettingRequestUser.user;
      }
    );
  };

  resetPassword = (token, newPassword) => {
    return API.callGraphql(this.queries.resetPassword(token, newPassword)).then(
      (response) => {
        if (response.errors && response.errors.length) {
          throw Error(response.errors[0].message);
        }

        if (!response.data || !response.data.resettingUser)
          throw Error("Error");

        return { changed: !response.data.resettingUser.user };
      }
    );
  };

  setGoogleRecaptchaToken = (googleRecaptchaToken) => {
    API.setGoogleRecaptchaToken(googleRecaptchaToken);
    return this;
  };
}

export default new AuthService();
