import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Icon,
  Paper,
  Typography,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import BadgeClassStampChainSelector from "../BadgeClass/BadgeClassStampChainSelector";
import BadgeClassTypeSelector from "../BadgeClass/BadgeClassTypeSelector";
import { CreateBadgeContext } from "../../context/CreateBadgeProvider";
import LoadingButton from "../Common/LoadingButton";
import usePromise from "../../hooks/usePromise";
import BadgeClassesService from "../../services/BadgeClassesService";
import SvgEditor from "../SvgEditor/SvgEditor";
import { FormattedMessage, useIntl } from "react-intl";
import ErrorsAlert from "../Common/ErrorsAlert";

var saveSvgEditor = () => {};

const CreateBadgeStep1 = () => {
  const {
    badgeClass,
    currentStep,
    handleNextStep,
    handleBackStep,
    setBadgeClass,
    badgeClassConfig,
  } = useContext(CreateBadgeContext);
  const [saveData, errorsSaving, saving, setPromiseSave] = usePromise();
  const [useBlockchainStamp, setUseBlockchainStamp] = useState(
    badgeClass.timestamps ? true : false
  );
  const [allowTimestamps, setAllowTimestamps] = useState(true);
  const intl = useIntl();

  BadgeClassesService.setDefaultErrorMessage(
    intl.formatMessage({
      id: "errors.unexpected-error",
      defaultMessage: "An unexpected error ocurred.",
    })
  );

  const handleCheckboxChange = (event) => {
    setUseBlockchainStamp(event.target.checked);
  };

  const handleSubmit = () => {
    saveSvgEditor(badgeClass);
  };

  useEffect(() => {
    if (badgeClass.saved) {
      setPromiseSave(BadgeClassesService.saveBadgeClass(badgeClass));
      setBadgeClass({
        ...badgeClass,
        saved: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [badgeClass.saved]);

  useEffect(() => {
    if (saveData) {
      handleNextStep();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveData]);

  useEffect(() => {
    badgeClassConfig.frameLevels.map((level) => {
      level.level.name === badgeClass.level &&
        setAllowTimestamps(level.level.allowedTimestamp);
      return level;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [badgeClass.level]);

  useEffect(() => {
    if (!allowTimestamps || !useBlockchainStamp) {
      setBadgeClass({
        ...badgeClass,
        timestamps: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowTimestamps, useBlockchainStamp]);

  return (
    <Grid container>
      <Grid item xs sm={12}>
        <Box mb={2}>
          <ErrorsAlert errors={errorsSaving} />
        </Box>
      </Grid>
      <Grid item sm={8}>
        <Paper variant="outlined">
          <SvgEditor onMount={(saveFn) => (saveSvgEditor = saveFn)} />
        </Paper>
        <Box
          mt={3}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Button
            variant="text"
            onClick={handleBackStep}
            disabled={!currentStep}
          >
            <FormattedMessage
              id="badge-class-wizard.back-button"
              defaultMessage="Back"
              description="Back button in wizard"
            />
          </Button>
          <LoadingButton
            loading={saving ? "true" : undefined}
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            <Typography noWrap variant="button">
              <FormattedMessage
                id="badge-class-wizard.save-continue-button"
                defaultMessage="Save and continue"
                description="Save and continue button in wizard"
              />
            </Typography>
          </LoadingButton>
        </Box>
      </Grid>
      <Grid item sm={4}>
        <Box display="flex" flexDirection="column">
          <Box>
            <BadgeClassTypeSelector levels={badgeClassConfig.frameLevels} />
          </Box>
          <Box mb={2} display={allowTimestamps ? "flex" : "none"}>
            <Box display="grid" alignItems="center">
              <Icon></Icon>
            </Box>
            <Box>
              <FormControlLabel
                checked={useBlockchainStamp}
                onChange={handleCheckboxChange}
                label={intl.formatMessage({
                  id: "badge-class-wizard.use-blockchains-label",
                  defaultMessage: "Use Blockchain timestamps",
                  description: "Label of use blockchain timestamps in wizard",
                })}
                control={<Checkbox />}
              />
              {useBlockchainStamp && (
                <BadgeClassStampChainSelector
                  chains={badgeClassConfig.frameTimestampAuthorities}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default CreateBadgeStep1;
