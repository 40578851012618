import React, { useContext, useEffect } from "react";
import AdminLayout from "../layouts/AdminLayout";
import { Box, Breadcrumbs, Icon, Typography } from "@material-ui/core";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { FormattedMessage } from "react-intl";
import IssuerForm from "../components/Issuer/IssuerForm";
import { Alert } from "@material-ui/lab";
import usePromise from "../hooks/usePromise";
import IssuersService from "../services/IssuersService";
import ErrorsAlert from "../components/Common/ErrorsAlert";
import { UserContext } from "../context/UserProvider";
import history from "../utils/history";

const CreateIssuer = () => {
  const [data, errors, loading, setPromise] = usePromise();
  const { setIssuerById } = useContext(UserContext);

  const handleCreateIssuerFormSubmit = (formValues) => {
    setPromise(IssuersService.createIssuer(formValues));
  };

  useEffect(() => {
    if (data?.issuer?.uuid) {
      setIssuerById(data.issuer.uuid).then(() =>
        history.push("/issuer-settings/identity")
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <AdminLayout>
      <Box p={2}>
        <Breadcrumbs separator=">" aria-label="breadcrumb">
          <Link style={{ color: "inherit" }} to="/">
            <FormattedMessage
              id="routes.protected.dashboard"
              defaultMessage="Dashboard"
              description="Dashboard menu breadcrumbs title"
            />
          </Link>
          <Typography color="textPrimary">
            <FormattedMessage
              id="header.create-issuer"
              description="Create Issuer button"
              defaultMessage="Create Issuer"
            />
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box p={2}>
        <Alert color="info" variant="outlined" icon={<Icon>info</Icon>}>
          <FormattedMessage
            id="create-issuer.info-message"
            defaultMessage="Before start configure your issuer profile. This info will be attached to your badges."
          />
        </Alert>
        {errors && (
          <Box mt={2}>
            <ErrorsAlert errors={errors} />
          </Box>
        )}
        <Box mt={2}>
          <IssuerForm
            handleFormSubmit={handleCreateIssuerFormSubmit}
            loading={loading}
          />
        </Box>
      </Box>
    </AdminLayout>
  );
};

export default CreateIssuer;
