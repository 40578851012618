import { Box, Breadcrumbs, Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import BadgeClassSearchInput from "../components/BadgeClass/BadgeClassSearchInput";
import BadgeStatusSelect from "../components/Badge/BadgeStatusSelect";
import BadgeList from "../components/Badge/BadgeList";
import AdminLayout from "../layouts/AdminLayout";
import { FormattedMessage } from "react-intl";

const Assertions = () => {
  const [search, setSearch] = useState("");
  const [statuses, setStatuses] = useState(["pending", "processed", "error"]);
  const [tags, setTags] = useState([]);
  const params = useParams();
  const pageNumber = params.pageNumber ? parseInt(params.pageNumber, 10) : 1;

  return (
    <AdminLayout>
      <Box p={2}>
        <Breadcrumbs separator=">" aria-label="breadcrumb">
          <Link style={{ color: "inherit" }} to="/">
            <FormattedMessage
              id="routes.protected.dashboard"
              defaultMessage="Dashboard"
              description="Dashboard menu breadcrumbs title"
            />
          </Link>
          <Typography color="textPrimary">
            <FormattedMessage
              id="routes.protected.assertions"
              defaultMessage="My Assertions"
              description="My Assertions menu link and breadcrumbs title"
            />
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box p={2}>
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <BadgeClassSearchInput
              setSearch={setSearch}
              setTags={setTags}
              params={params}
            />
          </Grid>
          <Grid item xs={2}>
            <BadgeStatusSelect
              setStatuses={setStatuses}
              statuses={statuses}
              params={params}
            />
          </Grid>
        </Grid>
      </Box>
      <Box p={2}>
        <BadgeList
          pageNumber={pageNumber}
          limit={5}
          search={search}
          statuses={statuses}
          tags={tags}
        />
      </Box>
    </AdminLayout>
  );
};

export default Assertions;
