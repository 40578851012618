import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import React from "react";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom";

const SettingsMenu = (props) => {
  const { routes, ...rest } = props;
  const location = useLocation();

  if (!routes) {
    return <></>;
  }

  return (
    <List style={{ padding: 0 }} component="nav" {...rest}>
      {routes.map((route) => (
        <ListItem
          button
          component={Link}
          to={route.to}
          disabled={route.disabled}
          selected={location && route.to === location.pathname}
          key={route.to}
        >
          {route.icon && <ListItemIcon>{route.icon}</ListItemIcon>}
          <ListItemText primary={route.message} secondary={route.chip ?? ""} />
        </ListItem>
      ))}
    </List>
  );
};

export default SettingsMenu;
