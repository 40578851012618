import { Box, Icon, Typography } from "@material-ui/core";
import React from "react";

const BatchBadgeClass = (props) => {
  const iconWidth = props.iconWidth || 125;
  const iconHeight = props.iconHeight || 100;
  const thumbnailKey = `${iconWidth}x${iconHeight}`;

  if (!props.badgeClass) {
    return <></>;
  }

  return (
    <Box display="flex" alignItems="center">
      <Box p={1}>
        {props.badgeClass.thumbnails &&
        props.badgeClass.thumbnails[thumbnailKey] ? (
          <img
            src={props.badgeClass.thumbnails[thumbnailKey]}
            alt="badge icon"
            width={iconWidth}
          />
        ) : (
          <Icon style={{ fontSize: 150 }}>shield</Icon>
        )}
      </Box>
      <Box>
        <Typography variant="h5">{props.badgeClass.name}</Typography>
        <Typography variant="subtitle1">
          {props.badgeClass.description}
        </Typography>
      </Box>
    </Box>
  );
};

export default BatchBadgeClass;
