import {
  Box,
  FormControl,
  InputLabel,
  Select,
  TextField,
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { mdiFormatBold, mdiFormatItalic } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useEffect, useState } from "react";

const fontFamilies = [
  {
    label: "Sefif",
    value: "serif",
  },
  {
    label: "Sans-serif",
    value: "sans-serif",
  },
  {
    label: "Cursive",
    value: "cursive",
  },
  {
    label: "Fantasy",
    value: "fantasy",
  },
  {
    label: "Monospace",
    value: "monospace",
  },
];

const SvgEditorTextTools = (props) => {
  const [fontValues, setFontValues] = useState([]);

  const handleOnChange = (event, values) => {
    setFontValues(values);
    props.onFontBoldChange(values.indexOf("bold") !== -1);
    props.onFontItalicChange(values.indexOf("italic") !== -1);
  };

  useEffect(() => {
    let values = [];
    props.fontItalic && values.push("italic");
    props.fontBold && values.push("bold");
    setFontValues(values);
  }, [props.fontItalic, props.fontBold]);

  return (
    <Box display="flex" flexDirection="row">
      <ToggleButtonGroup onChange={handleOnChange} value={fontValues}>
        <ToggleButton value="bold">
          <Icon path={mdiFormatBold} size={0.8}></Icon>
        </ToggleButton>
        <ToggleButton value="italic">
          <Icon path={mdiFormatItalic} size={0.8}></Icon>
        </ToggleButton>
      </ToggleButtonGroup>
      <Box ml={1}>
        <FormControl size="small" variant="outlined">
          <InputLabel htmlFor="outlined-font-family">Font</InputLabel>
          <Select
            native
            value={props.fontFamily ?? ""}
            onChange={props.onFontFamilyChange}
            label="Font"
            inputProps={{
              name: "font-family",
              id: "outlined-font-family",
            }}
          >
            {fontFamilies.map((f) => (
              <option value={f.value} aria-label={f.label} key={f.value}>
                {f.label}
              </option>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box ml={1}>
        <TextField
          variant="outlined"
          id="standard-number"
          label="Size"
          type="number"
          size="small"
          InputProps={{
            inputProps: { min: 1, max: 100 },
            onChange: props.onFontSizeChange,
          }}
          InputLabelProps={{
            shrink: true,
          }}
          value={props.fontSize ?? ""}
        />
      </Box>
    </Box>
  );
};

export default SvgEditorTextTools;
