import { Box, CircularProgress, Grid, Typography } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import usePromise from "../../hooks/usePromise";
import BadgesService from "../../services/BadgesService";
import history from "../../utils/history";
import BadgeItem from "./BadgeItem";

const BadgeList = (props) => {
  const limit = props.limit ? parseInt(props.limit, 10) : 5;
  const [data, errors, loading, setPromise] = usePromise();
  const intl = useIntl();

  BadgesService.setDefaultErrorMessage(
    intl.formatMessage({
      id: "errors.unexpected-error",
      defaultMessage: "An unexpected error ocurred.",
    })
  );

  const getBadges = (pageNumber, search, earner, statuses) => {
    setPromise(
      BadgesService.getBadges(pageNumber, limit, search, earner, statuses)
    );
  };

  useEffect(() => {
    getBadges(props.pageNumber, props.search, props.earner, props.statuses);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.pageNumber, props.search, props.earner, props.statuses]);

  const handlePaginationChange = (evt, page) => {
    history.push("/history/badges/" + page);
  };

  if (loading) {
    return (
      <Box display="grid" justifyItems="center">
        <CircularProgress />
      </Box>
    );
  }

  if (errors) {
    return <Box>{errors[0]}</Box>;
  }

  if (!data.collection) {
    return (
      <Box>
        <Typography color="textSecondary">
          <FormattedMessage
            id="badge-list.nothing-found"
            defaultMessage="Nothing found"
          />
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <Grid container direction="column" spacing={4}>
        {data.collection.map((badge) => (
          <Grid key={badge.uuid} item>
            <BadgeItem badge={badge} />
          </Grid>
        ))}
        {!data.collection.length && (
          <Grid key="empty" item>
            <Typography color="textSecondary">
              <FormattedMessage
                id="badge-list.nothing-found"
                defaultMessage="Nothing found"
              />
            </Typography>
          </Grid>
        )}
      </Grid>
      {data.paginationInfo.lastPage > 1 && (
        <Box mt={2} p={2} display="flex" justifyContent="center">
          <Pagination
            page={props.pageNumber}
            count={data.paginationInfo.lastPage}
            color="primary"
            onChange={handlePaginationChange}
          />
        </Box>
      )}
    </>
  );
};

export default BadgeList;
