import { Chip, Icon } from "@material-ui/core";
import { FormattedMessage } from "react-intl";

const issuerSettingsRoutes = [
  {
    to: "/issuer-settings/profile",
    message: (
      <FormattedMessage
        id="routes.protected.settings.profile"
        defaultMessage="Profile"
        description="Issuer profile link and breadcrumbs title"
      />
    ),
    icon: <Icon>domain</Icon>,
  },
  {
    to: "/issuer-settings/identity",
    message: (
      <FormattedMessage
        id="routes.protected.settings.identity"
        defaultMessage="Identity"
        description="Issuer identity link and breadcrumbs title"
      />
    ),
    icon: <Icon>fingerprint</Icon>,
  },
  {
    to: "/issuer-settings/subscription-plan",
    message: (
      <FormattedMessage
        id="routes.protected.settings.plan"
        defaultMessage="Subscription Plan"
        description="Issuer plan link and breadcrumbs title"
      />
    ),
    icon: <Icon>card_membership</Icon>,
  },
  {
    to: "/issuer-settings/staff",
    message: (
      <FormattedMessage
        id="routes.protected.settings.staff"
        defaultMessage="Staff"
        description="Staff menu link and breadcrumbs title"
      />
    ),
    icon: <Icon>group</Icon>,
    chip: (
      <Chip
        size="small"
        label="Coming Soon!"
        color="default"
        component="span"
      />
    ),
    disabled: true,
  },
  {
    to: "/issuer-settings/certification-templates",
    message: (
      <FormattedMessage
        id="routes.protected.settings.certification-templates"
        defaultMessage="Certification Templates"
        description="Certification Templates menu link"
      />
    ),
    icon: <Icon>insert_drive_file</Icon>,
    chip: (
      <Chip
        size="small"
        label="Coming Soon!"
        color="default"
        component="span"
      />
    ),
    disabled: true,
  },
  {
    to: "/issuer-settings/api-access",
    message: (
      <FormattedMessage
        id="routes.protected.settings.api-access"
        defaultMessage="API Access"
        description="API Access menu link"
      />
    ),
    icon: <Icon>vpn_key</Icon>,
    chip: (
      <Chip
        size="small"
        label="Coming Soon!"
        color="default"
        component="span"
      />
    ),
    disabled: true,
  },
  {
    to: "/issuer-settings/white-label",
    message: (
      <FormattedMessage
        id="routes.protected.settings.white-label"
        defaultMessage="White Label"
        description="White Label menu link"
      />
    ),
    icon: <Icon>store_mall_directory</Icon>,
    chip: (
      <Chip
        size="small"
        label="Coming Soon!"
        color="default"
        component="span"
      />
    ),
    disabled: true,
  },
];

export { issuerSettingsRoutes };
