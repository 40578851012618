import { Box } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React from "react";
import { FormattedMessage } from "react-intl";

const SignUpSuccess = (props) => {
  return (
    <Box>
      <Alert variant="standard" color="success">
        <FormattedMessage
          id="signup.success-message"
          description="Message shown after user register"
          defaultMessage="Great! We have sent you an email to {email} for you validation. If you don't see the email, remember to check the spam folder."
          values={{ email: props.email }}
        />
      </Alert>
    </Box>
  );
};

export default SignUpSuccess;
