import { Box, Button, Paper, Icon, Typography } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import React, { useContext, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ValidatorForm } from "react-material-ui-form-validator";
import { SendBadgesBatchContext } from "../../context/SendBadgesBatchProvider";
import EarnerSelectInput from "../Earner/EarnerSelectInput";
import EarnersSelectionDialog from "../Earner/EarnersSelectionDialog";

const SendBadgesStep1 = () => {
  const {
    batch,
    addEarners,
    removeEarner,
    currentStep,
    handleNextStep,
    handleBackStep,
  } = useContext(SendBadgesBatchContext);

  const intl = useIntl();
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleSubmit = () => {
    batch.assertions.length && handleNextStep();
  };

  const handleDialogClose = (event) => {
    setDialogOpen(false);
  };

  const handleEarnersSelection = (event, selectedIds) => {
    addEarners(selectedIds);
    setDialogOpen(false);
  };

  const handleRemoveEarner = (assertionId) => {
    removeEarner(assertionId);
  };

  const stringSortComparator = (v1, v2, param1, param2) => {
    let row1 = param1.api.getRow(param1.id);
    let row2 = param2.api.getRow(param2.id);
    v1 =
      row1.earner && row1.earner[param1.field]
        ? row1.earner[param1.field][0].toString()
        : "";
    v2 =
      row2.earner && row2.earner[param2.field]
        ? row2.earner[param2.field][0].toString()
        : "";
    return (v1 > v2) - (v1 < v2);
  };

  const columns = [
    {
      field: "externalId",
      headerName: intl.formatMessage({
        id: "earners-grid.id-field-name",
        defaultMessage: "ID",
        description: "ID column header name in recipients grid",
      }),
      width: 150,
      disableColumnMenu: true,
      filterable: false,
      sortable: true,
      type: "string",
      sortComparator: stringSortComparator,
      renderCell: (params) => {
        return <>{params.row.earner.externalId}</>;
      },
    },
    {
      field: "email",
      headerName: intl.formatMessage({
        id: "earners-grid.email-field-name",
        defaultMessage: "Email",
        description: "Email column header name in recipients grid",
      }),
      flex: true,
      disableColumnMenu: true,
      filterable: false,
      sortable: true,
      sortComparator: stringSortComparator,
      renderCell: (params) => {
        return <>{params.row.earner.email}</>;
      },
    },
    {
      field: "name",
      headerName: intl.formatMessage({
        id: "earners-grid.name-field-name",
        defaultMessage: "Name",
        description: "Name column header name in recipients grid",
      }),
      flex: true,
      disableColumnMenu: true,
      filterable: false,
      sortable: true,
      sortComparator: stringSortComparator,
      renderCell: (params) => {
        return <>{params.row.earner.name}</>;
      },
    },
    {
      field: "actions",
      headerName: " ",
      align: "center",
      renderCell: (params) => {
        return (
          <Button onClick={() => handleRemoveEarner(params.row.id)}>
            <Icon fontSize="small">clear</Icon>
            <Typography variant="button">
              <FormattedMessage
                id="send-badges-wizard.remove-earner-button"
                defaultMessage="Remove"
                description="Remove Recipient from list button"
              />
            </Typography>
          </Button>
        );
      },
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
    },
  ];

  return (
    <ValidatorForm onSubmit={handleSubmit}>
      <Box display="flex">
        <Box flexGrow={1}>
          <EarnerSelectInput />
        </Box>
        <Box flexShrink={1} ml={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setDialogOpen(true)}
          >
            <FormattedMessage
              id="send-badges-wizard.add-earners-button"
              defaultMessage="Add recipients"
              description="Add Recipients to list button"
            />
          </Button>
        </Box>
      </Box>
      <Box mt={2}>
        <Typography>
          <FormattedMessage
            id="send-badges-wizard.selected-earners-title"
            defaultMessage="Selected Recipients"
            description="Selected Recipients list title"
          />{" "}
          ({batch.assertions.length})
        </Typography>
      </Box>
      <Paper variant="outlined">
        <div style={{ height: "100%", width: "100%" }}>
          <DataGrid
            columns={columns}
            rows={batch.assertions}
            loading={false}
            pagination
            pageSize={10}
            rowCount={batch.assertions.length}
            disableSelectionOnClick={true}
            disableColumnSelector={true}
            disableColumnReorder={true}
            autoHeight={true}
          ></DataGrid>
        </div>
      </Paper>
      <Box
        mt={3}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Button variant="text" onClick={handleBackStep} disabled={!currentStep}>
          <FormattedMessage
            id="send-badges-wizard.back-button"
            defaultMessage="Back"
            description="Back button in wizard"
          />
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={!batch.assertions.length}
        >
          <Typography noWrap variant="button">
            <FormattedMessage
              id="send-badges-wizard.next-button"
              defaultMessage="Next"
              description="Next button in wizard"
            />
          </Typography>
        </Button>
      </Box>
      <EarnersSelectionDialog
        open={dialogOpen}
        handleClose={handleDialogClose}
        handleEarnersSelection={handleEarnersSelection}
      />
    </ValidatorForm>
  );
};

export default SendBadgesStep1;
