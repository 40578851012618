import {
  Box,
  Breadcrumbs,
  Button,
  makeStyles,
  Typography,
  Icon,
  CircularProgress,
  Paper,
} from "@material-ui/core";
import ErrorsAlert from "../components/Common/ErrorsAlert";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import AdminLayout from "../layouts/AdminLayout";
import { useDropzone } from "react-dropzone";
import FileUploadService from "../services/FileUploadService";
import EarnersService from "../services/EarnersService";
import history from "../utils/history";
import LoadingButton from "../components/Common/LoadingButton";
import usePromise from "../hooks/usePromise";
import useSnackbar from "../hooks/useSnackbar";
import { Alert } from "@material-ui/lab";
import ImportEarnersFileInformationDialog from "../components/Earner/ImportEarnersFileInformationDialog";

const useStyle = makeStyles({
  dropzone: {
    borderStyle: "dashed",
  },
});

const ImportEarners = () => {
  const classes = useStyle();
  const [uploading, setUploading] = useState(false);
  const [file, setFile] = useState({
    name: "file.csv",
    type: "",
    uuid: "",
    tags: [],
  });
  const [earnersData, errors, loading, setPromise] = usePromise();
  const [showSnackbar] = useSnackbar();
  const intl = useIntl();
  const [infoDialogVisible, setInfoDialogVisible] = useState(false);

  EarnersService.setDefaultErrorMessage(
    intl.formatMessage({
      id: "errors.unexpected-error",
      defaultMessage: "An unexpected error ocurred.",
    })
  );

  const handleFilesDrop = async (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length) {
      showSnackbar(
        intl.formatMessage({
          id: "import-earners:unsupported-file-type",
          defaultMessage: "Unsupported file type.",
        }),
        "error",
        2500
      );
      return;
    }

    setUploading(true);
    let fileUUID = file.uuid;
    let uploadResponse = await FileUploadService.uploadFile(acceptedFiles[0]);

    if (uploadResponse) {
      fileUUID = uploadResponse["uuid"];
    } else {
      fileUUID = "";
      showSnackbar(
        intl.formatMessage({
          id: "import-earners:upload-error",
          defaultMessage: "An error has ocurred.",
        }),
        "error",
        2500
      );
    }

    setFile({
      ...file,
      name: acceptedFiles[0].name,
      type: acceptedFiles[0].type,
      uuid: fileUUID,
    });
    setUploading(false);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleFilesDrop,
    accept: "text/csv",
  });

  const handleChangeClick = () => {
    setFile({ ...file, uuid: "" });
  };

  const handleCancelClick = () => {
    history.goBack();
  };

  const handleImportClick = async () => {
    setPromise(EarnersService.importEarners(file.uuid, file.tags));
  };

  return (
    <AdminLayout>
      <Box p={2}>
        <Breadcrumbs separator=">" aria-label="breadcrumb">
          <Link style={{ color: "inherit" }} to="/">
            <FormattedMessage
              id="routes.protected.dashboard"
              defaultMessage="Dashboard"
              description="Dashboard menu breadcrumbs title"
            />
          </Link>
          <Link style={{ color: "inherit" }} to="/recipients">
            <FormattedMessage
              id="routes.protected.earners"
              defaultMessage="Recipients"
              description="Recipients menu link and breadcrumbs title"
            />
          </Link>
          <Typography color="textPrimary">
            <FormattedMessage
              id="import-earners.import-earners-title"
              defaultMessage="Import Recipients"
              description="Title shown in breadcrumbs when importing recipients"
            />
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box px={2}>
        <Alert
          severity="info"
          variant="outlined"
          action={
            <Button
              variant="outlined"
              size="small"
              onClick={() => setInfoDialogVisible(true)}
            >
              <FormattedMessage
                id="button.more-information"
                defaultMessage="More info"
                description="More information button"
              />
            </Button>
          }
        >
          <FormattedMessage
            id="import-earners.import-earners-help"
            defaultMessage="You must import a CSV file containing External ID, Name, Email and Tags in comma separated columns."
            description="Info shown in recipients import page."
          />
        </Alert>
      </Box>
      <Box p={2}>
        <Box
          display={file.uuid !== "" ? "none" : "grid"}
          p={2}
          border={1}
          borderRadius={3}
          justifyItems="center"
          className={classes.dropzone}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          {uploading && <CircularProgress />}
          {!uploading && (
            <>
              <Icon style={{ height: 25 }}>upload</Icon>
              <Typography variant="body1" color="textSecondary">
                <FormattedMessage
                  id="dropzone:drag-drop-text"
                  defaultMessage="Drag 'n' drop some files here, or click to select files"
                  description="Text shown in drag and drop area"
                />
              </Typography>
            </>
          )}
        </Box>
        {file.uuid !== "" && !earnersData && !errors && (
          <Paper>
            <Box p={2} display="flex" justifyContent="space-between">
              <Typography variant="h5">{file.name}</Typography>
              <Button variant="contained" onClick={handleChangeClick}>
                <FormattedMessage
                  id="import-earners:change-button"
                  defaultMessage="Change"
                  description="Change button text"
                />
              </Button>
            </Box>
          </Paper>
        )}
        {!earnersData && !errors && (
          <Box display="flex" justifyContent="space-between" mt={2}>
            <Button onClick={handleCancelClick} disabled={uploading || loading}>
              <FormattedMessage
                id="import-earners:cancel-button"
                defaultMessage="Cancel"
                description="Cancel button text"
              />
            </Button>
            {file.uuid !== "" && (
              <LoadingButton
                loading={loading ? "true" : null}
                variant="contained"
                color="primary"
                onClick={handleImportClick}
              >
                <FormattedMessage
                  id="import-earners:import-button"
                  defaultMessage="Import"
                  description="Import button text"
                />
              </LoadingButton>
            )}
          </Box>
        )}

        {errors && errors.length && (
          <Box mt={2}>
            <ErrorsAlert errors={errors} />
          </Box>
        )}

        {earnersData && (
          <Alert severity="success">
            <FormattedMessage
              id="import-earners:earners-imported-message"
              defaultMessage="{quantity} {quantity, plural, 
              =0 {Recipients}
              one {Recipient}
              other {Recipients}
              } imported"
              description="Recipients imported text"
              values={{
                quantity: earnersData.length,
              }}
            />
          </Alert>
        )}

        {(earnersData || errors) && (
          <Box display="flex" justifyContent="center" mt={2}>
            <Button component={Link} to="/recipients">
              <FormattedMessage
                id="import-earners.return-to-earners-button"
                defaultMessage="Return to recipients"
                description="Return to recipients button text"
              />
            </Button>
          </Box>
        )}
      </Box>
      <ImportEarnersFileInformationDialog
        open={infoDialogVisible}
        onClose={() => {
          setInfoDialogVisible(false);
        }}
      />
    </AdminLayout>
  );
};

export default ImportEarners;
