import { Box, Breadcrumbs, Typography } from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import SettingsContainer from "../components/Settings/SettingsContainer";
import { issuerSettingsRoutes } from "../config/issuerSettingsRoutes";
import { SubscriptionPlanProvider } from "../context/SubscriptionPlanProvider";
import AdminLayout from "../layouts/AdminLayout";
import * as Settings from "./settings";

const IssuerSettings = () => {
  const params = useParams();

  const currentComponent = (path) => {
    switch (path) {
      case "validate-identity":
      case "identity":
        return <Settings.Identity />;
      case "subscription-plan":
        return (
          <SubscriptionPlanProvider>
            <Settings.SubscriptionPlan />
          </SubscriptionPlanProvider>
        );
      case "staff":
        return <Settings.Staff />;
      case "profile":
      default:
        return <Settings.Profile />;
    }
  };

  const component = currentComponent(params.current);

  return (
    <AdminLayout>
      <Box p={2}>
        <Breadcrumbs separator=">" aria-label="breadcrumb">
          <Link style={{ color: "inherit" }} to="/">
            <FormattedMessage
              id="routes.protected.dashboard"
              defaultMessage="Dashboard"
              description="Dashboard menu breadcrumbs title"
            />
          </Link>
          <Typography color="textPrimary">
            <FormattedMessage
              id="routes.protected.issuer-settings"
              defaultMessage="Issuer Settings"
              description="Issuer Settings menu link"
            />
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box p={2}>
        <SettingsContainer routes={issuerSettingsRoutes} variant="outlined">
          {component}
        </SettingsContainer>
      </Box>
    </AdminLayout>
  );
};

export default IssuerSettings;
