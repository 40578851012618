import { Icon } from "@material-ui/core";
import React from "react";

const BatchStatusIcon = ({ status, ...rest }) => {
  const statusIcon = (status) => {
    switch (status) {
      case "draft":
        return (
          <Icon fontSize="inherit" {...rest}>
            edit_note
          </Icon>
        );
      case "pending":
        return (
          <Icon fontSize="inherit" {...rest}>
            pending_actions
          </Icon>
        );
      case "processing":
        return (
          <Icon fontSize="inherit" {...rest}>
            settings
          </Icon>
        );
      case "processed":
        return (
          <Icon fontSize="inherit" {...rest}>
            workspace_premium
          </Icon>
        );
      case "processed_with_errors":
        return (
          <Icon fontSize="inherit" {...rest}>
            error_outline
          </Icon>
        );
      default:
    }
  };

  return <>{statusIcon(status)}</>;
};

export default BatchStatusIcon;
