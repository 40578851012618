import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "./context/ThemeProvider";
import { LanguageProvider } from "./context/LanguageProvider";
import { UserProvider } from "./context/UserProvider";
import { SnackbarProvider } from "./context/SnackbarProvider";
import { DownloadManagerProvider } from "./context/DownloadManagerProvider";
import { Switch, Route, Router } from "react-router-dom";
import ProtectedRoute from "./components/Misc/ProtectedRoute";
import history from "./utils/history";
import * as Pages from "./pages";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

function App() {
  return (
    <UserProvider>
      <LanguageProvider>
        <ThemeProvider>
          <GoogleReCaptchaProvider
            reCaptchaKey={process.env.REACT_APP_RECAPTCHA_V3_PUBLIC_KEY}
            useRecaptchaNet={true}
          >
            <SnackbarProvider>
              <DownloadManagerProvider>
                <>
                  <CssBaseline />
                  <Router history={history}>
                    <Switch>
                      <Route path="/terms" component={Pages.Terms} />
                      <Route
                        path="/privacy-policy"
                        component={Pages.PrivacyPolicy}
                      />
                      <Route path="/login" component={Pages.Login} />
                      <Route path="/signup" component={Pages.SignUp} />
                      <Route
                        path="/validate/:validationHash"
                        component={Pages.SignUp}
                      />
                      <Route
                        path="/reset-password/:resetHash"
                        component={Pages.ResetPassword}
                      />
                      <Route
                        path="/reset-password"
                        component={Pages.ResetPassword}
                      />
                      <ProtectedRoute
                        path="/create-issuer"
                        component={Pages.CreateIssuer}
                        issuerRequired={false}
                      />
                      <ProtectedRoute
                        path="/recipients/:pageNumber"
                        component={Pages.Earners}
                        issuerRequired={true}
                      />
                      <ProtectedRoute
                        path="/recipients"
                        component={Pages.Earners}
                        issuerRequired={true}
                      />
                      <ProtectedRoute
                        path="/import-recipients"
                        component={Pages.ImportEarners}
                        issuerRequired={true}
                      />
                      <ProtectedRoute
                        path="/add-recipient"
                        component={Pages.ManageEarner}
                        issuerRequired={true}
                      />
                      <ProtectedRoute
                        path="/edit-recipient/:earnerUuid"
                        component={Pages.ManageEarner}
                        issuerRequired={true}
                      />
                      <ProtectedRoute
                        path="/badges/:pageNumber"
                        component={Pages.Badges}
                        issuerRequired={true}
                      />
                      <ProtectedRoute
                        path="/badges"
                        component={Pages.Badges}
                        issuerRequired={true}
                      />
                      <ProtectedRoute
                        path="/assertions/:pageNumber"
                        component={Pages.Assertions}
                        issuerRequired={true}
                      />
                      <ProtectedRoute
                        path="/assertions"
                        component={Pages.Assertions}
                        issuerRequired={true}
                      />
                      <ProtectedRoute
                        path="/create-badge"
                        component={Pages.CreateBadge}
                        issuerRequired={true}
                      />
                      <ProtectedRoute
                        path="/edit-badge/:badgeUuid"
                        component={Pages.CreateBadge}
                        issuerRequired={true}
                      />
                      <ProtectedRoute
                        path="/send-badges/:badgeUuid"
                        component={Pages.SendBadges}
                        issuerRequired={true}
                      />
                      <ProtectedRoute
                        path="/send-badges"
                        component={Pages.SendBadges}
                        issuerRequired={true}
                      />
                      <ProtectedRoute
                        path="/batch/:batchId"
                        component={Pages.BatchSummary}
                        issuerRequired={true}
                      />
                      <ProtectedRoute
                        path="/batches/:pageNumber"
                        component={Pages.Batches}
                        issuerRequired={true}
                      />
                      <ProtectedRoute
                        path="/batches"
                        component={Pages.Batches}
                        issuerRequired={true}
                      />
                      <ProtectedRoute
                        path="/history/:tabNumber/:pageNumber"
                        component={Pages.History}
                        issuerRequired={true}
                      />
                      <ProtectedRoute
                        path="/history/:tabNumber"
                        component={Pages.History}
                        issuerRequired={true}
                      />
                      <ProtectedRoute
                        path="/history"
                        component={Pages.History}
                        issuerRequired={true}
                      />
                      <Route
                        path="/validator/:badgeText"
                        component={Pages.Validator}
                        issuerRequired={true}
                      />
                      <Route path="/validator" component={Pages.Validator} />
                      <ProtectedRoute
                        path="/balance"
                        component={Pages.Balance}
                        onlyAdmin={true}
                        issuerRequired={true}
                      />
                      <ProtectedRoute
                        path="/issuer-settings/:current/:hash"
                        component={Pages.IssuerSettings}
                        onlyAdmin={true}
                        issuerRequired={true}
                      />
                      <ProtectedRoute
                        path="/issuer-settings/:current"
                        component={Pages.IssuerSettings}
                        onlyAdmin={true}
                        issuerRequired={true}
                      />
                      <ProtectedRoute
                        path="/issuer-settings"
                        component={Pages.IssuerSettings}
                        onlyAdmin={true}
                        issuerRequired={true}
                      />
                      <Route
                        path="/verify-timestamp/:timestampType/:timestampPayload"
                        component={Pages.VerifyTimestamp}
                      />
                      <Route
                        path="/verify-timestamp"
                        component={Pages.VerifyTimestamp}
                      />
                      <ProtectedRoute
                        path="/"
                        component={Pages.Dashboard}
                        issuerRequired={true}
                      />
                    </Switch>
                  </Router>
                </>
              </DownloadManagerProvider>
            </SnackbarProvider>
          </GoogleReCaptchaProvider>
        </ThemeProvider>
      </LanguageProvider>
    </UserProvider>
  );
}

export default App;
