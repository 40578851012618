import {
  Box,
  Collapse,
  Link,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import SubscriptionPlansTable from "./SubscriptionPlansTable";

const useStyle = makeStyles((theme) => ({
  currentPlan: {
    borderColor: theme.palette.primary.main,
  },
}));

const CustomSubscriptionPlan = ({ plan, ...rest }) => {
  const classes = useStyle();
  const [showDetails, setShowDetails] = useState(false);

  if (!plan) {
    return <></>;
  }

  return (
    <Paper variant="outlined" className={classes.currentPlan}>
      <Box p={2}>
        <Box mb={1}>
          <Typography variant="subtitle2">
            <FormattedMessage
              id="custom-plan.title"
              defaultMessage="Custom Plan"
            />
          </Typography>
          <Typography variant="body2">
            <FormattedMessage
              id="custom-plan.detail"
              defaultMessage="Currently you have a custom plan. If you need any changes, please
            contact our sales representative."
            />
          </Typography>
        </Box>
        <Collapse in={showDetails}>
          <SubscriptionPlansTable plans={[plan]} showPrices />
        </Collapse>
        <Box mt={1} justifyItems="center" display="grid">
          <Link onClick={() => setShowDetails(!showDetails)} href="#">
            <Typography variant="caption">
              {showDetails ? (
                <FormattedMessage
                  id="plan-details.hide"
                  defaultMessage="Hide your plan details"
                />
              ) : (
                <FormattedMessage
                  id="plan-details.view"
                  defaultMessage="View your plan details"
                />
              )}
            </Typography>
          </Link>
        </Box>
      </Box>
    </Paper>
  );
};

export default CustomSubscriptionPlan;
