import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  Typography,
} from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";

const IssuerImageRemoveConfirmDialog = ({
  handleConfirm,
  handleClose,
  ...rest
}) => {
  return (
    <Dialog {...rest}>
      <DialogTitle>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography variant="h6">
            <FormattedMessage
              id="issuer-image.remove-dialog-title"
              defaultMessage="Delete Image"
            />
          </Typography>
          <IconButton size="small" onClick={handleClose}>
            <Icon>close</Icon>
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers={true}>
        <Typography>
          <FormattedMessage
            defaultMessage="You are going to delete the issuer image. Are you sure?"
            id="issuer-image.remove-dialog-text"
          />
        </Typography>
      </DialogContent>
      <DialogActions>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          flexGrow={1}
          p={1}
        >
          <Button onClick={handleClose}>
            <FormattedMessage
              defaultMessage="Cancel"
              description="Cancel button text"
              id="dialog.cancel-button"
            />
          </Button>
          <Button
            onClick={() => handleConfirm()}
            variant="contained"
            color="primary"
          >
            <FormattedMessage
              defaultMessage="Yes, delete"
              id="issuer-image.remove-dialog-button"
            />
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default IssuerImageRemoveConfirmDialog;
