import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  Typography,
} from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";
import SubscriptionPlansTable from "./SubscriptionPlansTable";
import LoadingButton from "../Common/LoadingButton";
import ErrorsAlert from "../Common/ErrorsAlert";

const SelectNewPlanDialog = ({
  currentPlan,
  newPlan,
  handleClose,
  handleConfirm,
  loading,
  errors,
  ...rest
}) => {
  if (!newPlan || !currentPlan) {
    return <></>;
  }

  return (
    <Dialog {...rest}>
      <DialogTitle>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography variant="h6">
            <FormattedMessage
              id="select-new-plan-dialog.title"
              defaultMessage="Select Subscription Plan"
            />
          </Typography>
          <IconButton size="small" onClick={handleClose}>
            <Icon>close</Icon>
          </IconButton>
        </Box>
        {errors && (
          <Box mt={2}>
            <ErrorsAlert errors={errors} />
          </Box>
        )}
      </DialogTitle>
      <DialogContent>
        <Box mb={2} justifyItems="center" display="grid">
          <Typography variant="body1">
            <FormattedMessage
              defaultMessage="We are going to change your plan from {oldPlanName} to {newPlanName}. Are you sure?"
              values={{
                oldPlanName: currentPlan.description,
                newPlanName: newPlan.description,
              }}
              id="select-new-plan-dialog.description"
            />
          </Typography>
        </Box>
        <SubscriptionPlansTable plans={[currentPlan, newPlan]} showPrices />
      </DialogContent>
      <DialogActions>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          flexGrow={1}
          p={1}
        >
          <Button onClick={handleClose}>
            <FormattedMessage
              defaultMessage="Cancel"
              description="Cancel button text"
              id="dialog.cancel-button"
            />
          </Button>
          <LoadingButton
            onClick={handleConfirm}
            variant="contained"
            color="primary"
            loading={loading ? "true" : undefined}
          >
            <FormattedMessage
              defaultMessage="Continue"
              id="select-new-plan-dialog.continue-button"
            />
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default SelectNewPlanDialog;
