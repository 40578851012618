import React from "react";
import AdminLayout from "../layouts/AdminLayout";
import AccountBalances from "../components/Account/AccountBalances";
import BadgeClassList from "../components/BadgeClass/BadgeClassList";
import IssuerStatistics from "../components/Statistic/IssuerStatistics";
import { Box, Grid, Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";

const Dashboard = () => {
  return (
    <AdminLayout>
      <Box p={2}>
        <Grid container spacing={2}>
          <Grid item sm={12} md={6}>
            <Box py={1}>
              <Typography variant="subtitle2" color="textSecondary">
                <FormattedMessage
                  id="routes.protected.balance"
                  defaultMessage="Balance"
                  description="Balance menu link"
                />
              </Typography>
            </Box>
            <AccountBalances style={{ height: "80%" }} />
          </Grid>
          <Grid item sm={12} md={6}>
            <Box py={1}>
              <Typography variant="subtitle2" color="textSecondary">
                <FormattedMessage
                  id="dashboard.statistics-title"
                  defaultMessage="Sent badges"
                />
              </Typography>
            </Box>
            <IssuerStatistics style={{ height: "80%" }} />
          </Grid>
          <Grid item sm={12}>
            <Box pb={1}>
              <Typography variant="subtitle2" color="textSecondary">
                <FormattedMessage
                  id="routes.protected.badges"
                  defaultMessage="My Badges"
                  description="My Badges menu link and breadcrumbs title"
                />
              </Typography>
            </Box>
            <BadgeClassList
              limit={5}
              pageNumber={1}
              search=""
              statuses={["draft", "used"]}
              sortBy="updatedAt"
              order="DESC"
              paginationDisable={true}
              itemType="reduce"
            />
          </Grid>
        </Grid>
      </Box>
    </AdminLayout>
  );
};

export default Dashboard;
