import { Button, CircularProgress } from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";

const LoadingButton = (props) => {
  const [dimensions, setDimensions] = useState([0, 0]);
  const ref = useRef(null);

  useEffect(() => {
    if (
      ref.current &&
      ref.current.getBoundingClientRect().width &&
      ref.current.getBoundingClientRect().height
    ) {
      let width = ref.current.getBoundingClientRect().width;
      let height = ref.current.getBoundingClientRect().height;
      setDimensions([width, height]);
    }
  }, [props.children]);

  const styles = dimensions[0]
    ? { width: `${dimensions[0]}px`, height: `${dimensions[1]}px` }
    : {};

  return (
    <Button
      disabled={props.loading === "true" ? true : undefined}
      ref={ref}
      style={styles}
      {...props}
    >
      {props.loading ? (
        <CircularProgress size={24} color="inherit" />
      ) : (
        props.children
      )}
    </Button>
  );
};

export default LoadingButton;
