import { createMuiTheme } from "@material-ui/core";

export const lightTheme = createMuiTheme({
  name: "light",
  palette: {
    type: "light",
    primary: {
      light: "#FE953D",
      main: "#FF7400",
      dark: "#CB5C00",
      contrastText: "#FFF",
    },
    secondary: {
      light: "#31CB31",
      main: "#00CC00",
      dark: "#00A200",
      contrastText: "#FFF",
    },
  },
});
