import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";
import React, { useContext } from "react";
import { CreateBadgeContext } from "../../context/CreateBadgeProvider";

const BadgeClassStampChainSelector = (props) => {
  const { badgeClass, setBadgeClass } = useContext(CreateBadgeContext);

  const currentTimestamps = badgeClass.timestamps
    ? badgeClass.timestamps.split(",")
    : [];

  const handleBadgeClassStampChainChange = (event) => {
    if (event.target.checked) {
      currentTimestamps.push(event.target.value);
    } else {
      currentTimestamps.splice(
        currentTimestamps.indexOf(event.target.value),
        1
      );
    }

    setBadgeClass({
      ...badgeClass,
      timestamps: currentTimestamps.join(","),
    });
  };

  return (
    <>
      <FormGroup row={true}>
        {props.chains.map((chain) => (
          <FormControlLabel
            key={chain.timestampAuthority.cryptoSignerId}
            control={
              <Checkbox
                name="timestamps"
                onChange={handleBadgeClassStampChainChange}
                checked={
                  currentTimestamps.indexOf(
                    chain.timestampAuthority.cryptoSignerId
                  ) !== -1
                    ? true
                    : false
                }
                value={chain.timestampAuthority.cryptoSignerId}
              />
            }
            label={chain.timestampAuthority.name}
          />
        ))}
      </FormGroup>
    </>
  );
};

export default BadgeClassStampChainSelector;
