import {
  Box,
  Button,
  Divider,
  Grid,
  Icon,
  Paper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import MoreOptionsMenu from "../Common/MoreOptionsMenu";
import BadgeDownloadButton from "./BadgeDownloadButton";
import BadgePrintButton from "./BadgePrintButton";
import { RevokeBadgeContext } from "../../context/RevokeBadgeProvider";
import { Link } from "react-router-dom";
import BadgeResendButton from "./BadgeResendButton";

const BadgeItem = (props) => {
  const [status] = useState(props.badge.status);
  const [revoked, setRevoked] = useState(props.badge.revoked);
  const [revocationReason, setRevocationReason] = useState(
    props.badge.revocationReason
  );
  const intl = useIntl();
  const {
    handleRevokeButtonClick,
    revocatedBadges,
    revocationData,
  } = useContext(RevokeBadgeContext);

  const badgeStatusLabels = {
    draft: intl.formatMessage({
      id: "badge-status.status-draft",
      defaultMessage: "Draft",
    }),
    pending: intl.formatMessage({
      id: "badge-status.status-pending",
      defaultMessage: "Pending",
    }),
    processing: intl.formatMessage({
      id: "badge-status.status-processing",
      defaultMessage: "Processing",
    }),
    stamping: intl.formatMessage({
      id: "badge-status.status-stamping",
      defaultMessage: "Stamping",
    }),
    stamped: intl.formatMessage({
      id: "badge-status.status-stamped",
      defaultMessage: "Stamped",
    }),
    signed: intl.formatMessage({
      id: "badge-status.status-signed",
      defaultMessage: "Signed",
    }),
    processed: intl.formatMessage({
      id: "badge-status.status-processed",
      defaultMessage: "Processed",
    }),
    error: intl.formatMessage({
      id: "badge-status.status-error",
      defaultMessage: "Error",
    }),
  };

  useEffect(() => {
    if (revocatedBadges && revocatedBadges.length) {
      if (revocatedBadges.includes(props.badge.uuid)) {
        setRevoked(true);
        setRevocationReason(revocationData.revocationReason);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [revocatedBadges, revocationData]);

  if (status === "deleted") {
    return "";
  }

  return (
    <Paper variant="elevation" elevation={1}>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={3}
          md={1}
          lg={1}
          container
          justify="center"
          alignContent="center"
        >
          <Box p={1}>
            {props.badge.badgeClass.thumbnails &&
            props.badge.badgeClass.thumbnails["125x100"] ? (
              <img
                src={props.badge.badgeClass.thumbnails["125x100"]}
                alt="badge icon"
                style={{ maxWidth: "100%" }}
              />
            ) : (
              <Icon style={{ fontSize: 64 }}>shield</Icon>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={9} md={5} lg={5} container>
          <Box p={1}>
            <Typography variant="h6" noWrap={true}>
              {props.badge.badgeClass.name}
            </Typography>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Icon
                style={{ fontSize: "1em" }}
                title={intl.formatMessage({
                  id: "badge-item:recipient-label",
                  defaultMessage: "Recipient",
                })}
              >
                person
              </Icon>
              &nbsp;
              <Typography variant="body2" noWrap={true}>
                {props.badge.email.earner.name}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          container
          justify="flex-end"
          alignItems="center"
        >
          <Box display="flex" px={2} justifyItems="right">
            <Box px={2}>
              <Typography variant="body2" color="textSecondary">
                <FormattedMessage
                  id="badge-list.status-title"
                  defaultMessage="Status"
                  description="Title in badge's list."
                />
              </Typography>
              <Box fontSize={2} display="flex" alignItems="center">
                {revocationReason ? (
                  <Typography display="inline">
                    <Tooltip title={revocationReason} aria-label="info">
                      <Icon color="action">info_sign</Icon>
                    </Tooltip>
                  </Typography>
                ) : (
                  ""
                )}
                {props.badge.errors && (
                  <Typography
                    variant="body2"
                    color="error"
                    display="inline"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Icon>error_outline</Icon>
                    &nbsp;
                    <FormattedMessage
                      defaultMessage="Error"
                      id="badge-status.status-error"
                    />
                  </Typography>
                )}
                {revoked && (
                  <Typography variant="body2" color="error" display="inline">
                    &nbsp;
                    <FormattedMessage
                      defaultMessage="Revoked"
                      id="badge.revoked-label"
                      description="Badge revoked text"
                    />
                  </Typography>
                )}
                {!props.badge.errors && !revoked && (
                  <Typography variant="body1">
                    {badgeStatusLabels[status]}
                  </Typography>
                )}
              </Box>
            </Box>
            {status !== "draft" && (
              <Box px={2}>
                <Typography variant="body2" color="textSecondary">
                  <FormattedMessage
                    id="badge-list.score-title"
                    defaultMessage="Score"
                    description="Score in badge's list."
                  />
                </Typography>
                <Typography variant="body1">{props.badge.score}</Typography>
              </Box>
            )}
            {status !== "draft" && (
              <Box px={2}>
                <Typography variant="body2" color="textSecondary">
                  <FormattedMessage
                    id="badge-list.issued-on-title"
                    defaultMessage="Issued On"
                    description="Issued On in badge's list."
                  />
                </Typography>
                <Typography variant="body2" noWrap>
                  {intl.formatDate(props.badge.issuedOn, {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: false,
                  })}{" "}
                  Hs.
                </Typography>
              </Box>
            )}
            {status !== "draft" && (
              <Box px={2}>
                <Typography variant="body2" color="textSecondary">
                  <FormattedMessage
                    id="badge-list.issued-on-batch"
                    defaultMessage="Batch #{number}"
                    description="Batch in badge's list."
                    values={{ number: props.badge.batch.number }}
                  />
                </Typography>
                <Button
                  to={"/batch/" + props.badge.batch.uuid}
                  component={Link}
                  variant="outlined"
                >
                  <Typography variant="body2" noWrap>
                    <FormattedMessage
                      id="badge-list.open-batch-button"
                      defaultMessage="Open batch"
                    />
                  </Typography>
                </Button>
              </Box>
            )}
            <MoreOptionsMenu>
              {status === "processed" && (
                <>
                  <Box>
                    <Box>
                      <BadgeDownloadButton
                        uuid={props.badge.uuid}
                        startIcon={<Icon>download</Icon>}
                        style={{ padding: "0.5em 1em" }}
                        fullWidth={true}
                      />
                    </Box>
                    {props.badge.pdf && <Divider style={{ width: "100%" }} />}
                    {props.badge.pdf && (
                      <Box>
                        <BadgePrintButton
                          uuid={props.badge.uuid}
                          startIcon={<Icon>print</Icon>}
                          style={{ padding: "0.5em 1em" }}
                          fullWidth={true}
                        />
                      </Box>
                    )}
                    {!revoked && <Divider style={{ width: "100%" }} />}
                    {!revoked && (
                      <Box>
                        <Button
                          onClick={() => handleRevokeButtonClick(props.badge)}
                          data-test="revoke"
                          startIcon={<Icon>block</Icon>}
                          style={{ padding: "0.5em 1em" }}
                          fullWidth={true}
                        >
                          <FormattedMessage
                            defaultMessage="Revoke"
                            id="badge.revoke-button"
                            description="Badge revoke button text"
                          />
                        </Button>
                      </Box>
                    )}
                    <Divider style={{ width: "100%" }} />
                    <Box>
                      <BadgeResendButton
                        uuid={props.badge.uuid}
                        style={{ padding: "0.5em 1em" }}
                        fullWidth={true}
                      />
                    </Box>
                  </Box>
                </>
              )}
            </MoreOptionsMenu>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default BadgeItem;
