import { Box, Popper } from "@material-ui/core";
import { ToggleButton } from "@material-ui/lab";
import { mdiSettingsHelper } from "@mdi/js";
import Icon, { Stack } from "@mdi/react";
import React, { useState } from "react";

const ToggleButtonSelector = (props) => {
  const [subToolsOpen, setSubToolsOpen] = useState(false);
  const [toolsRef, setToolsRef] = useState(null);
  const [currentButton, setCurrentButton] = useState(props.children[0]);

  const toggleTools = (event) => {
    if (event.type === "mousedown") {
      setToolsRef(event.currentTarget);
      setSubToolsOpen(true);
    }
    if (event.type === "mouseup") {
      setSubToolsOpen(false);
    }
  };

  const setSubTool = (event, el) => {
    setCurrentButton(el);
    setSubToolsOpen(false);
    props.onChange(event, el.props.value);
  };

  return (
    <ToggleButton
      className={props.className}
      selected={props.value === currentButton.props.value}
      value={currentButton.props.value}
      aria-label={currentButton.props.value}
      onClick={(event) => props.onChange(event, currentButton.props.value)}
      onMouseDown={toggleTools}
      onMouseUp={toggleTools}
      onMouseLeave={() => setSubToolsOpen(false)}
    >
      <Stack size={1.2}>
        {currentButton.props.children}
        <Icon path={mdiSettingsHelper} size={1.2}></Icon>
      </Stack>
      <Popper open={subToolsOpen} anchorEl={toolsRef} placement="right-start">
        <Box display="flex" flexDirection="column">
          {React.Children.map(props.children, (child) => {
            return React.cloneElement(child, {
              onMouseUp: (event) => setSubTool(event, child),
            });
          })}
        </Box>
      </Popper>
    </ToggleButton>
  );
};

export default ToggleButtonSelector;
