import React, { useEffect, useState } from "react";
import usePromise from "../hooks/usePromise";
import BadgeRevokeDialog from "../components/Badge/BadgeRevokeDialog";
import BadgesService from "../services/BadgesService";
import { useIntl } from "react-intl";

const RevokeBadgeContext = React.createContext();

const RevokeBadgeProvider = (props) => {
  const intl = useIntl();
  const [
    revocationData,
    revocationErrors,
    revoking,
    setRevokingPromise,
  ] = usePromise();

  const [openRevokeDialog, setOpenRevokeDialog] = useState(false);
  const [currentBadges, setCurrentBadges] = useState([]);
  const [revocatedBadges, setRevocatedBadges] = useState([]);

  BadgesService.setDefaultErrorMessage(
    intl.formatMessage({
      id: "errors.unexpected-error",
      defaultMessage: "An unexpected error ocurred.",
    })
  );

  const handleCloseRevokeDialog = () => {
    setOpenRevokeDialog(false);
    setCurrentBadges([]);
  };

  const handleRevokeButtonClick = (badge) => {
    setCurrentBadges([...currentBadges, badge.id || badge.uuid]);
    setOpenRevokeDialog(true);
  };

  const handleRevoke = (revocationReason) => {
    if (currentBadges.length) {
      return setRevokingPromise(
        BadgesService.revokeBadge(currentBadges[0], revocationReason)
      );
    }
  };

  useEffect(() => {
    if (revocationData && !revocationErrors) {
      setOpenRevokeDialog(false);
      setRevocatedBadges(currentBadges);
      setCurrentBadges([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [revocationData]);

  return (
    <RevokeBadgeContext.Provider
      value={{
        revoking,
        revocatedBadges,
        revocationData,
        handleRevokeButtonClick,
        ...props.value,
      }}
    >
      {props.children}
      <BadgeRevokeDialog
        open={openRevokeDialog}
        handleClose={handleCloseRevokeDialog}
        revoking={revoking}
        handleRevoke={handleRevoke}
      />
    </RevokeBadgeContext.Provider>
  );
};

export { RevokeBadgeProvider, RevokeBadgeContext };
