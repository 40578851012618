import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  makeStyles,
  Popper,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { CreateBadgeContext } from "../../context/CreateBadgeProvider";
import usePromise from "../../hooks/usePromise";
import BadgeClassesService from "../../services/BadgeClassesService";
import ErrorsAlert from "../Common/ErrorsAlert";
import LoadingButton from "../Common/LoadingButton";

const useStyles = makeStyles((theme) => ({
  arrow: {
    position: "absolute",
    fontSize: 7,
    width: "3em",
    height: "3em",
    "&::before": {
      content: '""',
      margin: "auto",
      marginRight: "-0.4em",
      display: "block",
      width: "2em",
      height: "2em",
      borderStyle: "solid",
      borderWidth: "1px 0 0 1px",
      borderColor: `rgba(0, 0, 0, 0.12)`,
      backgroundColor: `${theme.palette.background.paper}`,
      transform: "rotate(-45deg)",
    },
  },
}));

const CreateBadgeStep0 = () => {
  const {
    badgeClass,
    setBadgeClass,
    currentStep,
    handleNextStep,
    handleBackStep,
  } = useContext(CreateBadgeContext);
  const [saveData, errorsSaving, saving, setPromiseSave] = usePromise();
  const [tags, setTags] = useState([]);
  const [currentInput, setCurrentInput] = useState("");
  const intl = useIntl();
  const [arrowRef, setArrowRef] = useState(null);
  const classes = useStyles();

  BadgeClassesService.setDefaultErrorMessage(
    intl.formatMessage({
      id: "errors.unexpected-error",
      defaultMessage: "An unexpected error ocurred.",
    })
  );

  const handleTagsChange = (event, value) => {
    handleFormChange({ target: { name: "tagNames", value: value } });
  };

  const handleSubmit = () => {
    setPromiseSave(BadgeClassesService.saveBadgeClass(badgeClass));
  };

  const handleFormChange = (event) => {
    setBadgeClass({
      ...badgeClass,
      [event.target.name]: event.target.value,
    });
  };

  const handleInputFocus = (event) => {
    setCurrentInput(event.currentTarget);
  };

  const handleInputBlur = (event) => {
    setCurrentInput(null);
  };

  useEffect(() => {
    BadgeClassesService.getBadgeClassTags().then((tags) => {
      setTags(tags);
    });
  }, []);

  useEffect(() => {
    if (saveData) {
      !badgeClass.uuid && setBadgeClass({ ...badgeClass, uuid: saveData.uuid });
      handleNextStep();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveData]);

  const validationMessages = {
    required: intl.formatMessage({
      id: "validation.field-required",
      defaultMessage: "This field is required",
      description: "Label shown on required form field when is required",
    }),
  };

  const inputs = {
    name: {
      label: intl.formatMessage({
        id: "badge-class.name-input",
        defaultMessage: "Name",
        description: "Email input label",
      }),
      placeholder: intl.formatMessage({
        id: "badge-class.name-placeholder",
        defaultMessage: "Badge's name",
        description: "Name input placeholder",
      }),
      documentation: intl.formatMessage({
        id: "badge-class.name-documentation",
        defaultMessage: "Enter your Badge name",
        description: "Extra information shown when completing the input",
      }),
    },
    description: {
      label: intl.formatMessage({
        id: "badge-class.description-input",
        defaultMessage: "Description",
        description: "Description input label",
      }),
      placeholder: intl.formatMessage({
        id: "badge-class.description-placeholder",
        defaultMessage: "Badge description",
        description: "Description textarea placeholder",
      }),
      documentation: intl.formatMessage({
        id: "badge-class.description-documentation",
        defaultMessage: "Enter a description",
        description: "Extra information shown when completing the input",
      }),
    },
    criteria: {
      label: intl.formatMessage({
        id: "badge-class.criteria-input",
        defaultMessage: "Criteria",
        description: "Criteria input label",
      }),
      placeholder: intl.formatMessage({
        id: "badge-class.criteria-placeholder",
        defaultMessage: "Badge's criteria",
        description: "Criteria input placeholder",
      }),
      documentation: intl.formatMessage({
        id: "badge-class.criteria-documentation",
        defaultMessage: "Explain the methods evaluated to assert the badge",
        description: "Extra information shown when completing the input",
      }),
    },
    tags: {
      label: intl.formatMessage({
        id: "badge-class.tags-input",
        defaultMessage: "Tags",
        description: "Tags selector label",
      }),
      placeholder: intl.formatMessage({
        id: "badge-class.tags-placeholder",
        defaultMessage: "Select tags",
        description: "Tags selector placeholder",
      }),
      documentation: intl.formatMessage({
        id: "badge-class.tags-documentation",
        defaultMessage: "Tag your badges for better organization",
        description: "Extra information shown when completing the input",
      }),
    },
  };

  return (
    <Grid container>
      <Grid item xs sm={12}>
        <Box mb={2}>
          <ErrorsAlert errors={errorsSaving} />
        </Box>
      </Grid>
      <Grid item xs sm={6}>
        <ValidatorForm onSubmit={handleSubmit}>
          <Box display="flex" flexDirection="column">
            <Box>
              <TextValidator
                onChange={handleFormChange}
                value={badgeClass.name}
                variant="outlined"
                label={inputs.name.label}
                name="name"
                fullWidth
                placeholder={inputs.name.placeholder}
                validators={["required"]}
                errorMessages={[validationMessages.required]}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
              />
            </Box>
            <Box mt={3}>
              <TextValidator
                multiline
                onChange={handleFormChange}
                value={badgeClass.description}
                rows={4}
                variant="outlined"
                label={inputs.description.label}
                name="description"
                fullWidth
                placeholder={inputs.description.placeholder}
                validators={["required"]}
                errorMessages={[validationMessages.required]}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
              />
            </Box>
            <Box mt={3}>
              <TextValidator
                multiline
                onChange={handleFormChange}
                value={badgeClass.criteria}
                rows={4}
                variant="outlined"
                label={inputs.criteria.label}
                name="criteria"
                fullWidth
                placeholder={inputs.criteria.placeholder}
                validators={["required"]}
                errorMessages={[validationMessages.required]}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
              />
            </Box>
            <Box mt={3}>
              <Autocomplete
                value={badgeClass.tagNames || []}
                onChange={handleTagsChange}
                options={tags}
                fullWidth
                multiple
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={inputs.tags.label}
                    name="tags"
                    placeholder={inputs.tags.placeholder}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    inputProps={{ ...params.inputProps, maxLength: 63 }}
                  />
                )}
              />
            </Box>
          </Box>
          <Box
            mt={3}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Button
              variant="text"
              onClick={handleBackStep}
              disabled={!currentStep}
            >
              <FormattedMessage
                id="badge-class-wizard.back-button"
                defaultMessage="Back"
                description="Back button in wizard"
              />
            </Button>
            <LoadingButton
              loading={saving ? "true" : undefined}
              variant="contained"
              color="primary"
              type="submit"
            >
              <Typography noWrap variant="button">
                <FormattedMessage
                  id="badge-class-wizard.save-continue-button"
                  defaultMessage="Save and continue"
                  description="Save and continue button in wizard"
                />
              </Typography>
            </LoadingButton>
          </Box>
        </ValidatorForm>
      </Grid>
      <Grid item xs sm={6}>
        <Popper
          anchorEl={currentInput}
          placement="right"
          open={currentInput ? true : false}
          modifiers={{
            arrow: {
              enabled: true,
              element: arrowRef,
            },
            preventOverflow: {
              enabled: true,
              boundariesElement: "scrollParent",
            },
          }}
        >
          <Box m={2}>
            <Card variant="outlined">
              <CardHeader
                title={
                  <Typography variant="h6">
                    {currentInput && inputs[currentInput.name]
                      ? inputs[currentInput.name].label
                      : ""}
                  </Typography>
                }
              ></CardHeader>
              <Divider></Divider>
              <CardContent>
                <Typography variant="body1">
                  {currentInput && inputs[currentInput.name]
                    ? inputs[currentInput.name].documentation
                    : ""}
                </Typography>
              </CardContent>
            </Card>
          </Box>
          <span ref={setArrowRef} className={classes.arrow} />
        </Popper>
      </Grid>
    </Grid>
  );
};

export default CreateBadgeStep0;
