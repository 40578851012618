import { Box, CircularProgress, Divider, Typography } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import ErrorsAlert from "../../components/Common/ErrorsAlert";
import IssuerForm from "../../components/Issuer/IssuerForm";
import { UserContext } from "../../context/UserProvider";
import usePromise from "../../hooks/usePromise";
import IssuersService from "../../services/IssuersService";

const Profile = () => {
  const [
    issuerData,
    loadIssuerErrors,
    loadingIssuer,
    setPromiseLoadIssuer,
  ] = usePromise();

  const [data, errors, loading, setPromise] = usePromise();

  const { issuer } = useContext(UserContext);

  const handleFormSubmit = (formValues) => {
    setPromise(IssuersService.updateIssuer(issuer.uuid, formValues));
  };

  useEffect(() => {
    issuer && setPromiseLoadIssuer(IssuersService.getIssuer(issuer.uuid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [issuer]);

  return (
    <>
      <Box mb={1} display="flex" flexDirection="row" alignItems="center">
        <Typography variant="h6">
          <FormattedMessage
            id="routes.protected.settings.profile"
            defaultMessage="Profile"
            description="Issuer profile link and breadcrumbs title"
          />
        </Typography>
      </Box>
      <Divider />
      <Box pt={2}>
        {errors && (
          <Box mb={2}>
            <ErrorsAlert errors={errors} />
          </Box>
        )}
        {loadIssuerErrors && (
          <Box mb={2}>
            <ErrorsAlert errors={loadIssuerErrors} />
          </Box>
        )}
        {loadingIssuer && (
          <Box display="flex" justifyContent="center" mb={2}>
            <CircularProgress />
          </Box>
        )}
        {issuerData && (
          <IssuerForm
            disableIdentityInputs={true}
            handleFormSubmit={handleFormSubmit}
            loading={loading}
            issuer={issuerData}
            buttonText={
              <FormattedMessage
                id="issuer-form.save-button"
                description="Save changes button"
                defaultMessage="Save changes"
              />
            }
          />
        )}
      </Box>
    </>
  );
};

export default Profile;
