import { Box, Breadcrumbs, Button, Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import BadgeClassSearchInput from "../components/BadgeClass/BadgeClassSearchInput";
import BadgeClassStatusSelect from "../components/BadgeClass/BadgeClassStatusSelect";
import BadgeClassList from "../components/BadgeClass/BadgeClassList";
import AdminLayout from "../layouts/AdminLayout";
import { FormattedMessage } from "react-intl";

const Badges = () => {
  const [search, setSearch] = useState("");
  const [statuses, setStatuses] = useState(["draft", "used"]);
  const [tags, setTags] = useState([]);
  const params = useParams();
  const pageNumber = params.pageNumber ? parseInt(params.pageNumber, 10) : 1;

  return (
    <AdminLayout>
      <Box p={2}>
        <Breadcrumbs separator=">" aria-label="breadcrumb">
          <Link style={{ color: "inherit" }} to="/">
            <FormattedMessage
              id="routes.protected.dashboard"
              defaultMessage="Dashboard"
              description="Dashboard menu breadcrumbs title"
            />
          </Link>
          <Typography color="textPrimary">
            <FormattedMessage
              id="routes.protected.badges"
              defaultMessage="My Badges"
              description="My Badges menu link and breadcrumbs title"
            />
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box p={2}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <BadgeClassSearchInput
              setSearch={setSearch}
              setTags={setTags}
              params={params}
            />
          </Grid>
          <Grid item xs={2}>
            <BadgeClassStatusSelect
              setStatuses={setStatuses}
              statuses={statuses}
              params={params}
            />
          </Grid>
          <Grid item xs={2}>
            <Box ml={1}>
              <Button
                color="primary"
                variant="contained"
                component={Link}
                to="/create-badge"
                fullWidth
              >
                <Typography noWrap variant="button">
                  <FormattedMessage
                    id="badges.create-badge-text"
                    defaultMessage="Create Badge"
                    description="Create Badge button text"
                  />
                </Typography>
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box p={2}>
        <BadgeClassList
          limit={5}
          pageNumber={pageNumber}
          search={search}
          statuses={statuses}
          tags={tags}
        />
      </Box>
    </AdminLayout>
  );
};

export default Badges;
