import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Link, useParams } from "react-router-dom";
import ErrorsAlert from "../components/Common/ErrorsAlert";
import LoadingButton from "../components/Common/LoadingButton";
import usePromise from "../hooks/usePromise";
import useSnackbar from "../hooks/useSnackbar";
import AdminLayout from "../layouts/AdminLayout";
import EarnersService from "../services/EarnersService";
import history from "../utils/history";

const ManageEarner = () => {
  const params = useParams();
  const isCreatingEarner = params.earnerUuid ? false : true;
  const intl = useIntl();
  const [earner, setEarner] = useState({
    externalId: null,
    name: null,
    email: null,
    tagNames: [],
  });
  const [tags, setTags] = useState([]);
  const [saveAndAddAnother, setSaveAndAddAnother] = useState(false);
  const [saveData, errorsSaving, saving, setPromiseSave] = usePromise();
  const [tagsData, errorsTags, loadingTags, setPromiseTags] = usePromise();
  const [
    earnerData,
    errorsEarner,
    loadingEarner,
    setPromiseEarner,
  ] = usePromise();
  const earnerForm = useRef(null);
  const [showSnackbar] = useSnackbar();

  EarnersService.setDefaultErrorMessage(
    intl.formatMessage({
      id: "errors.unexpected-error",
      defaultMessage: "An unexpected error ocurred.",
    })
  );

  const handleTagsChange = (event, value) => {
    handleFormChange({ target: { name: "tagNames", value: value } });
  };

  const handleFormChange = (event) => {
    setEarner({
      ...earner,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = () => {
    setPromiseSave(EarnersService.saveEarner(earner));
  };

  const handleCancelClick = () => {
    history.goBack();
  };

  const handleAddAnotherClick = () => {
    setSaveAndAddAnother(true);
    earnerForm.current.submit();
  };

  useEffect(() => {
    if (saveData && saveData.uuid) {
      if (saveAndAddAnother) {
        showSnackbar(
          intl.formatMessage({
            id: "form.saved-message",
            defaultMessage: "Saved!",
            description: "Message shown on success after saving form",
          })
        );
        setEarner({
          ...earner,
          externalId: "",
          name: "",
          email: "",
          tagNames: [],
        });
      } else {
        history.goBack();
      }
      setSaveAndAddAnother(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveData]);

  useEffect(() => {
    if (earnerData) {
      setEarner(earnerData);
    }
  }, [earnerData]);

  useEffect(() => {
    if (!isCreatingEarner && params.earnerUuid) {
      setPromiseEarner(EarnersService.getEarner(params.earnerUuid));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tagsData) {
      setTags(tagsData);
    }
  }, [tagsData]);

  useEffect(() => {
    setPromiseTags(EarnersService.getEarnersTags());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validationMessages = {
    required: intl.formatMessage({
      id: "validation.field-required",
      defaultMessage: "This field is required",
      description: "Label shown on required form field when is required",
    }),
    isEmail: intl.formatMessage({
      id: "validation.field-email",
      defaultMessage: "Email is not valid",
      description: "Label shown on required form field when email is not valid",
    }),
  };

  const inputs = {
    externalId: {
      label: intl.formatMessage({
        id: "manage-earner.id-input",
        defaultMessage: "ID",
        description: "Email input label",
      }),
      placeholder: intl.formatMessage({
        id: "manage-earner.id-placeholder",
        defaultMessage: "Enter an unique ID",
        description: "Name input placeholder",
      }),
    },
    name: {
      label: intl.formatMessage({
        id: "manage-earner.name-input",
        defaultMessage: "Name",
        description: "Name input label",
      }),
      placeholder: intl.formatMessage({
        id: "manage-earner.name-placeholder",
        defaultMessage: "Recipient's name",
        description: "Name input placeholder",
      }),
      documentation: intl.formatMessage({
        id: "manage-earner.name-documentation",
        defaultMessage: "Enter recipient's name",
        description: "Extra information shown when completing the input",
      }),
    },
    email: {
      label: intl.formatMessage({
        id: "manage-earner.email-input",
        defaultMessage: "Email",
        description: "Email input label",
      }),
      placeholder: intl.formatMessage({
        id: "manage-earner.email-placeholder",
        defaultMessage: "Recipient's email",
        description: "Email input placeholder",
      }),
      documentation: intl.formatMessage({
        id: "manage-earner.email-documentation",
        defaultMessage: "Enter recipient's email",
        description: "Extra information shown when completing the input",
      }),
    },
    tags: {
      label: intl.formatMessage({
        id: "manage-earner.tags-input",
        defaultMessage: "Tags",
        description: "Tags selector label",
      }),
      placeholder: intl.formatMessage({
        id: "manage-earner.tags-placeholder",
        defaultMessage: "Select tags",
        description: "Tags selector placeholder",
      }),
      documentation: intl.formatMessage({
        id: "manage-earner.tags-documentation",
        defaultMessage: "Tag your recipients for a better organization",
        description: "Extra information shown when completing the input",
      }),
    },
  };

  return (
    <AdminLayout>
      <Box p={2}>
        <Breadcrumbs separator=">" aria-label="breadcrumb">
          <Link style={{ color: "inherit" }} to="/">
            <FormattedMessage
              id="routes.protected.dashboard"
              defaultMessage="Dashboard"
              description="Dashboard menu breadcrumbs title"
            />
          </Link>
          <Link style={{ color: "inherit" }} to="/recipients">
            <FormattedMessage
              id="routes.protected.earners"
              defaultMessage="Recipients"
              description="Recipients menu link and breadcrumbs title"
            />
          </Link>
          <Typography color="textPrimary">
            {isCreatingEarner ? (
              <FormattedMessage
                id="manage-earner.add-earner-title"
                defaultMessage="Add Recipient"
                description="Title shown in breadcrumbs when adding an recipient"
              />
            ) : (
              <FormattedMessage
                id="manage-earner.edit-earner-title"
                defaultMessage="Edit Recipient"
                description="Title shown in breadcrumbs when editing an recipient"
              />
            )}
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box p={2}>
        {!isCreatingEarner && loadingEarner ? (
          <Box display="grid" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          <Paper>
            <Box p={2}>
              <ErrorsAlert errors={errorsEarner} />
              <ErrorsAlert errors={errorsSaving} />
              <ErrorsAlert errors={errorsTags} />
            </Box>
            <Box p={2}>
              <ValidatorForm onSubmit={handleSubmit} ref={earnerForm}>
                <Box display="flex" flexDirection="column">
                  <Box>
                    <TextValidator
                      onChange={handleFormChange}
                      value={earner.externalId}
                      variant="outlined"
                      label={inputs.externalId.label}
                      name="externalId"
                      fullWidth
                      placeholder={inputs.externalId.placeholder}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Box>
                  <Box mt={3}>
                    <TextValidator
                      onChange={handleFormChange}
                      value={earner.name}
                      variant="outlined"
                      label={inputs.name.label}
                      name="name"
                      fullWidth
                      placeholder={inputs.name.placeholder}
                      validators={["required"]}
                      errorMessages={[validationMessages.required]}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Box>
                  <Box mt={3}>
                    <TextValidator
                      onChange={handleFormChange}
                      value={earner.email}
                      variant="outlined"
                      label={inputs.email.label}
                      name="email"
                      fullWidth
                      placeholder={inputs.email.placeholder}
                      validators={["required", "isEmail"]}
                      errorMessages={[
                        validationMessages.required,
                        validationMessages.isEmail,
                      ]}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Box>
                  <Box mt={3}>
                    <Autocomplete
                      value={earner.tagNames || []}
                      onChange={handleTagsChange}
                      options={tags}
                      loading={loadingTags}
                      fullWidth
                      multiple
                      freeSolo
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label={inputs.tags.label}
                          name="tags"
                          placeholder={inputs.tags.placeholder}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{ ...params.inputProps, maxLength: 63 }}
                        />
                      )}
                    />
                  </Box>
                </Box>
                {!loadingEarner && (
                  <Box
                    mt={3}
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <Button variant="text" onClick={handleCancelClick}>
                      <FormattedMessage
                        id="manage-earner.cancel-button"
                        defaultMessage="Cancel"
                        description="Cancel button in form"
                      />
                    </Button>
                    <LoadingButton
                      loading={
                        saving && !saveAndAddAnother ? "true" : undefined
                      }
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      {isCreatingEarner ? (
                        <FormattedMessage
                          id="manage-earner.add-button"
                          defaultMessage="Add Recipient"
                          description="Add button in form"
                          noWrap
                        />
                      ) : (
                        <FormattedMessage
                          id="manage-earner.edit-button"
                          defaultMessage="Edit"
                          description="Edit button in form"
                          noWrap
                        />
                      )}
                    </LoadingButton>
                  </Box>
                )}
                {isCreatingEarner && !loadingEarner && (
                  <Box
                    mt={1}
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-end"
                  >
                    <LoadingButton
                      loading={saving && saveAndAddAnother ? "true" : undefined}
                      variant="text"
                      onClick={handleAddAnotherClick}
                    >
                      <Typography variant="button" noWrap>
                        <FormattedMessage
                          id="manage-earner.add-another-button"
                          defaultMessage="Save and add another"
                          description="Save and add another button in form"
                        />
                      </Typography>
                    </LoadingButton>
                  </Box>
                )}
              </ValidatorForm>
            </Box>
          </Paper>
        )}
      </Box>
    </AdminLayout>
  );
};

export default ManageEarner;
