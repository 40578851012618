import { Box } from "@material-ui/core";
import React from "react";
import Download from "./Download";

const DownloadDeck = (props) => {
  if (!props.downloads || !props.downloads.length) {
    return <></>;
  }

  return (
    <Box style={{ position: "fixed", left: "5px", bottom: "5px" }}>
      {props.downloads.map((download) => (
        <Download key={download.filename} download={download} />
      ))}
    </Box>
  );
};

export default DownloadDeck;
