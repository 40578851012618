const terms = {
  es: (
    <>
      <p>Última actualización: 05 de Abril de 2022</p>
      <p>
        1. <b>Introducción</b>
      </p>
      <p>
        Bienvenido a <b>Trusted Badges</b> (“Compañía”, “nosotros”,
        “nuestro/a”)!
      </p>
      <p>
        Estos términos de servicio (“Términos”, “Términos y condiciones”,
        “Términos de servicio”) gobiernan el uso de nuestro website alojado en{" "}
        <b>app.trustedbadges.com</b> (juntos o individualmente “Servicio”)
        operado por <b>Trusted Badges</b>.
      </p>
      <p>
        Nuestra{" "}
        <a href="/privacy-policy" target="_blank">
          Política de privacidad
        </a>{" "}
        también gobierna el uso de nuestro Servicio y explica cómo recolectamos,
        almacenamos y divulgamos la información resultante del uso de nuestro
        website.
      </p>
      <p>
        Su acuerdo con nosotros incluye estos Términos y nuestra Política de
        privacidad (“Acuerdos”). Usted reconoce haber leído y comprendido los
        Acuerdos, y acepta estar obligado por ellos.
      </p>
      <p>
        Si no está de acuerdo con (o no puede cumplir con) los Acuerdos,
        entonces usted no puede usar el Servicio, pero infórmenos enviando un
        correo electrónico a <b>info@trustedbadges.com</b> para que podamos
        intentar encontrar una solución. Estos Términos se aplican a todos los
        visitantes, usuarios y otras personas que deseen acceder o utilizar el
        Servicio.
      </p>
      <p>
        2. <b>Comunicaciones</b>
      </p>
      <p>
        Al utilizar nuestro Servicio, acepta suscribirse a boletines, materiales
        promocionales o de marketing y otra información que podamos enviar. Sin
        embargo, puede optar por no recibir ninguna o todas estas comunicaciones
        de nuestra parte siguiendo el enlace para cancelar la suscripción o
        enviando un correo electrónico a info@trustedbadges.com.
      </p>

      <p>
        3. <b>Concursos, sorteos y promociones</b>
      </p>
      <p>
        Cualquier concurso, sorteo u otra promoción (colectivamente,
        “Promociones”) disponibles a través del Servicio pueden estar gobernadas
        por reglas que estén separadas de estos Términos de servicio. Si usted
        participa en cualquiera de estas Promociones, por favor revise las
        reglas aplicables así como también nuestra Política de Privacidad. Si
        las reglas de una Promoción entran en conflictos con estos Términos de
        serivicio, las reglas de la Promoción serán las aplicables.
      </p>

      <p>
        4. <b>Contenido</b>
      </p>
      <p>
        El contenido encontrado en o a través de este Servicio es propiedad de
        Trusted Badges o usado con su permiso. Usted no puede distribuir,
        modificar, transmitir, reusar, descargar, republicar, copiar, o usar
        dicho contenido, ni parcial ni completamente, para propósitos
        comerciales o beneficio propio, sin nuestro expreso permiso escrito por
        adelantado.
      </p>
      <p>
        5. <b>Usos prohibidos</b>
      </p>
      <p>
        Usted puede utilizar el Servicio solo para fines lícitos y de acuerdo
        con estos términos. Usted acepta no utilizar el Servicio:
      </p>
      <p>
        0.1. En alguna forma que infrinja cualquier ley o regulación nacional o
        internacional.
      </p>
      <p>
        0.2. Con el propósito de explotar, dañar, o intentando explotar o dañar
        menores de cualquier manera exponiendolos a contenido inapropiado de
        cualquier otro modo.
      </p>
      <p>
        0.3. Para transmitir, o procurar el envío de cualquier material
        publicitario o promocional, incluyendo cualquier “correo basura”,
        ”cadena de cartas,” “spam,” o cualquier otra solicitud similar.
      </p>
      <p>
        0.4. Para hacerse pasar por o intentar hacerse pasar por la Compañía, un
        empleado de la Compañía, otro usuario, o cualquier otra persona o
        entidad.
      </p>
      <p>
        0.5. En alguna forma que infrinja los derechos de otros, o en alguna
        forma que sea ilegal, amenazante, fraudulenta, o dañina, o en conexión
        con cualquier propósito ilegítimo, ilegal, fraudulento, o dañino.
      </p>
      <p>
        0.6. Para participar en cualquier conducta que restrinja o inhiba el uso
        o disfrute del Servicio de otra persona, o la cual, tanto sea
        determinado por nosotros, pueda dañar u ofender a la Compañía o usuarios
        del Serivcio o los exponga a algún riesgo.
      </p>
      <p>Adicionalmente, usted acepta no:</p>
      <p>
        0.1. Usar el Serivicio de alguna manera que pueda deshabilitar,
        sobrecargar, dañar, o perjudicar el Servicio o interferir el uso del
        Serivicio a otra parte, incluyendo su capacidad de participiar de
        actividades en tiempo real a través del Servicio.
      </p>
      <p>
        0.2. Usar cualquier robot, araña, o cualquier otro dispositivo
        automático, proceso, o medios para acceder al Servicio cualquiera sea el
        propósito, incluidos el monitoreo o copia de cualquier material en el
        Servicio.
      </p>
      <p>
        0.3. Usar cualquier proceso manual para monitorizar o copiar cualquier
        material en el Servicio, o para cualquier otro propósito desautorizado
        sin nuestro consentimiento escrito previo.
      </p>
      <p>
        0.4. Usar cualquier dispositivo, software, o programa que interfiera con
        el correcto funcionamiento del Servicio.
      </p>
      <p>
        0.5. Introducir cualquier virus, caballo de troya, gusano, bomba lógica,
        u otro material que sea malicioso o tecnologicamente dañino.
      </p>
      <p>
        0.6. Intentar obtener acceso no autorizado a, interferir con, dañar, o
        interrumpir cualquier parte del Servicio, el servidor en el cual el
        Servicio es almacenado, o cualquier servidor, computadora, o base de
        datos conectada al Servicio.
      </p>
      <p>
        0.7. Atacar el Servicio a través de un ataque de denegación de servicio
        (DoS) o un ataque de denegación de servicio distribuído (DDos).
      </p>
      <p>
        0.8. Tomar cualquier acción que pueda dañar o falsear la reputación de
        la Compañía.
      </p>
      <p>
        0.9. Cualquier otra forma de intentar interferir con el correcto
        funcionamiento del Servicio.
      </p>
      <p>
        6. <b>Analítica</b>
      </p>
      <p>
        Podríamos usar proveedores de servicios tercerizados para monitorizar y
        analizar el uso de nuestro Servicio.
      </p>
      <p>
        7. <b>Prohibido el uso por menores</b>
      </p>
      <p>
        El Servicio está destinado únicamente para el acceso y uso de personas
        de al menos trece (13) años. Accediento o usando el Servicio, usted
        garantiza y representa que tiene al menos trece (13) años de edad y con
        plena autoridad, derecho y capacidad para celebrar este acuerdo y
        cumplir con todos los términos y condiciones de los Términos. Si no
        tiene al menos trece (13) años, tiene prohibido tanto el acceso como uso
        del Servicio
      </p>
      <p>
        8. <b>Cuentas</b>
      </p>
      <p>
        Cuando crea una cuenta con nosotros, garantiza que tiene más de 13 años
        y que la información que nos proporciona es precisa, completa y actual
        en todo momento. La información inexacta, incompleta u obsoleta puede
        resultar en la terminación inmediata de su cuenta en el Servicio.
      </p>
      <p>
        Usted es responsable de mantener la confidencialidad de su cuenta y
        contraseña, lo que incluye, entre otros, la restricción de acceso a su
        computadora y/o cuenta. Usted acepta la responsabilidad por todas y cada
        una de las actividades o acciones que ocurran bajo su cuenta y/o
        contraseña, ya sea que su contraseña sea con nuestro Servicio o con un
        servicio de terceros. Debe notificarnos inmediatamente al darse cuenta
        de cualquier violación de seguridad o uso no autorizado de su cuenta.
      </p>
      <p>
        No puede usar como nombre de usuario el nombre de otra persona o entidad
        o que no esté legalmente disponible para su uso, un nombre o marca
        comercial que esté sujeto a cualquier derecho de otra persona o entidad
        que no sea usted, sin la autorización correspondiente. No puede usar
        como nombre de usuario ningún nombre que sea ofensivo, vulgar u obsceno.
      </p>
      <p>
        Nos reservamos el derecho de rechazar el servicio, cancelar cuentas,
        eliminar o editar contenido o cancelar pedidos a nuestro exclusivo
        criterio.
      </p>
      <p>
        9. <b>Propiedad intelectual</b>
      </p>
      <p>
        El servicio y su contenido original (excluyendo el Contenido
        proporcionado por los usuarios), las características y la funcionalidad
        son y seguirán siendo propiedad exclusiva de Trusted Badges y sus
        licenciantes. El servicio está protegido por derechos de autor, marcas
        registradas y otras leyes de países extranjeros. Nuestras marcas
        comerciales no se pueden utilizar en relación con ningún producto o
        servicio sin el consentimiento previo por escrito de Trusted Badges.
      </p>
      <p>
        10. <b>Política de derechos de autor</b>
      </p>
      <p>
        Respetamos los derechos de propiedad intelectual de otros. Es nuestra
        política responder a cualquier reclamo de que el Contenido publicado en
        el Servicio infringe los derechos de autor u otros derechos de propiedad
        intelectual ("Infracción") de cualquier persona o entidad.
      </p>
      <p>
        Si es propietario de derechos de autor, o está autorizado en nombre de
        uno, y cree que el trabajo protegido por derechos de autor se ha copiado
        de una manera que constituye una infracción de derechos de autor, envíe
        su reclamo por correo electrónico a info@trustedbadges.com, con el
        asunto: "Infracción de derechos de autor" e incluya en su reclamación
        una descripción detallada de la supuesta infracción como se detalla a
        continuación, en "Aviso y procedimiento de la DMCA para reclamaciones
        por infracción de derechos de autor"
      </p>
      <p>
        Usted puede ser responsable de los daños (incluidos los costos y los
        honorarios de los abogados) por declaraciones falsas o reclamos de mala
        fe sobre la infracción de cualquier Contenido que se encuentre en y/o a
        través del Servicio de sus derechos de autor.
      </p>
      <p>
        11.
        <b>
          Aviso y procedimiento de la DMCA para reclamaciones por infracción de
          derechos de autor
        </b>
      </p>
      <p>
        Puede enviar una notificación de conformidad con la Digital Millennium
        Copyright Act (DMCA) proporcionando a nuestro Agente de derechos de
        autor la siguiente información por escrito (consulte 17 U.S.C 512(c)(3)
        para obtener más detalles):
      </p>
      <p>
        0.1. una firma electrónica o física de la persona autorizada para actuar
        en nombre del propietario de los derechos de autor;
      </p>
      <p>
        0.2. una descripción del trabajo protegido por derechos de autor que
        afirma que se ha infringido, incluida la URL (es decir, la dirección de
        la página web) de la ubicación donde existe el trabajo protegido por
        derechos de autor o una copia del trabajo protegido por derechos de
        autor;
      </p>
      <p>
        0.3. identificación de la URL u otra ubicación específica en el Servicio
        donde se encuentra el material que usted afirma que está infringiendo;
      </p>
      <p>
        0.4. su dirección, número de teléfono y dirección de correo electrónico;
      </p>
      <p>
        0.5. una declaración suya de que cree de buena fe que el uso en disputa
        no está autorizado por el propietario de los derechos de autor, su
        agente o la ley;
      </p>
      <p>
        Puede comunicarse con nuestro agente de derechos de autor por correo
        electrónico a info@trustedbadges.com.
      </p>
      <p>
        12. <b>Informes de errores y comentarios</b>
      </p>
      <p>
        Puede proporcionarnos directamente a info@trustedbadges.com o a través
        de sitios y herramientas de terceros información y comentarios sobre
        errores, sugerencias de mejoras, ideas, problemas, quejas y otros
        asuntos relacionados con nuestro Servicio ("Comentarios"). Usted
        reconoce y acepta que: (i) no conservará, adquirirá ni hará valer ningún
        derecho de propiedad intelectual u otro derecho, título o interés en los
        Comentarios; (ii) La Compañía puede tener ideas de desarrollo similares
        a los Comentarios; (iii) Los comentarios no contienen información
        confidencial o información de propiedad de usted o de un tercero; y (iv)
        La Compañía no tiene ninguna obligación de confidencialidad con respecto
        a los Comentarios. En caso de que la transferencia de la propiedad de
        los Comentarios no sea posible debido a las leyes obligatorias
        aplicables, usted otorga a la Compañía y sus afiliados un derecho de uso
        exclusivo, transferible, irrevocable, gratuito, sublicenciable,
        ilimitado y perpetuo (incluyendo copiar, modificar, crear trabajos
        derivados, publicar, distribuir y comercializar) de los Comentarios de
        cualquier manera y para cualquier propósito.
      </p>
      <p>
        13. <b>Enlaces a otros sitios web</b>
      </p>
      <p>
        Nuestro Servicio puede contener enlaces a sitios web o servicios de
        terceros que Trusted Badges no posee ni controla.
      </p>
      <p>
        Trusted Badges no tiene control ni asume ninguna responsabilidad por el
        contenido, las políticas de privacidad o las prácticas de los sitios web
        o servicios de terceros. No garantizamos las ofertas de ninguna de estas
        entidades/individuos o sus sitios web.
      </p>
      <p>
        USTED RECONOCE Y ACEPTA QUE LA COMPAÑÍA NO SERÁ RESPONSABLE NI
        RESPONSABLE, DIRECTA O INDIRECTAMENTE, POR CUALQUIER DAÑO O PÉRDIDA
        CAUSADA O ALEGADA COMO CAUSADA POR O EN RELACIÓN CON EL USO O LA
        DEPENDENCIA DE CUALQUIER CONTENIDO, BIENES O SERVICIOS DISPONIBLES EN O
        A TRAVÉS DE CUALQUIER DICHOS SITIOS WEB O SERVICIOS DE TERCEROS.
      </p>
      <p>
        LE RECOMENDAMOS ENCARECIDAMENTE QUE LEA LOS TÉRMINOS DE SERVICIO Y LAS
        POLÍTICAS DE PRIVACIDAD DE CUALQUIER SITIO WEB O SERVICIOS DE TERCEROS
        QUE USTED VISITE.
      </p>
      <p>
        14. <b>Renuncia de garantía</b>
      </p>
      <p>
        ESTOS SERVICIOS SON PROPORCIONADOS POR LA EMPRESA "TAL CUAL" Y "SEGÚN
        DISPONIBILIDAD". LA COMPAÑÍA NO HACE REPRESENTACIONES NI GARANTÍAS DE
        NINGÚN TIPO, EXPRESAS O IMPLÍCITAS, EN CUANTO AL FUNCIONAMIENTO DE SUS
        SERVICIOS, O LA INFORMACIÓN, CONTENIDO O MATERIALES INCLUIDOS EN ELLOS.
        USTED ACEPTA EXPRESAMENTE QUE EL USO DE ESTOS SERVICIOS, SU CONTENIDO Y
        CUALQUIER SERVICIO O ARTÍCULO OBTENIDO DE NOSOTROS ES BAJO SU PROPIO
        RIESGO.
      </p>
      <p>
        NI LA COMPAÑÍA NI NINGUNA PERSONA ASOCIADA CON LA COMPAÑÍA OFRECE
        NINGUNA GARANTÍA O REPRESENTACIÓN CON RESPECTO A LA INTEGRIDAD,
        SEGURIDAD, FIABILIDAD, CALIDAD, EXACTITUD O DISPONIBILIDAD DE LOS
        SERVICIOS. SIN LIMITAR LO ANTERIOR, NI LA COMPAÑÍA NI NINGUNA PERSONA
        ASOCIADA CON LA COMPAÑÍA REPRESENTA O GARANTIZA QUE LOS SERVICIOS, SU
        CONTENIDO O CUALQUIER SERVICIO O ARTÍCULO OBTENIDO A TRAVÉS DE LOS
        SERVICIOS SERÁ EXACTO, CONFIABLE, LIBRE DE ERRORES O ININTERRUMPIDO, QUE
        LOS DEFECTOS SERÁN CORREGIDOS, QUE LOS SERVICIOS O EL SERVIDOR QUE LO
        HACE DISPONIBLE ESTÁN LIBRES DE VIRUS U OTROS COMPONENTES DAÑINOS O QUE
        LOS SERVICIOS O CUALQUIER SERVICIO O ARTÍCULO OBTENIDO A TRAVÉS DE LOS
        SERVICIOS CUMPLIRÁN SUS NECESIDADES O EXPECTATIVAS.
      </p>
      <p>
        POR EL PRESENTE, LA COMPAÑÍA RENUNCIA A TODAS LAS GARANTÍAS DE CUALQUIER
        TIPO, YA SEA EXPLÍCITA O IMPLÍCITA, LEGAL O DE OTRO TIPO, INCLUYENDO,
        ENTRE OTRAS, CUALQUIER GARANTÍA DE COMERCIABILIDAD, NO INFRACCIÓN E
        IDONEIDAD PARA UN FIN PARTICULAR.
      </p>
      <p>
        LO ANTERIOR NO AFECTA LAS GARANTÍAS QUE NO PUEDEN SER EXCLUIDAS O
        LIMITADAS BAJO LA LEY APLICABLE.
      </p>
      <p>
        15. <b>Limitación de responsabilidad</b>
      </p>
      <p>
        A EXCEPCIÓN DE LO PROHIBIDO POR LA LEY, USTED NOS EXONERARÁ DE
        RESPONSABILIDAD A NOSOTROS Y A NUESTROS FUNCIONARIOS, DIRECTORES,
        EMPLEADOS Y AGENTES POR CUALQUIER DAÑO INDIRECTO, PUNITIVO, ESPECIAL,
        INCIDENTAL O CONSECUENTE, SIN EMBARGO QUE SURJA (INCLUYENDO LOS
        HONORARIOS DE LOS ABOGADOS Y TODOS LOS COSTOS Y GASTOS RELACIONADOS CON
        EL LITIGIO Y EL ARBITRAJE, O EN EL JUICIO O EN LA APELACIÓN, EN SU CASO,
        SE INSTITUYA O NO EL LITIGIO O EL ARBITRAJE), YA SEA EN UNA ACCIÓN DE
        CONTRATO, NEGLIGENCIA U OTRA ACCIÓN DE AGRAVIO, O QUE SURJA DE O EN
        RELACIÓN CON ESTE ACUERDO, INCLUYENDO SIN LIMITACIÓN CUALQUIER RECLAMO
        POR LESIONES PERSONALES O DAÑOS A LA PROPIEDAD, QUE SURJA DE ESTE
        ACUERDO Y CUALQUIER VIOLACIÓN POR PARTE DE USTED DE CUALQUIER LEY,
        ESTATUTO, NORMA O REGLAMENTO FEDERAL, ESTATAL O LOCAL REGULACIONES,
        INCLUSO SI LA EMPRESA HA SIDO PREVIAMENTE ADVERTIDA DE LA POSIBILIDAD DE
        TALES DAÑOS. SALVO LO PROHIBIDO POR LA LEY, SI EXISTE RESPONSABILIDAD
        POR PARTE DE LA COMPAÑÍA, ESTA SERÁ LIMITADA A LA CANTIDAD PAGADA POR
        LOS PRODUCTOS Y/O SERVICIOS, Y BAJO NINGUNA CIRCUNSTANCIA HABRÁ DAÑOS
        CONSECUENTES O PUNITIVOS. ALGUNOS ESTADOS NO PERMITEN LA EXCLUSIÓN O
        LIMITACIÓN DE DAÑOS PUNITIVOS, INCIDENTALES O CONSECUENTES, POR LO QUE
        LA LIMITACIÓN O EXCLUSIÓN ANTERIOR PUEDE NO SER APLICABLE EN SU CASO.
      </p>
      <p>
        16. <b>Terminación</b>
      </p>
      <p>
        Podemos rescindir o suspender su cuenta y prohibir el acceso al Servicio
        de inmediato, sin previo aviso ni responsabilidad, a nuestro exclusivo
        criterio, por cualquier motivo y sin limitación, incluido, entre otros,
        el incumplimiento de los Términos.
      </p>
      <p>
        Si desea cancelar su cuenta, simplemente puede dejar de usar el
        Servicio.
      </p>
      <p>
        Todas las disposiciones de los Términos que, por su naturaleza, deban
        sobrevivir a la terminación sobrevivirán a la terminación, incluidas,
        entre otras, las disposiciones de propiedad, las exenciones de garantía,
        la indemnización y las limitaciones de responsabilidad.
      </p>
      <p>
        17. <b>Ley aplicable</b>
      </p>
      <p>
        Estos Términos se regirán e interpretarán de conformidad con las leyes
        de Argentina, cuya ley aplicable se aplica al acuerdo sin tener en
        cuenta sus disposiciones sobre conflicto de leyes.
      </p>
      <p>
        Nuestra incapacidad para hacer cumplir cualquier derecho o disposición
        de estos Términos no se considerará una renuncia a esos derechos. Si
        alguna disposición de estos Términos se considera inválida o inaplicable
        por un tribunal, las disposiciones restantes de estos Términos
        permanecerán en vigor. Estos Términos constituyen el acuerdo completo
        entre nosotros con respecto a nuestro Servicio y sustituyen y reemplazan
        cualquier acuerdo anterior que hayamos tenido entre nosotros con
        respecto al Servicio.
      </p>
      <p>
        18. <b>Cambios en el servicio</b>
      </p>
      <p>
        Nos reservamos el derecho de retirar o modificar nuestro Servicio, y
        cualquier servicio o material que proporcionemos a través del Servicio,
        a nuestro exclusivo criterio y sin previo aviso. No seremos responsables
        si, por algún motivo, la totalidad o parte del Servicio no está
        disponible en algún momento o durante algún período. De vez en cuando,
        podemos restringir el acceso a algunas partes del Servicio, o al
        Servicio completo, a los usuarios, incluidos los usuarios registrados.
      </p>
      <p>
        19. <b>Modificaciones a los términos</b>
      </p>
      <p>
        Podemos modificar los Términos en cualquier momento mediante la
        publicación de los términos modificados en este sitio. Es su
        responsabilidad revisar estos Términos periódicamente.
      </p>
      <p>
        Su uso continuado de la Plataforma luego de la publicación de los
        Términos revisados significa que acepta y está de acuerdo con los
        cambios. Se espera que revise esta página con frecuencia para estar al
        tanto de cualquier cambio, ya que son vinculantes para usted.
      </p>
      <p>
        Al continuar accediendo o utilizando nuestro Servicio después de que las
        revisiones entren en vigencia, usted acepta estar sujeto a los términos
        revisados. Si no está de acuerdo con los nuevos términos, ya no está
        autorizado a utilizar el Servicio.
      </p>
      <p>
        20. <b>Renuncia y separabilidad</b>
      </p>
      <p>
        Ninguna renuncia por parte de la Compañía a cualquier término o
        condición establecida en los Términos se considerará una renuncia
        adicional o continua de dicho término o condición o una renuncia a
        cualquier otro término o condición, y cualquier falla de la Compañía
        para hacer valer un derecho o disposición bajo los Términos se
        considerará no constituye una renuncia a tal derecho o disposición.
      </p>
      <p>
        Si un tribunal u otro tribunal de jurisdicción competente considera que
        alguna disposición de los Términos es inválida, ilegal o inaplicable por
        cualquier motivo, dicha disposición se eliminará o limitará en la medida
        mínima de modo que las disposiciones restantes de los Términos continúen
        en pleno vigor y efecto.
      </p>
      <p>
        21. <b>Reconocimiento</b>
      </p>
      <p>
        MEDIANTE EL USO DEL SERVICIO O CUALQUIER OTRO SERVICIO PROVISTO POR
        NOSOTROS, USTED RECONOCE HABER LEÍDO ESTOS TÉRMINOS DE SERVICIO Y ACEPTA
        ESTAR OBLIGADO POR ELLOS.
      </p>
      <p>
        22. <b>Contáctenos</b>
      </p>
      <p>
        Por favor envíe su retroalimentación, comentarios o solicitudes de
        soporte técnico por email a: <b>info@trustedbadges.com</b>.
      </p>
    </>
  ),
  en: (
    <>
      <p>Last updated: 2022-04-05</p>
      <p>
        1. <b>Introduction</b>
      </p>
      <p>
        Welcome to <b>Trusted Badges</b> (“Company”, “we”, “our”, “us”)!
      </p>
      <p>
        These Terms of Service (“Terms”, “Terms of Service”) govern your use of
        our website located at <b>app.trustedbadges.com</b> (together or
        individually “Service”) operated by <b>Trusted Badges</b>.
      </p>
      <p>
        Our
        <a href="/privacy-policy" target="_blank">
          Privacy Policy
        </a>
        also governs your use of our Service and explains how we collect,
        safeguard and disclose information that results from your use of our web
        pages.
      </p>
      <p>
        Your agreement with us includes these Terms and our Privacy Policy
        (“Agreements”). You acknowledge that you have read and understood
        Agreements, and agree to be bound of them.
      </p>
      <p>
        If you do not agree with (or cannot comply with) Agreements, then you
        may not use the Service, but please let us know by emailing at
        <b>info@trustedbadges.com</b> so we can try to find a solution. These
        Terms apply to all visitors, users and others who wish to access or use
        Service.
      </p>
      <p>
        2. <b>Communications</b>
      </p>
      <p>
        By using our Service, you agree to subscribe to newsletters, marketing
        or promotional materials and other information we may send. However, you
        may opt out of receiving any, or all, of these communications from us by
        following the unsubscribe link or by emailing at info@trustedbadges.com.
      </p>

      <p>
        3. <b>Contests, Sweepstakes and Promotions</b>
      </p>
      <p>
        Any contests, sweepstakes or other promotions (collectively,
        “Promotions”) made available through Service may be governed by rules
        that are separate from these Terms of Service. If you participate in any
        Promotions, please review the applicable rules as well as our Privacy
        Policy. If the rules for a Promotion conflict with these Terms of
        Service, Promotion rules will apply.
      </p>

      <p>
        4. <b>Content</b>
      </p>
      <p>
        Content found on or through this Service are the property of Trusted
        Badges or used with permission. You may not distribute, modify,
        transmit, reuse, download, repost, copy, or use said Content, whether in
        whole or in part, for commercial purposes or for personal gain, without
        express advance written permission from us.
      </p>
      <p>
        5. <b>Prohibited Uses</b>
      </p>
      <p>
        You may use Service only for lawful purposes and in accordance with
        Terms. You agree not to use Service:
      </p>
      <p>
        0.1. In any way that violates any applicable national or international
        law or regulation.
      </p>
      <p>
        0.2. For the purpose of exploiting, harming, or attempting to exploit or
        harm minors in any way by exposing them to inappropriate content or
        otherwise.
      </p>
      <p>
        0.3. To transmit, or procure the sending of, any advertising or
        promotional material, including any “junk mail”, “chain letter,” “spam,”
        or any other similar solicitation.
      </p>
      <p>
        0.4. To impersonate or attempt to impersonate Company, a Company
        employee, another user, or any other person or entity.
      </p>
      <p>
        0.5. In any way that infringes upon the rights of others, or in any way
        is illegal, threatening, fraudulent, or harmful, or in connection with
        any unlawful, illegal, fraudulent, or harmful purpose or activity.
      </p>
      <p>
        0.6. To engage in any other conduct that restricts or inhibits anyone’s
        use or enjoyment of Service, or which, as determined by us, may harm or
        offend Company or users of Service or expose them to liability.
      </p>
      <p>Additionally, you agree not to:</p>
      <p>
        0.1. Use Service in any manner that could disable, overburden, damage,
        or impair Service or interfere with any other party’s use of Service,
        including their ability to engage in real time activities through
        Service.
      </p>
      <p>
        0.2. Use any robot, spider, or other automatic device, process, or means
        to access Service for any purpose, including monitoring or copying any
        of the material on Service.
      </p>
      <p>
        0.3. Use any manual process to monitor or copy any of the material on
        Service or for any other unauthorized purpose without our prior written
        consent.
      </p>
      <p>
        0.4. Use any device, software, or routine that interferes with the
        proper working of Service.
      </p>
      <p>
        0.5. Introduce any viruses, trojan horses, worms, logic bombs, or other
        material which is malicious or technologically harmful.
      </p>
      <p>
        0.6. Attempt to gain unauthorized access to, interfere with, damage, or
        disrupt any parts of Service, the server on which Service is stored, or
        any server, computer, or database connected to Service.
      </p>
      <p>
        0.7. Attack Service via a denial-of-service attack or a distributed
        denial-of-service attack.
      </p>
      <p>0.8. Take any action that may damage or falsify Company rating.</p>
      <p>
        0.9. Otherwise attempt to interfere with the proper working of Service.
      </p>
      <p>
        6. <b>Analytics</b>
      </p>
      <p>
        We may use third-party Service Providers to monitor and analyze the use
        of our Service.
      </p>
      <p>
        7. <b>No Use By Minors</b>
      </p>
      <p>
        Service is intended only for access and use by individuals at least
        thirteen (13) years old. By accessing or using Service, you warrant and
        represent that you are at least thirteen (13) years of age and with the
        full authority, right, and capacity to enter into this agreement and
        abide by all of the terms and conditions of Terms. If you are not at
        least thirteen (13) years old, you are prohibited from both the access
        and usage of Service.
      </p>
      <p>
        8. <b>Accounts</b>
      </p>
      <p>
        When you create an account with us, you guarantee that you are above the
        age of 13, and that the information you provide us is accurate,
        complete, and current at all times. Inaccurate, incomplete, or obsolete
        information may result in the immediate termination of your account on
        Service.
      </p>
      <p>
        You are responsible for maintaining the confidentiality of your account
        and password, including but not limited to the restriction of access to
        your computer and/or account. You agree to accept responsibility for any
        and all activities or actions that occur under your account and/or
        password, whether your password is with our Service or a third-party
        service. You must notify us immediately upon becoming aware of any
        breach of security or unauthorized use of your account.
      </p>
      <p>
        You may not use as a username the name of another person or entity or
        that is not lawfully available for use, a name or trademark that is
        subject to any rights of another person or entity other than you,
        without appropriate authorization. You may not use as a username any
        name that is offensive, vulgar or obscene.
      </p>
      <p>
        We reserve the right to refuse service, terminate accounts, remove or
        edit content, or cancel orders in our sole discretion.
      </p>
      <p>
        9. <b>Intellectual Property</b>
      </p>
      <p>
        Service and its original content (excluding Content provided by users),
        features and functionality are and will remain the exclusive property of
        Trusted Badges and its licensors. Service is protected by copyright,
        trademark, and other laws of and foreign countries. Our trademarks may
        not be used in connection with any product or service without the prior
        written consent of Trusted Badges.
      </p>
      <p>
        10. <b>Copyright Policy</b>
      </p>
      <p>
        We respect the intellectual property rights of others. It is our policy
        to respond to any claim that Content posted on Service infringes on the
        copyright or other intellectual property rights (“Infringement”) of any
        person or entity.
      </p>
      <p>
        If you are a copyright owner, or authorized on behalf of one, and you
        believe that the copyrighted work has been copied in a way that
        constitutes copyright infringement, please submit your claim via email
        to info@trustedbadges.com, with the subject line: “Copyright
        Infringement” and include in your claim a detailed description of the
        alleged Infringement as detailed below, under “DMCA Notice and Procedure
        for Copyright Infringement Claims”
      </p>
      <p>
        You may be held accountable for damages (including costs and attorneys’
        fees) for misrepresentation or bad-faith claims on the infringement of
        any Content found on and/or through Service on your copyright.
      </p>
      <p>
        11. <b>DMCA Notice and Procedure for Copyright Infringement Claims</b>
      </p>
      <p>
        You may submit a notification pursuant to the Digital Millennium
        Copyright Act (DMCA) by providing our Copyright Agent with the following
        information in writing (see 17 U.S.C 512(c)(3) for further detail):
      </p>
      <p>
        0.1. an electronic or physical signature of the person authorized to act
        on behalf of the owner of the copyright’s interest;
      </p>
      <p>
        0.2. a description of the copyrighted work that you claim has been
        infringed, including the URL (i.e., web page address) of the location
        where the copyrighted work exists or a copy of the copyrighted work;
      </p>
      <p>
        0.3. identification of the URL or other specific location on Service
        where the material that you claim is infringing is located;
      </p>
      <p>0.4. your address, telephone number, and email address;</p>
      <p>
        0.5. a statement by you that you have a good faith belief that the
        disputed use is not authorized by the copyright owner, its agent, or the
        law;
      </p>
      <p>
        0.6. a statement by you, made under penalty of perjury, that the above
        information in your notice is accurate and that you are the copyright
        owner or authorized to act on the copyright owner’s behalf.
      </p>
      <p>
        You can contact our Copyright Agent via email at info@trustedbadges.com.
      </p>
      <p>
        12. <b>Error Reporting and Feedback</b>
      </p>
      <p>
        You may provide us either directly at info@trustedbadges.com or via
        third party sites and tools with information and feedback concerning
        errors, suggestions for improvements, ideas, problems, complaints, and
        other matters related to our Service (“Feedback”). You acknowledge and
        agree that: (i) you shall not retain, acquire or assert any intellectual
        property right or other right, title or interest in or to the Feedback;
        (ii) Company may have development ideas similar to the Feedback; (iii)
        Feedback does not contain confidential information or proprietary
        information from you or any third party; and (iv) Company is not under
        any obligation of confidentiality with respect to the Feedback. In the
        event the transfer of the ownership to the Feedback is not possible due
        to applicable mandatory laws, you grant Company and its affiliates an
        exclusive, transferable, irrevocable, free-of-charge, sub-licensable,
        unlimited and perpetual right to use (including copy, modify, create
        derivative works, publish, distribute and commercialize) Feedback in any
        manner and for any purpose.
      </p>
      <p>
        13. <b>Links To Other Web Sites</b>
      </p>
      <p>
        Our Service may contain links to third party web sites or services that
        are not owned or controlled by Trusted Badges.
      </p>
      <p>
        Trusted Badges has no control over, and assumes no responsibility for
        the content, privacy policies, or practices of any third party web sites
        or services. We do not warrant the offerings of any of these
        entities/individuals or their websites.
      </p>
      <p>
        YOU ACKNOWLEDGE AND AGREE THAT COMPANY SHALL NOT BE RESPONSIBLE OR
        LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY DAMAGE OR LOSS CAUSED OR ALLEGED
        TO BE CAUSED BY OR IN CONNECTION WITH USE OF OR RELIANCE ON ANY SUCH
        CONTENT, GOODS OR SERVICES AVAILABLE ON OR THROUGH ANY SUCH THIRD PARTY
        WEB SITES OR SERVICES.
      </p>
      <p>
        WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND PRIVACY POLICIES
        OF ANY THIRD PARTY WEB SITES OR SERVICES THAT YOU VISIT.
      </p>
      <p>
        14. <b>Disclaimer Of Warranty</b>
      </p>
      <p>
        THESE SERVICES ARE PROVIDED BY COMPANY ON AN “AS IS” AND “AS AVAILABLE”
        BASIS. COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND,
        EXPRESS OR IMPLIED, AS TO THE OPERATION OF THEIR SERVICES, OR THE
        INFORMATION, CONTENT OR MATERIALS INCLUDED THEREIN. YOU EXPRESSLY AGREE
        THAT YOUR USE OF THESE SERVICES, THEIR CONTENT, AND ANY SERVICES OR
        ITEMS OBTAINED FROM US IS AT YOUR SOLE RISK.
      </p>
      <p>
        NEITHER COMPANY NOR ANY PERSON ASSOCIATED WITH COMPANY MAKES ANY
        WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY,
        RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE SERVICES. WITHOUT
        LIMITING THE FOREGOING, NEITHER COMPANY NOR ANYONE ASSOCIATED WITH
        COMPANY REPRESENTS OR WARRANTS THAT THE SERVICES, THEIR CONTENT, OR ANY
        SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL BE ACCURATE,
        RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED,
        THAT THE SERVICES OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF
        VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE SERVICES OR ANY SERVICES
        OR ITEMS OBTAINED THROUGH THE SERVICES WILL OTHERWISE MEET YOUR NEEDS OR
        EXPECTATIONS.
      </p>
      <p>
        COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
        IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY
        WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR
        PARTICULAR PURPOSE.
      </p>
      <p>
        THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR
        LIMITED UNDER APPLICABLE LAW.
      </p>
      <p>
        15. <b>Limitation Of Liability</b>
      </p>
      <p>
        EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR OFFICERS,
        DIRECTORS, EMPLOYEES, AND AGENTS HARMLESS FOR ANY INDIRECT, PUNITIVE,
        SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGE, HOWEVER IT ARISES
        (INCLUDING ATTORNEYS’ FEES AND ALL RELATED COSTS AND EXPENSES OF
        LITIGATION AND ARBITRATION, OR AT TRIAL OR ON APPEAL, IF ANY, WHETHER OR
        NOT LITIGATION OR ARBITRATION IS INSTITUTED), WHETHER IN AN ACTION OF
        CONTRACT, NEGLIGENCE, OR OTHER TORTIOUS ACTION, OR ARISING OUT OF OR IN
        CONNECTION WITH THIS AGREEMENT, INCLUDING WITHOUT LIMITATION ANY CLAIM
        FOR PERSONAL INJURY OR PROPERTY DAMAGE, ARISING FROM THIS AGREEMENT AND
        ANY VIOLATION BY YOU OF ANY FEDERAL, STATE, OR LOCAL LAWS, STATUTES,
        RULES, OR REGULATIONS, EVEN IF COMPANY HAS BEEN PREVIOUSLY ADVISED OF
        THE POSSIBILITY OF SUCH DAMAGE. EXCEPT AS PROHIBITED BY LAW, IF THERE IS
        LIABILITY FOUND ON THE PART OF COMPANY, IT WILL BE LIMITED TO THE AMOUNT
        PAID FOR THE PRODUCTS AND/OR SERVICES, AND UNDER NO CIRCUMSTANCES WILL
        THERE BE CONSEQUENTIAL OR PUNITIVE DAMAGES. SOME STATES DO NOT ALLOW THE
        EXCLUSION OR LIMITATION OF PUNITIVE, INCIDENTAL OR CONSEQUENTIAL
        DAMAGES, SO THE PRIOR LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.
      </p>
      <p>
        16. <b>Termination</b>
      </p>
      <p>
        We may terminate or suspend your account and bar access to Service
        immediately, without prior notice or liability, under our sole
        discretion, for any reason whatsoever and without limitation, including
        but not limited to a breach of Terms.
      </p>
      <p>
        If you wish to terminate your account, you may simply discontinue using
        Service.
      </p>
      <p>
        All provisions of Terms which by their nature should survive termination
        shall survive termination, including, without limitation, ownership
        provisions, warranty disclaimers, indemnity and limitations of
        liability.
      </p>
      <p>
        17. <b>Governing Law</b>
      </p>
      <p>
        These Terms shall be governed and construed in accordance with the laws
        of Argentina, which governing law applies to agreement without regard to
        its conflict of law provisions.
      </p>
      <p>
        Our failure to enforce any right or provision of these Terms will not be
        considered a waiver of those rights. If any provision of these Terms is
        held to be invalid or unenforceable by a court, the remaining provisions
        of these Terms will remain in effect. These Terms constitute the entire
        agreement between us regarding our Service and supersede and replace any
        prior agreements we might have had between us regarding Service.
      </p>
      <p>
        18. <b>Changes To Service</b>
      </p>
      <p>
        We reserve the right to withdraw or amend our Service, and any service
        or material we provide via Service, in our sole discretion without
        notice. We will not be liable if for any reason all or any part of
        Service is unavailable at any time or for any period. From time to time,
        we may restrict access to some parts of Service, or the entire Service,
        to users, including registered users.
      </p>
      <p>
        19. <b>Amendments To Terms</b>
      </p>
      <p>
        We may amend Terms at any time by posting the amended terms on this
        site. It is your responsibility to review these Terms periodically.
      </p>
      <p>
        Your continued use of the Platform following the posting of revised
        Terms means that you accept and agree to the changes. You are expected
        to check this page frequently so you are aware of any changes, as they
        are binding on you.
      </p>
      <p>
        By continuing to access or use our Service after any revisions become
        effective, you agree to be bound by the revised terms. If you do not
        agree to the new terms, you are no longer authorized to use Service.
      </p>
      <p>
        20. <b>Waiver And Severability</b>
      </p>
      <p>
        No waiver by Company of any term or condition set forth in Terms shall
        be deemed a further or continuing waiver of such term or condition or a
        waiver of any other term or condition, and any failure of Company to
        assert a right or provision under Terms shall not constitute a waiver of
        such right or provision.
      </p>
      <p>
        If any provision of Terms is held by a court or other tribunal of
        competent jurisdiction to be invalid, illegal or unenforceable for any
        reason, such provision shall be eliminated or limited to the minimum
        extent such that the remaining provisions of Terms will continue in full
        force and effect.
      </p>
      <p>
        21. <b>Acknowledgement</b>
      </p>
      <p>
        BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE THAT
        YOU HAVE READ THESE TERMS OF SERVICE AND AGREE TO BE BOUND BY THEM.
      </p>
      <p>
        22. <b>Contact Us</b>
      </p>
      <p>
        Please send your feedback, comments, requests for technical support by
        email: <b>info@trustedbadges.com</b>.
      </p>
    </>
  ),
};

export { terms };
