import { Box, Button, Grid, Paper, Typography } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { RevokeBadgeProvider } from "../../context/RevokeBadgeProvider";
import { SendBadgesBatchContext } from "../../context/SendBadgesBatchProvider";
import usePromise from "../../hooks/usePromise";
import BatchesService from "../../services/BatchesService";
import history from "../../utils/history";
import BatchAssertions from "../Batch/BatchAssertions";
import BatchBadgeClass from "../Batch/BatchBadgeClass";
import BatchQuotation from "../Batch/BatchQuotation";
import ErrorsAlert from "../Common/ErrorsAlert";
import LoadingButton from "../Common/LoadingButton";

const SendBadgesStep3 = () => {
  const {
    batch,
    currentStep,
    handleBackStep,
    validatedIdentities,
    affordable,
    setAffordable,
  } = useContext(SendBadgesBatchContext);

  const [awardData, awardErrors, awarding, setAwardPromise] = usePromise();
  const [saveData, saveErrors, saving, setSavePromise] = usePromise();
  const intl = useIntl();

  BatchesService.setDefaultErrorMessage(
    intl.formatMessage({
      id: "errors.unexpected-error",
      defaultMessage: "An unexpected error ocurred.",
    })
  );

  const handleSave = () => {
    setSavePromise(BatchesService.saveBatch(batch.uuid));
  };

  const handleAward = () => {
    setAwardPromise(BatchesService.awardBatch(batch.uuid));
  };

  useEffect(() => {
    let data = awardData || saveData;
    if (data) {
      history.push("/batch/" + data.uuid);
    }
  }, [awardData, saveData]);

  return (
    <>
      <Box mt={2}>
        <ErrorsAlert errors={saveErrors} />
        <ErrorsAlert errors={awardErrors} />
        <ErrorsAlert
          errors={
            affordable === false
              ? [
                  intl.formatMessage({
                    id: "errors.not-affordable",
                    defaultMessage: "You have not balance",
                  }),
                ]
              : null
          }
        />
      </Box>
      <Box mt={2}>
        <Typography variant="h6">
          <FormattedMessage
            id="send-badges-wizard.badge-title"
            defaultMessage="Badge"
            description="Badge title"
          />
        </Typography>
      </Box>
      <Paper variant="outlined">
        <Grid container>
          <Grid item xs>
            <BatchBadgeClass badgeClass={batch.badgeClass} />
          </Grid>
          <Grid item xs>
            <BatchQuotation
              batchStatus={batch.status}
              batchUuid={batch.uuid}
              setAffordable={setAffordable}
            />
          </Grid>
        </Grid>
      </Paper>
      <Box mt={2}>
        <Typography variant="h6">
          <FormattedMessage
            id="send-badges-wizard.selected-earners-title"
            defaultMessage="Selected Recipients"
            description="Selected Recipients list title"
          />{" "}
          ({batch.assertions.length})
        </Typography>
      </Box>
      <Paper variant="outlined">
        <RevokeBadgeProvider>
          <BatchAssertions assertions={batch.assertions} />
        </RevokeBadgeProvider>
      </Paper>
      <Box
        mt={3}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Button variant="text" onClick={handleBackStep} disabled={!currentStep}>
          <FormattedMessage
            id="send-badges-wizard.back-button"
            defaultMessage="Back"
            description="Back button in wizard"
          />
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          onClick={handleAward}
          loading={awarding}
          disabled={
            !batch.assertions.length ||
            !affordable ||
            saving ||
            !validatedIdentities.length
          }
        >
          <Typography noWrap variant="button">
            <FormattedMessage
              id="send-badges-wizard.award-button"
              defaultMessage="Award"
              description="Award button in wizard"
            />
          </Typography>
        </LoadingButton>
      </Box>
      <Box mt={1} display="flex" flexDirection="row" justifyContent="flex-end">
        <LoadingButton
          onClick={handleSave}
          loading={saving}
          disabled={!batch.assertions.length || awarding}
        >
          <Typography noWrap variant="button">
            <FormattedMessage
              id="send-badges-wizard.save-and-award-later-button"
              defaultMessage="Save and award later"
              description="Save and award later button in wizard"
            />
          </Typography>
        </LoadingButton>
      </Box>
    </>
  );
};

export default SendBadgesStep3;
