import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  Typography,
} from "@material-ui/core";
import React, { useContext } from "react";
import { useIntl } from "react-intl";
import { importEarnersInformation } from "../../config/importEarnersInformation";
import { LanguageContext } from "../../context/LanguageProvider";

const ImportEarnersFileInformationDialog = (props) => {
  const intl = useIntl();
  const { locale } = useContext(LanguageContext);
  const translatedInfo = importEarnersInformation[locale];

  return (
    <Dialog maxWidth="lg" fullWidth {...props}>
      <DialogTitle disableTypography>
        <Box display="flex" flexDirection="horizontal">
          <Box flexGrow={1}>
            <Typography variant="h6">
              {intl.formatMessage({
                id: "import-earners.info-dialog-title",
                defaultMessage: "About Recipients Import File",
              })}
            </Typography>
          </Box>
          {props.onClose && (
            <Box flexShrink={1}>
              <IconButton aria-label="close" onClick={props.onClose}>
                <Icon>close</Icon>
              </IconButton>
            </Box>
          )}
        </Box>
      </DialogTitle>
      <DialogContent>{translatedInfo}</DialogContent>
    </Dialog>
  );
};

export default ImportEarnersFileInformationDialog;
