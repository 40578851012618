import {
  Box,
  Button,
  Icon,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { plansCapabilities } from "../../config/plansCapabilities";
import { planSupplies } from "../../config/planSupplies";
import { SubscriptionPlanContext } from "../../context/SubscriptionPlanProvider";
import FormattedCurrency from "../Account/FormattedCurrency";

const SubscriptionPlansTable = ({
  plans,
  showPrices,
  showButtons,
  disableSelectButton,
  ...rest
}) => {
  const { selectNewPlan } = useContext(SubscriptionPlanContext);

  const handleSelectPlanClick = (plan) => {
    selectNewPlan(plan);
  };

  return (
    <TableContainer component={Paper} variant="outlined">
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            {plans &&
              plans.map((plan) => (
                <TableCell align="center" key={plan.name}>
                  {plan.description}
                </TableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {plansCapabilities.map((capability) => (
            <TableRow key={capability.id}>
              <TableCell>
                {capability.description}
                {capability.mark ?? ""}
              </TableCell>
              {plans &&
                plans.map((plan) => (
                  <TableCell
                    align="center"
                    key={`${plan.name}-${capability.id}`}
                  >
                    {plan.enabledCapabilities.indexOf(capability.id) !== -1 ? (
                      capability.customCheck ? (
                        capability.customCheck(plan)
                      ) : (
                        <Icon>check</Icon>
                      )
                    ) : (
                      "-"
                    )}
                  </TableCell>
                ))}
            </TableRow>
          ))}
          <TableRow>
            <TableCell>
              <FormattedMessage id="plans.supplies" defaultMessage="Supplies" />
            </TableCell>
            {plans &&
              plans.map((plan) => (
                <TableCell align="center" key={`${plan.name}-supplies`}>
                  {plan.supplies ? (
                    plan.supplies.map((supply) => (
                      <Box
                        key={`${plan.name}-supplies-${supply.currency.ticker}`}
                      >
                        <FormattedCurrency
                          value={supply.value}
                          currency={supply.currency}
                          variant="body1"
                          currencyProps={{
                            color: "textSecondary",
                            variant: "caption",
                            style: {
                              paddingBottom: "0.15em",
                              paddingTop: "0.15em",
                              paddingLeft: "0.2em",
                              paddingRight: "0.2em",
                            },
                          }}
                        />
                        <Tooltip
                          display="inline"
                          title={planSupplies[supply.topUpType]}
                        >
                          <Icon style={{ fontSize: 16 }}>info_outlined</Icon>
                        </Tooltip>
                      </Box>
                    ))
                  ) : (
                    <TableCell>Nothing</TableCell>
                  )}
                </TableCell>
              ))}
          </TableRow>
          {showPrices && (
            <TableRow key="mothlyFee">
              <TableCell>
                <FormattedMessage
                  id="monthly-fee"
                  defaultMessage="Monthly Fee"
                />
              </TableCell>
              {plans &&
                plans.map((plan) => (
                  <TableCell align="center" key={`${plan.name}-price`}>
                    {plan.price ? (
                      <FormattedCurrency
                        value={plan.price.value}
                        currency={plan.price.currency}
                        variant="body1"
                        currencyProps={{
                          color: "textSecondary",
                          variant: "caption",
                          style: {
                            paddingBottom: "0.15em",
                            paddingTop: "0.15em",
                            paddingLeft: "0.2em",
                            paddingRight: "0.2em",
                          },
                        }}
                      />
                    ) : (
                      <FormattedMessage id="free" defaultMessage="Free" />
                    )}
                  </TableCell>
                ))}
            </TableRow>
          )}
          {showButtons && (
            <TableRow key="actions">
              <TableCell></TableCell>
              {plans &&
                plans.map((plan) =>
                  plan.currentPlan ? (
                    <TableCell align="center" key={`${plan.name}-button`}>
                      <Typography variant="button" color="textSecondary">
                        <FormattedMessage
                          id="current-plan"
                          defaultMessage="Current Plan"
                        />
                      </Typography>
                    </TableCell>
                  ) : (
                    <TableCell align="center" key={`${plan.name}-button`}>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleSelectPlanClick(plan)}
                        disabled={disableSelectButton}
                      >
                        <FormattedMessage
                          id="select-plan-button"
                          defaultMessage="Select Plan"
                        />
                      </Button>
                    </TableCell>
                  )
                )}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SubscriptionPlansTable;
