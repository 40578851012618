import API from "../utils/API";
import AuthService from "./AuthService";
import Service from "./Service";

class EarnersService extends Service {
  queries = {
    getEarners: (...params) =>
      `query { byIssuerQueryEarners(issuer:"/api/issuers/${params[0]}", page:${params[1]}, itemsPerPage:${params[2]}, search:"${params[3]}" ${params[4]}) { collection { id, uuid, name, email, externalId }, paginationInfo { totalCount } } }`,
    getEarner: (...params) =>
      `query { earner(id:"/api/earners/${params[0]}") { uuid, name, email, externalId, tagNames } }`,
    createEarner: (...params) =>
      `mutation{createEarner(input:{issuer:"/api/issuers/${params[0]}",name:"${params[1]}",email:"${params[2]}",tagNames:[${params[3]}] ${params[4]}}){earner{ uuid }}}`,
    updateEarner: (...params) =>
      `mutation{updateEarner(input:{id:"/api/earners/${params[0]}",name:"${params[1]}",email:"${params[2]}",tagNames:[${params[3]}] ${params[4]}}){earner{ uuid }}}`,
    deleteEarner: (...params) =>
      `mutation{removeEarner(input:{id:"/api/earners/${params[0]}"}){earner{ uuid }}}`,
    getEarnersTags: (...params) =>
      `query { earnerQueryTags(issuer:"/api/issuers/${params[0]}", itemsPerPage:${params[1]} ${params[2]}) { edges { node {  _id, name } } } }`,
    importEarners: (...params) =>
      `query { importEarners(issuer:"/api/issuers/${params[0]}", uploadedfile_uuid:"${params[1]}" ${params[2]}) { uuid, externalId, name, email } }`,
  };

  getEarners = async (limit, pageNumber, search, tags) => {
    let issuer = AuthService.getCurrentIssuer();
    tags = tags && tags.length ? `, tags:["${tags.join('","')}"]` : ``;
    search = search ? search : "";
    pageNumber = pageNumber ? pageNumber : 0;
    limit = limit ? limit : 10;

    return API.callGraphql(
      this.queries.getEarners(issuer.uuid, pageNumber, limit, search, tags)
    ).then((response) => {
      this.errorParser(response);

      if (!response.data || !response.data.byIssuerQueryEarners)
        this.throwDefaultError();

      return response.data.byIssuerQueryEarners;
    });
  };

  getEarner = async (earnerUuid) => {
    earnerUuid = earnerUuid ? earnerUuid : "";

    return API.callGraphql(this.queries.getEarner(earnerUuid)).then(
      (response) => {
        this.errorParser(response);

        if (!response.data || !response.data.earner) this.throwDefaultError();

        return response.data.earner;
      }
    );
  };

  createEarner = async (earner) => {
    let issuer = AuthService.getCurrentIssuer();
    let tags = earner.tagNames.length ? `"${earner.tagNames.join('","')}"` : ``;
    let externalId = earner.externalId
      ? `,externalId:"${earner.externalId}"`
      : ``;
    return API.callGraphql(
      this.queries.createEarner(
        issuer.uuid,
        earner.name,
        earner.email,
        tags,
        externalId
      )
    ).then((response) => {
      this.errorParser(response);

      if (!response.data || !response.data.createEarner)
        this.throwDefaultError();

      return response.data.createEarner.earner;
    });
  };

  updateEarner = async (earner) => {
    let tags = earner.tagNames.length ? `"${earner.tagNames.join('","')}"` : ``;
    let externalId = earner.externalId
      ? `,externalId:"${earner.externalId}"`
      : ``;
    return API.callGraphql(
      this.queries.updateEarner(
        earner.uuid,
        earner.name,
        earner.email,
        tags,
        externalId
      )
    ).then((response) => {
      this.errorParser(response);

      if (!response.data || !response.data.updateEarner)
        this.throwDefaultError();

      return response.data.updateEarner.earner;
    });
  };

  saveEarner = async (earner) => {
    if (earner.uuid) {
      return this.updateEarner(earner);
    } else {
      return this.createEarner(earner);
    }
  };

  deleteEarner = async (earnerId) => {
    earnerId = earnerId ? earnerId : "";

    return API.callGraphql(this.queries.deleteEarner(earnerId)).then(
      (response) => {
        this.errorParser(response);

        if (!response.data || !response.data.removeEarner)
          this.throwDefaultError();

        return response.data.removeEarner.earner;
      }
    );
  };

  getEarnersTags = async (search, limit) => {
    let issuer = AuthService.getCurrentIssuer();
    limit = limit ? limit : 10;
    search = search ? ', search:"' + search + '"' : "";

    return API.callGraphql(
      this.queries.getEarnersTags(issuer.uuid, limit, search)
    ).then((response) => {
      this.errorParser(response);

      if (!response.data || !response.data.earnerQueryTags)
        this.throwDefaultError();

      let tags = [];
      response.data.earnerQueryTags.edges.map(function (edge) {
        return tags.push(edge.node.name);
      });
      return tags;
    });
  };

  importEarners = async (fileUUID, tags) => {
    let issuer = AuthService.getCurrentIssuer();
    tags = tags && tags.length ? `, tags:["${tags.join('","')}"]` : `, tags:[]`;

    return API.callGraphql(
      this.queries.importEarners(issuer.uuid, fileUUID, tags)
    ).then((response) => {
      this.errorParser(response);

      if (!response.data || !response.data.importEarners)
        this.throwDefaultError();

      return response.data.importEarners;
    });
  };
}

export default new EarnersService();
