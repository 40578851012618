import axios from "axios";
import qs from "qs";
import history from "./history";

const API_URL = process.env.REACT_APP_API_URL;
const API_TOKEN_URL = process.env.REACT_APP_API_TOKEN_URL;
const API_CLIENT_ID = process.env.REACT_APP_API_CLIENT_ID;
const API_HOST = process.env.REACT_APP_API_HOST;

const axiosInstance = axios.create();

class API {
  constructor() {
    axiosInstance.interceptors.request.use(
      function (config) {
        const oauthToken = localStorage.getItem("access_token");
        if (oauthToken) {
          config.headers["Authorization"] = "Bearer " + oauthToken;
        }
        const googleRecaptchaToken = localStorage.getItem("recaptcha_token");
        if (googleRecaptchaToken) {
          config.headers["X-Recaptcha-Token"] = googleRecaptchaToken;
          localStorage.removeItem("recaptcha_token");
        }
        return config;
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      }
    );

    axiosInstance.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (error.response.status === 401) {
          const _api = new API();
          return _api.refreshToken().then((isTokenRefreshed) => {
            if (isTokenRefreshed) {
              error.config.headers["Authorization"] =
                "Bearer " + localStorage.getItem("access_token");
              error.config.baseURL = undefined;
              return axiosInstance.request(error.config);
            }
          });
        }

        return Promise.reject(error);
      }
    );
  }

  /**
   *
   * @param {String} query
   * @returns {data: Object, response: Array} Response data and response errors
   */
  callGraphql = (query) => {
    return axiosInstance
      .post(API_URL, { query: query })
      .then((data) => {
        let _responseData = null;
        let _responseErrors = [];
        if (data.errors && data.errors.length) {
          _responseErrors = data.errors;
        }

        if (data.data.errors && data.data.errors.length) {
          _responseErrors = data.data.errors;
        }

        if (data.data.data) {
          _responseData = data.data.data;
        }

        return { data: _responseData, errors: _responseErrors };
      })
      .catch((errors) => {
        return { data: null, errors: errors };
      });
  };

  /**
   *
   * @param {String} email
   * @param {String} password
   * @return {true} On success login
   * @return {false} On bad credentials
   * @throws Exception in connection error
   */
  login = (email, password) => {
    const oauthParams = {
      grant_type: "password",
      client_id: API_CLIENT_ID,
      client_secret: "",
      username: email,
      password: password,
      scope: "",
    };

    return axiosInstance
      .post(API_TOKEN_URL, qs.stringify(oauthParams))
      .then((res) => {
        if (res.data.access_token) {
          localStorage.setItem("access_token", res.data.access_token);
          localStorage.setItem("refresh_token", res.data.refresh_token);
          return true;
        }
        return false;
      })
      .catch((err) => {
        throw err;
      });
  };

  logout = () => {
    localStorage.clear();
  };

  hasAccessToken = () => {
    return localStorage.getItem("access_token");
  };

  hasRefreshToken = () => {
    return localStorage.getItem("refresh_token");
  };

  refreshToken = () => {
    if (!this.hasRefreshToken()) {
      return Promise.resolve(false);
    }

    const oauthParams = {
      grant_type: "refresh_token",
      client_id: API_CLIENT_ID,
      refresh_token: localStorage.getItem("refresh_token"),
    };

    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");

    return axiosInstance
      .post(API_TOKEN_URL, qs.stringify(oauthParams))
      .then((res) => {
        if (res.data.access_token) {
          localStorage.setItem("access_token", res.data.access_token);
          localStorage.setItem("refresh_token", res.data.refresh_token);
          return true;
        }
        history.push("/login");
        return false;
      })
      .catch((err) => {
        history.push("/login");
        return false;
      });
  };

  axios = () => {
    return axiosInstance;
  };

  downloadFile = (endpoint) => {
    return axiosInstance.get(API_HOST + endpoint, { responseType: "blob" });
  };

  setGoogleRecaptchaToken = (googleRecaptchaToken) => {
    localStorage.setItem("recaptcha_token", googleRecaptchaToken);
    return this;
  };
}

export default new API();
