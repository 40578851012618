import {
  Box,
  Breadcrumbs,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link, useParams } from "react-router-dom";
import CreateBadgeStep0 from "../components/CreateBadge/CreateBadgeStep0";
import CreateBadgeStep1 from "../components/CreateBadge/CreateBadgeStep1";
import CreateBadgeStep2 from "../components/CreateBadge/CreateBadgeStep2";
import { CreateBadgeProvider } from "../context/CreateBadgeProvider";
import AdminLayout from "../layouts/AdminLayout";

const CreateBadge = () => {
  const params = useParams();
  const isCreatingBadge = params.badgeUuid ? false : true;

  const steps = [
    <FormattedMessage
      id="badge-class-wizard.step-badge-info"
      defaultMessage="Badge Info"
      description="Badge Info step label"
    />,
    <FormattedMessage
      id="badge-class-wizard.step-badge-design"
      defaultMessage="Badge Design"
      description="Badge Design step label"
    />,
    <FormattedMessage
      id="badge-class-wizard.step-preview"
      defaultMessage="Preview"
      description="Preview step label"
    />,
  ];

  const [currentStep, setCurrentStep] = useState(0);
  const lastStep = steps.length - 1;

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <CreateBadgeStep0 />;
      case 1:
        return <CreateBadgeStep1 />;
      case 2:
        return <CreateBadgeStep2 />;
      default:
    }
  };

  const handleNextStep = () => {
    let nextStep = currentStep + 1;

    if (nextStep >= steps.length) {
      return;
    }

    setCurrentStep(nextStep);
  };

  const handleBackStep = () => {
    let nextStep = currentStep - 1;

    if (nextStep < 0) {
      return;
    }

    setCurrentStep(nextStep);
  };

  return (
    <AdminLayout>
      <Box p={2}>
        <Breadcrumbs separator=">" aria-label="breadcrumb">
          <Link style={{ color: "inherit" }} to="/">
            <FormattedMessage
              id="routes.protected.dashboard"
              defaultMessage="Dashboard"
              description="Dashboard menu breadcrumbs title"
            />
          </Link>
          <Link style={{ color: "inherit" }} to="/badges">
            <FormattedMessage
              id="routes.protected.badges"
              defaultMessage="My Badges"
              description="My Badges menu link and breadcrumbs title"
            />
          </Link>
          <Typography color="textPrimary">
            {isCreatingBadge ? (
              <FormattedMessage
                id="badge-class-wizard.create-badge-title"
                defaultMessage="Create Badge"
                description="Title shown in breadcrumbs when creating a badge"
              />
            ) : (
              <FormattedMessage
                id="badge-class-wizard.edit-badge-title"
                defaultMessage="Edit Badge"
                description="Title shown in breadcrumbs when editing a badge"
              />
            )}
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box p={2}>
        <Paper>
          <Stepper activeStep={currentStep} alternativeLabel>
            {steps.map((label, pos) => (
              <Step key={pos}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Box py={3} px={3}>
            <CreateBadgeProvider
              badgeUuid={params.badgeUuid}
              isCreatingBadge={isCreatingBadge}
              value={{ currentStep, lastStep, handleNextStep, handleBackStep }}
            >
              {getStepContent(currentStep)}
            </CreateBadgeProvider>
          </Box>
        </Paper>
      </Box>
    </AdminLayout>
  );
};

export default CreateBadge;
