import {
  Box,
  Button,
  Chip,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { CreateBadgeContext } from "../../context/CreateBadgeProvider";
import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  tags: {
    "& > *": {
      marginRight: theme.spacing(0.5),
    },
  },
}));

const CreateBadgeStep2 = (props) => {
  const { badgeClass, currentStep, handleBackStep } = useContext(
    CreateBadgeContext
  );
  const classes = useStyles();

  return (
    <>
      <Paper variant="outlined">
        <Box display="flex" justifyContent="left">
          <Box py={2}>
            <img
              src={`data:image/svg+xml;utf8,${encodeURIComponent(
                badgeClass.svgFinal
              )}`}
              alt="badge"
              style={{ maxWidth: "250px" }}
            />
          </Box>
          <Box p={2}>
            <Typography variant="h4" justifyContent="center">
              {badgeClass.name}
            </Typography>
            <Box py={1}>
              <Typography variant="body1">{badgeClass.description}</Typography>
            </Box>
            <Box py={1} className={classes.tags}>
              {badgeClass.tagNames.map((tag) => (
                <Chip label={tag} key={tag} size="small" />
              ))}
            </Box>
          </Box>
        </Box>
      </Paper>
      <Box pt={3} display="flex">
        <Box flexGrow={1}>
          <Button
            variant="text"
            onClick={handleBackStep}
            disabled={!currentStep}
          >
            <FormattedMessage
              id="badge-class-wizard.back-button"
              defaultMessage="Back"
              description="Back button in wizard"
            />
          </Button>
        </Box>
        <Box display="grid" justifyItems="right">
          <Box>
            <Button
              variant="contained"
              color="primary"
              to={"/send-badges/" + badgeClass.uuid}
              component={Link}
            >
              <Typography noWrap variant="button">
                <FormattedMessage
                  id="badge-class-wizard.send-badges-button"
                  defaultMessage="Send Badges"
                  description="'Send Badges' button in wizard"
                />
              </Typography>
            </Button>
          </Box>
          <Box py={1}>
            <Button variant="text" to="/badges" component={Link}>
              <Typography noWrap variant="button">
                <FormattedMessage
                  id="badge-class-wizard.back-to-my-badges-button"
                  defaultMessage="Back to my badges"
                  description="'Back to my badges' button in wizard"
                />
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CreateBadgeStep2;
