import { Box, CircularProgress, Typography } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import usePromise from "../../hooks/usePromise";
import BadgeClassesService from "../../services/BadgeClassesService";
import history from "../../utils/history";
import BadgeClassDeleteDialog from "./BadgeClassDeleteDialog";
import BadgeClassItem from "./BadgeClassItem";

const BadgeClassList = (props) => {
  const intl = useIntl();
  const limit = props.limit ? parseInt(props.limit, 10) : 5;
  const sortBy = props.sortBy ?? null;
  const order = props.order ?? null;
  const paginationDisable = props.paginationDisable ?? false;
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [currentBadgeClassUUID, setCurrentBadgeClassUUID] = useState(null);
  const [data, errors, loading, setPromise] = usePromise();
  const [deleteData, deleteErrors, deleting, setDeletePromise] = usePromise();

  BadgeClassesService.setDefaultErrorMessage(
    intl.formatMessage({
      id: "errors.unexpected-error",
      defaultMessage: "An unexpected error ocurred.",
    })
  );

  const getBadgeClasses = (
    pageNumber,
    search,
    statuses,
    tags,
    sortBy,
    order
  ) => {
    setPromise(
      BadgeClassesService.getBadgeClasses(
        limit,
        pageNumber,
        search,
        statuses,
        tags,
        sortBy,
        order
      )
    );
  };

  useEffect(() => {
    getBadgeClasses(
      props.pageNumber,
      props.search,
      props.statuses,
      props.tags,
      sortBy,
      order
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.pageNumber,
    props.search,
    props.statuses,
    props.tags,
    sortBy,
    order,
  ]);

  const handlePaginationChange = (evt, page) => {
    history.push("/badges/" + page);
  };

  const handleBadgeClassDeleteClick = (badgeClassUUID) => {
    setCurrentBadgeClassUUID(badgeClassUUID);
    setDeleteDialogOpen(true);
  };

  const handleBadgeClassDelete = () => {
    setDeletePromise(
      BadgeClassesService.deleteBadgeClass(currentBadgeClassUUID).then(() => {
        setDeleteDialogOpen(false);
        getBadgeClasses(
          props.pageNumber,
          props.search,
          props.statuses,
          props.tags
        );
      })
    );
  };

  if (loading) {
    return (
      <Box display="grid" justifyItems="center">
        <CircularProgress />
      </Box>
    );
  }

  if (errors) {
    return <Box>{errors[0]}</Box>;
  }

  if (!data.collection) {
    return (
      <Box>
        <FormattedMessage
          id="badge-class-list.nothing-found"
          defaultMessage="Nothing found"
        />
      </Box>
    );
  }

  return (
    <>
      <Box>
        {data.collection.map((badgeClass) => (
          <Box key={badgeClass.uuid} mb={2}>
            <BadgeClassItem
              badgeClass={badgeClass}
              handleDeleteClick={handleBadgeClassDeleteClick}
              type={props.itemType}
            />
          </Box>
        ))}
        {!data.collection.length && (
          <Box key="empty" alignContent="center">
            <Typography>
              <FormattedMessage
                id="badge-class-list.nothing-found"
                defaultMessage="Nothing found"
              />
            </Typography>
          </Box>
        )}
      </Box>
      {!paginationDisable && data.paginationInfo.lastPage > 1 && (
        <Box mt={2} p={2} display="flex" justifyContent="center">
          <Pagination
            page={props.pageNumber}
            count={data.paginationInfo.lastPage}
            color="primary"
            onChange={handlePaginationChange}
          />
        </Box>
      )}
      <BadgeClassDeleteDialog
        handleDelete={handleBadgeClassDelete}
        open={deleteDialogOpen}
        handleClose={() => setDeleteDialogOpen(false)}
        deleting={deleting}
      />
    </>
  );
};

export default BadgeClassList;
