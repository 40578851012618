import { Grid, Paper } from "@material-ui/core";
import React from "react";
import SettingsMenu from "./SettingsMenu";

const SettingsContainer = (props) => {
  const { routes, children, ...rest } = props;

  return (
    <Paper {...rest}>
      <Grid container>
        <Grid item lg={3} style={{ backgroundColor: "#F2F2F2" }}>
          <SettingsMenu routes={routes} />
        </Grid>
        <Grid
          item
          lg={9}
          style={{ backgroundColor: "#FEFEFE", padding: "1em" }}
        >
          {children}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SettingsContainer;
