import { Box } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React from "react";
import { FormattedMessage } from "react-intl";

const ResetPasswordRequestSuccess = (props) => {
  return (
    <Box>
      <Alert variant="standard" color="success">
        <FormattedMessage
          id="reset-password-request.success-message"
          description="Message shown after user request a password reset link"
          defaultMessage="We have sent you a password reset link to {email}. If you don't receive the email, remember to check the spam folder."
          values={{ email: props.email }}
        />
      </Alert>
    </Box>
  );
};

export default ResetPasswordRequestSuccess;
