import React, { useContext } from "react";
import {
  FormControl,
  Icon,
  InputAdornment,
  MenuItem,
  Select,
} from "@material-ui/core";
import languages from "../../config/languages";
import { LanguageContext } from "../../context/LanguageProvider";

const LanguageSwitcher = () => {
  const { switchLanguage, locale } = useContext(LanguageContext);
  return (
    <FormControl variant="outlined" size="small">
      <Select
        onChange={(event) => {
          switchLanguage(event.target.value);
        }}
        value={locale}
        variant="outlined"
        startAdornment={
          <InputAdornment position="start">
            <Icon fontSize="small">translate</Icon>
          </InputAdornment>
        }
      >
        {languages.map((language) => (
          <MenuItem value={language.locale} key={language.locale}>
            {language.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default LanguageSwitcher;
