import API from "../utils/API";
import Service from "./Service";

const API_FILE_UPLOAD_URL = process.env.REACT_APP_API_FILE_UPLOAD_URL;

class FileUploadService extends Service {
  uploadFile = async (file) => {
    let formData = new FormData();
    formData.append("file", file);
    return API.axios()
      .post(API_FILE_UPLOAD_URL, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        this.errorParser(response);

        return response.data;
      })
      .catch((error) => {
        return false;
      });
  };
}

export default new FileUploadService();
