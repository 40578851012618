import {
  Box,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";
import React, { useContext, useState } from "react";
import { FormattedDate, FormattedMessage } from "react-intl";
import ErrorsAlert from "../../components/Common/ErrorsAlert";
import SubscriptionPlansTable from "../../components/SubscriptionPlan/SubscriptionPlansTable";
import CustomSubscriptionPlan from "../../components/SubscriptionPlan/CustomSubscriptionPlan";
import SubscriptionPlanPaper from "../../components/SubscriptionPlan/SubscriptionPlanPaper";
import { SubscriptionPlanContext } from "../../context/SubscriptionPlanProvider";
import RequestedSubscriptionPlanDetail from "../../components/SubscriptionPlan/RequestedSubscriptionPlanDetail";
import { Alert } from "@material-ui/lab";

const SubscriptionPlan = () => {
  const {
    issuerPlan,
    plans,
    loadingPlans,
    errorsLoadingPlans,
    requestedSubscription,
    currentSubscription,
    nextSubscription,
  } = useContext(SubscriptionPlanContext);
  const [showDetails, setShowDetails] = useState(false);

  return (
    <>
      <Box mb={1} display="flex" flexDirection="row" alignItems="center">
        <Typography variant="h6">
          <FormattedMessage
            id="routes.protected.settings.plan"
            defaultMessage="Subscription Plan"
            description="Issuer plan link and breadcrumbs title"
          />
        </Typography>
      </Box>
      <Divider />
      <Box pt={2}>
        {requestedSubscription && (
          <Box mb={3}>
            <RequestedSubscriptionPlanDetail />
          </Box>
        )}
        {currentSubscription.expirationDate && (
          <Box mb={3}>
            <Alert color="info">
              <FormattedMessage
                id="subscription-expiration"
                defaultMessage="Your plan {planDescription} will expire on {expirationDate}. "
                values={{
                  planDescription: currentSubscription.issuerPlan.description,
                  expirationDate: (
                    <FormattedDate value={currentSubscription.expirationDate} />
                  ),
                }}
              />
              {nextSubscription && (
                <FormattedMessage
                  id="next-sub"
                  defaultMessage="Then you will continue with {planDescription} plan."
                  values={{
                    planDescription: nextSubscription.issuerPlan.description,
                  }}
                />
              )}
            </Alert>
          </Box>
        )}
        {loadingPlans && (
          <Box display="flex" justifyContent="center" mb={2}>
            <CircularProgress />
          </Box>
        )}
        {errorsLoadingPlans && (
          <Box mb={2}>
            <ErrorsAlert errors={errorsLoadingPlans} />
          </Box>
        )}
        {issuerPlan && !issuerPlan.public && (
          <Box mb={2}>
            <CustomSubscriptionPlan plan={issuerPlan} />
          </Box>
        )}
        {plans && (
          <Box mt={2} mb={2}>
            <Grid container direction="row" spacing={2}>
              {plans &&
                plans.map((plan) => (
                  <Grid item xs={4} key={plan.name}>
                    <SubscriptionPlanPaper
                      plan={plan}
                      disableSelectButton={
                        requestedSubscription || nextSubscription ? true : false
                      }
                    />
                  </Grid>
                ))}
            </Grid>
            <Box mt={1}>
              <Collapse in={showDetails}>
                <Box mt={2}>
                  <SubscriptionPlansTable
                    plans={plans}
                    showButtons
                    showPrices
                    disableSelectButton={
                      requestedSubscription || nextSubscription ? true : false
                    }
                  />
                </Box>
              </Collapse>
              <Box mt={1} justifyItems="center" display="grid">
                <Link onClick={() => setShowDetails(!showDetails)} href="#">
                  <Typography variant="caption">
                    {showDetails ? (
                      <FormattedMessage
                        id="plans-comparation.hide"
                        defaultMessage="Hide plans comparison"
                      />
                    ) : (
                      <FormattedMessage
                        id="plans-comparation.view"
                        defaultMessage="View plans comparison"
                      />
                    )}
                  </Typography>
                </Link>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default SubscriptionPlan;
