import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@material-ui/core";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      width: 250,
    },
  },
};

const BadgeClassStatusSelect = (props) => {
  const intl = useIntl();
  const handleStatusChange = (event) => {
    if (!event.target.value.length) {
      props.setStatuses(["draft", "used", "archived"]);
    }
    props.setStatuses(event.target.value);
    props.params.pageNumber = 1;
  };

  const badgeClassStatusLabels = {
    draft: intl.formatMessage({
      id: "badge-class-status.status-draft",
      defaultMessage: "DRAFT",
    }),
    used: intl.formatMessage({
      id: "badge-class-status.status-used",
      defaultMessage: "USED",
    }),
    archived: intl.formatMessage({
      id: "badge-class-status.status-archived",
      defaultMessage: "ARCHIVED",
    }),
  };

  const badgeClassStatuses = [
    {
      label: badgeClassStatusLabels.draft,
      value: "draft",
    },
    {
      label: badgeClassStatusLabels.used,
      value: "used",
    },
    {
      label: badgeClassStatusLabels.archived,
      value: "archived",
    },
  ];

  const getSelectedText = (selected) => {
    if (
      !selected ||
      !selected.length ||
      selected.length === badgeClassStatuses.length
    ) {
      return intl.formatMessage({
        id: "badge-class-status-select.status-all",
        defaultMessage: "All",
      });
    }

    let translatedStatus = [];
    selected.map((status) =>
      translatedStatus.push(badgeClassStatusLabels[status])
    );
    return translatedStatus.join(", ").toUpperCase();
  };

  return (
    <FormControl fullWidth variant="outlined" size="small">
      {props.statuses.length ? (
        <InputLabel disableAnimation={true}>
          <FormattedMessage
            id="badge-class-status-select.select-label"
            defaultMessage="Status"
          />
        </InputLabel>
      ) : (
        ""
      )}
      <Select
        multiple
        value={props.statuses}
        onChange={handleStatusChange}
        renderValue={getSelectedText}
        MenuProps={MenuProps}
        label={intl.formatMessage({
          id: "badge-class-status-select.select-label",
          defaultMessage: "Status",
        })}
        displayEmpty
      >
        {badgeClassStatuses.map((status) => (
          <MenuItem key={status.label} value={status.value}>
            <Checkbox
              checked={props.statuses.indexOf(status.value) > -1}
              color="primary"
            />
            <ListItemText primary={status.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default BadgeClassStatusSelect;
