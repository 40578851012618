import React, { useContext, useState } from "react";
import { Button, Icon, Menu, MenuItem } from "@material-ui/core";
import { Link } from "react-router-dom";
import { routes } from "../../config/routes";
import { UserContext } from "../../context/UserProvider";

const DesktopMenu = () => {
  const { user, loadingUser } = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorPos, setAnchorPos] = useState(null);

  const menuRoutes = user ? routes.protected : routes.public;

  const handleClick = (event, pos) => {
    setAnchorEl(event.currentTarget);
    setAnchorPos(pos);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorPos(null);
  };

  if (loadingUser) {
    return null;
  }

  return (
    <>
      {menuRoutes.map((route, pos) =>
        route.routes ? (
          <Button
            onClick={(evt) => handleClick(evt, pos)}
            key={pos}
            aria-controls={`simple-menu-` + pos}
            aria-haspopup="true"
            endIcon={<Icon>arrow_drop_down</Icon>}
          >
            {route.message}
          </Button>
        ) : (
          <Button component={Link} to={route.to} key={pos}>
            {route.message}
          </Button>
        )
      )}
      {menuRoutes.map(
        (route, pos) =>
          route.routes && (
            <Menu
              id={`simple-menu-` + pos}
              key={`simple-menu-` + pos}
              open={anchorPos === pos}
              onClose={handleClose}
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            >
              {route.routes.map((route, pos) => (
                <MenuItem
                  onClick={handleClose}
                  component={Link}
                  to={route.to}
                  key={pos}
                >
                  {route.message}
                </MenuItem>
              ))}
            </Menu>
          )
      )}
    </>
  );
};

export default DesktopMenu;
