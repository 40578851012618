import React, { useState, useEffect } from "react";
import AuthService from "../services/AuthService";
import API from "../utils/API";
import history from "../utils/history";

const UserContext = React.createContext();

const UserProvider = (props) => {
  const [user, setUserState] = useState(false);
  const [issuer, setIssuer] = useState(false);
  const [loadingUser, setLoadingUser] = useState(true);
  const [referer, setReferer] = useState(null);

  useEffect(() => {
    if (!API.hasAccessToken()) {
      setLoadingUser(false);
      return;
    }

    loadUser(false);
  }, []);

  const loadUser = async (issuerId) => {
    setLoadingUser(true);

    return AuthService.getUser()
      .then((currentUser) => {
        setUserState(currentUser);

        if (issuerId) {
          let currentIssuer = false;
          currentUser.userIssuers.map((userIssuer) => {
            if (userIssuer.issuer.uuid === issuerId) {
              currentIssuer = userIssuer.issuer;
            }
            return true;
          });

          AuthService.setCurrentIssuer(currentIssuer);
        }

        setIssuer(AuthService.getCurrentIssuer());
        setLoadingUser(false);
      })
      .catch((err) => {
        setUserState(false);
        setIssuer(false);
        setLoadingUser(false);
      });
  };

  const setIssuerById = (issuerId) => {
    return loadUser(issuerId).then(() => {
      history.push("/reload");
      history.goBack();
    });
  };

  const setUser = (user) => {
    setUserState(user);
    setIssuer(AuthService.getCurrentIssuer());
  };

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        loadingUser,
        issuer,
        setIssuerById,
        referer,
        setReferer,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export { UserProvider, UserContext };
