import { Box, Button } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

const ResetPasswordSuccess = () => {
  return (
    <Box display="grid" justifyItems="center">
      <Alert variant="standard" color="success">
        <FormattedMessage
          id="reset-password.success-message"
          description="Message shown after user resets his password successfully"
          defaultMessage="Your password was changed successfully!"
        />
      </Alert>
      <Box mt={2}>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/login"
        >
          <FormattedMessage
            id="header.login"
            description="Log In button"
            defaultMessage="Log In"
          />
        </Button>
      </Box>
    </Box>
  );
};

export default ResetPasswordSuccess;
