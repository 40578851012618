import { Box, Button, Paper, Typography } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import React, { useContext, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { SendBadgesBatchContext } from "../../context/SendBadgesBatchProvider";
import usePromise from "../../hooks/usePromise";
import BatchesService from "../../services/BatchesService";
import ErrorsAlert from "../Common/ErrorsAlert";
import LoadingButton from "../Common/LoadingButton";

const SendBadgesStep2 = () => {
  const {
    batch,
    setBatchUuid,
    setAssertionScore,
    currentStep,
    handleNextStep,
    handleBackStep,
  } = useContext(SendBadgesBatchContext);

  const intl = useIntl();
  const [saveData, saveErrors, saving, setSavePromise] = usePromise();

  BatchesService.setDefaultErrorMessage(
    intl.formatMessage({
      id: "errors.unexpected-error",
      defaultMessage: "An unexpected error ocurred.",
    })
  );

  const handleSubmit = () => {
    setSavePromise(BatchesService.createBatch(batch));
  };

  const handleScoreChange = (assertionId, score) => {
    setAssertionScore(assertionId, score);
  };

  useEffect(() => {
    if (saveData) {
      setBatchUuid(saveData.uuid);
      handleNextStep();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveData]);

  const validationMessages = {
    autocompleteRequire: intl.formatMessage({
      id: "validation.field-required",
      defaultMessage: "This field is required",
      description: "Label shown on required form field when is required",
    }),
  };

  const inputs = {
    score: {
      placeholder: intl.formatMessage({
        id: "send-badges.score-input-placeholder",
        defaultMessage: "Score",
        description: "Recipients score input placeholder",
      }),
    },
  };

  const columns = [
    {
      field: "externalId",
      headerName: intl.formatMessage({
        id: "earners-grid.id-field-name",
        defaultMessage: "ID",
        description: "ID column header name in recipients grid",
      }),
      width: 150,
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        return <>{params.row.earner.externalId}</>;
      },
    },
    {
      field: "email",
      headerName: intl.formatMessage({
        id: "earners-grid.email-field-name",
        defaultMessage: "Email",
        description: "Email column header name in recipients grid",
      }),
      flex: true,
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        return <>{params.row.earner.email}</>;
      },
    },
    {
      field: "name",
      headerName: intl.formatMessage({
        id: "earners-grid.name-field-name",
        defaultMessage: "Name",
        description: "Name column header name in recipients grid",
      }),
      flex: true,
      disableColumnMenu: true,
      filterable: false,
      sortable: false,

      renderCell: (params) => {
        return <>{params.row.earner.name}</>;
      },
    },
    {
      field: "actions",
      headerName: " ",
      width: 150,
      renderCell: (params) => {
        return (
          <Box mt={2}>
            <TextValidator
              variant="outlined"
              placeholder={inputs.score.placeholder}
              value={params.row.score}
              onChange={(e) => handleScoreChange(params.row.id, e.target.value)}
              validators={["required"]}
              errorMessages={[validationMessages.required]}
              inputProps={{ maxLength: 31 }}
            />
          </Box>
        );
      },
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
    },
  ];

  return (
    <ValidatorForm onSubmit={handleSubmit}>
      <Box mb={2}>
        <ErrorsAlert errors={saveErrors} />
      </Box>
      <Box>
        <Typography>
          <FormattedMessage
            id="send-badges-wizard.selected-earners-title"
            defaultMessage="Selected Recipients"
            description="Selected Recipients list title"
          />{" "}
          ({batch.assertions.length})
        </Typography>
      </Box>
      <Paper variant="outlined">
        <div style={{ height: "100%", width: "100%" }}>
          <DataGrid
            columns={columns}
            rows={batch.assertions}
            loading={false}
            pagination
            rowCount={batch.assertions.length}
            disableSelectionOnClick={true}
            disableDensitySelector={true}
            disableColumnSelector={true}
            disableColumnReorder={true}
            autoHeight={true}
            state={{
              keyboard: {
                cell: null,
                columnHeader: null,
                isMultipleKeyPressed: false,
              },
            }}
          ></DataGrid>
        </div>
      </Paper>
      <Box
        mt={3}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Button variant="text" onClick={handleBackStep} disabled={!currentStep}>
          <FormattedMessage
            id="send-badges-wizard.back-button"
            defaultMessage="Back"
            description="Back button in wizard"
          />
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          type="submit"
          disabled={!batch.assertions.length}
          loading={saving}
        >
          <Typography noWrap variant="button">
            <FormattedMessage
              id="send-badges-wizard.next-button"
              defaultMessage="Next"
              description="Next button in wizard"
            />
          </Typography>
        </LoadingButton>
      </Box>
    </ValidatorForm>
  );
};

export default SendBadgesStep2;
