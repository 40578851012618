import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import Dropzone from "react-dropzone";
import { FormattedMessage, useIntl } from "react-intl";
import ErrorsAlert from "../Common/ErrorsAlert";

const useStyle = makeStyles({
  dropzone: {
    borderStyle: "dashed",
  },
});

const SvgEditorUploader = (props) => {
  const classes = useStyle();
  const [fileErrors, setFileErrors] = useState(null);
  const intl = useIntl();

  const handleFileDrop = (acceptedFiles, fileRejections) => {
    let errors = [];
    fileRejections.map((f) => {
      return errors.push(
        intl.formatMessage(
          {
            id: "file-not-svg",
            defaultMessage: "File {fileName} is not an SVG",
          },
          { fileName: f.file.name }
        )
      );
    });
    errors.length && setFileErrors(errors);

    let fileContents = [];

    let responsePromise = new Promise((resolve, reject) => {
      acceptedFiles.map((file) => {
        const reader = new FileReader();
        reader.onabort = () => reject("file reading was aborted");
        reader.onerror = () => reject("file reading has failed");
        reader.onload = () => {
          const binaryStr = reader.result;
          fileContents.push(binaryStr);

          if (fileContents.length === acceptedFiles.length) {
            resolve(fileContents);
          }
        };

        return reader.readAsText(file);
      });
    });

    props.onDrop(responsePromise);
  };

  return (
    <>
      <Dialog open={props.open} onClose={() => props.handleClose()}>
        <DialogTitle>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Typography variant="subtitle1">Import SVG</Typography>
            <IconButton size="small" onClick={() => props.handleClose()}>
              <Icon>close</Icon>
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers={true}>
          {fileErrors && (
            <Box marginBottom="1em">
              <ErrorsAlert errors={fileErrors} />
            </Box>
          )}
          <Dropzone
            accept="image/svg+xml"
            onDrop={(acceptedFiles, fileRejections) =>
              handleFileDrop(acceptedFiles, fileRejections)
            }
          >
            {({ getRootProps, getInputProps, isDragActive }) => (
              <Box
                display="grid"
                p={2}
                border={1}
                borderRadius={3}
                justifyItems="center"
                className={classes.dropzone}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <Icon color="action">upload</Icon>
                <Typography variant="body1" color="textSecondary">
                  <FormattedMessage
                    id="dropzone:drag-drop-text"
                    defaultMessage="Drag 'n' drop some files here, or click to select files"
                    description="Text shown in drag and drop area"
                  />
                </Typography>
              </Box>
            )}
          </Dropzone>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SvgEditorUploader;
