import { Box, Breadcrumbs, Typography } from "@material-ui/core";
import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { Link, useParams } from "react-router-dom";
import { BadgesValidator } from "matedapps-badges-validator";
import AdminLayout from "../layouts/AdminLayout";
import API from "../utils/API";
import { LanguageContext } from "../context/LanguageProvider";

const Validator = () => {
  const params = useParams();
  const badgeText = params.badgeText ? atob(unescape(params.badgeText)) : null;
  const { locale } = useContext(LanguageContext);

  return (
    <AdminLayout>
      <Box p={2}>
        <Breadcrumbs separator=">" aria-label="breadcrumb">
          <Link style={{ color: "inherit" }} to="/">
            <FormattedMessage
              id="routes.protected.dashboard"
              defaultMessage="Dashboard"
              description="Dashboard menu breadcrumbs title"
            />
          </Link>
          <Typography color="textPrimary">
            <FormattedMessage
              id="routes.protected.validator"
              defaultMessage="Badges Validator"
              description="Validator menu link"
            />
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box p={2}>
        <BadgesValidator
          axios={API.axios()}
          validationApiURL={process.env.REACT_APP_VALIDATOR_URL}
          verifyApiURL={process.env.REACT_APP_API_TIMESTAMP_VERIFY_URL}
          badgeText={badgeText}
          locale={locale}
        />
      </Box>
    </AdminLayout>
  );
};

export default Validator;
