import { Box, Icon, Typography } from "@material-ui/core";
import React from "react";
import { useIntl } from "react-intl";

const IssuerIdentityStatus = ({ status, color }) => {
  const intl = useIntl();

  const getStatus = (status) => {
    switch (status) {
      case "validating":
        return intl.formatMessage({
          id: "identity-status.validating",
          defaultMessage: "Validating",
        });
      case "validated":
        return intl.formatMessage({
          id: "identity-status.validated",
          defaultMessage: "Validated",
        });
      default:
        return intl.formatMessage({
          id: "identity-status.not-validated",
          defaultMessage: "Not Validated",
        });
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "validating":
        return "#C9C900";
      case "validated":
        return "#009900";
      default:
        return "#999999";
    }
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case "validating":
        return <Icon fontSize="small">access_time</Icon>;
      case "validated":
        return <Icon fontSize="small">check_circle_outlined</Icon>;
      default:
        return <Icon fontSize="small">block</Icon>;
    }
  };

  return (
    <Box display="flex" flexDirection="row">
      <Typography variant="body2" style={{ color: getStatusColor(status) }}>
        {getStatus(status)}
      </Typography>
      <Box
        display="flex"
        flexDirection="row"
        pl={0.5}
        style={{ color: getStatusColor(status) }}
      >
        {getStatusIcon(status)}
      </Box>
    </Box>
  );
};

export default IssuerIdentityStatus;
