class IssuerPlan {
  constructor(
    name,
    description,
    enabledCapabilities,
    supplies,
    price,
    currentPlan,
    publicPlan
  ) {
    this.name = name;
    this.description = description;
    this.enabledCapabilities = enabledCapabilities;
    this.supplies = supplies;
    this.price = price;
    this.currentPlan = currentPlan;
    this.public = publicPlan;
  }
}

export default IssuerPlan;
