import { Button, Icon } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import React, { useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { SubscriptionPlanContext } from "../../context/SubscriptionPlanProvider";

const getAlertColor = (paymentStatus) => {
  switch (paymentStatus) {
    case "pending":
      return "warning";
    case "processing":
      return "info";
    case "authorized":
      return "success";
    default:
      return "error";
  }
};

const getAlertIcon = (paymentStatus) => {
  switch (paymentStatus) {
    case "pending":
      return <Icon>help</Icon>;
    case "processing":
      return <Icon>payment</Icon>;
    case "authorized":
      return <Icon>check</Icon>;
    default:
      return <Icon>error</Icon>;
  }
};
const SubscriptionPlanPaymentStatus = ({ requestedSubscription, ...rest }) => {
  const intl = useIntl();
  const { setOpenPaymentDialog } = useContext(SubscriptionPlanContext);

  const titles = {
    pending: intl.formatMessage({
      id: "subscriptio-payment-status-alert-title.pending",
      defaultMessage: "Subscription Payment Pending",
    }),
    processing: intl.formatMessage({
      id: "subscriptio-payment-status-alert-title.processing",
      defaultMessage: "Subscription Payment In Process",
    }),
    authorized: intl.formatMessage({
      id: "subscriptio-payment-status-alert-title.authorized",
      defaultMessage: "Subscription Payment Authorized",
    }),
    error: intl.formatMessage({
      id: "subscriptio-payment-status-alert-title.error",
      defaultMessage: "Subscription Payment Error",
    }),
  };

  const info = {
    pending: intl.formatMessage({
      id: "subscriptio-payment-status-alert-info.pending",
      defaultMessage:
        "Please complete your new subscription payment to proceed",
    }),
    processing: intl.formatMessage({
      id: "subscriptio-payment-status-alert-info.processing",
      defaultMessage: "We are processing your payment",
    }),
    authorized: intl.formatMessage({
      id: "subscriptio-payment-status-alert-info.authorized",
      defaultMessage: "Your payment was successfully authorized",
    }),
    error: intl.formatMessage({
      id: "subscriptio-payment-status-alert-info.error",
      defaultMessage: "Oops! Something went wrong",
    }),
  };

  if (!requestedSubscription.payment) {
    return <></>;
  }

  return (
    <Alert
      color={getAlertColor(requestedSubscription.payment.status)}
      icon={getAlertIcon(requestedSubscription.payment.status)}
      action={
        requestedSubscription.payment.status === "pending" && (
          <Button
            color="inherit"
            size="small"
            variant="outlined"
            onClick={() => setOpenPaymentDialog(true)}
          >
            <FormattedMessage
              id="requested-plan.pay-button"
              defaultMessage="Complete Payment"
            />
          </Button>
        )
      }
    >
      <AlertTitle>
        {titles[requestedSubscription.payment.status] ?? titles["error"]}
      </AlertTitle>
      {info[requestedSubscription.payment.status] ?? info["error"]}
    </Alert>
  );
};

export default SubscriptionPlanPaymentStatus;
