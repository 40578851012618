import {
  Box,
  CircularProgress,
  Grow,
  Icon,
  Paper,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

const Download = (props) => {
  const [download, setDownload] = useState(props.download);

  useEffect(() => {
    download.downloadPromise &&
      download.downloadPromise
        .then((response) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: download.filetype })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", download.filename);

          setDownload({ ...download, status: "downloaded" });

          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);

          setTimeout(() => {
            setDownload({ ...download, remove: true, downloadPromise: false });
          }, 1000);
        })
        .catch((err) => {
          setDownload({ ...download, status: "error" });
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [download.downloadPromise]);

  if (!download) {
    return <></>;
  }

  return (
    <Grow in={!download.remove}>
      <Paper variant="outlined" style={{ padding: "5px", marginTop: "5px" }}>
        <Box display="flex">
          <Box display="grid" alignItems="center" style={{ padding: "15px" }}>
            {download.status === "error" && (
              <Icon fontSize="1em" color="error">
                warning
              </Icon>
            )}
            {download.status === "downloaded" && (
              <Icon fontSize="1em" color="primary">
                check
              </Icon>
            )}
            {download.status === "downloading" && (
              <CircularProgress size="1.5em" />
            )}
          </Box>
          <Box style={{ paddingRight: "10px" }}>
            <Typography variant="caption" color="textSecondary">
              <FormattedMessage id="downloading" defaultMessage="Downloading" />
            </Typography>
            <Typography variant="body2">{download.filename}</Typography>
          </Box>
        </Box>
      </Paper>
    </Grow>
  );
};

export default Download;
