import { Box, CircularProgress } from "@material-ui/core";
import ErrorsAlert from "../components/Common/ErrorsAlert";
import React, { useEffect, useState } from "react";
import usePromise from "../hooks/usePromise";
import BadgeClassesService from "../services/BadgeClassesService";
import { useIntl } from "react-intl";

const CreateBadgeContext = React.createContext();

const CreateBadgeProvider = (props) => {
  const intl = useIntl();
  const [badgeClassData, errors, loading, setPromiseLoad] = usePromise();
  const [
    badgeClassConfigData,
    configErrors,
    configLoading,
    setPromiseLoadConfig,
  ] = usePromise();

  const [badgeClass, setBadgeClass] = useState({
    name: "",
    description: "",
    criteria: "",
    tagNames: [],
    level: "basic",
    timestamps: "",
    svgDesign: "",
    svgFinal: "",
    saved: false,
  });

  const [badgeClassConfig, setBadgeClassConfig] = useState({
    frameLevels: [],
    frameTimestampAuthorities: [],
    anchorPoints: [],
    tsaImageWidth: null,
    tsaImageHeight: null,
  });

  BadgeClassesService.setDefaultErrorMessage(
    intl.formatMessage({
      id: "errors.unexpected-error",
      defaultMessage: "An unexpected error ocurred.",
    })
  );

  useEffect(() => {
    if (!props.isCreatingBadge) {
      setPromiseLoad(BadgeClassesService.getBadgeClass(props.badgeUuid));
    }
    if (!props.preventBadgeClassConfigLoad) {
      setPromiseLoadConfig(BadgeClassesService.getConfig());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (badgeClassData) {
      setBadgeClass(badgeClassData);
    }
    if (badgeClassConfigData) {
      setBadgeClassConfig(badgeClassConfigData);
    }
  }, [badgeClassData, badgeClassConfigData]);

  if (errors) {
    return (
      <Box>
        <ErrorsAlert errors={errors} />
      </Box>
    );
  }

  if (configErrors) {
    return (
      <Box>
        <ErrorsAlert errors={configErrors} />
      </Box>
    );
  }

  return (
    <CreateBadgeContext.Provider
      value={{ ...props.value, badgeClass, setBadgeClass, badgeClassConfig }}
    >
      {loading || configLoading ? (
        <Box display="grid" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        props.children
      )}
    </CreateBadgeContext.Provider>
  );
};

export { CreateBadgeProvider, CreateBadgeContext };
