import React, { useContext, useState } from "react";
import {
  Button,
  Collapse,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  SwipeableDrawer,
} from "@material-ui/core";
import { routes } from "../../config/routes";
import { UserContext } from "../../context/UserProvider";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const MobileMenu = (props) => {
  const [drawerOpen, setDrawerOpen] = useState(props.drawerOpen || false);
  const { user } = useContext(UserContext);
  const classes = useStyles();
  const [anchorPos, setAnchorPos] = useState(null);

  const menuRoutes = user ? routes.protected : routes.public;

  const handleClick = (event, pos) => {
    event.stopPropagation();
    setAnchorPos(anchorPos ? null : pos);
  };

  return (
    <>
      <Button
        onClick={() => {
          setDrawerOpen(true);
        }}
      >
        <Icon>menu</Icon>
      </Button>
      <SwipeableDrawer
        open={drawerOpen}
        onOpen={() => {
          setDrawerOpen(true);
        }}
        onClose={() => {
          setDrawerOpen(false);
        }}
      >
        <div role="presentation" onClick={() => setDrawerOpen(false)}>
          <List>
            {menuRoutes.map((route, pos) =>
              route.routes ? (
                <>
                  <ListItem
                    button
                    key={pos}
                    onClick={(evt) => handleClick(evt, pos)}
                  >
                    <ListItemIcon>{route.icon}</ListItemIcon>
                    <ListItemText primary={route.message} />
                    {anchorPos === pos ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={anchorPos === pos} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {route.routes.map((route, pos) => (
                        <ListItem
                          button
                          key={pos}
                          to={route.to}
                          component={Link}
                          className={classes.nested}
                        >
                          <ListItemIcon>{route.icon}</ListItemIcon>
                          <ListItemText primary={route.message} />
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                </>
              ) : (
                <ListItem button key={route.to} to={route.to} component={Link}>
                  <ListItemIcon>{route.icon}</ListItemIcon>
                  <ListItemText primary={route.message} />
                </ListItem>
              )
            )}
            {user && (
              <ListItem
                button
                key="/send-badges"
                to="/send-badges"
                component={Link}
              >
                <ListItemIcon>
                  <Icon>send</Icon>
                </ListItemIcon>
                <ListItemText
                  primary={
                    <FormattedMessage
                      id="button.send-badges"
                      defaultMessage="Send Badges"
                    />
                  }
                />
              </ListItem>
            )}
          </List>
        </div>
      </SwipeableDrawer>
    </>
  );
};

export default MobileMenu;
