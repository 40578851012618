import { Button } from "@material-ui/core";
import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { DownloadManagerContext } from "../../context/DownloadManagerProvider";
import API from "../../utils/API";

const BadgeDownloadButton = (props) => {
  const fileName = props.filename || props.uuid + ".png";

  const { addDownload } = useContext(DownloadManagerContext);

  const handleClick = () => {
    addDownload(
      API.downloadFile("/badges/" + props.uuid + ".png"),
      fileName,
      "image/png"
    );
  };

  return (
    <Button onClick={handleClick} {...props} data-test="download">
      {props.children ? (
        props.children
      ) : (
        <FormattedMessage
          defaultMessage="Download"
          id="badge.download-button"
          description="Badge download button text"
        />
      )}
    </Button>
  );
};

export default BadgeDownloadButton;
