import {
  Box,
  Button,
  CircularProgress,
  Icon,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { FormattedMessage, useIntl } from "react-intl";
import FileUploadService from "../../services/FileUploadService";
import API from "../../utils/API";
import usePromise from "../../hooks/usePromise";
import IssuerImageRemoveConfirmDialog from "./IssuerImageRemoveConfirmDialog";

const useStyle = makeStyles({
  dropzone: {
    borderStyle: "dashed",
    height: "100%",
    overflow: "hidden",
  },
});

const IssuerFormImageField = ({ onChange, value, ...rest }) => {
  const classes = useStyle();
  const intl = useIntl();
  const [currentImage, setCurrentImage] = useState(value);
  const [preview, setPreview] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const [
    dataImage,
    errorsLoading,
    loadingImage,
    setPromiseLoadImage,
  ] = usePromise();

  useEffect(() => {
    if (currentImage?.uuid) {
      onChange({ target: { name: "image", value: currentImage.uuid } });
      setPromiseLoadImage(
        API.downloadFile("/uploaded_files/" + currentImage.uuid)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentImage]);

  useEffect(() => {
    if (dataImage.data) {
      setShowConfirmDialog(false);
      setPreview(URL.createObjectURL(dataImage.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataImage]);

  const handleFileDrop = (acceptedFiles, fileRejections) => {
    let errors = [];
    fileRejections.map((f) => {
      return errors.push(
        intl.formatMessage(
          {
            id: "file-not-image",
            defaultMessage: "File {fileName} is not a valid image.",
          },
          { fileName: f.file.name }
        )
      );
    });
    //errors.length && setFileErrors(errors);
    setUploading(true);
    FileUploadService.uploadFile(acceptedFiles[0]).then((data) => {
      setCurrentImage(data);
      setUploading(false);
    });
  };

  if (preview) {
    return (
      <>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignContent="center"
          style={{ minHeight: "30vh" }}
        >
          <img
            src={preview}
            alt="test"
            data-testid="issuerImage"
            style={{
              maxWidth: "100%",
              maxHeight: "30vh",
              objectFit: "contain",
            }}
          />
          <Button
            onClick={(evt) => setShowConfirmDialog(true)}
            variant="outlined"
            size="small"
            startIcon={<Icon>delete</Icon>}
          >
            <FormattedMessage
              id="issuer-form.delete-button"
              defaultMessage="Delete"
            />
          </Button>
        </Box>
        <IssuerImageRemoveConfirmDialog
          handleConfirm={() => {
            setPreview(null);
            onChange({ target: { name: "image", value: "" } });
          }}
          handleClose={() => setShowConfirmDialog(false)}
          open={showConfirmDialog}
        />
      </>
    );
  }

  return (
    <Dropzone
      accept="image/*"
      onDrop={(acceptedFiles, fileRejections) =>
        handleFileDrop(acceptedFiles, fileRejections)
      }
    >
      {({ getRootProps, getInputProps, isDragActive }) => (
        <Box
          display="flex"
          flexDirection="column"
          p={4}
          border={1}
          borderRadius={3}
          justifyContent="center"
          alignItems="center"
          className={classes.dropzone}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          {(loadingImage || uploading) && <CircularProgress />}
          {!loadingImage && !uploading && (
            <>
              <Typography variant="body1" color="textSecondary">
                <FormattedMessage
                  id="issuer-form.image-label"
                  defaultMessage="Profile image"
                />
              </Typography>
              <Box m={2}>
                <Icon fontSize="large" color="action">
                  image
                </Icon>
              </Box>
              <Typography
                align="center"
                variant="caption"
                color="textSecondary"
              >
                <FormattedMessage
                  id="issuer-form.image-description"
                  defaultMessage="Drag 'n' drop an image here, or click to select one."
                />
              </Typography>
            </>
          )}
        </Box>
      )}
    </Dropzone>
  );
};

export default IssuerFormImageField;
