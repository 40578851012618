import { Box, Button, TextField, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { SendBadgesBatchContext } from "../../context/SendBadgesBatchProvider";
import usePromise from "../../hooks/usePromise";
import EarnersService from "../../services/EarnersService";

const EarnerSelectInput = () => {
  const intl = useIntl();
  const [earnersData, errors, loading, setPromise] = usePromise();
  const [earners, setEarners] = useState([]);
  const [search, setSearch] = useState("");

  const { addEarners, getEarnersIds } = useContext(SendBadgesBatchContext);

  const inputs = {
    earners: {
      label: intl.formatMessage({
        id: "send-badges.earners-input",
        defaultMessage: "Search recipient",
        description: "Recipients search label",
      }),
      placeholder: intl.formatMessage({
        id: "send-badges.earners-input-placeholder",
        defaultMessage: "Search recipient by ID, name or email",
        description: "Recipients search placeholder",
      }),
    },
  };

  EarnersService.setDefaultErrorMessage(
    intl.formatMessage({
      id: "errors.unexpected-error",
      defaultMessage: "An unexpected error ocurred.",
    })
  );

  useEffect(() => {
    setPromise(EarnersService.getEarners(5, 1, search));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPromise(EarnersService.getEarners(5, 1, search));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (earnersData) {
      setEarners(earnersData.collection);
    }
  }, [earnersData]);

  const handleInputChange = (event) => {
    setSearch(event.target.value);
  };

  const getLabel = (option) => {
    return `${option.externalId ? "[" + option.externalId + "] " : ""} ${
      option.name
    } - ${option.email}`;
  };

  const handleAutocompleteChange = (event, value, reason) => {
    if (reason === "select-option") {
      addEarners([value]);
      event.stopPropagation();
    }
    if (reason === "clear") {
      setSearch("");
    }
  };

  return (
    <Autocomplete
      options={earners}
      loading={loading}
      noOptionsText={errors ? errors[0] : undefined}
      disableCloseOnSelect={true}
      onChange={handleAutocompleteChange}
      getOptionLabel={(option) => getLabel(option)}
      getOptionDisabled={(option) =>
        getEarnersIds().indexOf(option.uuid) !== -1
      }
      renderOption={(option) => (
        <Box
          flexGrow={1}
          display="flex"
          flexDirection="row"
          justifyItems="space-between"
        >
          <Box flexGrow={1}>{getLabel(option)}</Box>
          <Box>
            {getEarnersIds().indexOf(option.uuid) !== -1 ? (
              <Typography variant="caption">
                <FormattedMessage
                  id="earners-select-input.added-text"
                  defaultMessage="Added"
                  description="Text to display when Recipient has been added to a list."
                />
              </Typography>
            ) : (
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={(e) => {
                  addEarners([option]);
                  e.stopPropagation();
                }}
              >
                <FormattedMessage
                  id="earners-select-input.add-button"
                  defaultMessage="Add"
                  description="Add Recipient to list Button text."
                />
              </Button>
            )}
          </Box>
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={inputs.earners.label}
          placeholder={inputs.earners.placeholder}
          onChange={handleInputChange}
          variant="outlined"
          size="small"
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
};

export default EarnerSelectInput;
