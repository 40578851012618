import {
  Box,
  Breadcrumbs,
  Card,
  CardContent,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { TimestampVerifier } from "matedapps-timestamp-verifier";
import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { Link, useParams } from "react-router-dom";
import { LanguageContext } from "../context/LanguageProvider";
import AdminLayout from "../layouts/AdminLayout";
import API from "../utils/API";

const useStyle = makeStyles((theme) => ({
  cardContent: {
    padding: "0px",
  },
  cardTitle: {
    backgroundColor: `${theme.palette.primary.light}`,
    color: `${theme.palette.primary.contrastText}`,
    padding: "10px",
  },
}));

const VerifyTimestamp = () => {
  const classes = useStyle();
  const params = useParams();
  const timestampType = params.timestampType;
  const timestampPayload = params.timestampPayload;
  const { locale } = useContext(LanguageContext);

  return (
    <AdminLayout>
      <Box p={2}>
        <Breadcrumbs separator=">" aria-label="breadcrumb">
          <Link style={{ color: "inherit" }} to="/">
            <FormattedMessage
              id="routes.protected.dashboard"
              defaultMessage="Dashboard"
              description="Dashboard menu breadcrumbs title"
            />
          </Link>
          <Typography color="textPrimary">
            <FormattedMessage
              id="timestmap-verifier-title"
              defaultMessage="Timestamp Verifier"
              description="Timestamp Verifier title"
            />
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box p={2}>
        <Card variant="outlined">
          <CardContent className={classes.cardContent}>
            <Box
              display="grid"
              justifyContent="center"
              className={classes.cardTitle}
            >
              <Typography variant="h5">
                <FormattedMessage
                  id="timestmap-verifier-title"
                  defaultMessage="Timestamp Verifier"
                  description="Timestamp Verifier title"
                />
              </Typography>
            </Box>
            <Box p={2}>
              <TimestampVerifier
                axios={API.axios()}
                verifyApiURL={process.env.REACT_APP_API_TIMESTAMP_VERIFY_URL}
                timestampAuthority={timestampType}
                hash={timestampPayload}
                locale={locale}
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </AdminLayout>
  );
};

export default VerifyTimestamp;
