import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { SubscriptionPlanContext } from "../../context/SubscriptionPlanProvider";
import FormattedCurrency from "../Account/FormattedCurrency";

const useStyle = makeStyles((theme) => ({
  currentPlan: {
    borderColor: theme.palette.primary.main,
    height: "100%",
  },
}));

const SubscriptionPlanPaper = ({ plan, disableSelectButton, ...rest }) => {
  const classes = useStyle();
  const { selectNewPlan } = useContext(SubscriptionPlanContext);

  const handleSelectPlanClick = (plan) => {
    selectNewPlan(plan);
  };

  if (!plan) {
    return <></>;
  }

  return (
    <Paper
      variant="outlined"
      className={plan.currentPlan ? classes.currentPlan : ""}
    >
      <Box p={2}>
        <Box display="grid" justifyContent="center">
          <Typography variant="h5" color="primary">
            {plan.description}
          </Typography>
        </Box>
        <Box mt={1} display="grid" justifyContent="center">
          <List color="textSecondary">
            <ListItem>
              {plan.price ? (
                <ListItemText
                  primaryTypographyProps={{
                    variant: "body2",
                    color: "textSecondary",
                  }}
                >
                  <FormattedMessage
                    id="monthly-fee"
                    defaultMessage="Monthly Fee"
                  />
                  {": "}
                  <FormattedCurrency
                    value={plan.price.value}
                    currency={plan.price.currency}
                    variant="body2"
                    color="textPrimary"
                    currencyProps={{
                      color: "textPrimary",
                      variant: "caption",
                      style: {
                        paddingBottom: "0.15em",
                        paddingTop: "0.15em",
                        paddingLeft: "0.2em",
                        paddingRight: "0.2em",
                      },
                    }}
                  />
                </ListItemText>
              ) : (
                <ListItemText>
                  <Typography variant="body2" color="textSecondary">
                    <FormattedMessage id="free" defaultMessage="Free" />
                  </Typography>
                </ListItemText>
              )}
            </ListItem>
          </List>
        </Box>
        <Box mt={1} display="grid" justifyContent="center">
          {plan.currentPlan ? (
            <Typography variant="button" color="textSecondary">
              <FormattedMessage
                id="current-plan"
                defaultMessage="Current Plan"
              />
            </Typography>
          ) : (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleSelectPlanClick(plan)}
              disabled={disableSelectButton}
            >
              <FormattedMessage
                id="select-plan-button"
                defaultMessage="Select Plan"
              />
            </Button>
          )}
        </Box>
      </Box>
    </Paper>
  );
};

export default SubscriptionPlanPaper;
