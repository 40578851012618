import { Box, CircularProgress } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import { UserContext } from "../../context/UserProvider";
import AdminLayout from "../../layouts/AdminLayout";
import API from "../../utils/API";
import history from "../../utils/history";

const ProtectedRoute = ({
  children,
  onlyAdmin,
  path,
  issuerRequired,
  ...rest
}) => {
  const { user, issuer, loadingUser, setReferer } = useContext(UserContext);
  useEffect(() => {
    setReferer(null);

    //Everything OK, don't save referer
    if (API.hasAccessToken() && user && (!onlyAdmin || user.admin)) {
      return;
    }

    //Save referer if will redirect to login
    if (
      history.location.pathname !== "/login" &&
      history.location.pathname !== rest.loginPath
    ) {
      setReferer(history.location.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loadingUser) {
      return;
    }

    //Redirect to /create-issuer if user is logged in and doesn't have issuer (and page requires issuer)
    if (issuerRequired && user && !issuer && path !== "/create-issuer") {
      history.push("/create-issuer");
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingUser, user, issuer, path, issuerRequired]);

  if (loadingUser) {
    return (
      <AdminLayout>
        <Box display="grid" justifyContent="center" my={4}>
          <CircularProgress />
        </Box>
      </AdminLayout>
    );
  }

  if (issuerRequired && user && !issuer && path !== "/create-issuer") {
    return (
      <AdminLayout>
        <Box display="grid" justifyContent="center" my={4}>
          <CircularProgress />
        </Box>
      </AdminLayout>
    );
  }

  if (API.hasAccessToken() && user && (!onlyAdmin || user.admin)) {
    return <Route path={path} {...rest} />;
  }

  return (
    <Route path={path}>
      <Redirect to={rest.loginPath || "/login"} />
    </Route>
  );
};

export default ProtectedRoute;
