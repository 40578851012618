import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { mdiArrangeBringForward, mdiArrangeSendBackward } from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";

const SvgEditorOrderTools = (props) => {
  return (
    <ToggleButtonGroup
      exclusive
      aria-label="toolbar"
      onChange={props.onOrderChange}
    >
      <ToggleButton value="backward" aria-label="Send Backward" key="backward">
        <Icon size={0.8} path={mdiArrangeSendBackward}></Icon>
      </ToggleButton>
      <ToggleButton value="forward" aria-label="Bring Forward" key="forward">
        <Icon size={0.8} path={mdiArrangeBringForward}></Icon>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default SvgEditorOrderTools;
