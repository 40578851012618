import { Box, CircularProgress, Paper } from "@material-ui/core";
import React, { useEffect } from "react";
import AccountService from "../../services/AccountService";
import usePromise from "../../hooks/usePromise";
import FormattedCurrency from "./FormattedCurrency";

const AccountBalances = (props) => {
  const [data, errors, loading, setPromise, reset] = usePromise();

  useEffect(() => {
    setPromise(AccountService.getAccountBalances());
    return () => {
      reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (errors) {
    return <></>;
  }

  return (
    <Paper variant="outlined" {...props}>
      <Box p={3}>
        {loading && (
          <Box display="grid" justifyContent="center">
            <CircularProgress size="1.4rem" />
          </Box>
        )}
        {data &&
          data.map((balance) => (
            <Box
              key={balance.id}
              display="flex"
              alignItems="flex-end"
              justifyContent="right"
            >
              <FormattedCurrency
                value={balance.value}
                currency={balance.currency}
                variant="h5"
                currencyProps={{
                  color: "textSecondary",
                  variant: "caption",
                  style: {
                    minWidth: "3em",
                    paddingLeft: "0.3em",
                    paddingBottom: "0.25em",
                  },
                }}
              />
            </Box>
          ))}
      </Box>
    </Paper>
  );
};

export default AccountBalances;
