import { Box, Button, Icon, Typography } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import React, { useContext, useState } from "react";
import { FormattedMessage } from "react-intl";
import { SubscriptionPlanContext } from "../../context/SubscriptionPlanProvider";
import RequestedSubscriptionPlanCancelDialog from "./RequestedSubscriptionPlanCancelDialog";
import SubscriptionPlanPaymentStatus from "./SubscriptionPlanPaymentStatus";

const RequestedSubscriptionPlanDetail = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const {
    requestedSubscription,
    cancelRequestedSubscription,
    cancellingRequestedSubscription,
    errorsCancelling,
    setRequestedSubscription,
  } = useContext(SubscriptionPlanContext);

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleConfirm = () => {
    cancelRequestedSubscription().then(() => {
      setOpenDialog(false);
      setRequestedSubscription(false);
    });
  };

  if (!requestedSubscription) {
    return <></>;
  }

  return (
    <>
      {!requestedSubscription.payment ||
      requestedSubscription.payment.gatewayName !== "MERCADOPAGO" ? (
        <Alert color="warning" icon={<Icon>schedule</Icon>}>
          <AlertTitle>
            <FormattedMessage
              id="requested-plan.title"
              defaultMessage="We are changing your plan to {planDescription}"
              values={{
                planDescription: requestedSubscription.issuerPlan.description,
              }}
            />
          </AlertTitle>
        </Alert>
      ) : (
        <SubscriptionPlanPaymentStatus
          requestedSubscription={requestedSubscription}
        />
      )}
      <Box mt={1} justifyContent="right" display="grid">
        <Button
          variant="outlined"
          color="default"
          size="small"
          onClick={() => setOpenDialog(true)}
        >
          <Typography variant="caption">
            <FormattedMessage
              id="requested-plan.cancel-button"
              defaultMessage="Cancel Subscription Plan Change"
            />
          </Typography>
        </Button>
      </Box>
      <RequestedSubscriptionPlanCancelDialog
        open={openDialog}
        handleConfirm={handleConfirm}
        handleClose={handleDialogClose}
        cancelling={cancellingRequestedSubscription}
        errors={errorsCancelling}
      />
    </>
  );
};

export default RequestedSubscriptionPlanDetail;
