import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Icon,
  IconButton,
  Typography,
} from "@material-ui/core";
import React, { useContext, useState } from "react";
import { FormattedMessage } from "react-intl";
import { SubscriptionPlanContext } from "../../context/SubscriptionPlanProvider";
import FormattedCurrency from "../Account/FormattedCurrency";
import ErrorsAlert from "../Common/ErrorsAlert";
import MercadoPagoCardForm from "../Payments/MercadoPagoCardForm";

const calculateAmount = (value, decimals) => {
  return value / Math.pow(10, decimals ?? 2);
};

const SubscriptionPaymentDialog = ({ open, onClose, payment, ...props }) => {
  const {
    requestedSubscription,
    authorizeSubscription,
    errorsAuthorizing,
    authorizing,
    resetAuthorizePromise,
  } = useContext(SubscriptionPlanContext);

  const [cardPaymentBrickController, setCardPaymentBrickController] = useState(
    null
  );

  const handleClose = () => {
    cardPaymentBrickController && cardPaymentBrickController.unmount();
    resetAuthorizePromise();
    onClose();
  };

  if (!requestedSubscription || !requestedSubscription.issuerPlanPrice) {
    return <></>;
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth={true}>
      <DialogTitle>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography variant="subtitle1">
            <FormattedMessage
              id="subscription-payment-dialog.title"
              defaultMessage="Subscription payment"
            />
          </Typography>
          <IconButton size="small" onClick={handleClose}>
            <Icon>close</Icon>
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers={true}>
        <Box p={1}>
          <Typography variant="subtitle1" paragraph={true} align="center">
            <FormattedMessage
              id="subscription-payment-dialog.proportional-payment-text"
              defaultMessage="We will charge you {proportionalAmount} pro rata for the current month. After that we will charge you {amount} monthly."
              values={{
                proportionalAmount: (
                  <FormattedCurrency
                    currency={requestedSubscription.issuerPlanPrice.currency}
                    value={requestedSubscription.proportionalAmount}
                  />
                ),
                amount: (
                  <FormattedCurrency
                    currency={requestedSubscription.issuerPlanPrice.currency}
                    value={requestedSubscription.issuerPlanPrice.value}
                  />
                ),
              }}
            />
          </Typography>
        </Box>
        <Divider />
        <Box p={1}>
          {requestedSubscription.payment.gatewayName === "MERCADOPAGO" ? (
            <MercadoPagoCardForm
              amount={calculateAmount(
                requestedSubscription.issuerPlanPrice.value,
                requestedSubscription.issuerPlanPrice.currency.decimals
              )}
              onSubmit={(cardFormData) => authorizeSubscription(cardFormData)}
              authorizing={authorizing}
              errorsAuthorizing={errorsAuthorizing}
              setCardPaymentBrickController={setCardPaymentBrickController}
              cardPaymentBrickController={cardPaymentBrickController}
            />
          ) : (
            <ErrorsAlert errors={["Oops! Something went wrong."]} />
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SubscriptionPaymentDialog;
