import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  Typography,
} from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";
import LoadingButton from "../Common/LoadingButton";

const EarnerDeleteDialog = (props) => {
  return (
    <>
      <Dialog open={props.open} onClose={props.handleClose}>
        <DialogTitle>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Typography variant="subtitle1">
              <FormattedMessage
                id="earner-delete-dialog.title"
                defaultMessage="Delete Recipient"
              />
            </Typography>
            <IconButton size="small" onClick={props.handleClose}>
              <Icon>close</Icon>
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers={true}>
          <Typography>
            <FormattedMessage
              id="earner-delete-dialog.information"
              defaultMessage="You are going to delete the recipient permanently. Are you sure?"
            />
          </Typography>
        </DialogContent>
        <DialogActions>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            flexGrow={1}
            p={1}
          >
            <Button onClick={props.handleClose}>
              <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
            </Button>
            <LoadingButton
              loading={props.deleting}
              onClick={props.handleDelete}
              variant="contained"
              color="primary"
            >
              <FormattedMessage
                id="earner-delete-dialog.confirm-button"
                defaultMessage="Yes, delete"
              />
            </LoadingButton>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EarnerDeleteDialog;
