import { Box, Button, CircularProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Link, useParams } from "react-router-dom";
import usePromise from "../../hooks/usePromise";
import AuthService from "../../services/AuthService";
import ErrorsAlert from "../Common/ErrorsAlert";

const SignUpValidation = () => {
  const params = useParams();
  const [data, errors, loading, setPromise] = usePromise();

  useEffect(() => {
    if (params.validationHash) {
      setPromise(AuthService.validateRegistration(params.validationHash));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.validationHash]);

  return (
    <Box p={5} display="grid" justifyItems="center">
      {loading && <CircularProgress color="primary" />}
      {errors && <ErrorsAlert errors={errors} />}
      {data.validated && (
        <Box display="grid" justifyItems="center">
          <Alert variant="standard" color="success">
            <FormattedMessage
              id="validation.success-message"
              description="Message shown after user validates registration"
              defaultMessage="Your email was verified successfully!"
            />
          </Alert>
          <Box mt={2}>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to="/login"
            >
              <FormattedMessage
                id="header.login"
                description="Log In button"
                defaultMessage="Log In"
              />
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default SignUpValidation;
