import { Box } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { ValidatorForm } from "react-form-validator-core";
import { FormattedMessage, useIntl } from "react-intl";
import TextValidator from "react-material-ui-form-validator/lib/TextValidator";
import LoadingButton from "../Common/LoadingButton";
import GoogleRecaptchaInfo from "../Misc/GoogleRecaptchaInfo";

const ResetPasswordForm = (props) => {
  const intl = useIntl();
  const [formData, setFormData] = useState({ password: "", repassword: "" });
  const passwordInput = useRef(null);

  const handleInputChange = (event) => {
    event.preventDefault();
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
      if (value !== passwordInput.current.state.value) {
        return false;
      }
      return true;
    });
  }, []);

  const validationMessages = {
    required: intl.formatMessage({
      id: "validation.field-required",
      defaultMessage: "This field is required",
      description: "Label shown on required form field when is required",
    }),
    isPasswordMatch: intl.formatMessage({
      id: "validation.field-password-match",
      defaultMessage: "Passwords don't match",
      description:
        "Label shown on confirm password form field when doesn't match original password",
    }),
  };

  const inputs = {
    password: {
      label: intl.formatMessage({
        id: "form.new-password-input",
        defaultMessage: "New Password",
        description: "Password input label",
      }),
      placeholder: intl.formatMessage({
        id: "form.new-password-placeholder",
        defaultMessage: "Please enter your new password",
        description: "Password input placeholder",
      }),
    },
    repassword: {
      label: intl.formatMessage({
        id: "form.confirm-password-input",
        defaultMessage: "Confirm new password",
        description: "Confirm password input label",
      }),
      placeholder: intl.formatMessage({
        id: "form.confirm-password-placeholder",
        defaultMessage: "Please re enter your password",
        description: "Confirm password input placeholder",
      }),
    },
  };

  return (
    <ValidatorForm
      onSubmit={() => props.handleFormSubmit(formData)}
      data-testid="formResetPassword"
    >
      <Box mt={1}>
        <TextValidator
          onChange={handleInputChange}
          name="password"
          type="password"
          value={formData["password"]}
          label={inputs.password.label}
          placeholder={inputs.password.placeholder}
          fullWidth
          validators={["required"]}
          errorMessages={[validationMessages.required]}
          inputProps={{ "data-testid": "inputPassword" }}
          ref={passwordInput}
          variant="outlined"
        />
      </Box>
      <Box mt={1}>
        <TextValidator
          onChange={handleInputChange}
          name="repassword"
          type="password"
          value={formData["repassword"]}
          label={inputs.repassword.label}
          placeholder={inputs.repassword.placeholder}
          fullWidth
          validators={["isPasswordMatch", "required"]}
          errorMessages={[
            validationMessages.isPasswordMatch,
            validationMessages.required,
          ]}
          inputProps={{ "data-testid": "inputRepassword" }}
          variant="outlined"
        />
      </Box>
      <GoogleRecaptchaInfo />
      <Box mt={2} justifyContent="center" display="flex" alignItems="center">
        <LoadingButton
          loading={props.loading ? "true" : undefined}
          size="medium"
          color="primary"
          variant="contained"
          type="submit"
        >
          <FormattedMessage
            id="form.reset-password-button"
            description="Change password button"
            defaultMessage="Change password"
          />
        </LoadingButton>
      </Box>
    </ValidatorForm>
  );
};

export default ResetPasswordForm;
