import { Box } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import React, { useEffect, useState } from "react";
import usePromise from "../../hooks/usePromise";
import EarnersService from "../../services/EarnersService";
import ErrorsAlert from "../Common/ErrorsAlert";
import EarnerGridRow from "./EarnerGridRow";
import { useIntl } from "react-intl";

const EarnerList = (props) => {
  const [rows, setRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [data, errors, loading, setPromise] = usePromise();
  const limit = props.limit ? parseInt(props.limit, 10) : 25;
  const intl = useIntl();

  EarnersService.setDefaultErrorMessage(
    intl.formatMessage({
      id: "errors.unexpected-error",
      defaultMessage: "An unexpected error ocurred.",
    })
  );

  const renderRowButtons = (params) => {
    return (
      <EarnerGridRow
        earnerId={params.row.uuid}
        handleDeleteButtonClick={props.handleDeleteButtonClick}
      />
    );
  };

  const columns = [
    {
      field: "externalId",
      headerName: intl.formatMessage({
        id: "earner-grid-columns.ID",
        defaultMessage: "ID",
      }),
      width: 150,
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
    },
    {
      field: "email",
      headerName: intl.formatMessage({
        id: "earner-grid-columns.email",
        defaultMessage: "Email",
      }),
      flex: true,
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
    },
    {
      field: "name",
      headerName: intl.formatMessage({
        id: "earner-grid-columns.name",
        defaultMessage: "Name",
      }),
      flex: true,
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
    },
    {
      field: "issued_times",
      headerName: intl.formatMessage({
        id: "earner-grid-columns.badges-received",
        defaultMessage: "Badges received",
      }),
      width: 200,
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
      hide: true,
    },
    {
      field: "actions",
      headerName: intl.formatMessage({
        id: "earner-grid-columns.actions",
        defaultMessage: "Actions",
      }),
      width: 150,
      renderCell: renderRowButtons,
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
    },
  ];

  const handlePageChange = (params) => {
    setPromise(
      EarnersService.getEarners(
        limit,
        params.page + 1,
        props.search,
        props.tags
      )
    );
  };

  useEffect(() => {
    setPromise(
      EarnersService.getEarners(
        limit,
        props.pageNumber,
        props.search,
        props.tags
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data) {
      setRows(data.collection);
      setRowCount(data.paginationInfo.totalCount);
    }
  }, [data]);

  useEffect(() => {
    setPromise(
      EarnersService.getEarners(
        limit,
        props.pageNumber,
        props.search,
        props.tags
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tags, props.search]);

  useEffect(() => {
    if (props.removeEarnerId) {
      setRows(
        rows.filter((earnerRow) => {
          return earnerRow.uuid !== props.removeEarnerId;
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.removeEarnerId]);

  if (errors) {
    return (
      <Box>
        <ErrorsAlert errors={errors} />
      </Box>
    );
  }

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <DataGrid
        columns={columns}
        rows={rows}
        loading={loading}
        pagination
        pageSize={limit}
        rowCount={rowCount}
        paginationMode="server"
        onPageChange={handlePageChange}
        disableSelectionOnClick={true}
        disableColumnSelector={true}
        disableColumnReorder={true}
        autoHeight={true}
      ></DataGrid>
    </div>
  );
};

export default EarnerList;
