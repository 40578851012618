import API from "../utils/API";
import AuthService from "./AuthService";
import Service from "./Service";

class StatisticService extends Service {
  queries = {
    getIssuerStatisticsForMetric: (...params) =>
      `query { byIssuerQueryIssuerStatistics(issuer: "/api/issuers/${params[0]}", metric: "${params[1]}") {
        collection {
          statistic {
            _id
            type
            code
            value
          }
        }
      }}`,
  };

  getIssuerStatistics = async () => {
    let issuer = AuthService.getCurrentIssuer();

    return API.callGraphql(
      this.queries.getIssuerStatisticsForMetric(issuer.uuid, "badge.count")
    ).then((response) => {
      this.errorParser(response);

      if (!response.data.byIssuerQueryIssuerStatistics) {
        this.throwDefaultError();
      }

      let statistics = response.data.byIssuerQueryIssuerStatistics.collection.map(
        (issuerStatistic) => {
          return issuerStatistic.statistic;
        }
      );

      return statistics;
    });
  };
}

export default new StatisticService();
