import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  InputAdornment,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import usePromise from "../../hooks/usePromise";
import IssuersService from "../../services/IssuersService";
import ErrorsAlert from "../Common/ErrorsAlert";
import LoadingButton from "../Common/LoadingButton";
import IssuerIdentityContainer from "./IssuerIdentityContainer";

const IssuerIdentityEmailForm = ({ issuer, ...rest }) => {
  const intl = useIntl();
  const [email, setEmail] = useState(rest.email);
  const [status, setStatus] = useState(rest.status);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [data, errors, loading, setPromise] = usePromise();

  useEffect(() => {
    if (data.issuerIdentityValidation) {
      setStatus(data.issuerIdentityValidation.status);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const validationMessages = {
    required: intl.formatMessage({
      id: "validation.field-required",
      defaultMessage: "This field is required",
      description: "Label shown on required form field when is required",
    }),
    isEmail: intl.formatMessage({
      id: "validation.field-email",
      defaultMessage: "Email is not valid",
      description: "Label shown on required form field when email is not valid",
    }),
  };

  const handleValidationSubmit = () => {
    setPromise(IssuersService.createIdentity(issuer, "email", email));
  };

  const handleConfirmEditClick = () => {
    setStatus("not_validated");
    setOpenConfirmDialog(false);
  };

  const handleConfirmEditDialogClose = () => {
    setOpenConfirmDialog(false);
  };

  return (
    <IssuerIdentityContainer type="email" status={status}>
      <Typography variant="body2" color="textSecondary">
        <FormattedMessage
          id="issuer-identity.email-validation-instructions"
          defaultMessage="Prove the ownership of an email by receiving a validation link."
        />
      </Typography>
      <ValidatorForm onSubmit={handleValidationSubmit}>
        <Box display="flex" flexDirection="row" mt={1}>
          <Box flexGrow={1}>
            <TextValidator
              variant="outlined"
              fullWidth
              label={intl.formatMessage({
                id: "issuer-form.email-label",
                defaultMessage: "Email",
              })}
              placeholder={intl.formatMessage({
                id: "issuer-form.email-placeholder",
                defaultMessage: "info@ht-school.edu",
              })}
              value={email}
              onChange={(evt) => {
                setEmail(evt.target.value);
              }}
              disabled={status !== "not_validated"}
              validators={["required", "isEmail"]}
              errorMessages={[
                validationMessages.required,
                validationMessages.isEmail,
              ]}
              InputProps={{
                endAdornment: status !== "not_validated" && (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setOpenConfirmDialog(true)}>
                      <Icon>edit</Icon>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          {status === "not_validated" && (
            <Box ml={1}>
              <LoadingButton
                variant="outlined"
                loading={loading ? "true" : undefined}
                type="submit"
              >
                <Typography noWrap variant="button">
                  <FormattedMessage
                    id="issuer-identity.validate-button"
                    defaultMessage="Validate"
                  />
                </Typography>
              </LoadingButton>
            </Box>
          )}
        </Box>
        {status === "validating" && (
          <Box mt={1}>
            <Alert icon={false} color="warning">
              <FormattedMessage
                id="issuer-identity.email-validation-text"
                defaultMessage="We sent you and email to {email} with a validation link."
                values={{ email: email }}
              />
            </Alert>
          </Box>
        )}
        <Dialog open={openConfirmDialog} onClose={handleConfirmEditDialogClose}>
          <DialogTitle>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography variant="subtitle1">
                <FormattedMessage
                  id="issuer-identity-modify-identity-dialog.title"
                  defaultMessage="Warning"
                />
              </Typography>
              <IconButton size="small" onClick={handleConfirmEditDialogClose}>
                <Icon>close</Icon>
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent dividers={true}>
            <FormattedMessage
              id="issuer-identity-modify-identity-dialog.content"
              defaultMessage="You will loose your prevoius email validation if you modify this value. Are you sure?"
            />
          </DialogContent>
          <DialogActions>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              flexGrow={1}
              p={1}
            >
              <Button onClick={handleConfirmEditDialogClose}>
                <FormattedMessage
                  id="issuer-identity-modify-identity-dialog.button-cancel"
                  defaultMessage="Cancel"
                />
              </Button>
              <LoadingButton
                loading={undefined}
                onClick={handleConfirmEditClick}
                variant="contained"
                color="primary"
              >
                <FormattedMessage
                  id="issuer-identity-modify-identity-dialog.confirm-button"
                  defaultMessage="Yes, continue"
                />
              </LoadingButton>
            </Box>
          </DialogActions>
        </Dialog>
        {errors && (
          <Box mt={1}>
            <ErrorsAlert errors={errors} />
          </Box>
        )}
      </ValidatorForm>
    </IssuerIdentityContainer>
  );
};

export default IssuerIdentityEmailForm;
