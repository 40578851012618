import { Box, Chip, makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import useWindowSize from "../../hooks/useWindowSize";

const useStyles = makeStyles((theme) => ({
  tags: {
    "& > *": {
      marginRight: theme.spacing(0.5),
      marginTop: theme.spacing(0.5),
    },
  },
}));

const TagList = (props) => {
  const tags = props.tags ?? [];
  const classes = useStyles();
  const [hiddenTags, setHiddenTags] = useState(2);
  const tagsContainer = useRef(null);
  const windowSize = useWindowSize();

  useEffect(() => {
    let count = Array.from(tagsContainer.current.children).filter(
      (e) =>
        e.offsetTop >=
        tagsContainer.current.offsetTop + tagsContainer.current.offsetHeight
    ).length;
    setHiddenTags(count);
    return () => {
      setHiddenTags(0);
    };
  }, [windowSize.width]);

  return (
    <Box
      ref={tagsContainer}
      className={classes.tags}
      style={{ maxHeight: "2em", overflow: "hidden" }}
    >
      {hiddenTags > 0 && (
        <Chip
          style={{ float: "right", zIndex: 999 }}
          label={`+${hiddenTags}`}
          key="more_tags"
          size="small"
        />
      )}
      {tags.map((tag) => (
        <Chip label={tag} key={tag} size="small" />
      ))}
    </Box>
  );
};

export default TagList;
