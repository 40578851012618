import React, { createContext, useState } from "react";
import DownloadDeck from "../components/Downloads/DownloadDeck";

const DownloadManagerContext = createContext(null);

const DownloadManagerProvider = (props) => {
  const [downloads, setDownloads] = useState([]);

  const addDownload = (promise, filename, filetype) => {
    setDownloads([
      ...downloads,
      {
        downloadPromise: promise,
        filename: filename,
        filetype: filetype,
        status: "downloading",
      },
    ]);
  };

  return (
    <DownloadManagerContext.Provider value={{ addDownload }}>
      {props.children}
      <DownloadDeck downloads={downloads} />
    </DownloadManagerContext.Provider>
  );
};

export { DownloadManagerProvider, DownloadManagerContext };
