import React from "react";
import {
  mdiVectorSquare,
  mdiVectorLine,
  mdiPencil,
  mdiImageOutline,
  mdiVectorCircle,
  mdiFountainPenTip,
  mdiCursorDefaultOutline,
  mdiFormatText,
  mdiVectorEllipse,
  mdiVectorRectangle,
} from "@mdi/js";
import ToggleButtonSelector from "../Common/ToggleButtonSelector";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import Icon from "@mdi/react";

const tools = [
  {
    value: "select",
    icon: mdiCursorDefaultOutline,
    label: "Select tool",
  },
  {
    value: "fhpath",
    icon: mdiPencil,
    label: "Pencil tool",
  },
  {
    value: "path",
    icon: mdiFountainPenTip,
    label: "Path tool",
  },
  {
    value: "line",
    icon: mdiVectorLine,
    label: "Line tool",
  },
  {
    label: "squares",
    group: [
      {
        value: "square",
        icon: mdiVectorSquare,
        label: "Square tool",
      },
      {
        value: "rect",
        icon: mdiVectorRectangle,
        label: "Rectangle tool",
      },
      {
        value: "fhrect",
        icon: mdiVectorRectangle,
        label: "Free Hand Rectangle tool",
      },
    ],
  },
  {
    label: "circles",
    group: [
      {
        value: "circle",
        icon: mdiVectorCircle,
        label: "Circle tool",
      },
      {
        value: "ellipse",
        icon: mdiVectorEllipse,
        label: "Ellipse tool",
      },
      {
        value: "fhellipse",
        icon: mdiVectorEllipse,
        label: "Free Hand Ellipse tool",
      },
    ],
  },
  {
    value: "text",
    icon: mdiFormatText,
    label: "Text tool",
  },
  {
    value: "image",
    icon: mdiImageOutline,
    label: "Image tool",
  },
];

const SvgEditorToolbar = (props) => {
  return (
    <ToggleButtonGroup
      orientation="vertical"
      value={props.tool}
      exclusive
      onChange={props.onToolbarChange}
      aria-label="toolbar"
    >
      {tools.map((t) =>
        t.group ? (
          <ToggleButtonSelector value={props.tool} key={t.label}>
            {t.group.map((tg) => (
              <ToggleButton
                value={tg.value}
                aria-label={tg.value}
                key={tg.value}
              >
                <Icon path={tg.icon} size={0.8}>
                  {tg.label}
                </Icon>
              </ToggleButton>
            ))}
          </ToggleButtonSelector>
        ) : (
          <ToggleButton value={t.value} aria-label={t.value} key={t.value}>
            <Icon path={t.icon} size={0.8}>
              {t.label}
            </Icon>
          </ToggleButton>
        )
      )}
    </ToggleButtonGroup>
  );
};

export default SvgEditorToolbar;
