import { FormattedMessage } from "react-intl";

const planSupplies = {
  limit: (
    <FormattedMessage
      id="plan-supplies.limit"
      defaultMessage="Non Cumulative"
    />
  ),
  cumulative: (
    <FormattedMessage
      id="plan-supplies.cumulative"
      defaultMessage="Cumulative"
    />
  ),
  expiredCumulative: (
    <FormattedMessage
      id="plan-supplies.expired-cumulative"
      defaultMessage="Cumulative (expires after 6 months)"
    />
  ),
};

export { planSupplies };
