import React from "react";
import { Alert } from "@material-ui/lab";
import { Box } from "@material-ui/core";

const ErrorsAlert = (props) => {
  if (!props.errors) {
    return "";
  }

  return (
    <Box>
      <Alert variant="standard" color="error">
        {props.errors.map((error) => (
          <Box key={error}>{error}</Box>
        ))}
      </Alert>
    </Box>
  );
};

export default ErrorsAlert;
