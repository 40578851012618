import { Icon, Switch } from "@material-ui/core";
import React, { useContext } from "react";
import { ThemeContext } from "../../context/ThemeProvider";

function ThemeSwitcher() {
  const { switchTheme, theme } = useContext(ThemeContext);

  const toggleTheme = (event) => {
    switchTheme(event.target.checked ? "light" : "dark");
  };

  return (
    <>
      <Switch
        checked={theme.name === "light"}
        onChange={toggleTheme}
        icon={<Icon fontSize="small">brightness_4</Icon>}
        checkedIcon={
          <Icon fontSize="small" color="primary">
            brightness_5
          </Icon>
        }
        color="primary"
      />
    </>
  );
}

export default ThemeSwitcher;
