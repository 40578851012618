import React, { useState } from "react";
import { MuiThemeProvider, useMediaQuery } from "@material-ui/core";
import { lightTheme, darkTheme } from "../themes/default";

const ThemeContext = React.createContext({});

const ThemeProvider = (props) => {
  const themeMap = {
    light: lightTheme,
    dark: darkTheme,
  };

  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const defaultTheme =
    localStorage.getItem("theme") ?? (prefersDarkMode ? "dark" : "light");

  const [themeName, setTheme] = useState(defaultTheme);
  const theme = themeMap[themeName];

  const switchTheme = (theme) => {
    localStorage.setItem("theme", theme);
    setTheme(theme);
  };

  return (
    <ThemeContext.Provider value={{ switchTheme, theme }}>
      <MuiThemeProvider theme={theme}>{props.children}</MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

export { ThemeProvider, ThemeContext };
