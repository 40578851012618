import IssuerPlan from "../entities/IssuerPlan";
import API from "../utils/API";
import AuthService from "./AuthService";
import Service from "./Service";

class PlansService extends Service {
  queries = {
    getPlans: (issuerUuid) =>
      `query { issuerPlans {
        edges {
          node {
            name,
            description,
            enabledCapabilities
            issuerPlanSupplies {
              edges {
                node {
                  currency {
                    ticker
                    decimals
                  },
                  value,
                  topUpType
                }
              }
            }
          }
        }
      },
      issuer(id:"/api/issuers/${issuerUuid}") { 
        name, issuerPlan { name, description, enabledCapabilities, public, issuerPlanSupplies {
          edges {
            node {
              currency {
                ticker
                decimals
              },
              value,
              topUpType
            }
          }
        } } 
      },
      issuerPlanPrices: byIssuerQueryIssuerPlanPrices(issuer:"/api/issuers/${issuerUuid}") { 
        value, currency {ticker}, issuerPlan { name } 
      },
      requestedSubscriptions: byIssuerQueryIssuerPlanSubscriptions(issuer:"/api/issuers/${issuerUuid}", status:"pending") { uuid, status, issuerPlan { description }, issuerPlanPrice { value, currency {ticker, decimals} }, proportionalAmount, expirationDate, payment { gatewayName, status } },
      authorizedSubscriptions: byIssuerQueryIssuerPlanSubscriptions(issuer:"/api/issuers/${issuerUuid}", status:"authorized") { uuid, status, issuerPlan { description }, expirationDate, issuerPlanPrice { value, currency {ticker} } }
    }`,
    requestPlan: (issuerUuid, planName) => `mutation {
      requestIssuerPlanSubscription(input:{
        issuer:"/api/issuers/${issuerUuid}", 
        plan:"/api/issuer_plans/${planName}"
      }){  
        issuerPlanSubscription {uuid, status, issuerPlan { description }, issuerPlanPrice { value, currency {ticker, decimals} }, proportionalAmount, payment { gatewayName, status }},
      }
    }`,
    cancelRequestedPlan: (issuerUuid, subscriptionUuid) => `mutation {
      cancelIssuerPlanSubscription(input:{
        id:"/api/issuer_plan_subscriptions/${subscriptionUuid}",
        issuer:"/api/issuers/${issuerUuid}"
      }){  
        issuerPlanSubscription {uuid, status, issuerPlan { description }}
      }
    }`,
    authorizeRequestedPlan: (
      issuerUuid,
      subscriptionUuid,
      cardData
    ) => `mutation {
      authorizeIssuerPlanSubscription(input:{
        id:"/api/issuer_plan_subscriptions/${subscriptionUuid}",
        issuer:"/api/issuers/${issuerUuid}",
        paymentData:"${cardData}"
      }){  
        issuerPlanSubscription {uuid, status, issuerPlan { description }, payment { gatewayName, status }}
      }
    }`,
  };

  getPlans = async (currencyTicker) => {
    let issuer = AuthService.getCurrentIssuer();
    return API.callGraphql(this.queries.getPlans(issuer.uuid)).then(
      (response) => {
        this.errorParser(response);

        if (!response.data.issuerPlans || !response.data.issuer)
          this.throwDefaultError();

        let issuerPlan = new IssuerPlan();
        if (response.data.issuer.issuerPlan) {
          issuerPlan.name = response.data.issuer.issuerPlan.name;
          issuerPlan.description = response.data.issuer.issuerPlan.description;
          issuerPlan.enabledCapabilities =
            response.data.issuer.issuerPlan.enabledCapabilities;
          issuerPlan.public = response.data.issuer.issuerPlan.public;
          issuerPlan.supplies = [];
          if (response.data.issuer.issuerPlan.issuerPlanSupplies.edges) {
            for (let j in response.data.issuer.issuerPlan.issuerPlanSupplies
              .edges) {
              let supply = {
                currency:
                  response.data.issuer.issuerPlan.issuerPlanSupplies.edges[j]
                    .node.currency,
                value:
                  response.data.issuer.issuerPlan.issuerPlanSupplies.edges[j]
                    .node.value,
                topUpType:
                  response.data.issuer.issuerPlan.issuerPlanSupplies.edges[j]
                    .node.topUpType,
              };
              issuerPlan.supplies.push(supply);
            }
          }
        }

        let plans = [];
        for (let i in response.data.issuerPlans.edges) {
          let respPlan = response.data.issuerPlans.edges[i].node;
          let plan = new IssuerPlan();
          plan.name = respPlan.name;
          plan.description = respPlan.description;
          plan.enabledCapabilities = respPlan.enabledCapabilities;
          plan.supplies = [];
          if (respPlan.issuerPlanSupplies.edges) {
            for (let j in respPlan.issuerPlanSupplies.edges) {
              let supply = {
                currency: respPlan.issuerPlanSupplies.edges[j].node.currency,
                value: respPlan.issuerPlanSupplies.edges[j].node.value,
                topUpType: respPlan.issuerPlanSupplies.edges[j].node.topUpType,
              };
              plan.supplies.push(supply);
            }
          }
          plan.currentPlan = respPlan.name === issuerPlan.name;
          plan.public = true;
          for (let j in response.data.issuerPlanPrices) {
            if (
              response.data.issuerPlanPrices[j].issuerPlan.name === plan.name
            ) {
              plan.price = response.data.issuerPlanPrices[j];
            }
          }
          plans.push(plan);
        }

        let requestedSubscription = null;
        if (response.data.requestedSubscriptions) {
          requestedSubscription = response.data.requestedSubscriptions[0];
        }

        let currentSubscription = null;
        let nextSubscription = null;
        if (response.data.authorizedSubscriptions) {
          currentSubscription = response.data.authorizedSubscriptions[0];
          issuerPlan.price = currentSubscription
            ? currentSubscription.issuerPlanPrice
            : null;

          if (response.data.authorizedSubscriptions[1]) {
            nextSubscription = response.data.authorizedSubscriptions[1];
          }
        }

        return {
          plans: plans,
          issuerPlan: issuerPlan,
          requestedSubscription: requestedSubscription,
          currentSubscription: currentSubscription,
          nextSubscription: nextSubscription,
        };
      }
    );
  };

  requestPlan = async (plan) => {
    let issuer = AuthService.getCurrentIssuer();

    return API.callGraphql(
      this.queries.requestPlan(issuer.uuid, plan.name)
    ).then((response) => {
      this.errorParser(response);

      if (!response.data.requestIssuerPlanSubscription)
        this.throwDefaultError();

      return response.data.requestIssuerPlanSubscription.issuerPlanSubscription;
    });
  };

  cancelRequestedPlan = async (requestedSubscription) => {
    let issuer = AuthService.getCurrentIssuer();

    return API.callGraphql(
      this.queries.cancelRequestedPlan(issuer.uuid, requestedSubscription.uuid)
    ).then((response) => {
      this.errorParser(response);

      if (!response.data.cancelIssuerPlanSubscription) this.throwDefaultError();

      return true;
    });
  };

  authorizeRequestedPlan = async (requestedSubscription, paymentData) => {
    let issuer = AuthService.getCurrentIssuer();
    const paymentDataBase64 = btoa(JSON.stringify(paymentData));

    return API.callGraphql(
      this.queries.authorizeRequestedPlan(
        issuer.uuid,
        requestedSubscription.uuid,
        paymentDataBase64
      )
    ).then((response) => {
      this.errorParser(response);

      if (!response.data.authorizeIssuerPlanSubscription)
        this.throwDefaultError();

      return response.data.authorizeIssuerPlanSubscription;
    });
  };
}

export default new PlansService();
