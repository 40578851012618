import { Box, CircularProgress, Paper, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import StatisticService from "../../services/StatisticService";
import usePromise from "../../hooks/usePromise";
import { FormattedNumber, useIntl } from "react-intl";

const IssuerStatistics = (props) => {
  const [data, errors, loading, setPromise, reset] = usePromise();
  const intl = useIntl();

  useEffect(() => {
    setPromise(StatisticService.getIssuerStatistics());
    return () => {
      reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (errors) {
    return <></>;
  }

  const statisticTypes = {
    beginning: intl.formatMessage({
      id: "statistic.metric-badge-type-beginning",
      defaultMessage: "From beginning",
    }),
  };

  return (
    <Paper variant="outlined" {...props}>
      <Box p={3}>
        {loading && (
          <Box display="grid" justifyContent="center">
            <CircularProgress size="1.4rem" />
          </Box>
        )}
        {data &&
          data.map((statistic) => (
            <Box
              key={statistic._id}
              display="flex"
              alignItems="center"
              justifyContent="left"
              fontSize="2em"
            >
              {` ${statisticTypes[statistic.type]}: `}
              <FormattedNumber value={statistic.value} />
            </Box>
          ))}
      </Box>
    </Paper>
  );
};

export default IssuerStatistics;
