import { Box, Breadcrumbs, Typography } from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import BatchList from "../components/Batch/BatchList";
import AdminLayout from "../layouts/AdminLayout";

const Batches = () => {
  return (
    <AdminLayout>
      <Box p={2}>
        <Breadcrumbs separator=">" aria-label="breadcrumb">
          <Link style={{ color: "inherit" }} to="/">
            <FormattedMessage
              id="routes.protected.dashboard"
              defaultMessage="Dashboard"
              description="Dashboard menu breadcrumbs title"
            />
          </Link>
          <Typography color="textPrimary">
            <FormattedMessage
              id="routes.protected.batches"
              defaultMessage="Badges History"
              description="Badges History menu link"
            />
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box p={2}>
        <BatchList />
      </Box>
    </AdminLayout>
  );
};

export default Batches;
