import { Button, Icon } from "@material-ui/core";
import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { ResendConfirmContext } from "../../context/ResendConfirmProvider";

const BatchResendButton = (props) => {
  const uuid = props.uuid;
  const { handleResendClick, setUuid, setMode } = useContext(
    ResendConfirmContext
  );

  const handleClick = () => {
    setMode("batch");
    setUuid(uuid);
    handleResendClick();
  };

  return (
    <Button
      onClick={handleClick}
      {...props}
      data-test="resend"
      startIcon={<Icon>send</Icon>}
    >
      {props.children ? (
        props.children
      ) : (
        <FormattedMessage
          defaultMessage="Resend emails"
          id="batch.resend-button"
          description="Batch resend emails button text"
        />
      )}
    </Button>
  );
};

export default BatchResendButton;
