import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Icon,
  Paper,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { Autocomplete } from "@material-ui/lab";
import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { SendBadgesBatchContext } from "../../context/SendBadgesBatchProvider";
import usePromise from "../../hooks/usePromise";
import BadgeClassesService from "../../services/BadgeClassesService";
import ErrorsAlert from "../Common/ErrorsAlert";

const SendBadgesStep0 = () => {
  const {
    batch,
    setBadgeClass,
    currentStep,
    handleNextStep,
    handleBackStep,
  } = useContext(SendBadgesBatchContext);

  const [badgeClassesData, errors, loading, setPromise] = usePromise();
  const [badgeClasses, setBadgeClasses] = useState([]);
  const intl = useIntl();

  BadgeClassesService.setDefaultErrorMessage(
    intl.formatMessage({
      id: "errors.unexpected-error",
      defaultMessage: "An unexpected error ocurred.",
    })
  );

  const handleSubmit = () => {
    handleNextStep();
  };

  const handleFormChange = (event, value) => {
    setBadgeClass(value);
  };

  useEffect(() => {
    setPromise(
      BadgeClassesService.getBadgeClasses(50, 1, null, ["DRAFT", "USED"])
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (badgeClassesData) {
      setBadgeClasses(badgeClassesData.collection);
    }
  }, [badgeClassesData]);

  ValidatorForm.addValidationRule("autocompleteRequire", (value) => {
    return batch && batch.badgeClass;
  });

  const validationMessages = {
    autocompleteRequire: intl.formatMessage({
      id: "validation.field-required",
      defaultMessage: "This field is required",
      description: "Label shown on required form field when is required",
    }),
  };

  const inputs = {
    badgeClass: {
      label: intl.formatMessage({
        id: "send-badges.badge-class-input",
        defaultMessage: "Choose a badge",
        description: "Badge class select label",
      }),
    },
  };

  if (loading) {
    return (
      <Paper variant="outlined">
        <Box p={5} display="grid" justifyContent="center">
          <CircularProgress size={24} color="primary" />
        </Box>
      </Paper>
    );
  }

  if (errors.length) {
    return (
      <Paper variant="outlined">
        <Box p={5} display="grid" justifyContent="center">
          <ErrorsAlert errors={errors} />
        </Box>
      </Paper>
    );
  }

  if (!badgeClasses.length) {
    return (
      <Paper variant="outlined">
        <Box p={5} display="grid" justifyContent="center">
          <Typography variant="subtitle1">
            <FormattedMessage
              id="send-badges.without-badges-message"
              defaultMessage="You need to create a badge class before send it"
              description="Message to display when user tries to send badges before create a badge class."
            />
          </Typography>
          <Button
            color="primary"
            variant="contained"
            component={Link}
            to="/create-badge"
            fullWidth
          >
            <FormattedMessage
              id="badges.create-badge-text"
              defaultMessage="Create Badge"
              description="Create Badge button text"
            />
          </Button>
        </Box>
      </Paper>
    );
  }

  return (
    <ValidatorForm onSubmit={handleSubmit}>
      <Paper variant="outlined">
        <Box p={3}>
          <Grid container>
            <Grid item xs sm={6}>
              <Box display="flex" flexDirection="column" p={1}>
                <Box>
                  <Autocomplete
                    options={badgeClasses}
                    value={batch.badgeClass ?? undefined}
                    onChange={handleFormChange}
                    disableClearable
                    getOptionLabel={(option) => (option ? option.name : "")}
                    renderOption={(option) => (
                      <Box display="flex" flexDirection="row">
                        <Box mr={1}>
                          {option.thumbnails && option.thumbnails["125x100"] ? (
                            <img
                              src={option.thumbnails["125x100"]}
                              alt="badge icon"
                              width="16"
                            />
                          ) : (
                            <Icon style={{ fontSize: 15 }}>shield</Icon>
                          )}
                        </Box>
                        <Box>{option.name}</Box>
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextValidator
                        {...params}
                        label={inputs.badgeClass.label}
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                        validators={["autocompleteRequire"]}
                        errorMessages={[validationMessages.autocompleteRequire]}
                      />
                    )}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs sm={6}>
              <Box p={1} display="grid" justifyItems="center">
                {batch.badgeClass.thumbnails &&
                batch.badgeClass.thumbnails["250x200"] ? (
                  <img
                    src={batch.badgeClass.thumbnails["250x200"]}
                    alt="badge icon"
                    width="250"
                  />
                ) : (
                  <Icon style={{ fontSize: 150 }}>shield</Icon>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <Box
        mt={3}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Button variant="text" onClick={handleBackStep} disabled={!currentStep}>
          <FormattedMessage
            id="send-badges-wizard.back-button"
            defaultMessage="Back"
            description="Back button in wizard"
          />
        </Button>
        <Button variant="contained" color="primary" type="submit">
          <Typography noWrap variant="button">
            <FormattedMessage
              id="send-badges-wizard.next-button"
              defaultMessage="Next"
              description="Next button in wizard"
            />
          </Typography>
        </Button>
      </Box>
    </ValidatorForm>
  );
};

export default SendBadgesStep0;
