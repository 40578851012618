import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  Typography,
} from "@material-ui/core";
import React, { useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import LoadingButton from "../Common/LoadingButton";

const BadgeRevokeDialog = (props) => {
  const [reason, setReason] = useState("");
  const intl = useIntl();
  const revocationForm = useRef(null);

  const handleClose = (evt) => {
    setReason("");
    props.handleClose && props.handleClose(evt);
  };

  const handleRevoke = (revocationReason) => {
    revocationForm.current.isFormValid(false).then((isValid) => {
      isValid && props.handleRevoke(revocationReason).then(() => setReason(""));
    });
  };

  return (
    <>
      <Dialog open={props.open} onClose={handleClose}>
        <DialogTitle>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Typography variant="subtitle1">
              <FormattedMessage
                defaultMessage="Attention"
                description="Revoke dialog title"
                id="badge.revoke-dialog-title"
              />
            </Typography>
            <IconButton size="small" onClick={handleClose}>
              <Icon>close</Icon>
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers={true}>
          <Typography>
            <FormattedMessage
              defaultMessage="You are going to revoke the badge permanently. Are you sure?"
              description="Revoke dialog text"
              id="badge.revoke-dialog-text"
            />
          </Typography>
          <Box display="flex" mt={2}>
            <ValidatorForm
              ref={revocationForm}
              style={{ width: "100%" }}
              onSubmit={() => false}
            >
              <TextValidator
                validators={["required"]}
                errorMessages={[
                  intl.formatMessage({
                    id: "validation.field-required",
                    defaultMessage: "This field is required",
                    description:
                      "Label shown on required form field when is required",
                  }),
                ]}
                label={intl.formatMessage({
                  id: "badge.revoke-dialog-reason-label",
                  defaultMessage: "Revocation reason",
                })}
                placeholder={intl.formatMessage({
                  id: "badge.revoke-dialog-reason-placeholder",
                  defaultMessage: "Please enter a revocation reason",
                })}
                variant="outlined"
                multiline={true}
                fullWidth={true}
                value={reason}
                onChange={(evt) => setReason(evt.target.value)}
                inputProps={{ "data-testid": "reasonTextarea" }}
              />
            </ValidatorForm>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            flexGrow={1}
            p={1}
          >
            <Button onClick={handleClose}>
              <FormattedMessage
                defaultMessage="Cancel"
                description="Cancel button text"
                id="dialog.cancel-button"
              />
            </Button>
            <LoadingButton
              loading={props.revoking ? "true" : undefined}
              onClick={() => handleRevoke(reason)}
              variant="contained"
              color="primary"
            >
              <FormattedMessage
                defaultMessage="Yes, revoke"
                description="Revoke button text"
                id="badge.revoke-dialog-button"
              />
            </LoadingButton>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BadgeRevokeDialog;
