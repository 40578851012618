import { ClickAwayListener, Popper } from "@material-ui/core";
import { ToggleButton } from "@material-ui/lab";
import { mdiColorHelper, mdiFormatColorFill } from "@mdi/js";
import Icon, { Stack } from "@mdi/react";
import React, { useEffect, useState } from "react";
import { SketchPicker } from "react-color";

const SvgEditorColorPicker = (props) => {
  const [colorPickerOpen, setColorPickerOpen] = useState(false);
  const [colorRef, setColorRef] = useState(null);
  const [color, setColor] = useState({ rgb: { r: 0, g: 0, b: 0, a: 1 } });

  const onColorChange = (color, event) => {
    setColor(color);
    props.onColorChange(color);
  };

  const toggleColorPicker = (event) => {
    !colorRef && setColorRef(event.currentTarget);
    setColorPickerOpen(true);
  };

  const hexToRgbA = (hex) => {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split("");
      if (c.length === 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = "0x" + c.join("");
      return {
        rgb: { r: (c >> 16) & 255, g: (c >> 8) & 255, b: c & 255, a: 1 },
      };
    }
    return false;
  };

  const getRGBAObject = (RGBAString) => {
    if (!RGBAString) {
      return false;
    }

    let RGBAObject = {};

    if (RGBAString[0] === "#") {
      RGBAObject = hexToRgbA(RGBAString);
    } else {
      var rgbRegex = /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/;
      var partsRGBA = RGBAString.match(rgbRegex);
      if (!partsRGBA || partsRGBA.length < 4) {
        return false;
      }
      RGBAObject = {
        rgb: {
          r: partsRGBA[1],
          g: partsRGBA[2],
          b: partsRGBA[3],
          a: partsRGBA[4],
        },
      };
    }

    return RGBAObject;
  };

  useEffect(() => {
    setColor(getRGBAObject(props.color));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.color]);

  return (
    <ToggleButton onClick={toggleColorPicker} value={colorPickerOpen}>
      <Stack size={0.8}>
        <Icon path={props.icon || mdiFormatColorFill} size={0.8}></Icon>
        <Icon path={mdiColorHelper} size={0.8} color={props.color}></Icon>
      </Stack>
      <Popper
        open={colorPickerOpen}
        anchorEl={colorRef}
        placement="right-start"
      >
        <ClickAwayListener onClickAway={() => setColorPickerOpen(false)}>
          <SketchPicker
            onChange={onColorChange}
            onChangeComplete={onColorChange}
            color={color.rgb}
          />
        </ClickAwayListener>
      </Popper>
    </ToggleButton>
  );
};

export default SvgEditorColorPicker;
