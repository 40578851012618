import { Box, CircularProgress, List, ListItem } from "@material-ui/core";
import React, { useEffect } from "react";
import LevelService from "../../services/LevelService";
import usePromise from "../../hooks/usePromise";
import FormattedCurrency from "./FormattedCurrency";

const LevelPricesList = (props) => {
  const [data, errors, loading, setPromise, reset] = usePromise();

  useEffect(() => {
    setPromise(LevelService.getLevels());
    return () => {
      reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (errors) {
    return <></>;
  }

  if (loading) {
    return (
      <Box display="grid" justifyContent="center">
        <CircularProgress size="1.4rem" />
      </Box>
    );
  }

  return (
    <List {...props}>
      {data &&
        data.map((level) => (
          <ListItem divider disableGutters key={level.node.description}>
            <Box flexGrow={1}>{level.node.description}</Box>
            <FormattedCurrency
              value={level.node.levelPrices.edges[0].node.value}
              currency={level.node.levelPrices.edges[0].node.currency}
              currencyProps={{
                color: "textSecondary",
                variant: "body2",
                style: {
                  minWidth: "3em",
                  paddingLeft: "0.3em",
                },
              }}
            />
          </ListItem>
        ))}
    </List>
  );
};

export default LevelPricesList;
