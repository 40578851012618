const countries = [
  { icon: "ðŸ‡¦ðŸ‡«", name: "Afghanistan", code: "AF", prefix: "93" },
  { icon: "ðŸ‡¦ðŸ‡½", name: "Alland Islands", code: "AX", prefix: "358" },
  { icon: "ðŸ‡¦ðŸ‡±", name: "Albania", code: "AL", prefix: "355" },
  { icon: "ðŸ‡©ðŸ‡¿", name: "Algeria", code: "DZ", prefix: "213" },
  { icon: "ðŸ‡¦ðŸ‡¸", name: "American Samoa", code: "AS", prefix: "1-684" },
  { icon: "ðŸ‡¦ðŸ‡©", name: "Andorra", code: "AD", prefix: "376" },
  { icon: "ðŸ‡¦ðŸ‡´", name: "Angola", code: "AO", prefix: "244" },
  { icon: "ðŸ‡¦ðŸ‡®", name: "Anguilla", code: "AI", prefix: "1-264" },
  { icon: "ðŸ‡¦ðŸ‡¶", name: "Antarctica", code: "AQ", prefix: "672" },
  {
    icon: "ðŸ‡¦ðŸ‡¬",
    name: "Antigua and Barbuda",
    code: "AG",
    prefix: "1-268",
  },
  { icon: "ðŸ‡¦ðŸ‡·", name: "Argentina", code: "AR", prefix: "54" },
  { icon: "ðŸ‡¦ðŸ‡²", name: "Armenia", code: "AM", prefix: "374" },
  { icon: "ðŸ‡¦ðŸ‡¼", name: "Aruba", code: "AW", prefix: "297" },
  {
    icon: "ðŸ‡¦ðŸ‡º",
    name: "Australia",
    code: "AU",
    prefix: "61",
    suggested: true,
  },
  { icon: "ðŸ‡¦ðŸ‡¹", name: "Austria", code: "AT", prefix: "43" },
  { icon: "ðŸ‡¦ðŸ‡¿", name: "Azerbaijan", code: "AZ", prefix: "994" },
  { icon: "ðŸ‡§ðŸ‡¸", name: "Bahamas", code: "BS", prefix: "1-242" },
  { icon: "ðŸ‡§ðŸ‡­", name: "Bahrain", code: "BH", prefix: "973" },
  { icon: "ðŸ‡§ðŸ‡©", name: "Bangladesh", code: "BD", prefix: "880" },
  { icon: "ðŸ‡§ðŸ‡§", name: "Barbados", code: "BB", prefix: "1-246" },
  { icon: "ðŸ‡§ðŸ‡¾", name: "Belarus", code: "BY", prefix: "375" },
  { icon: "ðŸ‡§ðŸ‡ª", name: "Belgium", code: "BE", prefix: "32" },
  { icon: "ðŸ‡§ðŸ‡¿", name: "Belize", code: "BZ", prefix: "501" },
  { icon: "ðŸ‡§ðŸ‡¯", name: "Benin", code: "BJ", prefix: "229" },
  { icon: "ðŸ‡§ðŸ‡²", name: "Bermuda", code: "BM", prefix: "1-441" },
  { icon: "ðŸ‡§ðŸ‡¹", name: "Bhutan", code: "BT", prefix: "975" },
  { icon: "ðŸ‡§ðŸ‡´", name: "Bolivia", code: "BO", prefix: "591" },
  {
    icon: "ðŸ‡§ðŸ‡¦",
    name: "Bosnia and Herzegovina",
    code: "BA",
    prefix: "387",
  },
  { icon: "ðŸ‡§ðŸ‡¼", name: "Botswana", code: "BW", prefix: "267" },
  { icon: "ðŸ‡§ðŸ‡»", name: "Bouvet Island", code: "BV", prefix: "47" },
  { icon: "ðŸ‡§ðŸ‡·", name: "Brazil", code: "BR", prefix: "55" },
  {
    icon: "ðŸ‡®ðŸ‡´",
    name: "British Indian Ocean Territory",
    code: "IO",
    prefix: "246",
  },
  {
    icon: "ðŸ‡»ðŸ‡¬",
    name: "British Virgin Islands",
    code: "VG",
    prefix: "1-284",
  },
  { icon: "ðŸ‡§ðŸ‡³", name: "Brunei Darussalam", code: "BN", prefix: "673" },
  { icon: "ðŸ‡§ðŸ‡¬", name: "Bulgaria", code: "BG", prefix: "359" },
  { icon: "ðŸ‡§ðŸ‡«", name: "Burkina Faso", code: "BF", prefix: "226" },
  { icon: "ðŸ‡§ðŸ‡®", name: "Burundi", code: "BI", prefix: "257" },
  { icon: "ðŸ‡°ðŸ‡­", name: "Cambodia", code: "KH", prefix: "855" },
  { icon: "ðŸ‡¨ðŸ‡²", name: "Cameroon", code: "CM", prefix: "237" },
  {
    icon: "ðŸ‡¨ðŸ‡¦",
    name: "Canada",
    code: "CA",
    prefix: "1",
    suggested: true,
  },
  { icon: "ðŸ‡¨ðŸ‡»", name: "Cape Verde", code: "CV", prefix: "238" },
  { icon: "ðŸ‡°ðŸ‡¾", name: "Cayman Islands", code: "KY", prefix: "1-345" },
  {
    icon: "ðŸ‡¨ðŸ‡«",
    name: "Central African Republic",
    code: "CF",
    prefix: "236",
  },
  { icon: "ðŸ‡¹ðŸ‡©", name: "Chad", code: "TD", prefix: "235" },
  { icon: "ðŸ‡¨ðŸ‡±", name: "Chile", code: "CL", prefix: "56" },
  { icon: "ðŸ‡¨ðŸ‡³", name: "China", code: "CN", prefix: "86" },
  { icon: "ðŸ‡¨ðŸ‡½", name: "Christmas Island", code: "CX", prefix: "61" },
  {
    icon: "ðŸ‡¨ðŸ‡¨",
    name: "Cocos (Keeling) Islands",
    code: "CC",
    prefix: "61",
  },
  { icon: "ðŸ‡¨ðŸ‡´", name: "Colombia", code: "CO", prefix: "57" },
  { icon: "ðŸ‡°ðŸ‡²", name: "Comoros", code: "KM", prefix: "269" },
  {
    icon: "ðŸ‡¨ðŸ‡©",
    name: "Congo, Democratic Republic of the",
    code: "CG",
    prefix: "243",
  },
  {
    icon: "ðŸ‡¨ðŸ‡¬",
    name: "Congo, Republic of the",
    code: "CD",
    prefix: "242",
  },
  { icon: "ðŸ‡¨ðŸ‡°", name: "Cook Islands", code: "CK", prefix: "682" },
  { icon: "ðŸ‡¨ðŸ‡·", name: "Costa Rica", code: "CR", prefix: "506" },
  { icon: "ðŸ‡¨ðŸ‡®", name: "Cote d'Ivoire", code: "CI", prefix: "225" },
  { icon: "ðŸ‡­ðŸ‡·", name: "Croatia", code: "HR", prefix: "385" },
  { icon: "ðŸ‡¨ðŸ‡º", name: "Cuba", code: "CU", prefix: "53" },
  { icon: "ðŸ‡¨ðŸ‡¼", name: "Curacao", code: "CW", prefix: "599" },
  { icon: "ðŸ‡¨ðŸ‡¾", name: "Cyprus", code: "CY", prefix: "357" },
  { icon: "ðŸ‡¨ðŸ‡¿", name: "Czech Republic", code: "CZ", prefix: "420" },
  { icon: "ðŸ‡©ðŸ‡°", name: "Denmark", code: "DK", prefix: "45" },
  { icon: "ðŸ‡©ðŸ‡¯", name: "Djibouti", code: "DJ", prefix: "253" },
  { icon: "ðŸ‡©ðŸ‡²", name: "Dominica", code: "DM", prefix: "1-767" },
  { icon: "ðŸ‡©ðŸ‡´", name: "Dominican Republic", code: "DO", prefix: "1-809" },
  { icon: "ðŸ‡ªðŸ‡¨", name: "Ecuador", code: "EC", prefix: "593" },
  { icon: "ðŸ‡ªðŸ‡¬", name: "Egypt", code: "EG", prefix: "20" },
  { icon: "ðŸ‡¸ðŸ‡»", name: "El Salvador", code: "SV", prefix: "503" },
  { icon: "ðŸ‡¬ðŸ‡¶", name: "Equatorial Guinea", code: "GQ", prefix: "240" },
  { icon: "ðŸ‡ªðŸ‡·", name: "Eritrea", code: "ER", prefix: "291" },
  { icon: "ðŸ‡ªðŸ‡ª", name: "Estonia", code: "EE", prefix: "372" },
  { icon: "ðŸ‡ªðŸ‡¹", name: "Ethiopia", code: "ET", prefix: "251" },
  {
    icon: "ðŸ‡«ðŸ‡°",
    name: "Falkland Islands (Malvinas)",
    code: "FK",
    prefix: "500",
  },
  { icon: "ðŸ‡«ðŸ‡´", name: "Faroe Islands", code: "FO", prefix: "298" },
  { icon: "ðŸ‡«ðŸ‡¯", name: "Fiji", code: "FJ", prefix: "679" },
  { icon: "ðŸ‡«ðŸ‡®", name: "Finland", code: "FI", prefix: "358" },
  {
    icon: "ðŸ‡«ðŸ‡·",
    name: "France",
    code: "FR",
    prefix: "33",
    suggested: true,
  },
  { icon: "ðŸ‡¬ðŸ‡«", name: "French Guiana", code: "GF", prefix: "594" },
  { icon: "ðŸ‡µðŸ‡«", name: "French Polynesia", code: "PF", prefix: "689" },
  {
    icon: "ðŸ‡¹ðŸ‡«",
    name: "French Southern Territories",
    code: "TF",
    prefix: "262",
  },
  { icon: "ðŸ‡¬ðŸ‡¦", name: "Gabon", code: "GA", prefix: "241" },
  { icon: "ðŸ‡¬ðŸ‡²", name: "Gambia", code: "GM", prefix: "220" },
  { icon: "ðŸ‡¬ðŸ‡ª", name: "Georgia", code: "GE", prefix: "995" },
  {
    icon: "ðŸ‡©ðŸ‡ª",
    name: "Germany",
    code: "DE",
    prefix: "49",
    suggested: true,
  },
  { icon: "ðŸ‡¬ðŸ‡­", name: "Ghana", code: "GH", prefix: "233" },
  { icon: "ðŸ‡¬ðŸ‡®", name: "Gibraltar", code: "GI", prefix: "350" },
  { icon: "ðŸ‡¬ðŸ‡·", name: "Greece", code: "GR", prefix: "30" },
  { icon: "ðŸ‡¬ðŸ‡±", name: "Greenland", code: "GL", prefix: "299" },
  { icon: "ðŸ‡¬ðŸ‡©", name: "Grenada", code: "GD", prefix: "1-473" },
  { icon: "ðŸ‡¬ðŸ‡µ", name: "Guadeloupe", code: "GP", prefix: "590" },
  { icon: "ðŸ‡¬ðŸ‡º", name: "Guam", code: "GU", prefix: "1-671" },
  { icon: "ðŸ‡¬ðŸ‡¹", name: "Guatemala", code: "GT", prefix: "502" },
  { icon: "ðŸ‡¬ðŸ‡¬", name: "Guernsey", code: "GG", prefix: "44" },
  { icon: "ðŸ‡¬ðŸ‡¼", name: "Guinea-Bissau", code: "GW", prefix: "245" },
  { icon: "ðŸ‡¬ðŸ‡³", name: "Guinea", code: "GN", prefix: "224" },
  { icon: "ðŸ‡¬ðŸ‡¾", name: "Guyana", code: "GY", prefix: "592" },
  { icon: "ðŸ‡­ðŸ‡¹", name: "Haiti", code: "HT", prefix: "509" },
  {
    icon: "ðŸ‡­ðŸ‡²",
    name: "Heard Island and McDonald Islands",
    code: "HM",
    prefix: "672",
  },
  {
    icon: "ðŸ‡»ðŸ‡¦",
    name: "Holy See (Vatican City State)",
    code: "VA",
    prefix: "379",
  },
  { icon: "ðŸ‡­ðŸ‡³", name: "Honduras", code: "HN", prefix: "504" },
  { icon: "ðŸ‡­ðŸ‡°", name: "Hong Kong", code: "HK", prefix: "852" },
  { icon: "ðŸ‡­ðŸ‡º", name: "Hungary", code: "HU", prefix: "36" },
  { icon: "ðŸ‡®ðŸ‡¸", name: "Iceland", code: "IS", prefix: "354" },
  { icon: "ðŸ‡®ðŸ‡³", name: "India", code: "IN", prefix: "91" },
  { icon: "ðŸ‡®ðŸ‡©", name: "Indonesia", code: "ID", prefix: "62" },
  {
    icon: "ðŸ‡®ðŸ‡·",
    name: "Iran, Islamic Republic of",
    code: "IR",
    prefix: "98",
  },
  { icon: "ðŸ‡®ðŸ‡¶", name: "Iraq", code: "IQ", prefix: "964" },
  { icon: "ðŸ‡®ðŸ‡ª", name: "Ireland", code: "IE", prefix: "353" },
  { icon: "ðŸ‡®ðŸ‡²", name: "Isle of Man", code: "IM", prefix: "44" },
  { icon: "ðŸ‡®ðŸ‡±", name: "Israel", code: "IL", prefix: "972" },
  { icon: "ðŸ‡®ðŸ‡¹", name: "Italy", code: "IT", prefix: "39" },
  { icon: "ðŸ‡¯ðŸ‡²", name: "Jamaica", code: "JM", prefix: "1-876" },
  {
    icon: "ðŸ‡¯ðŸ‡µ",
    name: "Japan",
    code: "JP",
    prefix: "81",
    suggested: true,
  },
  { icon: "ðŸ‡¯ðŸ‡ª", name: "Jersey", code: "JE", prefix: "44" },
  { icon: "ðŸ‡¯ðŸ‡´", name: "Jordan", code: "JO", prefix: "962" },
  { icon: "ðŸ‡°ðŸ‡¿", name: "Kazakhstan", code: "KZ", prefix: "7" },
  { icon: "ðŸ‡°ðŸ‡ª", name: "Kenya", code: "KE", prefix: "254" },
  { icon: "ðŸ‡°ðŸ‡®", name: "Kiribati", code: "KI", prefix: "686" },
  {
    icon: "ðŸ‡°ðŸ‡µ",
    name: "Korea, Democratic People's Republic of",
    code: "KP",
    prefix: "850",
  },
  { icon: "ðŸ‡°ðŸ‡·", name: "Korea, Republic of", code: "KR", prefix: "82" },
  { icon: "ðŸ‡½ðŸ‡°", name: "Kosovo", code: "XK", prefix: "383" },
  { icon: "ðŸ‡°ðŸ‡¼", name: "Kuwait", code: "KW", prefix: "965" },
  { icon: "ðŸ‡°ðŸ‡¬", name: "Kyrgyzstan", code: "KG", prefix: "996" },
  {
    icon: "ðŸ‡±ðŸ‡¦",
    name: "Lao People's Democratic Republic",
    code: "LA",
    prefix: "856",
  },
  { icon: "ðŸ‡±ðŸ‡»", name: "Latvia", code: "LV", prefix: "371" },
  { icon: "ðŸ‡±ðŸ‡§", name: "Lebanon", code: "LB", prefix: "961" },
  { icon: "ðŸ‡±ðŸ‡¸", name: "Lesotho", code: "LS", prefix: "266" },
  { icon: "ðŸ‡±ðŸ‡·", name: "Liberia", code: "LR", prefix: "231" },
  { icon: "ðŸ‡±ðŸ‡¾", name: "Libya", code: "LY", prefix: "218" },
  { icon: "ðŸ‡±ðŸ‡®", name: "Liechtenstein", code: "LI", prefix: "423" },
  { icon: "ðŸ‡±ðŸ‡¹", name: "Lithuania", code: "LT", prefix: "370" },
  { icon: "ðŸ‡±ðŸ‡º", name: "Luxembourg", code: "LU", prefix: "352" },
  { icon: "ðŸ‡²ðŸ‡´", name: "Macao", code: "MO", prefix: "853" },
  {
    icon: "ðŸ‡²ðŸ‡°",
    name: "Macedonia, the Former Yugoslav Republic of",
    code: "MK",
    prefix: "389",
  },
  { icon: "ðŸ‡²ðŸ‡¬", name: "Madagascar", code: "MG", prefix: "261" },
  { icon: "ðŸ‡²ðŸ‡¼", name: "Malawi", code: "MW", prefix: "265" },
  { icon: "ðŸ‡²ðŸ‡¾", name: "Malaysia", code: "MY", prefix: "60" },
  { icon: "ðŸ‡²ðŸ‡»", name: "Maldives", code: "MV", prefix: "960" },
  { icon: "ðŸ‡²ðŸ‡±", name: "Mali", code: "ML", prefix: "223" },
  { icon: "ðŸ‡²ðŸ‡¹", name: "Malta", code: "MT", prefix: "356" },
  { icon: "ðŸ‡²ðŸ‡­", name: "Marshall Islands", code: "MH", prefix: "692" },
  { icon: "ðŸ‡²ðŸ‡¶", name: "Martinique", code: "MQ", prefix: "596" },
  { icon: "ðŸ‡²ðŸ‡·", name: "Mauritania", code: "MR", prefix: "222" },
  { icon: "ðŸ‡²ðŸ‡º", name: "Mauritius", code: "MU", prefix: "230" },
  { icon: "ðŸ‡¾ðŸ‡¹", name: "Mayotte", code: "YT", prefix: "262" },
  { icon: "ðŸ‡²ðŸ‡½", name: "Mexico", code: "MX", prefix: "52" },
  {
    icon: "ðŸ‡«ðŸ‡²",
    name: "Micronesia, Federated States of",
    code: "FM",
    prefix: "691",
  },
  { icon: "ðŸ‡²ðŸ‡©", name: "Moldova, Republic of", code: "MD", prefix: "373" },
  { icon: "ðŸ‡²ðŸ‡¨", name: "Monaco", code: "MC", prefix: "377" },
  { icon: "ðŸ‡²ðŸ‡³", name: "Mongolia", code: "MN", prefix: "976" },
  { icon: "ðŸ‡²ðŸ‡ª", name: "Montenegro", code: "ME", prefix: "382" },
  { icon: "ðŸ‡²ðŸ‡¸", name: "Montserrat", code: "MS", prefix: "1-664" },
  { icon: "ðŸ‡²ðŸ‡¦", name: "Morocco", code: "MA", prefix: "212" },
  { icon: "ðŸ‡²ðŸ‡¿", name: "Mozambique", code: "MZ", prefix: "258" },
  { icon: "ðŸ‡²ðŸ‡²", name: "Myanmar", code: "MM", prefix: "95" },
  { icon: "ðŸ‡³ðŸ‡¦", name: "Namibia", code: "NA", prefix: "264" },
  { icon: "ðŸ‡³ðŸ‡·", name: "Nauru", code: "NR", prefix: "674" },
  { icon: "ðŸ‡³ðŸ‡µ", name: "Nepal", code: "NP", prefix: "977" },
  { icon: "ðŸ‡³ðŸ‡±", name: "Netherlands", code: "NL", prefix: "31" },
  { icon: "ðŸ‡³ðŸ‡¨", name: "New Caledonia", code: "NC", prefix: "687" },
  { icon: "ðŸ‡³ðŸ‡¿", name: "New Zealand", code: "NZ", prefix: "64" },
  { icon: "ðŸ‡³ðŸ‡®", name: "Nicaragua", code: "NI", prefix: "505" },
  { icon: "ðŸ‡³ðŸ‡ª", name: "Niger", code: "NE", prefix: "227" },
  { icon: "ðŸ‡³ðŸ‡¬", name: "Nigeria", code: "NG", prefix: "234" },
  { icon: "ðŸ‡³ðŸ‡º", name: "Niue", code: "NU", prefix: "683" },
  { icon: "ðŸ‡³ðŸ‡«", name: "Norfolk Island", code: "NF", prefix: "672" },
  {
    icon: "ðŸ‡²ðŸ‡µ",
    name: "Northern Mariana Islands",
    code: "MP",
    prefix: "1-670",
  },
  { icon: "ðŸ‡³ðŸ‡´", name: "Norway", code: "NO", prefix: "47" },
  { icon: "ðŸ‡´ðŸ‡²", name: "Oman", code: "OM", prefix: "968" },
  { icon: "ðŸ‡µðŸ‡°", name: "Pakistan", code: "PK", prefix: "92" },
  { icon: "ðŸ‡µðŸ‡¼", name: "Palau", code: "PW", prefix: "680" },
  { icon: "ðŸ‡µðŸ‡¸", name: "Palestine, State of", code: "PS", prefix: "970" },
  { icon: "ðŸ‡µðŸ‡¦", name: "Panama", code: "PA", prefix: "507" },
  { icon: "ðŸ‡µðŸ‡¬", name: "Papua New Guinea", code: "PG", prefix: "675" },
  { icon: "ðŸ‡µðŸ‡¾", name: "Paraguay", code: "PY", prefix: "595" },
  { icon: "ðŸ‡µðŸ‡ª", name: "Peru", code: "PE", prefix: "51" },
  { icon: "ðŸ‡µðŸ‡­", name: "Philippines", code: "PH", prefix: "63" },
  { icon: "ðŸ‡µðŸ‡³", name: "Pitcairn", code: "PN", prefix: "870" },
  { icon: "ðŸ‡µðŸ‡±", name: "Poland", code: "PL", prefix: "48" },
  { icon: "ðŸ‡µðŸ‡¹", name: "Portugal", code: "PT", prefix: "351" },
  { icon: "ðŸ‡µðŸ‡·", name: "Puerto Rico", code: "PR", prefix: "1" },
  { icon: "ðŸ‡¶ðŸ‡¦", name: "Qatar", code: "QA", prefix: "974" },
  { icon: "ðŸ‡·ðŸ‡ª", name: "Reunion", code: "RE", prefix: "262" },
  { icon: "ðŸ‡·ðŸ‡´", name: "Romania", code: "RO", prefix: "40" },
  { icon: "ðŸ‡·ðŸ‡º", name: "Russian Federation", code: "RU", prefix: "7" },
  { icon: "ðŸ‡·ðŸ‡¼", name: "Rwanda", code: "RW", prefix: "250" },
  { icon: "ðŸ‡§ðŸ‡±", name: "Saint Barthelemy", code: "BL", prefix: "590" },
  { icon: "ðŸ‡¸ðŸ‡­", name: "Saint Helena", code: "SH", prefix: "290" },
  {
    icon: "ðŸ‡°ðŸ‡³",
    name: "Saint Kitts and Nevis",
    code: "KN",
    prefix: "1-869",
  },
  { icon: "ðŸ‡±ðŸ‡¨", name: "Saint Lucia", code: "LC", prefix: "1-758" },
  {
    icon: "ðŸ‡²ðŸ‡«",
    name: "Saint Martin (French part)",
    code: "MF",
    prefix: "590",
  },
  {
    icon: "ðŸ‡µðŸ‡²",
    name: "Saint Pierre and Miquelon",
    code: "PM",
    prefix: "508",
  },
  {
    icon: "ðŸ‡»ðŸ‡¨",
    name: "Saint Vincent and the Grenadines",
    code: "VC",
    prefix: "1-784",
  },
  { icon: "ðŸ‡¼ðŸ‡¸", name: "Samoa", code: "WS", prefix: "685" },
  { icon: "ðŸ‡¸ðŸ‡²", name: "San Marino", code: "SM", prefix: "378" },
  {
    icon: "ðŸ‡¸ðŸ‡¹",
    name: "Sao Tome and Principe",
    code: "ST",
    prefix: "239",
  },
  { icon: "ðŸ‡¸ðŸ‡¦", name: "Saudi Arabia", code: "SA", prefix: "966" },
  { icon: "ðŸ‡¸ðŸ‡³", name: "Senegal", code: "SN", prefix: "221" },
  { icon: "ðŸ‡·ðŸ‡¸", name: "Serbia", code: "RS", prefix: "381" },
  { icon: "ðŸ‡¸ðŸ‡¨", name: "Seychelles", code: "SC", prefix: "248" },
  { icon: "ðŸ‡¸ðŸ‡±", name: "Sierra Leone", code: "SL", prefix: "232" },
  { icon: "ðŸ‡¸ðŸ‡¬", name: "Singapore", code: "SG", prefix: "65" },
  {
    icon: "ðŸ‡¸ðŸ‡½",
    name: "Sint Maarten (Dutch part)",
    code: "SX",
    prefix: "1-721",
  },
  { icon: "ðŸ‡¸ðŸ‡°", name: "Slovakia", code: "SK", prefix: "421" },
  { icon: "ðŸ‡¸ðŸ‡®", name: "Slovenia", code: "SI", prefix: "386" },
  { icon: "ðŸ‡¸ðŸ‡§", name: "Solomon Islands", code: "SB", prefix: "677" },
  { icon: "ðŸ‡¸ðŸ‡´", name: "Somalia", code: "SO", prefix: "252" },
  { icon: "ðŸ‡¿ðŸ‡¦", name: "South Africa", code: "ZA", prefix: "27" },
  {
    icon: "ðŸ‡¬ðŸ‡¸",
    name: "South Georgia and the South Sandwich Islands",
    code: "GS",
    prefix: "500",
  },
  { icon: "ðŸ‡¸ðŸ‡¸", name: "South Sudan", code: "SS", prefix: "211" },
  { icon: "ðŸ‡ªðŸ‡¸", name: "Spain", code: "ES", prefix: "34" },
  { icon: "ðŸ‡±ðŸ‡°", name: "Sri Lanka", code: "LK", prefix: "94" },
  { icon: "ðŸ‡¸ðŸ‡©", name: "Sudan", code: "SD", prefix: "249" },
  { icon: "ðŸ‡¸ðŸ‡·", name: "Suriname", code: "SR", prefix: "597" },
  {
    icon: "ðŸ‡¸ðŸ‡¯",
    name: "Svalbard and Jan Mayen",
    code: "SJ",
    prefix: "47",
  },
  { icon: "ðŸ‡¸ðŸ‡¿", name: "Swaziland", code: "SZ", prefix: "268" },
  { icon: "ðŸ‡¸ðŸ‡ª", name: "Sweden", code: "SE", prefix: "46" },
  { icon: "ðŸ‡¨ðŸ‡­", name: "Switzerland", code: "CH", prefix: "41" },
  { icon: "ðŸ‡¸ðŸ‡¾", name: "Syrian Arab Republic", code: "SY", prefix: "963" },
  {
    icon: "ðŸ‡¹ðŸ‡¼",
    name: "Taiwan, Province of China",
    code: "TW",
    prefix: "886",
  },
  { icon: "ðŸ‡¹ðŸ‡¯", name: "Tajikistan", code: "TJ", prefix: "992" },
  { icon: "ðŸ‡¹ðŸ‡­", name: "Thailand", code: "TH", prefix: "66" },
  { icon: "ðŸ‡¹ðŸ‡±", name: "Timor-Leste", code: "TL", prefix: "670" },
  { icon: "ðŸ‡¹ðŸ‡¬", name: "Togo", code: "TG", prefix: "228" },
  { icon: "ðŸ‡¹ðŸ‡°", name: "Tokelau", code: "TK", prefix: "690" },
  { icon: "ðŸ‡¹ðŸ‡´", name: "Tonga", code: "TO", prefix: "676" },
  {
    icon: "ðŸ‡¹ðŸ‡¹",
    name: "Trinidad and Tobago",
    code: "TT",
    prefix: "1-868",
  },
  { icon: "ðŸ‡¹ðŸ‡³", name: "Tunisia", code: "TN", prefix: "216" },
  { icon: "ðŸ‡¹ðŸ‡·", name: "Turkey", code: "TR", prefix: "90" },
  { icon: "ðŸ‡¹ðŸ‡²", name: "Turkmenistan", code: "TM", prefix: "993" },
  {
    icon: "ðŸ‡¹ðŸ‡¨",
    name: "Turks and Caicos Islands",
    code: "TC",
    prefix: "1-649",
  },
  { icon: "ðŸ‡¹ðŸ‡»", name: "Tuvalu", code: "TV", prefix: "688" },
  { icon: "ðŸ‡ºðŸ‡¬", name: "Uganda", code: "UG", prefix: "256" },
  { icon: "ðŸ‡ºðŸ‡¦", name: "Ukraine", code: "UA", prefix: "380" },
  { icon: "ðŸ‡¦ðŸ‡ª", name: "United Arab Emirates", code: "AE", prefix: "971" },
  { icon: "ðŸ‡¬ðŸ‡§", name: "United Kingdom", code: "GB", prefix: "44" },
  {
    icon: "ðŸ‡¹ðŸ‡¿",
    name: "United Republic of Tanzania",
    code: "TZ",
    prefix: "255",
  },
  {
    icon: "ðŸ‡ºðŸ‡²",
    name: "United States",
    code: "US",
    prefix: "1",
    suggested: true,
  },
  { icon: "ðŸ‡ºðŸ‡¾", name: "Uruguay", code: "UY", prefix: "598" },
  { icon: "ðŸ‡»ðŸ‡®", name: "US Virgin Islands", code: "VI", prefix: "1-340" },
  { icon: "ðŸ‡ºðŸ‡¿", name: "Uzbekistan", code: "UZ", prefix: "998" },
  { icon: "ðŸ‡»ðŸ‡º", name: "Vanuatu", code: "VU", prefix: "678" },
  { icon: "ðŸ‡»ðŸ‡ª", name: "Venezuela", code: "VE", prefix: "58" },
  { icon: "ðŸ‡»ðŸ‡³", name: "Vietnam", code: "VN", prefix: "84" },
  { icon: "ðŸ‡¼ðŸ‡«", name: "Wallis and Futuna", code: "WF", prefix: "681" },
  { icon: "ðŸ‡ªðŸ‡­", name: "Western Sahara", code: "EH", prefix: "212" },
  { icon: "ðŸ‡¾ðŸ‡ª", name: "Yemen", code: "YE", prefix: "967" },
  { icon: "ðŸ‡¿ðŸ‡²", name: "Zambia", code: "ZM", prefix: "260" },
  { icon: "ðŸ‡¿ðŸ‡¼", name: "Zimbabwe", code: "ZW", prefix: "263" },
];

export { countries };
