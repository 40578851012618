import { Box, Breadcrumbs, Button, Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, useParams } from "react-router-dom";
import EarnerSearchInput from "../components/Earner/EarnerSearchInput";
import EarnerDeleteDialog from "../components/Earner/EarnerDeleteDialog";
import EarnerList from "../components/Earner/EarnerList";
import AdminLayout from "../layouts/AdminLayout";
import EarnersService from "../services/EarnersService";

const Earners = () => {
  const params = useParams();
  const pageNumber = params.pageNumber ? parseInt(params.pageNumber, 10) : 1;
  const [search, setSearch] = useState("");
  const [tags, setTags] = useState([]);
  const [currentEarnerId, setCurrentEarnerId] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [removeEarnerId, setRemoveEarnerId] = useState(null);
  const intl = useIntl();

  EarnersService.setDefaultErrorMessage(
    intl.formatMessage({
      id: "errors.unexpected-error",
      defaultMessage: "An unexpected error ocurred.",
    })
  );

  const handleDelete = () => {
    setDeleting(true);
    EarnersService.deleteEarner(currentEarnerId).then((data) => {
      setOpenDeleteDialog(false);
      setDeleting(false);
      setRemoveEarnerId(currentEarnerId);
    });
  };

  const handleDeleteButtonClick = (earnerId) => {
    setCurrentEarnerId(earnerId);
    setOpenDeleteDialog(true);
  };

  const handleDeleteDialogClose = () => {
    setOpenDeleteDialog(false);
  };

  return (
    <AdminLayout>
      <Box p={2}>
        <Breadcrumbs separator=">" aria-label="breadcrumb">
          <Link style={{ color: "inherit" }} to="/">
            <FormattedMessage
              id="routes.protected.dashboard"
              defaultMessage="Dashboard"
              description="Dashboard menu breadcrumbs title"
            />
          </Link>
          <FormattedMessage
            id="routes.protected.earners"
            defaultMessage="Recipients"
            description="Recipients menu link and breadcrumbs title"
          />
        </Breadcrumbs>
      </Box>
      <Box p={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <EarnerSearchInput
              setTags={setTags}
              setSearch={setSearch}
              params={params}
            />
          </Grid>
          <Grid item xs={6} md={2}>
            <Box ml={1}>
              <Button
                color="default"
                variant="contained"
                component={Link}
                to="/import-recipients"
                fullWidth
              >
                <FormattedMessage
                  id="earners.import-button"
                  defaultMessage="Import"
                  description="Import button text"
                />
              </Button>
            </Box>
          </Grid>
          <Grid item xs={6} md={2}>
            <Box ml={1}>
              <Button
                color="primary"
                variant="contained"
                component={Link}
                to="/add-recipient"
                fullWidth
              >
                <Typography variant="button">
                  <FormattedMessage
                    id="earners.add-earner-button"
                    defaultMessage="Add Recipient"
                    description="Add Recipient button text"
                  />
                </Typography>
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box p={2}>
        <EarnerList
          limit={10}
          pageNumber={pageNumber}
          search={search}
          tags={tags}
          handleDeleteButtonClick={handleDeleteButtonClick}
          removeEarnerId={removeEarnerId}
        />
        <EarnerDeleteDialog
          open={openDeleteDialog}
          handleClose={handleDeleteDialogClose}
          deleting={deleting}
          handleDelete={handleDelete}
        />
      </Box>
    </AdminLayout>
  );
};

export default Earners;
