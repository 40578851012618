import { Icon, IconButton, InputAdornment, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import BadgeClassesService from "../../services/BadgeClassesService";

const getCurrentWord = (caretPosition, currentValue) => {
  let lastWhiteBeforeCaret = currentValue
    .slice(0, caretPosition)
    .lastIndexOf(" ");
  let matches = currentValue.match(
    new RegExp(
      "^.{" + (lastWhiteBeforeCaret + 1) + "}([0-9a-zA-Z\u00C0-\u00FF:_-]*)"
    )
  );
  if (matches && matches.length > 1) {
    return matches[1];
  }
  return false;
};

const replaceCurrentWord = (caretPosition, currentValue, newWord) => {
  let correntWord = getCurrentWord(caretPosition, currentValue);
  if (correntWord) {
    return currentValue.replace(correntWord, newWord);
  }
  return currentValue;
};

const BadgeClassSearchInput = (props) => {
  const intl = useIntl();
  const serchInput = useRef(null);
  const [options, setOptions] = useState([]);
  const [rawSearch, setRawSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentCaretPosition, setCurrentCaretPosition] = useState(0);
  const [open, setOpen] = useState(false);

  BadgeClassesService.setDefaultErrorMessage(
    intl.formatMessage({
      id: "errors.unexpected-error",
      defaultMessage: "An unexpected error ocurred.",
    })
  );

  const handleSearchChange = (event, value, reason) => {
    if (!value) {
      return;
    }

    setRawSearch(
      replaceCurrentWord(
        currentCaretPosition,
        serchInput.current.value,
        "tag:" + value
      )
    );
  };

  const handleInputChange = () => {
    setRawSearch(serchInput.current.value);
  };

  const handleKeyUp = (event) => {
    if (event.key === "Enter") {
      handleSearch(event);
      setOpen(false);
      return;
    }

    setCurrentCaretPosition(event.target.selectionStart);
  };

  const handleSearch = () => {
    let tags = [];
    let search = serchInput.current.value;
    let matches = search.match(/tag:([0-9a-zA-Z\u00C0-\u00FF_-]+)/g);
    if (matches) {
      matches.map((match) => {
        tags.push(match.replace("_", " ").replace("tag:", ""));
        search = search.replace(match, "");
        return match;
      });
    }
    props.setTags(tags);
    props.setSearch(search.trim());
    props.params.pageNumber = 1;
  };

  useEffect(() => {
    let currentWord = getCurrentWord(currentCaretPosition, rawSearch).replace(
      "tag:",
      ""
    );

    if (!currentWord) {
      return;
    }

    let searchTimeout = setTimeout(() => {
      setOptions([]);
      setLoading(true);
      setOpen(true);
      BadgeClassesService.getBadgeClassTags(currentWord).then((tags) => {
        tags = tags.map((tag) => tag.replace(" ", "_"));
        setOptions(tags);
        setLoading(false);
      });
    }, 300);
    return () => {
      clearTimeout(searchTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawSearch]);

  const inputProps = {
    startAdornment: (
      <InputAdornment position="start">
        <IconButton onClick={handleSearch}>
          <Icon>search</Icon>
        </IconButton>
      </InputAdornment>
    ),
  };

  const inputs = {
    autocomplete: {
      loadingText: intl.formatMessage({
        id: "badges-search.loading",
        defaultMessage: "Loading...",
        description: "Loading text in search suggestions",
      }),
      noOptionsText: intl.formatMessage({
        id: "badges-search.no-suggestions-found",
        defaultMessage: "No suggestions",
        description: "Text shown in search suggestions when nothing is found",
      }),
    },
    textfield: {
      placeholder: intl.formatMessage({
        id: "badges-search.input-placeholder",
        defaultMessage: "Search by name, description or tag",
        description: "Placeholder of search input",
      }),
    },
  };

  return (
    <>
      <Autocomplete
        freesolo="true"
        options={options}
        open={open}
        getOptionLabel={(x) => "tag:" + x}
        loading={loading}
        loadingText={inputs.autocomplete.loadingText}
        noOptionsText={inputs.autocomplete.noOptionsText}
        inputValue={rawSearch}
        onChange={handleSearchChange}
        onInputChange={handleInputChange}
        onKeyUp={handleKeyUp}
        onBlur={() => setOpen(false)}
        filterOptions={(options, state) => {
          return options;
        }}
        renderInput={(params) => (
          <TextField
            variant="outlined"
            size="small"
            placeholder={inputs.textfield.placeholder}
            InputProps={{
              ...params.inputProps,
              startAdornment: inputProps.startAdornment,
            }}
            inputRef={serchInput}
            fullWidth
            ref={params.InputProps.ref}
          />
        )}
      ></Autocomplete>
    </>
  );
};

export default BadgeClassSearchInput;
