import { Box, CircularProgress, Icon } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useContext, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { UserContext } from "../../context/UserProvider";
import usePromise from "../../hooks/usePromise";
import IssuersService from "../../services/IssuersService";
import ErrorsAlert from "../Common/ErrorsAlert";
import IssuerIdentityContainer from "./IssuerIdentityContainer";
import IssuerIdentityDomainForm from "./IssuerIdentityDomainForm";
import IssuerIdentityEmailForm from "./IssuerIdentityEmailForm";

const IssuerIdentity = () => {
  const [issuerData, errors, loading, setPromise] = usePromise();
  const { issuer } = useContext(UserContext);

  useEffect(() => {
    issuer && setPromise(IssuersService.getIssuerAndIdentities(issuer.uuid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [issuer]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" mb={2}>
        <CircularProgress />
      </Box>
    );
  }

  if (errors) {
    return (
      <Box mb={2}>
        <ErrorsAlert errors={errors} />
      </Box>
    );
  }

  return (
    <Box>
      <Box>
        <Alert color="info" icon={<Icon fontSize="large">fingerprint</Icon>}>
          <FormattedMessage
            id="issuer-identity.main-information"
            defaultMessage="Community will trust that you are {name} judging this information."
            values={{ name: <strong>{issuerData.issuer?.name}</strong> }}
          />
        </Alert>
      </Box>
      {issuerData?.identities && (
        <Box mt={2}>
          <IssuerIdentityEmailForm
            email={
              issuerData && issuerData.identities["email"]
                ? issuerData.identities["email"].email
                : issuerData.issuer?.email
            }
            status={
              issuerData && issuerData.identities["email"]
                ? issuerData.identities["email"].status
                : "not_validated"
            }
            issuer={issuerData.issuer}
          />
        </Box>
      )}
      {issuerData?.identities && (
        <Box mt={2}>
          <IssuerIdentityDomainForm
            website={
              issuerData && issuerData.identities["domain"]
                ? issuerData.identities["domain"].domain
                : issuerData.issuer?.url
            }
            status={
              issuerData && issuerData.identities["domain"]
                ? issuerData.identities["domain"].status
                : "not_validated"
            }
            issuer={issuerData.issuer}
          />
        </Box>
      )}
      <Box mt={2}>
        <IssuerIdentityContainer
          type="documentation"
          comingSoon={true}
        ></IssuerIdentityContainer>
      </Box>
    </Box>
  );
};

export default IssuerIdentity;
