import {
  Box,
  Button,
  Divider,
  Grid,
  Icon,
  Paper,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import BadgeClassesService from "../../services/BadgeClassesService";
import MoreOptionsMenu from "../Common/MoreOptionsMenu";
import LoadingButton from "../Common/LoadingButton";
import useSnackbar from "../../hooks/useSnackbar";
import history from "../../utils/history";
import TagList from "../Common/TagList";

const BadgeClassItem = (props) => {
  const intl = useIntl();
  const [showSnackbar] = useSnackbar();
  const type = props.type ?? "default";
  const imgWidth = type === "reduce" ? "75" : "100%";
  const [status, setStatus] = useState(props.badgeClass.status);

  const badgeClassStatusLabels = {
    draft: intl.formatMessage({
      id: "badge-class-status.status-draft",
      defaultMessage: "DRAFT",
    }),
    used: intl.formatMessage({
      id: "badge-class-status.status-used",
      defaultMessage: "USED",
    }),
    archived: intl.formatMessage({
      id: "badge-class-status.status-archived",
      defaultMessage: "ARCHIVED",
    }),
  };

  BadgeClassesService.setDefaultErrorMessage(
    intl.formatMessage({
      id: "errors.unexpected-error",
      defaultMessage: "An unexpected error ocurred.",
    })
  );

  const handleCreateNewVersion = (badgeId) => {
    BadgeClassesService.newVersionBadgeClass(badgeId)
      .then((badgeClass) => {
        history.push("/edit-badge/" + badgeClass.uuid);
      })
      .catch((err) => {
        showSnackbar(err.message, "error", 2500);
      });
  };

  const handleArchive = (badgeId, currentStatus) => {
    BadgeClassesService.archiveBadgeClass(badgeId)
      .then((badgeClass) => {
        setStatus("archived");
      })
      .catch((err) => {
        showSnackbar(err.message, "error", 2500);
        setStatus(currentStatus);
      });
  };

  const handleUnarchive = (badgeId, currentStatus) => {
    BadgeClassesService.unarchiveBadgeClass(badgeId)
      .then((badgeClass) => {
        setStatus("used");
      })
      .catch((err) => {
        showSnackbar(err.message, "error", 2500);
        setStatus(currentStatus);
      });
  };

  if (status === "deleted") {
    return "";
  }

  return (
    <Paper variant="elevation" elevation={status === "archived" ? 0 : 2}>
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          sm={3}
          md={1}
          lg={2}
          container
          justify="center"
          alignContent="center"
        >
          <Box p={1}>
            {props.badgeClass.thumbnails &&
            props.badgeClass.thumbnails["125x100"] ? (
              <img
                src={props.badgeClass.thumbnails["125x100"]}
                alt="badge icon"
                width={imgWidth}
                style={{ maxWidth: "100%" }}
              />
            ) : (
              <Icon style={{ fontSize: 64 }}>shield</Icon>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={6} sm={9}>
          <Box py={1} px={2}>
            <Typography variant="h6" noWrap={true}>
              {props.badgeClass.name}
              {props.badgeClass.version > 1 && (
                <Typography display="inline" variant="subtitle2">
                  {" "}
                  v{props.badgeClass.version}
                </Typography>
              )}
            </Typography>
            <Typography variant="body2" noWrap={true}>
              {props.badgeClass.description}
            </Typography>
            {type !== "reduce" && <TagList tags={props.badgeClass.tagNames} />}
          </Box>
        </Grid>
        <Grid item xs={12} md={2} sm={4} container alignContent="center">
          <Box px={2}>
            <Typography variant="body2" color="textSecondary">
              <FormattedMessage
                id="badge-class-list.status-title"
                defaultMessage="Status"
                description="Title in badge's list."
              />
            </Typography>
            <Typography variant="body1">
              {badgeClassStatusLabels[status]}
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          md={3}
          lg={2}
          container
          justify="flex-end"
          alignItems="center"
        >
          <Box display="flex" px={2} justifyItems="right" alignItems="center">
            {status === "draft" && (
              <Box mr={1}>
                <Button
                  variant="contained"
                  color="default"
                  mr={2}
                  to={"/edit-badge/" + props.badgeClass.uuid}
                  component={Link}
                >
                  <FormattedMessage
                    id="badge-class-list.edit-button"
                    defaultMessage="Edit"
                    description="Edit button in badge's list."
                  />
                </Button>
              </Box>
            )}
            {status !== "archived" && (
              <Box mr={1}>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={"/send-badges/" + props.badgeClass.uuid}
                >
                  <FormattedMessage
                    id="badge-class-list.award-button"
                    defaultMessage="Award"
                    description="Award button in badge's list."
                  />
                </Button>
              </Box>
            )}
            {type !== "reduce" && (
              <Box>
                <MoreOptionsMenu>
                  {status === "draft" && (
                    <>
                      <Box display="grid" justifyItems="center" py={1} px={4}>
                        <Button
                          size="small"
                          onClick={() =>
                            props.handleDeleteClick(props.badgeClass.uuid)
                          }
                        >
                          <Icon fontSize="small">delete_forever</Icon>
                          <FormattedMessage
                            id="button.delete"
                            defaultMessage="Delete"
                          />
                        </Button>
                      </Box>
                    </>
                  )}
                  {status === "used" && (
                    <>
                      <Box display="grid" justifyItems="center" py={1} px={4}>
                        <Button
                          size="small"
                          onClick={() =>
                            handleCreateNewVersion(props.badgeClass.uuid)
                          }
                        >
                          <Icon fontSize="small">add</Icon>
                          <FormattedMessage
                            id="button.new-version"
                            defaultMessage="Create new version"
                          />
                        </Button>
                      </Box>
                      <Divider />
                      <Box display="grid" justifyItems="center" py={1} px={4}>
                        <Button
                          size="small"
                          onClick={() =>
                            handleArchive(
                              props.badgeClass.uuid,
                              props.badgeClass.status
                            )
                          }
                        >
                          <Icon fontSize="small">archive</Icon>
                          <FormattedMessage
                            id="button.archive"
                            defaultMessage="Archive"
                          />
                        </Button>
                      </Box>
                    </>
                  )}
                  {status === "archived" && (
                    <>
                      <Box display="grid" justifyItems="center" py={1} px={4}>
                        <LoadingButton
                          size="small"
                          onClick={() =>
                            handleUnarchive(
                              props.badgeClass.uuid,
                              props.badgeClass.status
                            )
                          }
                        >
                          <Icon fontSize="small">unarchive</Icon>
                          <FormattedMessage
                            id="button.unarchive"
                            defaultMessage="Unarchive"
                          />
                        </LoadingButton>
                      </Box>
                    </>
                  )}
                </MoreOptionsMenu>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default BadgeClassItem;
