import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  Icon,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";
import React, { useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

const EarnerGridRow = (props) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleToggle = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ButtonGroup
        variant="contained"
        color="default"
        aria-label="split button"
      >
        <Button to={"/edit-recipient/" + props.earnerId} component={Link}>
          <FormattedMessage id="button.edit" defaultMessage="Edit" />
        </Button>
        <Button
          color="default"
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDown />
        </Button>
      </ButtonGroup>
      <Popper open={open} anchorEl={anchorEl} role={undefined} transition>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  <MenuItem
                    onClick={() =>
                      props.handleDeleteButtonClick(props.earnerId)
                    }
                  >
                    <ListItemIcon>
                      <Icon size="small">delete_forever</Icon>
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <FormattedMessage
                          id="button.delete"
                          defaultMessage="Delete"
                        />
                      }
                    />
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default EarnerGridRow;
