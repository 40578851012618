import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@material-ui/core";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

const badgeStatuses = [
  {
    label: "DRAFT",
    value: "draft",
  },
  {
    label: "PENDING",
    value: "pending",
  },
  {
    label: "PROCESSING",
    value: "processing",
  },
  {
    label: "STAMPING",
    value: "stamping",
  },
  {
    label: "STAMPED",
    value: "stamped",
  },
  {
    label: "SIGNED",
    value: "signed",
  },
  {
    label: "PROCESSED",
    value: "processed",
  },
  {
    label: "ERROR",
    value: "error",
  },
];

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      width: 250,
    },
  },
};

const BadgeStatusSelect = (props) => {
  const intl = useIntl();

  const badgeStatusLabels = {
    draft: intl.formatMessage({
      id: "badge-status.status-draft",
      defaultMessage: "Draft",
    }),
    pending: intl.formatMessage({
      id: "badge-status.status-pending",
      defaultMessage: "Pending",
    }),
    processing: intl.formatMessage({
      id: "badge-status.status-processing",
      defaultMessage: "Processing",
    }),
    stamping: intl.formatMessage({
      id: "badge-status.status-stamping",
      defaultMessage: "Stamping",
    }),
    stamped: intl.formatMessage({
      id: "badge-status.status-stamped",
      defaultMessage: "Stamped",
    }),
    signed: intl.formatMessage({
      id: "badge-status.status-signed",
      defaultMessage: "Signed",
    }),
    processed: intl.formatMessage({
      id: "badge-status.status-processed",
      defaultMessage: "Processed",
    }),
    error: intl.formatMessage({
      id: "badge-status.status-error",
      defaultMessage: "Error",
    }),
  };

  const handleStatusChange = (event) => {
    if (!event.target.value.length) {
      props.setStatuses(["pending", "processed", "error"]);
    }
    props.setStatuses(event.target.value);
    props.params.pageNumber = 1;
  };

  const getSelectedText = (selected) => {
    if (
      !selected ||
      !selected.length ||
      selected.length === badgeStatuses.length
    ) {
      return intl.formatMessage({
        id: "badge-status-select.status-all",
        defaultMessage: "All",
      });
    }

    let translatedStatus = [];
    selected.map((status) => translatedStatus.push(badgeStatusLabels[status]));
    return translatedStatus.join(", ").toUpperCase();
  };

  return (
    <FormControl fullWidth variant="outlined" size="small">
      {props.statuses.length ? (
        <InputLabel disableAnimation={true}>
          <FormattedMessage
            id="badge-status-select.select-label"
            defaultMessage="Status"
          />
        </InputLabel>
      ) : (
        ""
      )}
      <Select
        multiple
        value={props.statuses}
        onChange={handleStatusChange}
        renderValue={getSelectedText}
        MenuProps={MenuProps}
        label={intl.formatMessage({
          id: "badge-status-select.select-label",
          defaultMessage: "Status",
        })}
        displayEmpty
      >
        {badgeStatuses.map((status) => (
          <MenuItem key={status.label} value={status.value}>
            <Checkbox
              checked={props.statuses.indexOf(status.value) > -1}
              color="primary"
            />
            <ListItemText primary={badgeStatusLabels[status.value]} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default BadgeStatusSelect;
