import React, { useEffect, useState } from "react";
import SelectNewPlanDialog from "../components/SubscriptionPlan/SelectNewPlanDialog";
import usePromise from "../hooks/usePromise";
import PlansService from "../services/PlansService";
import SubscriptionPaymentDialog from "../components/SubscriptionPlan/SubscriptionPaymentDialog";

const SubscriptionPlanContext = React.createContext();

const SubscriptionPlanProvider = ({ children, ...props }) => {
  const [
    data,
    errorsLoadingPlans,
    loadingPlans,
    setPromise,
    reset,
  ] = usePromise();

  const [
    requestData,
    errorsRequestingNewPlan,
    requestingNewPlan,
    setRequestPromise,
    resetRequest,
  ] = usePromise();

  const [
    cancelData,
    errorsCancelling,
    cancellingRequestedSubscription,
    setCancelRequest,
    resetCancelRequest,
  ] = usePromise();

  const [
    authorizeData,
    errorsAuthorizing,
    authorizing,
    setAuthorizePromise,
    resetAuthorizePromise,
  ] = usePromise();

  const [issuerPlan, setIssuerPlan] = useState(false);
  const [plans, setPlans] = useState(false);
  const [newPlan, setNewPlan] = useState(false);
  const [requestedSubscription, setRequestedSubscription] = useState(false);
  const [currentSubscription, setCurrentSubscription] = useState(false);
  const [nextSubscription, setNextSubscription] = useState(false);
  const [openPaymentDialog, setOpenPaymentDialog] = useState(false);

  const selectNewPlan = (plan) => {
    setNewPlan(plan);
  };

  const cancelNewPlan = () => {
    setNewPlan(false);
    resetRequest();
  };

  const confirmNewPlan = () => {
    setRequestPromise(PlansService.requestPlan(newPlan));
  };

  const authorizeSubscription = (paymentData) => {
    setAuthorizePromise(
      PlansService.authorizeRequestedPlan(requestedSubscription, paymentData)
    );
  };

  const cancelRequestedSubscription = () => {
    return setCancelRequest(
      PlansService.cancelRequestedPlan(requestedSubscription)
    );
  };

  useEffect(() => {
    setPromise(PlansService.getPlans());

    return () => {
      reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data.plans) {
      setPlans(data.plans);
    }
    if (data.issuerPlan) {
      setIssuerPlan(data.issuerPlan);
    }
    setRequestedSubscription(data.requestedSubscription ?? false);
    if (data.currentSubscription) {
      setCurrentSubscription(data.currentSubscription);
    }
    if (data.nextSubscription) {
      setNextSubscription(data.nextSubscription);
    }
  }, [data]);

  useEffect(() => {
    if (requestData && requestData.uuid) {
      if (requestData.status === "pending") {
        setNewPlan(false);
        setRequestedSubscription(requestData);
      }
      if (requestData.status === "authorized") {
        setNewPlan(false);
        setRequestedSubscription(false);
        setPromise(PlansService.getPlans());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestData]);

  useEffect(() => {
    if (authorizeData) {
      if (authorizeData.issuerPlanSubscription) {
        setRequestedSubscription(authorizeData.issuerPlanSubscription);
      }
      if (authorizeData.issuerPlanSubscription?.status === "authorized") {
        setOpenPaymentDialog(false);
        setPromise(PlansService.getPlans());
      }
    }

    return () => {
      resetAuthorizePromise();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorizeData]);

  return (
    <SubscriptionPlanContext.Provider
      value={{
        issuerPlan,
        plans,
        loadingPlans,
        errorsLoadingPlans,
        selectNewPlan,
        requestedSubscription,
        currentSubscription,
        nextSubscription,
        setRequestedSubscription,
        cancelRequestedSubscription,
        cancellingRequestedSubscription,
        errorsCancelling,
        setOpenPaymentDialog,
        authorizeSubscription,
        authorizing,
        errorsAuthorizing,
        resetAuthorizePromise,
        ...props.value,
      }}
    >
      {children}
      <SelectNewPlanDialog
        currentPlan={issuerPlan}
        newPlan={newPlan}
        open={newPlan && issuerPlan ? true : false}
        handleClose={cancelNewPlan}
        handleConfirm={confirmNewPlan}
        loading={requestingNewPlan}
        errors={errorsRequestingNewPlan}
        maxWidth="md"
        fullWidth
      />
      <SubscriptionPaymentDialog
        open={openPaymentDialog}
        onClose={() => setOpenPaymentDialog(false)}
      />
    </SubscriptionPlanContext.Provider>
  );
};

export { SubscriptionPlanProvider, SubscriptionPlanContext };
