import API from "../utils/API";
import Service from "./Service";

class LevelService extends Service {
  queries = {
    getLevels: () =>
      `query { levels {
        edges {
          node {
            name
            description
            levelPrices {
              edges {
                node {
                  value
                  currency {
                    ticker
                    decimals
                    plural
                  }
                }
              }
            }
          }
        }
      }
    }`,
  };

  getLevels = async () => {
    return API.callGraphql(this.queries.getLevels()).then((response) => {
      this.errorParser(response);

      if (!response.data.levels) this.throwDefaultError();

      return response.data.levels.edges;
    });
  };
}

export default new LevelService();
