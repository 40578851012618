import { Tooltip, Typography } from "@material-ui/core";
import React from "react";
import { FormattedNumber } from "react-intl";

const FormattedCurrency = (props) => {
  const { currency, value, disableCurrency, currencyProps, ...rest } = props;
  const ticker = currency.ticker ?? "USD";
  const currencyDescription = currency.plural ?? "";
  const decimals = currency.decimals ?? 2;
  const numberValue = value / Math.pow(10, decimals);

  const typographyProps = {
    ...{
      component: "span",
    },
    ...rest,
  };

  const currencyTypographyProps = {
    ...typographyProps,
    ...{
      component: "span",
      style: { paddingLeft: "0.3em" },
    },
    ...currencyProps,
  };

  return (
    <>
      <Typography {...typographyProps}>
        <FormattedNumber
          value={numberValue}
          minimumFractionDigits="0"
          maximumFractionDigits={decimals}
        />
      </Typography>
      {!disableCurrency && (
        <Tooltip title={currencyDescription}>
          <Typography {...currencyTypographyProps}>
            {ticker === "TBFREE" ? "FREE" : ticker}
          </Typography>
        </Tooltip>
      )}
    </>
  );
};

export default FormattedCurrency;
