import API from "../utils/API";
import AuthService from "./AuthService";
import Service from "./Service";

class BadgesService extends Service {
  queries = {
    getBadges: (...params) =>
      `query { byIssuerQueryBadges(issuer:"/api/issuers/${params[0]}", page:${
        params[1] ?? 1
      }, itemsPerPage:${params[2] ?? 10}  ${params[3]}  ${
        params[4]
      }) { collection {  uuid, badgeClass { name, thumbnails }, email { earner { name } }, batch { uuid, number }, issuedOn, score, status, revoked, revocationReason, pdf, errors }, paginationInfo { totalCount, lastPage } } }`,
    getBadge: (...params) =>
      `query { badge(id:"/api/badges/${params[0]}") { uuid } }`,
    revokeBadge: (...params) =>
      `mutation{revokeBadge(input:{id:"/api/badges/${
        params[0]
      }",revocationReason:"${
        params[1] ?? ""
      }"}){badge{ uuid, revoked, revocationReason }}}`,
    resendBadge: (...params) =>
      `mutation{resendBadge(input:{id:"/api/badges/${params[0]}"}){badge{ uuid }}}`,
  };

  getBadge = async (badgeUuid) => {
    badgeUuid = badgeUuid ? badgeUuid : "";

    return API.callGraphql(this.queries.getBadge(badgeUuid)).then(
      (response) => {
        this.errorParser(response);

        if (!response.data.badge) this.throwDefaultError();

        return response.data.badge;
      }
    );
  };

  getBadges = async (page, itemsPerPage, earner, search, statuses) => {
    let issuer = AuthService.getCurrentIssuer();
    let earnerQuery = earner ? `, earner: "${earner.id}"` : ``;
    let status =
      statuses && statuses.length ? `, status:["${statuses.join('","')}"]` : ``;

    return API.callGraphql(
      this.queries.getBadges(
        issuer.uuid,
        page,
        itemsPerPage,
        earnerQuery,
        status
      )
    ).then((response) => {
      this.errorParser(response);

      if (!response.data.byIssuerQueryBadges) this.throwDefaultError();

      return response.data.byIssuerQueryBadges;
    });
  };

  revokeBadge = async (badgeUuid, revocationReason) => {
    badgeUuid = badgeUuid ? badgeUuid : "";
    revocationReason = revocationReason ? revocationReason : "";

    return API.callGraphql(
      this.queries.revokeBadge(badgeUuid, revocationReason)
    ).then((response) => {
      this.errorParser(response);

      if (!response.data.revokeBadge) this.throwDefaultError();

      return response.data.revokeBadge.badge;
    });
  };

  resendBadge = async (badgeUuid) => {
    badgeUuid = badgeUuid ? badgeUuid : "";

    return API.callGraphql(this.queries.resendBadge(badgeUuid)).then(
      (response) => {
        this.errorParser(response);

        if (!response.data.resendBadge) this.throwDefaultError();

        return response.data.resendBadge.badge;
      }
    );
  };
}

export default new BadgesService();
