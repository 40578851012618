import { Box, CircularProgress } from "@material-ui/core";
import ErrorsAlert from "../components/Common/ErrorsAlert";
import React, { useEffect, useState } from "react";
import usePromise from "../hooks/usePromise";
import BadgeClassesService from "../services/BadgeClassesService";
import { useIntl } from "react-intl";

const SendBadgesBatchContext = React.createContext();

const SendBadgesBatchProvider = (props) => {
  const intl = useIntl();
  const [badgeClassData, errors, loading, setPromiseLoad] = usePromise();
  const [batch, setBatch] = useState({
    id: 0,
    badgeClass: false,
    assertions: [],
  });
  const [affordable, setAffordable] = useState(false);

  const setBadgeClass = (badgeClass) => {
    setBatch({ ...batch, badgeClass: badgeClass });
  };

  const getEarnersIds = () => {
    return batch.assertions.reduce((previousValue, currentValue) => {
      if (!previousValue) {
        previousValue = [];
      }
      previousValue.push(currentValue.earner.uuid);
      return previousValue;
    }, []);
  };

  const addEarners = (earners) => {
    const currentEarnersIds = getEarnersIds();
    const earnersToAdd = earners.filter((earner) => {
      return currentEarnersIds.indexOf(earner.uuid) === -1;
    });
    let assertions = batch.assertions;
    earnersToAdd.map((earner) => {
      assertions = [
        ...assertions,
        { id: earner.uuid, earner: earner, score: null },
      ];
      return assertions;
    });
    setBatch({ ...batch, assertions: assertions });
  };

  const removeEarner = (id) => {
    setBatch({
      ...batch,
      assertions: batch.assertions.filter((a) => {
        return a.id !== id;
      }),
    });
  };

  const setAssertionScore = (assertionId, score) => {
    setBatch({
      ...batch,
      assertions: batch.assertions.map((a) => {
        if (a.id === assertionId) {
          a.score = score;
        }
        return a;
      }),
    });
  };

  const setBatchUuid = (batchUuid) => {
    setBatch({
      ...batch,
      uuid: batchUuid,
    });
  };

  BadgeClassesService.setDefaultErrorMessage(
    intl.formatMessage({
      id: "errors.unexpected-error",
      defaultMessage: "An unexpected error ocurred.",
    })
  );

  useEffect(() => {
    if (props.badgeUuid) {
      setPromiseLoad(BadgeClassesService.getBadgeClass(props.badgeUuid));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (badgeClassData) {
      setBadgeClass(badgeClassData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [badgeClassData]);

  if (errors) {
    return (
      <Box>
        <ErrorsAlert errors={errors} />
      </Box>
    );
  }

  return (
    <SendBadgesBatchContext.Provider
      value={{
        ...props.value,
        batch,
        affordable,
        setAffordable,
        setBadgeClass,
        addEarners,
        removeEarner,
        setAssertionScore,
        getEarnersIds,
        setBatchUuid,
      }}
    >
      {loading ? (
        <Box display="grid" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        props.children
      )}
    </SendBadgesBatchContext.Provider>
  );
};

export { SendBadgesBatchProvider, SendBadgesBatchContext };
