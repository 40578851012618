import { Box, Button, Paper, Typography } from "@material-ui/core";
import React from "react";
import { FormattedDate, FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import MoreOptionsMenu from "../Common/MoreOptionsMenu";
import BatchResendButton from "./BatchResendButton";
import BatchStatus from "./BatchStatus";

const BatchItem = (props) => {
  if (!props.batch) {
    return <></>;
  }

  return (
    <Paper elevation={1}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        alignItems="center"
        p={1}
      >
        <Box flexShrink={1} px={1}>
          <Typography variant="overline" display="block">
            <FormattedMessage
              id="batch-list.batch-title"
              defaultMessage="Batch #"
              description="Batch title"
            />
          </Typography>
          <Typography variant="button">{props.batch.number}</Typography>
        </Box>
        <Box flexGrow={1} px={2}>
          <Typography variant="overline" display="block">
            <FormattedMessage
              id="batch-list.status-title"
              defaultMessage="Status"
              description="Status title"
            />
          </Typography>
          <BatchStatus variant="button" batch={props.batch} />
        </Box>
        <Box
          flexShrink={1}
          px={2}
          alignItems="flex-end"
          display="flex"
          flexDirection="column"
        >
          <Typography variant="overline" display="block">
            <FormattedMessage
              id="batch-list.badges-title"
              defaultMessage="Badges"
              description="Badges title"
            />
          </Typography>
          <Typography variant="button">{props.batch.badgesCount}</Typography>
        </Box>
        <Box flexShrink={1} px={2}>
          <Typography variant="overline" display="block">
            <FormattedMessage
              id="batch-list.created-at"
              defaultMessage="Created at"
            />
          </Typography>
          <Typography variant="button">
            <FormattedDate value={props.batch.createdAt} />
          </Typography>
        </Box>
        <Box flexShrink={1} pl={2} justifyContent="right" justifyItems="right">
          <Button
            variant="outlined"
            component={Link}
            to={"/batch/" + props.batch.uuid}
          >
            <FormattedMessage
              id="batch-list.batch-details-button"
              defaultMessage="Details"
              description="Batch details button text"
            />
          </Button>
          <MoreOptionsMenu>
            {props.batch.status === "processed" && (
              <Box>
                <BatchResendButton
                  uuid={props.batch.uuid}
                  style={{ padding: "0.5em 1em" }}
                  fullWidth={true}
                />
              </Box>
            )}
          </MoreOptionsMenu>
        </Box>
      </Box>
    </Paper>
  );
};

export default BatchItem;
