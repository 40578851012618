import React, { useContext, useEffect, useState } from "react";
import SvgCanvas from "svgedit/dist/svgcanvas/svgcanvas";
import { CreateBadgeContext } from "../../context/CreateBadgeProvider";
import jQuery from "jquery";
import { Box, makeStyles } from "@material-ui/core";
import SvgEditorUploader from "./SvgEditorUploader";
import SvgEditorStrokeSelector from "./SvgEditorStrokeSelector";
import SvgEditorToolbar from "./SvgEditorToolbar";
import SvgEditorColorPicker from "./SvgEditorColorPicker";
import SvgEditorOrderTools from "./SvgEditorOrderTools";
import { ToggleButtonGroup } from "@material-ui/lab";
import SvgEditorTextTools from "./SvgEditorTextTools";
import useSnackbar from "../../hooks/useSnackbar";

const useStyles = makeStyles((theme) => ({
  textInput: {
    width: "0px",
    height: "0px",
    border: "0px",
    margin: "0px",
    padding: "0px",
  },
}));

const toolsWithFill = [
  "rect",
  "fhrect",
  "square",
  "circle",
  "ellipse",
  "fhellipse",
  "text",
];

const toolsWithStroke = [
  "fhpath",
  "path",
  "line",
  "rect",
  "fhrect",
  "square",
  "circle",
  "ellipse",
  "fhellipse",
  "text",
];

const config = {
  initFill: { color: "FFFFFF", opacity: 1 },
  initStroke: { color: "000000", opacity: 1, dasharray: "none", width: 1 },
  text: {
    stroke_width: 0,
    font_size: 24,
    font_family: "serif",
    fill_color: "000000",
  },
  initOpacity: 1,
  imgPath: "editor/images/",
  dimensions: [500, 400],
  baseUnit: "px",
  show_outside_canvas: false,
};

var canvas;

const SvgEditor = (props) => {
  const { badgeClass, setBadgeClass, badgeClassConfig } = useContext(
    CreateBadgeContext
  );
  const [tool, setTool] = useState("select");
  const [strokeType, setStrokeType] = useState(config.initStroke.dasharray);
  const [strokeWidth, setStrokeWidth] = useState(config.initStroke.width);
  const [strokeColor, setStrokeColor] = useState(config.initStroke.color);
  const [fillColor, setFillColor] = useState(config.initFill.color);
  const [fontBold, setFontBold] = useState(false);
  const [fontItalic, setFontItalic] = useState(false);
  const [fontSize, setFontSize] = useState(false);
  const [fontFamily, setFontFamily] = useState(false);
  const [openFilePopup, setOpenFilePopup] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [defaultFillChanged, setDefaultFillChanged] = useState(false);
  const [showSnackbar] = useSnackbar();

  const classes = useStyles();

  const load = () => {
    canvas = new SvgCanvas(document.getElementById("svgcanvas"), config);
    canvas.updateCanvas(config.dimensions[0], config.dimensions[1]);
    if (badgeClass.svgDesign && badgeClass.svgDesign !== "undefined") {
      canvas.setSvgString(badgeClass.svgDesign);
    } else {
      canvas.importSvgString(`<?xml version="1.0" encoding="UTF-8" standalone="no"?>
      <svg
         xmlns:dc="http://purl.org/dc/elements/1.1/"
         xmlns:cc="http://creativecommons.org/ns#"
         xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
         xmlns:svg="http://www.w3.org/2000/svg"
         xmlns="http://www.w3.org/2000/svg"
         id="svg8"
         version="1.1"
         viewBox="0 0 210 297"
         height="297mm"
         width="210mm">
        <defs
           id="defs2" />
        <metadata
           id="metadata5">
          <rdf:RDF>
            <cc:Work
               rdf:about="">
              <dc:format>image/svg+xml</dc:format>
              <dc:type
                 rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
              <dc:title></dc:title>
            </cc:Work>
          </rdf:RDF>
        </metadata>
        <g
           id="layer1" />
      </svg>`);
      canvas.deleteSelectedElements();
    }

    canvas.renameCurrentLayer("editable");

    window.fill = function (colour) {
      canvas.getSelectedElements().forEach((el) => {
        el.setAttribute("fill", colour);
      });
    };

    canvas.textActions.setInputElem(jQuery("#text")[0]);

    canvas.bind("changed", onSvgChange);
    canvas.bind("selected", onSvgElementSelected);
  };

  const save = (badgeClass) => {
    canvas.bind("changed", () => {});
    canvas.setCurrentLayer("mask") && canvas.deleteCurrentLayer();
    canvas.setCurrentLayer("blockchains") && canvas.deleteCurrentLayer();
    try {
      let content = canvas.getSvgString();
      setLevel(badgeClass.level);
      setTimestamps(badgeClass.timestamps);
      let final = canvas.getSvgString();

      setBadgeClass({
        ...badgeClass,
        saved: true,
        svgDesign: content,
        svgFinal: final,
      });
    } catch (e) {
      showSnackbar("Oops... Somenthing went wrong.", "error");
    }
    canvas.bind("changed", onSvgChange);
  };

  const getLevel = (levelName) => {
    for (let pos in badgeClassConfig.frameLevels) {
      if (badgeClassConfig.frameLevels[pos].level.name === levelName) {
        return badgeClassConfig.frameLevels[pos];
      }
    }

    return false;
  };

  const getTSA = (blockchainName) => {
    for (let pos in badgeClassConfig.frameTimestampAuthorities) {
      if (
        badgeClassConfig.frameTimestampAuthorities[pos].timestampAuthority
          .cryptoSignerId === blockchainName
      ) {
        return badgeClassConfig.frameTimestampAuthorities[pos];
      }
    }

    return false;
  };

  const setLevel = (levelName) => {
    let level = getLevel(levelName);
    if (!level) {
      return true;
    }

    canvas.setCurrentLayer("mask") && canvas.deleteCurrentLayer();
    canvas.createLayer("mask");
    canvas.importSvgString(level.frameDesignMaskFile);
    canvas
      .getSelectedElements()[0]
      .setAttribute("transform", "matrix(1 0 0 1 0 0)");
    canvas.setCurrentLayer("editable");
  };

  const setTimestamps = (timestamps) => {
    let chains = timestamps ? timestamps.split(",") : [];

    canvas.setCurrentLayer("blockchains") && canvas.deleteCurrentLayer();
    canvas.createLayer("blockchains");
    chains.map((tsaName, pos) => {
      let tsa = getTSA(tsaName);
      if (!tsa) {
        return true;
      }

      let importedElement = canvas.importSvgString(tsa.logoDesignFile);

      if (!importedElement) {
        return true;
      }

      canvas
        .getSelectedElements()[0]
        .setAttribute("transform", "matrix(1 0 0 1 0 0)");
      canvas
        .getSelectedElements()[0]
        .setAttribute("width", badgeClassConfig.tsaLogoWidth);
      canvas
        .getSelectedElements()[0]
        .setAttribute("height", badgeClassConfig.tsaLogoHeight);

      if (!badgeClassConfig.anchorPoints[pos]) {
        return true;
      }

      canvas
        .getSelectedElements()[0]
        .setAttribute("x", badgeClassConfig.anchorPoints[pos].x);
      canvas
        .getSelectedElements()[0]
        .setAttribute("y", badgeClassConfig.anchorPoints[pos].y);
      return true;
    });
    canvas.setCurrentLayer("editable");
  };

  const onSvgChange = (elements, evt) => {
    //save();
    //var mode = svgCanvas.getMode();

    if (canvas.getMode() === "select") {
      canvas.setMode("select");
    }

    canvas.runExtensions("elementChanged", {
      elems: elements,
    });
  };

  const onSvgElementSelected = (event, elems) => {
    var mode = canvas.getMode();

    if (mode === "select") {
      canvas.setMode("select");
    }

    //var isNode = mode === "pathedit"; // if elems[1] is present, then we have more than one element

    let selectedElement = elems.length === 1 || !elems[1] ? elems[0] : null;
    let multiselected = elems.length >= 2 && elems[1];

    //if (!isNullish(selectedElement)) {
    // unless we're already in always set the mode of the editor to select because
    // upon creation of a text element the editor is switched into
    // select mode and this event fires - we need our UI to be in sync
    /*if (!isNode) {
      updateToolbar();
    }*/
    //} // if (!Utils.isNullish(elem))
    // Deal with pathedit mode

    //togglePathEditMode(isNode, elems);
    //updateContextPanel();
    canvas.runExtensions(
      "selectedChanged",
      /** @type {module:svgcanvas.SvgCanvas#event:ext_selectedChanged} */
      {
        elems: elems,
        selectedElement: selectedElement,
        multiselected: multiselected,
      }
    );

    if (!selectedElement) {
      setSelectedType("");
      return;
    }

    setSelectedType(selectedElement.tagName);

    setStrokeWidth(selectedElement.getAttribute("stroke-width"));
    setStrokeType(selectedElement.getAttribute("stroke-dasharray"));
    setStrokeColor(selectedElement.getAttribute("stroke"));
    setFillColor(selectedElement.getAttribute("fill"));

    if (selectedElement.tagName === "text") {
      jQuery("#text").val(selectedElement.textContent);
      setFontBold(selectedElement.getAttribute("font-weight") === "bold");
      setFontItalic(selectedElement.getAttribute("font-style") === "italic");
      setFontFamily(selectedElement.getAttribute("font-family"));
      setFontSize(selectedElement.getAttribute("font-size"));
    }
  };

  useEffect(() => {
    load();
    props.onMount(save);
    window.addEventListener("keyup", handleKeyPress);
    return () => {
      //TODO remove svg
      window.removeEventListener("keyup", handleKeyPress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    canvas.bind("changed", () => {});
    setLevel(badgeClass.level);
    setTimestamps(badgeClass.timestamps);

    setBadgeClass({
      ...badgeClass,
      level: badgeClass.level,
      timestamps: badgeClass.timestamps,
    });

    canvas.bind("changed", onSvgChange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [badgeClass.level, badgeClass.timestamps]);

  useEffect(() => {
    canvas && canvas.setMode(tool);

    if (!defaultFillChanged) {
      tool === "text"
        ? setFillColor(config.text.fill_color)
        : setFillColor(config.initFill.color);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tool]);

  const handleKeyPress = (event) => {
    switch (event.key) {
      case "Delete":
        canvas.deleteSelectedElements();
        break;
      default:
    }
  };

  const onToolbarChange = (event, value) => {
    if (!value) {
      return;
    }

    value === "image" ? setOpenFilePopup(true) : setTool(value);
  };

  const onStrokeTypeChange = (event) => {
    let stroke = event.target.value;
    setStrokeType(stroke);
    canvas.setStrokeAttr("stroke-dasharray", stroke);
  };

  const onStrokeWidthChange = (event, value) => {
    let width = event.target.value;
    setStrokeWidth(width);
    canvas.setStrokeWidth(width);
  };

  const getRGBAString = (color) => {
    if (!color.rgb) {
      return "";
    }
    return `rgb(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`;
  };

  const onStrokeColorChange = (color) => {
    setStrokeColor(getRGBAString(color));
    canvas.setPaintOpacity("stroke", color.rgb.a);
    canvas.setColor("stroke", getRGBAString(color));
  };

  const onFillColorChange = (color) => {
    setDefaultFillChanged(true);
    setFillColor(getRGBAString(color));
    canvas.setPaintOpacity("fill", color.rgb.a);
    canvas.setColor("fill", getRGBAString(color));
  };

  const onFontItalicChange = (value) => {
    setFontItalic(value);
    canvas.setItalic(value);
  };

  const onFontBoldChange = (value) => {
    setFontBold(value);
    canvas.setBold(value);
  };

  const onFontSizeChange = (event) => {
    let size = event.target.value;
    setFontSize(size);
    canvas.setFontSize(size);
  };

  const onFontFamilyChange = (event) => {
    let family = event.target.value;
    setFontFamily(family);
    canvas.setFontFamily(family);
  };

  const onTextChange = (event, value) => {
    canvas.setTextContent(event.target.value);
  };

  const onOrderChange = (event, value) => {
    if (value === "forward") {
      canvas.moveToTopSelectedElement();
    }

    if (value === "backward") {
      canvas.moveToBottomSelectedElement();
    }
  };

  const handleCloseFilePopup = () => {
    setOpenFilePopup(false);
  };

  const handleFilesDrop = (onDropPromise) => {
    onDropPromise.then((filesContents) => {
      canvas.bind("changed", () => {});
      filesContents.map((fileContent) => {
        try {
          canvas.setCurrentLayer("editable");
          canvas.importSvgString(prepareImportedString(fileContent));
          canvas.updateCanvas(config.dimensions[0], config.dimensions[1]);
          fixCanvasSelectedElement();
        } catch (e) {
          showSnackbar("Oops... something went wrong.", "error", 1500);
        }
        return true;
      });
      setOpenFilePopup(false);
      canvas.bind("changed", onSvgChange);
    });
  };

  const fixCanvasSelectedElement = () => {
    if (!canvas.getSelectedElements()) {
      return;
    }

    canvas
      .getElement(
        canvas.getSelectedElements()[0].getAttribute("xlink:href").substr(1)
      )
      .setAttribute("style", "overflow:visible;");

    canvas
      .getSelectedElements()[0]
      .setAttribute("transform", "matrix(0.3 0 0 0.3 0 0)");

    let posx = Math.floor(
      canvas.getWidth() / 2 -
        Math.ceil(
          Math.ceil(
            canvas.getSelectedElements()[0].getBoundingClientRect().width
          ) / 2
        )
    );
    let posy = Math.floor(
      canvas.getHeight() / 2 -
        Math.ceil(
          Math.ceil(
            canvas.getSelectedElements()[0].getBoundingClientRect().height
          ) / 2
        )
    );
    canvas.getSelectedElements() && canvas.moveSelectedElements(posx, posy);
  };

  const prepareImportedString = (fileContent) => {
    //Change autoclose <g/> to <g></g>
    fileContent = fileContent.replace(/<g([^/>]*)\/>/gm, "<g$1></g>");
    // Add wrapper
    fileContent = fileContent.replace(
      /<g([^<]*)>/,
      '<g transform="matrix(1,0,0,1,0,0)" id="wrapperTrustedBadges"><g $1>'
    );
    fileContent = replaceLast("</g>", "</g></g>", fileContent);

    // Clean width, height and viewbox on first svg tag
    const svgTags = /<svg[^<]*>/.exec(fileContent);
    if (svgTags) {
      //let firstSvgTag = svgTags[0].replace(/width="[^"]*"/, "");
      //firstSvgTag = firstSvgTag.replace(/width='[^']*'/, "");
      //firstSvgTag = firstSvgTag.replace(/height="[^"]*"/, "");
      //firstSvgTag = firstSvgTag.replace(/height='[^']*'/, "");
      let firstSvgTag = svgTags[0].replace(/viewBox="[^"]*"/, "");
      firstSvgTag = firstSvgTag.replace(/viewBox='[^']*'/, "");
      //firstSvgTag = firstSvgTag.replace(/viewBox="[^"]*"/, "");

      fileContent = fileContent.replace(svgTags[0], firstSvgTag);
    }

    return fileContent;
  };

  const replaceLast = (find, replace, string) => {
    var lastIndex = string.lastIndexOf(find);

    if (lastIndex === -1) {
      return string;
    }

    var beginString = string.substring(0, lastIndex);
    var endString = string.substring(lastIndex + find.length);

    return beginString + replace + endString;
  };

  return (
    <Box display="flex" flexDirection="row">
      <Box m={1}>
        <SvgEditorToolbar onToolbarChange={onToolbarChange} tool={tool} />
        <SvgEditorUploader
          onDrop={handleFilesDrop}
          open={openFilePopup}
          handleClose={handleCloseFilePopup}
        />
      </Box>
      <Box mt={1}>
        <Box display="flex" mb={1}>
          <Box>
            <SvgEditorOrderTools onOrderChange={onOrderChange} />
          </Box>
          <Box
            ml={1}
            hidden={selectedType === "" && toolsWithFill.indexOf(tool) === -1}
          >
            <ToggleButtonGroup>
              <SvgEditorColorPicker
                color={fillColor}
                onColorChange={onFillColorChange}
              />
            </ToggleButtonGroup>
          </Box>
          <Box
            ml={1}
            hidden={selectedType === "" && toolsWithStroke.indexOf(tool) === -1}
          >
            <SvgEditorStrokeSelector
              onStrokeTypeChange={onStrokeTypeChange}
              strokeType={strokeType}
              onStrokeWidthChange={onStrokeWidthChange}
              strokeWidth={strokeWidth}
              onStrokeColorChange={onStrokeColorChange}
              strokeColor={strokeColor}
            />
          </Box>
          <Box ml={1} hidden={selectedType !== "text"}>
            <SvgEditorTextTools
              onFontItalicChange={onFontItalicChange}
              fontItalic={fontItalic}
              onFontBoldChange={onFontBoldChange}
              fontBold={fontBold}
              onFontSizeChange={onFontSizeChange}
              fontSize={fontSize}
              onFontFamilyChange={onFontFamilyChange}
              fontFamily={fontFamily}
            />
          </Box>
        </Box>
        <div id="svgcanvas"></div>
        <input
          id="text"
          type="text"
          autoComplete="off"
          onChange={onTextChange}
          className={classes.textInput}
        />
      </Box>
    </Box>
  );
};

export default SvgEditor;
