import { Box, CircularProgress, Paper, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import AccountService from "../../services/AccountService";
import usePromise from "../../hooks/usePromise";
import FormattedCurrency from "./FormattedCurrency";

const AccountBalanceIndicator = (props) => {
  const currency = props.currency ?? "TBT";
  const [data, errors, loading, setPromise, reset] = usePromise();

  useEffect(() => {
    setPromise(AccountService.getAccountBalance(currency));
    return () => {
      reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (errors) {
    return <></>;
  }

  return (
    <Paper variant="outlined">
      <Box>
        {loading && (
          <Box display="grid" justifyContent="center" p={3}>
            <CircularProgress size="1.4rem" />
          </Box>
        )}
        {data && (
          <>
            <Box p={1}>
              <Typography variant="caption" color="textSecondary">
                {data.currency.plural}
              </Typography>
            </Box>
            <Box
              display="flex"
              alignItems="flex-end"
              justifyContent="right"
              flexDirection="row"
              pr={2}
              pb={2}
            >
              <FormattedCurrency
                value={data.value}
                currency={data.currency}
                variant="h5"
                currencyProps={{
                  color: "textSecondary",
                  variant: "caption",
                  style: {
                    paddingBottom: "0.15em",
                    paddingTop: "0.15em",
                    paddingLeft: "0.2em",
                    paddingRight: "0.2em",
                  },
                }}
              />
            </Box>
          </>
        )}
      </Box>
    </Paper>
  );
};

export default AccountBalanceIndicator;
