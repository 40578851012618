import API from "../utils/API";
import AuthService from "./AuthService";
import Service from "./Service";

class AccountService extends Service {
  queries = {
    getAccountBalances: (...params) =>
      `query { byIssuerQueryIssuerAccounts(issuer:"/api/issuers/${params[0]}") {
        edges {
          node {
            account {
              balances {
                edges {
                  node { id, value, currency {ticker, decimals, plural} }
                }
              }
            }
          }
        }
      }}`,
    getAccountBalanceByCurrency: (...params) =>
      `query { byIssuerQueryIssuerAccounts(issuer:"/api/issuers/${params[0]}", currency:"${params[1]}") {
      edges {
        node {
          account {
            balances {
              edges {
                node { value, currency {ticker, decimals, plural} }
              }
            }
          }
        }
      }
    }}`,
  };

  getAccountBalance = async (currency) => {
    let issuer = AuthService.getCurrentIssuer();
    currency = currency ? currency : "USD";

    return API.callGraphql(
      this.queries.getAccountBalanceByCurrency(issuer.uuid, currency)
    ).then((response) => {
      this.errorParser(response);

      if (!response.data.byIssuerQueryIssuerAccounts) this.throwDefaultError();

      const firstAccount = response.data.byIssuerQueryIssuerAccounts.edges.shift();

      if (!firstAccount) {
        return null;
      }

      const currencyBalance = firstAccount.node.account.balances.edges.find(
        (x) => x.node.currency.ticker === currency
      );

      if (!currencyBalance) {
        return null;
      }

      return currencyBalance.node;
    });
  };

  getAccountBalances = async () => {
    let issuer = AuthService.getCurrentIssuer();

    return API.callGraphql(this.queries.getAccountBalances(issuer.uuid)).then(
      (response) => {
        this.errorParser(response);

        if (!response.data.byIssuerQueryIssuerAccounts)
          this.throwDefaultError();

        const firstAccount = response.data.byIssuerQueryIssuerAccounts.edges.shift();

        if (!firstAccount) {
          return null;
        }

        let balances = firstAccount.node.account.balances.edges.map(
          (balance) => {
            return balance.node;
          }
        );

        return balances;
      }
    );
  };
}

export default new AccountService();
