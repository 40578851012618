import React, { useState } from "react";
import { IntlProvider } from "react-intl";
import * as languages from "../languages";

const LanguageContext = React.createContext();
const defaultLocale =
  localStorage.getItem("locale") ?? navigator.language.split(/[-_]/)[0] ?? "en";

const LanguageProvider = (props) => {
  const [locale, setLocale] = useState(defaultLocale);

  const switchLanguage = (locale) => {
    localStorage.setItem("locale", locale);
    setLocale(locale);
  };

  return (
    <LanguageContext.Provider value={{ switchLanguage, locale }}>
      <IntlProvider locale={locale} messages={languages[locale]}>
        {props.children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
};

export { LanguageProvider, LanguageContext };
