import API from "../utils/API";
import AuthService from "./AuthService";
import Service from "./Service";

class BatchesService extends Service {
  queries = {
    getBatches: (...params) =>
      `query { byIssuerQueryBatches(issuer:"/api/issuers/${params[0]}", page:${
        params[1] ?? 1
      }, itemsPerPage:${
        params[2] ?? 10
      }) { collection {  uuid, status, badgesCount, createdAt, number }, paginationInfo { totalCount, lastPage } } }`,
    getBatch: (...params) =>
      `query { batch(id:"/api/batches/${params[0]}") { uuid, number, status, badges { edges { node { uuid, score, status, pdf, revoked, errors, revocationReason, badgeClass { name, description, timestamps, thumbnails }, email { earner { externalId, name, email } } } } } } }`,
    createBatch: (...params) =>
      `mutation{createBatch(input:{issuer:"/api/issuers/${params[0]}",badges_batch:${params[1]}}){batch{ uuid }}}`,
    toPendingStatusBatch: (...params) =>
      `mutation{toPendingStatusBatch(input:{id:"/api/batches/${params[0]}"}){batch{ uuid }}}`,
    createQuotation: (...params) =>
      `mutation{createQuotation(input:{batch:"/api/batches/${params[0]}"}){quotation{_id}}}`,
    getQuotation: (...params) =>
      `query{
        isBatchAffordable(batch:"/api/batches/${params[0]}"){ batch{ quotation{ expiration, items{ edges{ node{ _id, description, quantity, rate, amount, currency { ticker, decimals, plural } } } }}} affordable}
      }`,
    processBatch: (...params) =>
      `mutation{processBatch(input:{id:"/api/batches/${params[0]}"}){batch{ uuid }}}`,
    resendBatch: (...params) =>
      `mutation{resendBatch(input:{id:"/api/batches/${params[0]}"}){batch{ uuid }}}`,
  };

  getBatch = async (batchUuid) => {
    batchUuid = batchUuid ? batchUuid : "";

    return API.callGraphql(this.queries.getBatch(batchUuid)).then(
      (response) => {
        this.errorParser(response);

        if (!response.data.batch) this.throwDefaultError();

        let badgeClass =
          response.data.batch.badges.edges[0].node.badgeClass ?? null;
        response.data.batch.badgeClass = badgeClass;

        if (response.data.batch.badges) {
          response.data.batch.badges = response.data.batch.badges.edges;
          response.data.batch.badges = response.data.batch.badges.map(
            (badge) => {
              let node = badge.node;
              node.id = node.uuid;
              node.earner = node.email.earner;
              return node;
            }
          );
        }

        return response.data.batch;
      }
    );
  };

  getBatches = async (page, itemsPerPage) => {
    let issuer = AuthService.getCurrentIssuer();

    return API.callGraphql(
      this.queries.getBatches(issuer.uuid, page, itemsPerPage)
    ).then((response) => {
      this.errorParser(response);

      if (!response.data.byIssuerQueryBatches) this.throwDefaultError();

      return response.data.byIssuerQueryBatches;
    });
  };

  createBatch = async (batch) => {
    let issuer = AuthService.getCurrentIssuer();
    let badges = [];

    batch.assertions.map((assertion) => {
      return badges.push(
        `{earner:"/api/earners/${assertion.earner.uuid}", badge_class:"/api/badge_classes/${batch.badgeClass.uuid}", score:"${assertion.score}"}`
      );
    });

    let badgesParam = `[${badges.join(",")}]`;

    return API.callGraphql(
      this.queries.createBatch(issuer.uuid, badgesParam)
    ).then((response) => {
      this.errorParser(response);

      if (!response.data.createBatch) this.throwDefaultError();

      return response.data.createBatch.batch;
    });
  };

  awardBatch = async (batchUuid) => {
    return API.callGraphql(this.queries.processBatch(batchUuid)).then(
      (response) => {
        this.errorParser(response);

        if (!response.data.processBatch) this.throwDefaultError();

        return response.data.processBatch.batch;
      }
    );
  };

  saveBatch = async (batchUuid) => {
    return API.callGraphql(this.queries.toPendingStatusBatch(batchUuid)).then(
      (response) => {
        this.errorParser(response);

        if (!response.data.toPendingStatusBatch) this.throwDefaultError();

        return response.data.toPendingStatusBatch.batch;
      }
    );
  };

  getQuotation = async (batchUuid) => {
    await this.createQuotation(batchUuid);

    return API.callGraphql(this.queries.getQuotation(batchUuid)).then(
      (response) => {
        this.errorParser(response);
        if (!response.data.isBatchAffordable) this.throwDefaultError();
        return response.data.isBatchAffordable;
      }
    );
  };

  createQuotation = async (batchUuid) => {
    return API.callGraphql(this.queries.createQuotation(batchUuid)).then(
      (response) => {
        this.errorParser(response);

        if (!response.data.createQuotation) this.throwDefaultError();

        return response.data.createQuotation.quotation._id;
      }
    );
  };

  resendBatch = async (batchUuid) => {
    return API.callGraphql(this.queries.resendBatch(batchUuid)).then(
      (response) => {
        this.errorParser(response);

        if (!response.data.resendBatch) this.throwDefaultError();

        return response.data.resendBatch.batch;
      }
    );
  };

  calculateTotal = (items) => {
    let total = {};
    for (let index in Object.values(items)) {
      let item = items[index].node;
      if (total[item.currency.ticker] === undefined) {
        total[item.currency.ticker] = {};
        total[item.currency.ticker]["amount"] = 0;
        total[item.currency.ticker]["currency"] = item.currency;
      }
      total[item.currency.ticker]["amount"] += item.rate * item.quantity;
    }
    return Object.values(total);
  };
}

export default new BatchesService();
