import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  Typography,
} from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";
import ErrorsAlert from "../Common/ErrorsAlert";
import LoadingButton from "../Common/LoadingButton";

const RequestedSubscriptionPlanCancelDialog = ({
  open,
  handleConfirm,
  handleClose,
  cancelling,
  errors,
  ...props
}) => {
  return (
    <Dialog open={open} onClose={handleClose} {...props}>
      <DialogTitle>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography variant="subtitle1">
            <FormattedMessage
              id="requested-plan-cancel-dialog.title"
              defaultMessage="Cancel Subscription Plan Change"
            />
          </Typography>
          <IconButton size="small" onClick={handleClose}>
            <Icon>close</Icon>
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers={true}>
        <Typography>
          <FormattedMessage
            id="requested-plan-cancel-dialog.information"
            defaultMessage="You are going to cancel your subscription plan change. Are you sure?"
          />
        </Typography>
        {errors && (
          <Box mt={1}>
            <ErrorsAlert errors={errors} />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          flexGrow={1}
          p={1}
        >
          <Button onClick={handleClose}>
            <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
          </Button>
          <LoadingButton
            loading={cancelling ? "true" : undefined}
            onClick={handleConfirm}
            variant="contained"
            color="primary"
          >
            <FormattedMessage
              id="requested-plan-cancel-dialog.confirm-button"
              defaultMessage="Yes, cancel"
            />
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default RequestedSubscriptionPlanCancelDialog;
