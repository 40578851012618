import { makeStyles } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React from "react";
import { useIntl } from "react-intl";
import { TextValidator } from "react-material-ui-form-validator";
import { countries } from "../../config/countries";

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

const CountrySelect = (props) => {
  const classes = useStyles();
  const intl = useIntl();

  const handleChange = (evt, value) => {
    props.onChange(value);
  };

  return (
    <Autocomplete
      id="country-select"
      options={countries}
      classes={{
        option: classes.option,
      }}
      autoHighlight
      disableClearable
      getOptionLabel={(option) => option.name}
      renderOption={(option) => (
        <>
          <span>{countryToFlag(option.code)}</span>
          {option.name} ({option.code})
        </>
      )}
      renderInput={(params) => (
        <TextValidator
          {...params}
          label={intl.formatMessage({
            id: "form.country-label",
            defaultMessage: "Country",
          })}
          variant="outlined"
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
          validators={props.validators}
          errorMessages={props.errorMessages}
        />
      )}
      name={props.name}
      value={props.value}
      onChange={handleChange}
      fullWidth
    />
  );
};

export default CountrySelect;
