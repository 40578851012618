import { Box } from "@material-ui/core";
import React, { useState } from "react";
import { ValidatorForm } from "react-form-validator-core";
import { FormattedMessage, useIntl } from "react-intl";
import TextValidator from "react-material-ui-form-validator/lib/TextValidator";
import LoadingButton from "../Common/LoadingButton";
import GoogleRecaptchaInfo from "../Misc/GoogleRecaptchaInfo";

const ResetPasswordRequestForm = (props) => {
  const intl = useIntl();
  const [formData, setFormData] = useState({ email: "" });

  const handleInputChange = (event) => {
    event.preventDefault();
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const validationMessages = {
    required: intl.formatMessage({
      id: "validation.field-required",
      defaultMessage: "This field is required",
      description: "Label shown on required form field when is required",
    }),
    isEmail: intl.formatMessage({
      id: "validation.field-email",
      defaultMessage: "Email is not valid",
      description: "Label shown on required form field when email is not valid",
    }),
  };

  const inputs = {
    email: {
      label: intl.formatMessage({
        id: "form.email-input",
        defaultMessage: "Email",
        description: "Email input label",
      }),
      placeholder: intl.formatMessage({
        id: "form.email-placeholder",
        defaultMessage: "Please enter your email",
        description: "Email input placeholder",
      }),
    },
  };

  return (
    <ValidatorForm
      onSubmit={() => props.handleFormSubmit(formData)}
      data-testid="formRequestResetPassword"
    >
      <Box>
        <TextValidator
          onChange={handleInputChange}
          name="email"
          label={inputs.email.label}
          value={formData["email"]}
          placeholder={inputs.email.placeholder}
          fullWidth
          validators={["required", "isEmail"]}
          errorMessages={[
            validationMessages.required,
            validationMessages.isEmail,
          ]}
          inputProps={{ "data-testid": "inputEmail" }}
          variant="outlined"
        />
      </Box>
      <GoogleRecaptchaInfo />
      <Box mt={2} justifyContent="center" display="flex" alignItems="center">
        <LoadingButton
          loading={props.loading ? "true" : undefined}
          size="medium"
          color="primary"
          variant="contained"
          type="submit"
        >
          <FormattedMessage
            id="form.reset-password-request-button"
            description="Password reset send instructions button"
            defaultMessage="Send Instructions"
          />
        </LoadingButton>
      </Box>
    </ValidatorForm>
  );
};

export default ResetPasswordRequestForm;
