import {
  Box,
  Breadcrumbs,
  Button,
  Icon,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link, useParams } from "react-router-dom";
import SendBadgesStep0 from "../components/SendBadges/SendBadgesStep0";
import SendBadgesStep1 from "../components/SendBadges/SendBadgesStep1";
import SendBadgesStep2 from "../components/SendBadges/SendBadgesStep2";
import SendBadgesStep3 from "../components/SendBadges/SendBadgesStep3";
import { SendBadgesBatchProvider } from "../context/SendBadgesBatchProvider";
import { UserContext } from "../context/UserProvider";
import usePromise from "../hooks/usePromise";
import AdminLayout from "../layouts/AdminLayout";
import IssuersService from "../services/IssuersService";

const SendBadges = () => {
  const params = useParams();
  const { issuer } = useContext(UserContext);
  const [
    validatedIdentities,
    errors,
    loadingIdentities,
    setPromise,
  ] = usePromise();

  useEffect(() => {
    issuer && setPromise(IssuersService.getValidatedIdentities(issuer.uuid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [issuer]);

  const steps = [
    <FormattedMessage
      id="send-badges-wizard.step-badge-class"
      defaultMessage="Badge"
      description="Badge Class step label"
    />,
    <FormattedMessage
      id="send-badges-wizard.step-earners"
      defaultMessage="Recipients"
      description="Recipients step label"
    />,
    <FormattedMessage
      id="send-badges-wizard.step-evidence"
      defaultMessage="Evidence"
      description="Evidence step label"
    />,
    <FormattedMessage
      id="send-badges-wizard.step-confirm"
      defaultMessage="Confirm and award"
      description="Confirm and award step label"
    />,
  ];

  const [currentStep, setCurrentStep] = useState(0);
  const lastStep = steps.length - 1;

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <SendBadgesStep0 />;
      case 1:
        return <SendBadgesStep1 />;
      case 2:
        return <SendBadgesStep2 />;
      case 3:
        return <SendBadgesStep3 />;
      default:
    }
  };

  const handleNextStep = () => {
    let nextStep = currentStep + 1;

    if (nextStep >= steps.length) {
      return;
    }

    setCurrentStep(nextStep);
  };

  const handleBackStep = () => {
    let nextStep = currentStep - 1;

    if (nextStep < 0) {
      return;
    }

    setCurrentStep(nextStep);
  };

  return (
    <AdminLayout>
      <Box p={2}>
        <Breadcrumbs separator=">" aria-label="breadcrumb">
          <Link style={{ color: "inherit" }} to="/">
            <FormattedMessage
              id="routes.protected.dashboard"
              defaultMessage="Dashboard"
              description="Dashboard menu breadcrumbs title"
            />
          </Link>
          <Typography color="textPrimary">
            <FormattedMessage
              id="send-badges-wizard.send-badges-title"
              defaultMessage="Send Badges"
              description="Title shown in breadcrumbs when sending badges"
            />
          </Typography>
        </Breadcrumbs>
      </Box>
      {!loadingIdentities && !validatedIdentities.length && (
        <Box p={2}>
          <Alert
            variant="standard"
            color="warning"
            icon={<Icon>warning</Icon>}
            action={
              <Button
                color="inherit"
                size="small"
                to="/issuer-settings/identity"
                component={Link}
              >
                <FormattedMessage
                  id="send-badges.issuer-not-validated-warning-button"
                  defaultMessage="Manage identity"
                />
              </Button>
            }
          >
            <FormattedMessage
              id="send-badges.issuer-not-validated-warning-message"
              defaultMessage="You won't be able to send badges until one of your identities is validated."
            />
          </Alert>
        </Box>
      )}
      <Box p={2}>
        <Paper>
          <Stepper activeStep={currentStep} alternativeLabel>
            {steps.map((label, pos) => (
              <Step key={pos}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Box py={3} px={3}>
            <SendBadgesBatchProvider
              badgeUuid={params.badgeUuid}
              value={{
                currentStep,
                lastStep,
                handleNextStep,
                handleBackStep,
                validatedIdentities,
              }}
            >
              {getStepContent(currentStep)}
            </SendBadgesBatchProvider>
          </Box>
        </Paper>
      </Box>
    </AdminLayout>
  );
};

export default SendBadges;
