class Service {
  defaultErrorMessage = null;

  errorMessages = {
    Error: "Error",
    "Empty response": "Empty response",
  };

  setErrorMessages = (customErrorMessages) => {
    this.errorMessages = { ...this.errorMessages, ...customErrorMessages };
    return this;
  };

  setDefaultErrorMessage = (defaultErrorMessage) => {
    this.defaultErrorMessage = defaultErrorMessage;
    return this;
  };

  throwDefaultError = () => {
    throw new ServiceError(
      this.defaultErrorMessage ?? this.errorMessages["Error"] ?? "Error"
    );
  };

  errorParser = (response) => {
    let errorMessage = false;

    if (response.errors && response.errors.length) {
      errorMessage =
        response.errors[0].debugMessage ?? response.errors[0].message;
    }

    if (!response.data && !errorMessage) {
      errorMessage = "Empty response";
    }

    if (errorMessage) {
      errorMessage =
        this.errorMessages[errorMessage] ??
        this.defaultErrorMessage ??
        errorMessage;
      throw new ServiceError(errorMessage);
    }
  };
}

class ServiceError extends Error {}

export default Service;
