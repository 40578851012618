import { Box, CircularProgress, Grid } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import usePromise from "../../hooks/usePromise";
import BatchesService from "../../services/BatchesService";
import history from "../../utils/history";
import BatchItem from "./BatchItem";

const BatchList = (props) => {
  const params = useParams();
  const pageNumber = params.pageNumber ? parseInt(params.pageNumber, 10) : 1;
  const limit = props.limit ? parseInt(props.limit, 10) : 5;
  const [data, errors, loading, setPromise] = usePromise();
  const intl = useIntl();

  BatchesService.setDefaultErrorMessage(
    intl.formatMessage({
      id: "errors.unexpected-error",
      defaultMessage: "An unexpected error ocurred.",
    })
  );

  useEffect(() => {
    if (pageNumber) {
      setPromise(BatchesService.getBatches(pageNumber, limit));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  const handlePaginationChange = (evt, page) => {
    history.push("/history/batches/" + page);
  };

  if (loading) {
    return (
      <Box display="grid" justifyItems="center">
        <CircularProgress />
      </Box>
    );
  }

  if (errors) {
    return <Box>{errors[0]}</Box>;
  }

  if (!data.collection) {
    return <Box>No hay</Box>;
  }

  return (
    <>
      <Grid container direction="column" spacing={2}>
        {data.collection &&
          data.collection.map((batch) => (
            <Grid item xs key={batch.uuid}>
              <BatchItem batch={batch} />
            </Grid>
          ))}
      </Grid>
      {data.paginationInfo.lastPage > 1 && (
        <Box mt={2} p={2} display="flex" justifyContent="center">
          <Pagination
            page={pageNumber}
            count={data.paginationInfo.lastPage}
            color="primary"
            onChange={handlePaginationChange}
          />
        </Box>
      )}
    </>
  );
};

export default BatchList;
