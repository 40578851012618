import React from "react";
import { SnackbarContext } from "../context/SnackbarProvider";

const useSnackbar = () => {
  const { showSnackbar } = React.useContext(SnackbarContext);

  return [showSnackbar];
};

export default useSnackbar;
