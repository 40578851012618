import { Typography } from "@material-ui/core";
import React from "react";
import { useIntl } from "react-intl";
import BatchStatusIcon from "./BatchStatusIcon";

const BatchStatus = (props) => {
  const intl = useIntl();
  const typographyVariant = props.variant || "h5";
  const status = props.batch ? props.batch.status ?? "" : "";

  const batchStatusLabels = {
    draft: intl.formatMessage({
      id: "batch-status.status-draft",
      defaultMessage: "Draft",
    }),
    pending: intl.formatMessage({
      id: "batch-status.status-pending",
      defaultMessage: "Pending",
    }),
    waiting: intl.formatMessage({
      id: "batch-status.status-waiting",
      defaultMessage: "Waiting",
    }),
    processing: intl.formatMessage({
      id: "batch-status.status-processing",
      defaultMessage: "Processing",
    }),
    processed: intl.formatMessage({
      id: "batch-status.status-processed",
      defaultMessage: "Processed",
    }),
    processed_with_errors: intl.formatMessage({
      id: "batch-status.status-processed_with_errors",
      defaultMessage: "Processed with errors",
    }),
    stopped: intl.formatMessage({
      id: "batch-status.status-stopped",
      defaultMessage: "Stopped",
    }),
  };

  return (
    <>
      <Typography
        variant={typographyVariant}
        style={{ display: "flex", alignItems: "center" }}
      >
        <BatchStatusIcon status={status} style={{ marginRight: "0.1em" }} />
        {batchStatusLabels[status]}
      </Typography>
    </>
  );
};

export default BatchStatus;
