import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { UserContext } from "../../context/UserProvider";
import {
  Box,
  Button,
  Divider,
  Grid,
  Hidden,
  Icon,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import AuthService from "../../services/AuthService";
import { Skeleton } from "@material-ui/lab";
import AvatarMenu from "../Common/AvatarMenu";

const useStyles = makeStyles((theme) => ({
  skeleton: {
    display: "flex",
    flexFlow: "row",
  },
  skeletonElement: {
    flexGrow: 1,
  },
}));

const UserMenu = () => {
  const classes = useStyles();
  const { user, setUser, loadingUser, issuer, setIssuerById } = useContext(
    UserContext
  );

  const handleLogout = () => {
    setUser(false);
    AuthService.logout();
  };

  if (loadingUser) {
    return (
      <div className={classes.skeleton}>
        <Hidden mdDown>
          <Skeleton
            animation="wave"
            variant="rect"
            width={150}
            className={classes.skeletonElement}
          />
        </Hidden>
        <Skeleton
          animation="wave"
          variant="circle"
          width={40}
          height={40}
          className={classes.skeletonElement}
        />
      </div>
    );
  }

  if (user) {
    return (
      <>
        <Box display="flex">
          <Hidden smDown>
            <Box mr={2} display="grid" alignItems="center">
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to="/send-badges"
              >
                <FormattedMessage
                  id="button.send-badges"
                  defaultMessage="Send Badges"
                />
              </Button>
            </Box>
          </Hidden>
          <Hidden mdDown>
            <Box
              display="grid"
              alignItems="middle"
              justifyItems="flex-end"
              mr={1}
            >
              <Typography variant="body1">
                {issuer ? issuer.name : ""}
              </Typography>
              <Typography color="textSecondary" variant="body2">
                {user.email}
              </Typography>
            </Box>
          </Hidden>
          <AvatarMenu avatar={user.email[0]}>
            <Box justifyItems="center" py={1} px={4}>
              <h3>{user.email}</h3>
            </Box>
            <Divider></Divider>
            {user.userIssuers.map((userIssuer) => (
              <Box key={userIssuer.issuer.uuid}>
                <Box display="flex" alignItems="center" py={1} px={4}>
                  <Box flexGrow={1}>
                    <Button
                      onClick={(evt) => {
                        setIssuerById(evt.currentTarget.id);
                      }}
                      id={userIssuer.issuer.uuid}
                    >
                      <Typography variant="body1">
                        {userIssuer.issuer.name}
                      </Typography>
                    </Button>
                  </Box>
                  {issuer && userIssuer.issuer.uuid === issuer.uuid && (
                    <Box mx={1} display="grid" alignItems="center">
                      <Icon color="primary" fontSize="small">
                        check
                      </Icon>
                    </Box>
                  )}
                  {issuer &&
                    userIssuer.issuer.uuid === issuer.uuid &&
                    userIssuer.admin && (
                      <Box display="flex" alignItems="center">
                        <Box mx={1}>
                          <IconButton
                            size="small"
                            to="/issuer-settings"
                            component={Link}
                          >
                            <Icon fontSize="small" color="action">
                              settings
                            </Icon>
                          </IconButton>
                        </Box>
                        <Box>
                          <IconButton
                            size="small"
                            to="/balance"
                            component={Link}
                          >
                            <Icon fontSize="small" color="action">
                              account_balance_wallet
                            </Icon>
                          </IconButton>
                        </Box>
                      </Box>
                    )}
                </Box>
                <Divider></Divider>
              </Box>
            ))}
            <Box
              display={issuer ? "none" : "grid"}
              justifyItems="center"
              py={1}
              px={4}
            >
              <Button
                size="small"
                startIcon={<Icon fontSize="small">add</Icon>}
                component={Link}
                to="/create-issuer"
              >
                <FormattedMessage
                  id="header.create-issuer"
                  description="Create Issuer button"
                  defaultMessage="Create Issuer"
                />
              </Button>
            </Box>
            <Divider></Divider>
            <Box display="grid" justifyItems="center" py={1} px={4}>
              <Button
                onClick={handleLogout}
                variant="outlined"
                endIcon={<Icon fontSize="small">logout</Icon>}
              >
                <FormattedMessage
                  id="header.signout"
                  description="Sign Out button"
                  defaultMessage="Sign Out"
                />
              </Button>
            </Box>
          </AvatarMenu>
        </Box>
      </>
    );
  } else {
    return (
      <Box display="grid" alignItems="middle" justifyItems="flex-end" mr={1}>
        <Hidden mdUp>
          <AvatarMenu>
            <Box
              display="flex"
              justifyContent="space-between"
              py={1}
              px={1}
              flexDirection="row"
            >
              <Button component={Link} to="/signup" color="primary">
                <FormattedMessage
                  id="header.signup"
                  description="Sign Up button"
                  defaultMessage="Sign Up"
                />
              </Button>
              <Button
                component={Link}
                to="/login"
                color="primary"
                variant="contained"
              >
                <FormattedMessage
                  id="header.login"
                  description="Log In button"
                  defaultMessage="Log In"
                />
              </Button>
            </Box>
          </AvatarMenu>
        </Hidden>
        <Hidden smDown>
          <Grid container spacing={1}>
            <Grid item>
              <Button component={Link} to="/signup" color="primary">
                <FormattedMessage
                  id="header.signuo"
                  description="Sign Up button"
                  defaultMessage="Sign Up"
                />
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to="/login"
              >
                <FormattedMessage
                  id="header.login"
                  description="Log In button"
                  defaultMessage="Log In"
                />
              </Button>
            </Grid>
          </Grid>
        </Hidden>
      </Box>
    );
  }
};

export default UserMenu;
