import API from "../utils/API";
import AuthService from "./AuthService";
import Service from "./Service";

class BadgeClassesService extends Service {
  queries = {
    getBadgeClasses: (...params) =>
      `query { byIssuerQueryBadgeClasses(issuer:"/api/issuers/${params[0]}", page:${params[1]}, itemsPerPage:${params[2]}, search:"${params[3]}" ${params[4]} ${params[5]} ${params[6]}) { collection {  uuid, name, description, status, version, tagNames, thumbnails }, paginationInfo { lastPage } } }`,
    getBadgeClass: (...params) =>
      `query { badgeClass(id:"/api/badge_classes/${params[0]}") { uuid, name, description, criteria, status, version, tagNames, thumbnails, level { name }, timestamps, svgDesign } }`,
    createBadgeClass: (...params) =>
      `mutation{createBadgeClass(input:{tagNames:[${params[0]}],name:"${params[1]}",issuer:"/api/issuers/${params[2]}",description:"${params[3]}",criteria:"${params[4]}",level:"/api/levels/${params[5]}",timestamps:"${params[6]}"}){badgeClass{ uuid }}}`,
    updateBadgeClass: (...params) =>
      `mutation{updateBadgeClass(input:{id:"/api/badge_classes/${
        params[0]
      }",tagNames:[${params[1]}],name:"${params[2]}",description:"${
        params[3]
      }",criteria:"${params[4]}",level:"/api/levels/${params[5]}",timestamps:"${
        params[6]
      }",svgDesign:"${btoa(
        unescape(encodeURIComponent(params[7]))
      )}"}){badgeClass{ uuid }}}`,
    newVersionBadgeClass: (...params) =>
      `mutation{newVersionBadgeClass(input:{id:"/api/badge_classes/${params[0]}"}){badgeClass{ uuid }}}`,
    deleteBadgeClass: (...params) =>
      `mutation{removeBadgeClass(input:{id:"/api/badge_classes/${params[0]}"}){badgeClass{ uuid }}}`,
    archiveBadgeClass: (...params) =>
      `mutation{toArchivedStatusMutationBadgeClass(input:{id:"/api/badge_classes/${params[0]}"}){badgeClass{ uuid }}}`,
    unarchiveBadgeClass: (...params) =>
      `mutation{toUsedStatusMutationBadgeClass(input:{id:"/api/badge_classes/${params[0]}"}){badgeClass{ uuid }}}`,
    getBadgeClassTags: (...params) =>
      `query { badgeClassQueryTags(issuer:"/api/issuers/${params[0]}", itemsPerPage:${params[1]} ${params[2]}) { edges { node {  _id, name } } } }`,
    getBadgeClassFrame: (...params) =>
      `query { byIssuerQueryBadgeClassFrame(issuer:"/api/issuers/${params[0]}") { anchorPoints, tsaLogoWidth, tsaLogoHeight, frameLevels { edges { node { level { name, enabled, allowedTimestamp } , mainColor, frameDesignMaskFile } } }, frameTimestampAuthorities { edges { node { timestampAuthority { cryptoSignerId, name, enabled }, logoDesignFile } } } } }`,
  };

  getBadgeClasses = async (
    limit,
    pageNumber,
    search,
    statuses,
    tags,
    sortBy,
    order
  ) => {
    let issuer = AuthService.getCurrentIssuer();
    let status =
      statuses && statuses.length ? `, status:["${statuses.join('","')}"]` : ``;
    tags = tags && tags.length ? `, tags:["${tags.join('","')}"]` : ``;
    search = search ? search : "";
    pageNumber = pageNumber ? pageNumber : 0;
    limit = limit ? limit : 10;
    let sort = order && sortBy ? `, sort:{${sortBy}:"${order}"}` : "";

    return API.callGraphql(
      this.queries.getBadgeClasses(
        issuer.uuid,
        pageNumber,
        limit,
        search,
        status,
        tags,
        sort
      )
    ).then((response) => {
      this.errorParser(response);

      if (!response.data || !response.data.byIssuerQueryBadgeClasses)
        throw this.throwDefaultError();

      return response.data.byIssuerQueryBadgeClasses;
    });
  };

  getBadgeClass = async (badgeClassUuid) => {
    badgeClassUuid = badgeClassUuid ? badgeClassUuid : "";

    return API.callGraphql(this.queries.getBadgeClass(badgeClassUuid)).then(
      (response) => {
        this.errorParser(response);

        if (!response.data || !response.data.badgeClass)
          this.throwDefaultError();

        if (response.data.badgeClass.svgDesign) {
          response.data.badgeClass.svgDesign = atob(
            response.data.badgeClass.svgDesign
          );
        }

        if (!response.data.badgeClass.timestamps) {
          response.data.badgeClass.timestamps = "";
        }

        if (response.data.badgeClass.level) {
          response.data.badgeClass.level = response.data.badgeClass.level.name;
        }

        return response.data.badgeClass;
      }
    );
  };

  createBadgeClass = async (badgeClass) => {
    let issuer = AuthService.getCurrentIssuer();
    let tags = badgeClass.tagNames.length
      ? `"${badgeClass.tagNames.join('","')}"`
      : ``;
    return API.callGraphql(
      this.queries.createBadgeClass(
        tags,
        badgeClass.name,
        issuer.uuid,
        badgeClass.description,
        badgeClass.criteria,
        badgeClass.level,
        badgeClass.timestamps
      )
    ).then((response) => {
      this.errorParser(response);

      if (!response.data.createBadgeClass) this.throwDefaultError();

      return response.data.createBadgeClass.badgeClass;
    });
  };

  updateBadgeClass = async (badgeClass) => {
    let tags = badgeClass.tagNames.length
      ? `"${badgeClass.tagNames.join('","')}"`
      : ``;
    return API.callGraphql(
      this.queries.updateBadgeClass(
        badgeClass.uuid,
        tags,
        badgeClass.name,
        badgeClass.description,
        badgeClass.criteria,
        badgeClass.level,
        badgeClass.timestamps,
        badgeClass.svgDesign
      )
    ).then((response) => {
      this.errorParser(response);

      if (!response.data.updateBadgeClass) this.throwDefaultError();

      return response.data.updateBadgeClass.badgeClass;
    });
  };

  saveBadgeClass = async (badgeClass) => {
    if (badgeClass.uuid) {
      return this.updateBadgeClass(badgeClass);
    } else {
      return this.createBadgeClass(badgeClass);
    }
  };

  newVersionBadgeClass = async (badgeClassId) => {
    badgeClassId = badgeClassId ? badgeClassId : "";

    return API.callGraphql(
      this.queries.newVersionBadgeClass(badgeClassId)
    ).then((response) => {
      this.errorParser(response);

      if (!response.data.newVersionBadgeClass) this.throwDefaultError();

      return response.data.newVersionBadgeClass.badgeClass;
    });
  };

  deleteBadgeClass = async (badgeClassId) => {
    badgeClassId = badgeClassId ? badgeClassId : "";

    return API.callGraphql(this.queries.deleteBadgeClass(badgeClassId)).then(
      (response) => {
        this.errorParser(response);

        if (!response.data.removeBadgeClass) this.throwDefaultError();

        return response.data.removeBadgeClass.badgeClass;
      }
    );
  };

  archiveBadgeClass = async (badgeClassId) => {
    badgeClassId = badgeClassId ? badgeClassId : "";

    return API.callGraphql(this.queries.archiveBadgeClass(badgeClassId)).then(
      (response) => {
        this.errorParser(response);

        if (!response.data.toArchivedStatusMutationBadgeClass)
          this.throwDefaultError();

        return response.data.toArchivedStatusMutationBadgeClass.badgeClass;
      }
    );
  };

  unarchiveBadgeClass = async (badgeClassId) => {
    badgeClassId = badgeClassId ? badgeClassId : "";

    return API.callGraphql(this.queries.unarchiveBadgeClass(badgeClassId)).then(
      (response) => {
        this.errorParser(response);

        if (!response.data.toUsedStatusMutationBadgeClass)
          this.throwDefaultError();

        return response.data.toUsedStatusMutationBadgeClass.badgeClass;
      }
    );
  };

  getBadgeClassTags = async (search, limit) => {
    let issuer = AuthService.getCurrentIssuer();
    limit = limit ? limit : 10;
    search = search ? ', search:"' + search + '"' : "";

    return API.callGraphql(
      this.queries.getBadgeClassTags(issuer.uuid, limit, search)
    ).then((response) => {
      this.errorParser(response);

      if (!response.data.badgeClassQueryTags) this.throwDefaultError();

      let tags = [];
      response.data.badgeClassQueryTags.edges.map(function (edge) {
        return tags.push(edge.node.name);
      });
      return tags;
    });
  };

  getConfig = async () => {
    let issuer = AuthService.getCurrentIssuer();

    return API.callGraphql(this.queries.getBadgeClassFrame(issuer.uuid)).then(
      (response) => {
        this.errorParser(response);

        if (!response.data.byIssuerQueryBadgeClassFrame)
          this.throwDefaultError();

        let levels = [];
        let timestampAuthorities = [];

        for (let posLevel in response.data.byIssuerQueryBadgeClassFrame
          .frameLevels.edges) {
          levels.push(
            response.data.byIssuerQueryBadgeClassFrame.frameLevels.edges[
              posLevel
            ].node
          );
        }

        for (let posTsa in response.data.byIssuerQueryBadgeClassFrame
          .frameTimestampAuthorities.edges) {
          timestampAuthorities.push(
            response.data.byIssuerQueryBadgeClassFrame.frameTimestampAuthorities
              .edges[posTsa].node
          );
        }

        let config = response.data.byIssuerQueryBadgeClassFrame;
        config.frameTimestampAuthorities = timestampAuthorities.filter(
          (value) => value
        );
        config.frameLevels = levels;

        return config;
      }
    );
  };
}

export default new BadgeClassesService();
