import { Icon } from "@material-ui/core";
import { FormattedMessage } from "react-intl";

const routes = {
  public: [],
  protected: [
    {
      to: "/badges",
      message: (
        <FormattedMessage
          id="routes.protected.badges"
          defaultMessage="My Badges"
          description="My Badges menu link and breadcrumbs title"
        />
      ),
      icon: <Icon>view_module</Icon>,
    },
    {
      to: "/recipients",
      message: (
        <FormattedMessage
          id="routes.protected.earners"
          defaultMessage="Recipients"
          description="Recipients menu link and breadcrumbs title"
        />
      ),
      icon: <Icon>group</Icon>,
    },
    {
      to: "/history",
      message: (
        <FormattedMessage
          id="routes.protected.history"
          defaultMessage="History"
          description="History menu link"
        />
      ),
      icon: <Icon>history</Icon>,
    },
    {
      message: (
        <FormattedMessage id="routes.protected.tools" defaultMessage="Tools" />
      ),
      icon: <Icon>build</Icon>,
      routes: [
        {
          to: "/validator",
          message: (
            <FormattedMessage
              id="routes.protected.validator"
              defaultMessage="Badges Validator"
              description="Validator menu link"
            />
          ),
          icon: <Icon>verified_user</Icon>,
        },
        {
          to: "/verify-timestamp",
          message: (
            <FormattedMessage
              id="timestmap-verifier-title"
              defaultMessage="Timestamp Verifier"
              description="Timestamp Verifier title"
            />
          ),
          icon: <Icon>verified_user</Icon>,
        },
      ],
    },
  ],
};

export { routes };
