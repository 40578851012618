import { useState } from "react";

const usePromise = () => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);
  const [data, setData] = useState(false);

  const reset = () => {
    setLoading(false);
    setData(false);
    setErrors(false);
  };

  const setPromise = (promise) => {
    setLoading(true);
    setErrors(false);
    return promise
      .then((data) => {
        setLoading(false);

        if (data && data.errors) {
          setErrors(data.errors);
          return false;
        }

        setData(data);
        setErrors(false);
        return true;
      })
      .catch((error) => {
        setLoading(false);
        setErrors([error.message]);
        setData(false);
        return false;
      });
  };

  return [data, errors, loading, setPromise, reset];
};

export default usePromise;
