import {
  Box,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";

const importEarnersInformation = {
  es: (
    <>
      <Typography>
        El archivo CSV debe tener los siguientes campos en el mismo órden
        descripto:
      </Typography>
      <Box mt="0.5em">
        <TableContainer component={Paper} variant="outlined">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID Externo</TableCell>
                <TableCell>Nombre y Apellido</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Etiquetas</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>4563</TableCell>
                <TableCell>Ana López</TableCell>
                <TableCell>analopez@example.com</TableCell>
                <TableCell>art, Instrument, student</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>7623</TableCell>
                <TableCell>Joaquín Bernal</TableCell>
                <TableCell>joaquinbernal@example.com</TableCell>
                <TableCell>drum, student</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>5544</TableCell>
                <TableCell>Javier Rodríguez</TableCell>
                <TableCell>javierrodriguez@example.com</TableCell>
                <TableCell>drum, Instrument</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box mt="1em">
        <Typography>Además debe cumplir con las siguientes reglas:</Typography>
        <List dense={true}>
          <ListItem>
            <ListItemIcon>
              <Icon>arrow_right</Icon>
            </ListItemIcon>
            <ListItemText primary="No debe incluir el encabezado." />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <Icon>arrow_right</Icon>
            </ListItemIcon>
            <ListItemText primary="Separador de campos: , (coma)" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <Icon>arrow_right</Icon>
            </ListItemIcon>
            <ListItemText primary="Separador de registros: salto de línea." />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <Icon>arrow_right</Icon>
            </ListItemIcon>
            <ListItemText primary='Utilizar " (comillas dobles) para envolver todos los campos, ya que todos son cadenas de texto.' />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <Icon>arrow_right</Icon>
            </ListItemIcon>
            <ListItemText primary='En el campo Etiquetas, el separador es , (coma), a su vez, todo el campo va entre " (comillas dobles), porque es una cadena de texto.' />
          </ListItem>
        </List>
      </Box>
      <Box mt="1em" mb="2em">
        <Typography variant="subtitle2">Ejemplo:</Typography>
        <Paper
          variant="outlined"
          style={{
            backgroundColor: "#333333",
            color: "#DEDEDE",
            padding: "1em",
          }}
        >
          <pre>
            "4563","Ana López","analopez@example.com","art, Instrument ,student"
            <br />
            "7623","Joaquín Bernal","joaquinbernal@example.com","drum ,student"
            <br />
            "5544","Javier Rodríguez","javierrodriguez@example.com","drum,
            Instrument"
          </pre>
        </Paper>
      </Box>
    </>
  ),
  en: (
    <>
      <Typography>
        CSV file must have the following columns in the exact order as
        described:
      </Typography>
      <Box mt="0.5em">
        <TableContainer component={Paper} variant="outlined">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>External Id</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Tags</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>4563</TableCell>
                <TableCell>Ana López</TableCell>
                <TableCell>analopez@example.com</TableCell>
                <TableCell>art, Instrument, student</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>7623</TableCell>
                <TableCell>Joaquín Bernal</TableCell>
                <TableCell>joaquinbernal@example.com</TableCell>
                <TableCell>drum, student</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>5544</TableCell>
                <TableCell>Javier Rodríguez</TableCell>
                <TableCell>javierrodriguez@example.com</TableCell>
                <TableCell>drum, Instrument</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box mt="1em">
        <Typography>Also, it must fulfill the next rules:</Typography>
        <List dense={true}>
          <ListItem>
            <ListItemIcon>
              <Icon>arrow_right</Icon>
            </ListItemIcon>
            <ListItemText primary="It must not include the header." />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <Icon>arrow_right</Icon>
            </ListItemIcon>
            <ListItemText primary="Field separator: , (comma)" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <Icon>arrow_right</Icon>
            </ListItemIcon>
            <ListItemText primary="Record separator: line break." />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <Icon>arrow_right</Icon>
            </ListItemIcon>
            <ListItemText primary='You must escape all fields in " (double quotation marks), they are all strings.' />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <Icon>arrow_right</Icon>
            </ListItemIcon>
            <ListItemText primary='Tags field is a string with comma separated tags. You must escape the field with " (double quotation marks).' />
          </ListItem>
        </List>
      </Box>
      <Box mt="1em" mb="2em">
        <Typography variant="subtitle2">Example:</Typography>
        <Paper
          variant="outlined"
          style={{
            backgroundColor: "#333333",
            color: "#DEDEDE",
            padding: "1em",
          }}
        >
          <pre>
            "4563","Ana López","analopez@example.com","art, Instrument ,student"
            <br />
            "7623","Joaquín Bernal","joaquinbernal@example.com","drum ,student"
            <br />
            "5544","Javier Rodríguez","javierrodriguez@example.com","drum,
            Instrument"
          </pre>
        </Paper>
      </Box>
    </>
  ),
};

export { importEarnersInformation };
