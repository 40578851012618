import { countries } from "../config/countries";
import API from "../utils/API";
import Service from "./Service";

class IssuersService extends Service {
  queries = {
    getIssuer: (uuid) => `query { 
            issuer(id:"/api/issuers/${uuid}") { name, description, email, phone, url, image, locale, country } 
        }`,
    createIssuer: (
      name,
      description,
      email,
      url,
      phone,
      image,
      locale,
      country
    ) => `mutation{
            createIssuer(input:{name:"${name}",email:"${email}",url:"${url}",image:"${image}",locale:"${locale}"${
      phone ? `,phone:"${phone}"` : ``
    }${country ? `,country:"${country}"` : ``}${
      description ? `,description:"${description}"` : ``
    }}) {issuer{uuid}}
        }`,
    updateIssuer: (
      uuid,
      name,
      description,
      phone,
      image,
      locale,
      country
    ) => `mutation{
            updateIssuer(input:{id:"/api/issuers/${uuid}", name:"${name}",image:"${image}",locale:"${locale}"${
      phone ? `,phone:"${phone}"` : ``
    }${country ? `,country:"${country}"` : ``}${
      description ? `,description:"${description}"` : ``
    }}) {issuer{uuid}}
        }`,
    getIssuerAndIdentities: (uuid) => `query {
      issuer(id:"/api/issuers/${uuid}") { uuid, name, description, email, phone, url, image, locale }
      byIssuerQueryIssuerIdentityValidations(issuer:"/api/issuers/${uuid}") {
            type,
            email,
            domain,
            validationDate,
            status
      }
    }`,
    createIssuerIdentityValidation: (uuid, type, email, domain) => `mutation {
      createIssuerIdentityValidation(input:{issuer:"/api/issuers/${uuid}", type:"${type}"${
      email ? ` , email:"${email}"` : ""
    }${domain ? ` , domain:"${domain}"` : ""}}){
        issuerIdentityValidation {type, email, domain, status}
      }
    }`,
    validateHashIssuerIdentityValidation: (uuid, hash) => `mutation {
      validateHashIssuerIdentityValidation(input:{issuer:"/api/issuers/${uuid}", validationHash:"${hash}"}){
        issuerIdentityValidation {type, email, domain, status}
      }
    }`,
    getValidatedIdentities: (uuid) => `query { 
      issuer(id:"/api/issuers/${uuid}") { validatedIdentities } 
    }`,
  };

  _getIssuerCountry = (countryCode) => {
    return countries.length && countryCode
      ? countries.find((c) => c.code === countryCode)
      : undefined;
  };

  getIssuer = async (uuid) => {
    return API.callGraphql(this.queries.getIssuer(uuid)).then((response) => {
      this.errorParser(response);

      if (!response.data || !response.data.issuer) this.throwDefaultError();

      response.data.issuer.country = this._getIssuerCountry(
        response.data.issuer.country
      );

      return response.data.issuer;
    });
  };

  createIssuer = async (issuer) => {
    return API.callGraphql(
      this.queries.createIssuer(
        issuer.name ?? "",
        issuer.description ?? null,
        issuer.email ?? "",
        issuer.url ?? "",
        issuer.phone ?? null,
        issuer.image ?? "",
        issuer.locale ?? "",
        issuer.country ? issuer.country.code : null
      )
    ).then((response) => {
      this.errorParser(response);

      if (!response.data || !response.data.createIssuer)
        this.throwDefaultError();

      return response.data.createIssuer;
    });
  };

  updateIssuer = async (uuid, issuer) => {
    return API.callGraphql(
      this.queries.updateIssuer(
        uuid,
        issuer.name ?? "",
        issuer.description ?? null,
        issuer.phone ?? null,
        issuer.image ?? "",
        issuer.locale ?? "",
        issuer.country ? issuer.country.code : null
      )
    ).then((response) => {
      this.errorParser(response);

      if (!response.data || !response.data.updateIssuer)
        this.throwDefaultError();

      return response.data.updateIssuer;
    });
  };

  getIssuerAndIdentities = async (uuid) => {
    return API.callGraphql(this.queries.getIssuerAndIdentities(uuid)).then(
      (response) => {
        this.errorParser(response);

        if (
          !response.data ||
          !response.data.issuer ||
          !response.data.byIssuerQueryIssuerIdentityValidations
        )
          this.throwDefaultError();

        let identities = [];
        response.data.byIssuerQueryIssuerIdentityValidations.map((identity) => {
          identities[identity.type] = identity;
          return identity;
        });

        return { issuer: response.data.issuer, identities: identities };
      }
    );
  };

  createIdentity = async (issuer, identityType, value) => {
    return API.callGraphql(
      this.queries.createIssuerIdentityValidation(
        issuer.uuid,
        identityType,
        identityType === "email" ? value : null,
        identityType === "domain" ? value : null
      )
    ).then((response) => {
      this.errorParser(response);

      if (!response.data || !response.data.createIssuerIdentityValidation)
        this.throwDefaultError();

      return response.data.createIssuerIdentityValidation;
    });
  };

  validateIdentityHash = async (issuer, hash) => {
    return API.callGraphql(
      this.queries.validateHashIssuerIdentityValidation(issuer.uuid, hash)
    ).then((response) => {
      this.errorParser(response);

      if (!response.data || !response.data.validateHashIssuerIdentityValidation)
        this.throwDefaultError();

      return response.data.validateHashIssuerIdentityValidation
        .issuerIdentityValidation;
    });
  };

  getValidatedIdentities = async (uuid) => {
    return API.callGraphql(this.queries.getValidatedIdentities(uuid)).then(
      (response) => {
        this.errorParser(response);

        if (!response.data || !response.data.issuer) this.throwDefaultError();

        return response.data.issuer.validatedIdentities;
      }
    );
  };
}

export default new IssuersService();
