import { Box, CircularProgress } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { LanguageContext } from "../../context/LanguageProvider";
import ErrorsAlert from "../Common/ErrorsAlert";
import LoadingButton from "../Common/LoadingButton";

const MercadoPagoCardForm = ({
  onSubmit,
  amount,
  authorizing,
  errorsAuthorizing,
  cardPaymentBrickController,
  setCardPaymentBrickController,
  ...rest
}) => {
  const { locale } = useContext(LanguageContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (locale && amount) {
      cardPaymentBrickController && cardPaymentBrickController.unmount();

      const mp = new window.MercadoPago(
        process.env.REACT_APP_MERCADOPAGO_PUBLIC_KEY,
        {
          locale: locale === "es" ? "es-AR" : "en-US",
        }
      );

      const bricksBuilder = mp.bricks();

      renderCardPaymentBrick(bricksBuilder, amount);
    }

    return () => {
      cardPaymentBrickController && cardPaymentBrickController.unmount();
      setLoading(false);
      setCardPaymentBrickController(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, amount]);

  const renderCardPaymentBrick = async (bricksBuilder, amount) => {
    const settings = {
      initialization: {
        amount: parseFloat(amount), //value of the payment to be processed
      },
      customization: {
        visual: {
          hideFormTitle: true,
          hidePaymentButton: true,
          style: {
            theme: "default", // 'default' |'dark' | 'bootstrap' | 'flat'
          },
        },
        paymentMethods: {
          minInstallments: 1,
          maxInstallments: 1,
        },
      },
      callbacks: {
        onReady: () => {
          // handle form ready
          setLoading(false);
        },
        onError: (error) => {
          console.log(error);
          // handle error
        },
        onSubmit: (cardFormData) => {},
      },
    };

    try {
      let controller = await bricksBuilder.create(
        "cardPayment",
        "cardPaymentBrick_container",
        settings
      );
      setCardPaymentBrickController(controller);
    } catch (e) {
      console.log("Cannot init MP Brick. Maybe dialog was closed?");
    }
  };

  const handleButtonClick = (evt) => {
    setLoading(true);
    cardPaymentBrickController
      .getFormData()
      .then((cardFormData) => {
        setLoading(false);
        onSubmit(cardFormData);
      })
      .catch((error) => {
        setLoading(false);
        // tratamiento de error al intentar llamar getFormData()
      });
  };

  return (
    <>
      {loading && !authorizing && (
        <Box p={1} display="grid" justifyItems="center">
          <CircularProgress />
        </Box>
      )}
      <div id="cardPaymentBrick_container"></div>
      <ErrorsAlert errors={errorsAuthorizing} />
      <Box display="grid" justifyItems="center">
        <LoadingButton
          loading={authorizing ? "true" : undefined}
          onClick={handleButtonClick}
          variant="contained"
          color="primary"
          disabled={loading || authorizing}
        >
          Suscribirme
        </LoadingButton>
      </Box>
    </>
  );
};

export default MercadoPagoCardForm;
