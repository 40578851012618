import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import React, { useState } from "react";

const SnackbarContext = React.createContext();

const SnackbarProvider = (props) => {
  const [snackbars, setSnackbars] = useState([]);

  const showSnackbar = (message, severity, autoHideDuration) => {
    setSnackbars([
      ...snackbars,
      {
        message: message,
        severity: severity,
        autoHideDuration: autoHideDuration,
      },
    ]);
  };

  const handleSnackbarClose = (snackbarPosition) => {
    setSnackbars([
      ...snackbars.slice(0, snackbarPosition),
      ...snackbars.slice(snackbarPosition + 1),
    ]);
  };

  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      {snackbars.map((snackbar, i) => (
        <Snackbar
          open={Boolean(snackbar.message)}
          key={i}
          autoHideDuration={snackbar.autoHideDuration || 1000}
          onClose={() => handleSnackbarClose(i)}
        >
          <MuiAlert
            onClose={() => handleSnackbarClose(i)}
            severity={snackbar.severity || "success"}
            elevation={0}
            variant="filled"
          >
            {snackbar.message}
          </MuiAlert>
        </Snackbar>
      ))}
      {props.children}
    </SnackbarContext.Provider>
  );
};

export { SnackbarProvider, SnackbarContext };
