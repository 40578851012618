import React, { useState } from "react";
import MuiLink from "@material-ui/core/Link/Link";
import { Box, Container } from "@material-ui/core";
import { ValidatorForm } from "react-form-validator-core";
import { FormattedMessage, useIntl } from "react-intl";
import TextValidator from "react-material-ui-form-validator/lib/TextValidator";
import LoadingButton from "../Common/LoadingButton";
import CheckboxValidator from "../Common/CheckboxValidator";
import { Link } from "react-router-dom";
import GoogleRecaptchaInfo from "../Misc/GoogleRecaptchaInfo";

const SignUpForm = (props) => {
  const intl = useIntl();
  const [formData, setFormData] = useState({
    email: "",
    name: "",
    lastname: "",
    password: "",
    terms: null,
  });

  const handleInputChange = (event) => {
    event.preventDefault();

    let newValue = event.target.value;

    if (event.target.name === "terms") {
      newValue = event.target.checked ? true : null;
    }

    setFormData({
      ...formData,
      [event.target.name]: newValue,
    });
  };

  const validationMessages = {
    required: intl.formatMessage({
      id: "validation.field-required",
      defaultMessage: "This field is required",
      description: "Label shown on required form field when is required",
    }),
    isEmail: intl.formatMessage({
      id: "validation.field-email",
      defaultMessage: "Email is not valid",
      description: "Label shown on required form field when email is not valid",
    }),
  };

  const inputs = {
    email: {
      label: intl.formatMessage({
        id: "form.email-input",
        defaultMessage: "Email",
        description: "Email input label",
      }),
      placeholder: intl.formatMessage({
        id: "form.email-placeholder",
        defaultMessage: "Please enter your email",
        description: "Email input placeholder",
      }),
    },
    password: {
      label: intl.formatMessage({
        id: "form.password-input",
        defaultMessage: "Password",
        description: "Password input label",
      }),
      placeholder: intl.formatMessage({
        id: "form.password-placeholder",
        defaultMessage: "Please enter your password",
        description: "Password input placeholder",
      }),
    },
    name: {
      label: intl.formatMessage({
        id: "form.name-input",
        defaultMessage: "Name",
        description: "Name input label",
      }),
      placeholder: intl.formatMessage({
        id: "form.name-placeholder",
        defaultMessage: "Please enter your name",
        description: "Name input placeholder",
      }),
    },
    lastname: {
      label: intl.formatMessage({
        id: "form.lastname-input",
        defaultMessage: "Last name (optional)",
        description: "Last name input label",
      }),
      placeholder: intl.formatMessage({
        id: "form.lastname-placeholder",
        defaultMessage: "Please enter your last name",
        description: "Last name input placeholder",
      }),
    },
  };

  return (
    <ValidatorForm
      onSubmit={() => props.handleFormSubmit(formData)}
      data-testid="formRegister"
    >
      <Container maxWidth="sm">
        <Box>
          <TextValidator
            onChange={handleInputChange}
            name="name"
            value={formData["name"]}
            label={inputs.name.label}
            placeholder={inputs.name.placeholder}
            fullWidth
            validators={["required"]}
            errorMessages={[validationMessages.required]}
            inputProps={{ "data-testid": "inputName" }}
            variant="outlined"
          />
        </Box>
        <Box mt={1}>
          <TextValidator
            onChange={handleInputChange}
            name="lastname"
            value={formData["lastname"]}
            label={inputs.lastname.label}
            placeholder={inputs.lastname.placeholder}
            fullWidth
            validators={[]}
            errorMessages={[validationMessages.required]}
            inputProps={{ "data-testid": "inputLastname" }}
            variant="outlined"
          />
        </Box>
        <Box mt={1}>
          <TextValidator
            onChange={handleInputChange}
            name="email"
            label={inputs.email.label}
            value={formData["email"]}
            placeholder={inputs.email.placeholder}
            fullWidth
            validators={["required", "isEmail"]}
            errorMessages={[
              validationMessages.required,
              validationMessages.isEmail,
            ]}
            inputProps={{ "data-testid": "inputEmail" }}
            variant="outlined"
          />
        </Box>
        <Box mt={1}>
          <TextValidator
            onChange={handleInputChange}
            name="password"
            type="password"
            value={formData["password"]}
            label={inputs.password.label}
            placeholder={inputs.password.placeholder}
            fullWidth
            validators={["required"]}
            errorMessages={[validationMessages.required]}
            inputProps={{ "data-testid": "inputPassword" }}
            variant="outlined"
          />
        </Box>
        <GoogleRecaptchaInfo />
        <Box mt={1} display="flex" alignItems="center" alignContent="middle">
          <CheckboxValidator
            name="terms"
            onChange={handleInputChange}
            value={formData["terms"]}
            checked={formData["terms"]}
            validators={["required"]}
            errorMessages={[validationMessages.required]}
            inputProps={{ "data-testid": "inputCheckbox" }}
          />
          <Box pt={0} display="flex">
            <FormattedMessage
              id="form.agree-terms"
              description="Agree terms and conditions text"
              defaultMessage="I agree the {terms} and the {privacyPolicy}"
              values={{
                terms: (
                  <Box mx={1} display="inline" component="span">
                    <MuiLink
                      to="/terms"
                      target="_blank"
                      color="secondary"
                      component={Link}
                    >
                      <FormattedMessage
                        id="form.terms"
                        description="Terms and conditions lnk"
                        defaultMessage="terms and conditions"
                      />
                    </MuiLink>
                  </Box>
                ),
                privacyPolicy: (
                  <Box mx={1} display="inline" component="span">
                    <MuiLink
                      to="/privacy-policy"
                      target="_blank"
                      color="secondary"
                      component={Link}
                    >
                      <FormattedMessage
                        id="form.privacy-policy"
                        description="Pivacy policy link"
                        defaultMessage="privacy policy"
                      />
                    </MuiLink>
                  </Box>
                ),
              }}
            />
          </Box>
        </Box>
        <Box mt={2} justifyContent="center" display="flex" alignItems="center">
          <LoadingButton
            loading={props.loading ? "true" : undefined}
            size="medium"
            color="primary"
            variant="contained"
            type="submit"
          >
            <FormattedMessage
              id="form.signup-button"
              description="Sign Up button"
              defaultMessage="Sign Up"
            />
          </LoadingButton>
        </Box>
      </Container>
    </ValidatorForm>
  );
};

export default SignUpForm;
