import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  Typography,
} from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";
import LoadingButton from "./LoadingButton";

const ResendConfirmDialog = (props) => {
  const { handleClose, handleResend, open, resending, mode, ...rest } = props;

  return (
    <Dialog open={open} onClose={handleClose} {...rest}>
      <DialogTitle>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography variant="h6">
            {mode === "badge" ? (
              <FormattedMessage
                defaultMessage="Resend Badge Email"
                description="Resend dialog title"
                id="badge.resend-dialog-title"
              />
            ) : (
              <FormattedMessage
                defaultMessage="Resend Batch Emails"
                description="Resend dialog title"
                id="batch.resend-dialog-title"
              />
            )}
          </Typography>
          <IconButton size="small" onClick={handleClose}>
            <Icon>close</Icon>
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers={true}>
        <Typography>
          {mode === "badge" ? (
            <FormattedMessage
              defaultMessage="You are going to resend the badge email. Are you sure?"
              id="badge.resend-dialog-text"
            />
          ) : (
            <FormattedMessage
              defaultMessage="You are going to resend all batch emails. Are you sure?"
              id="batch.resend-dialog-text"
            />
          )}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          flexGrow={1}
          p={1}
        >
          <Button onClick={handleClose}>
            <FormattedMessage
              defaultMessage="Cancel"
              description="Cancel button text"
              id="dialog.cancel-button"
            />
          </Button>
          <LoadingButton
            loading={resending ? "true" : undefined}
            onClick={() => handleResend()}
            variant="contained"
            color="primary"
          >
            {mode === "badge" ? (
              <FormattedMessage
                defaultMessage="Yes, resend email"
                description="Resend button text"
                id="badge.resend-dialog-button"
              />
            ) : (
              <FormattedMessage
                defaultMessage="Yes, resend emails"
                description="Resend button text"
                id="batch.resend-dialog-button"
              />
            )}
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ResendConfirmDialog;
