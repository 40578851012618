import { Icon, Tooltip, Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";

const plansCapabilities = [
  {
    id: "tsa_timestamp",
    description: (
      <FormattedMessage
        id="plans-capabilities.tsa_timestamp"
        defaultMessage="TSA Stamping"
      />
    ),
    icon: <Icon>view_module</Icon>,
    mark: (
      <Tooltip
        display="inline"
        title={
          <FormattedMessage
            id="plans-capabilities.depending_on_level_mark"
            defaultMessage="Depending on badge level"
          />
        }
      >
        <Icon style={{ fontSize: 16 }}>info_outlined</Icon>
      </Tooltip>
    ),
  },
  {
    id: "api_access",
    description: (
      <FormattedMessage
        id="plans-capabilities.api_access"
        defaultMessage="API Access"
      />
    ),
    icon: <Icon>view_module</Icon>,
  },
  {
    id: "cold_signature",
    description: (
      <FormattedMessage
        id="plans-capabilities.cold_signature"
        defaultMessage="Cold Signature"
      />
    ),
    icon: <Icon>view_module</Icon>,
    mark: (
      <Tooltip
        display="inline"
        title={
          <FormattedMessage
            id="plans-capabilities.depending_on_level_mark"
            defaultMessage="Depending on badge level"
          />
        }
      >
        <Icon style={{ fontSize: 16 }}>info_outlined</Icon>
      </Tooltip>
    ),
  },
  {
    id: "pdf_printing",
    description: (
      <FormattedMessage
        id="plans-capabilities.pdf_printing"
        defaultMessage="PDF Certificates"
      />
    ),
    icon: <Icon>view_module</Icon>,
    customCheck: function (plan) {
      return plan.enabledCapabilities.indexOf("custom_pdf") !== -1 ? (
        <Typography variant="caption">
          <FormattedMessage
            id="plans-capabilities.custom_template"
            defaultMessage="Custom Template"
          />
        </Typography>
      ) : (
        <Typography variant="caption">
          <FormattedMessage
            id="plans-capabilities.default_template"
            defaultMessage="Default Template"
          />
        </Typography>
      );
    },
  },
  {
    id: "team_management",
    description: (
      <FormattedMessage
        id="plans-capabilities.team_management"
        defaultMessage="Team Managment"
      />
    ),
    icon: <Icon>view_module</Icon>,
    customCheck: function (plan) {
      return plan.name === "standard" ? (
        <Typography variant="caption">
          <FormattedMessage
            id="plans-capabilities.team_max_users"
            defaultMessage="Limited To 3 Users"
          />
        </Typography>
      ) : (
        <Icon>check</Icon>
      );
    },
  },
  {
    id: "learning_paths",
    description: (
      <FormattedMessage
        id="plans-capabilities.learning_paths"
        defaultMessage="Learning Paths"
      />
    ),
    icon: <Icon>view_module</Icon>,
  },
  {
    id: "white_label",
    description: (
      <FormattedMessage
        id="plans-capabilities.white_label"
        defaultMessage="White Label"
      />
    ),
    icon: <Icon>view_module</Icon>,
  },
  {
    id: "custom_email",
    description: (
      <FormattedMessage
        id="plans-capabilities.custom_email"
        defaultMessage="Custom Issuing Email"
      />
    ),
    icon: <Icon>view_module</Icon>,
  },
  {
    id: "custom_badge_class_frame",
    description: (
      <FormattedMessage
        id="plans-capabilities.custom_badge_class_frame"
        defaultMessage="Custom Badges Frame"
      />
    ),
    icon: <Icon>view_module</Icon>,
    mark: (
      <Tooltip
        display="inline"
        title={
          <FormattedMessage
            id="plans-capabilities.depending_on_level_mark"
            defaultMessage="Depending on badge level"
          />
        }
      >
        <Icon style={{ fontSize: 16 }}>info_outlined</Icon>
      </Tooltip>
    ),
  },
];

export { plansCapabilities };
