import {
  Box,
  Breadcrumbs,
  Grid,
  Icon,
  Paper,
  Typography,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import AccountBalanceIndicator from "../components/Account/AccountBalanceIndicator";
import LevelPricesList from "../components/Account/LevelPricesList";
import AdminLayout from "../layouts/AdminLayout";

const Balance = () => {
  return (
    <AdminLayout>
      <Box p={2}>
        <Breadcrumbs separator=">" aria-label="breadcrumb">
          <Link style={{ color: "inherit" }} to="/">
            <FormattedMessage
              id="routes.protected.dashboard"
              defaultMessage="Dashboard"
              description="Dashboard menu breadcrumbs title"
            />
          </Link>
          <Typography color="textPrimary">
            <FormattedMessage
              id="routes.protected.balance"
              defaultMessage="Balance"
              description="Balance menu link"
            />
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box p={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <AccountBalanceIndicator currency="TBT" />
          </Grid>
          <Grid item xs={12} sm={4}>
            <AccountBalanceIndicator currency="TBFREE" />
          </Grid>
          <Grid item xs={12} sm={4}>
            <AccountBalanceIndicator currency="USD" />
          </Grid>
          <Grid item xs={12}>
            <Alert
              variant="outlined"
              color="info"
              icon={<Icon fontSize="small">info_circle</Icon>}
            >
              <AlertTitle>
                <FormattedMessage
                  id="balance.info-title"
                  defaultMessage="About TBT"
                />
              </AlertTitle>
              <Typography variant="body2">
                <FormattedMessage
                  id="balance.info"
                  defaultMessage="TBT (Trusted Badges Token) are consumed when you award badges. According to badge level it will consume more or less TBT."
                />
              </Typography>
              <Typography variant="body2">
                <FormattedMessage
                  id="balance.ask-for"
                  defaultMessage="Ask for TBT credit to your administrator."
                />
              </Typography>
            </Alert>
          </Grid>
          <Grid item xs={12}>
            <Paper variant="outlined">
              <Box p={1}>
                <Typography variant="h6">
                  <FormattedMessage
                    id="balance.current-level-prices"
                    defaultMessage="Current Level Prices"
                  />
                </Typography>
                <LevelPricesList disablePadding />
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </AdminLayout>
  );
};

export default Balance;
