import {
  Box,
  Button,
  Grid,
  Icon,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useContext } from "react";
import { CreateBadgeContext } from "../../context/CreateBadgeProvider";
import { useIntl } from "react-intl";

const BadgeClassTypeSelector = (props) => {
  const intl = useIntl();
  const { badgeClass, setBadgeClass } = useContext(CreateBadgeContext);
  const currentTypeId = badgeClass.level || "basic";

  const badgeClassLevels = {
    free: {
      id: "free",
      title: intl.formatMessage({
        id: "badge-levels.free-title",
        defaultMessage: "Free Badge",
      }),
      description: intl.formatMessage({
        id: "badge-levels.free-description",
        defaultMessage: "Badges are validated by Trusted Badges's URL",
      }),
      color: "#333333",
    },
    basic: {
      id: "basic",
      title: intl.formatMessage({
        id: "badge-levels.basic-title",
        defaultMessage: "Basic Badge",
      }),
      description: intl.formatMessage({
        id: "badge-levels.basic-description",
        defaultMessage: "Badges are signed by Trusted Badges",
      }),
      color: "#007719",
    },
    gold: {
      id: "gold",
      title: intl.formatMessage({
        id: "badge-levels.gold-title",
        defaultMessage: "Gold Badge",
      }),
      description: intl.formatMessage({
        id: "badge-levels.gold-description",
        defaultMessage: "Badges are signed by badge's issuer",
      }),
      color: "#ffb332",
    },
  };

  const handleBadgeClassTypeClick = (badgeClassLevel) => {
    setBadgeClass({
      ...badgeClass,
      level: badgeClassLevel,
    });
  };

  return (
    <>
      {props.levels.map(
        (level) =>
          badgeClassLevels[level.level.name] && (
            <Box
              mb={2}
              key={badgeClassLevels[level.level.name].title}
              display="flex"
            >
              <Box display="grid" alignItems="center">
                <Icon>
                  {currentTypeId === badgeClassLevels[level.level.name].id &&
                    "chevron_left"}
                </Icon>
              </Box>
              <Button
                variant="outlined"
                color={
                  currentTypeId === badgeClassLevels[level.level.name].id
                    ? "primary"
                    : "default"
                }
                onClick={() =>
                  handleBadgeClassTypeClick(
                    badgeClassLevels[level.level.name].id
                  )
                }
                fullWidth
                disabled={!level.level.enabled}
              >
                <Grid container>
                  <Grid item xs={2} container alignItems="center">
                    <Icon
                      style={{
                        color: badgeClassLevels[level.level.name].color,
                      }}
                      fontSize="large"
                    >
                      shield
                    </Icon>
                  </Grid>
                  <Grid item xs={9} container alignItems="center">
                    <Typography variant="body2" style={{ fontSize: "1.3em" }}>
                      {badgeClassLevels[level.level.name].title}
                    </Typography>
                  </Grid>
                  <Grid item xs={1} container alignItems="center">
                    <Tooltip
                      title={badgeClassLevels[level.level.name].description}
                    >
                      <Icon color="action" style={{ fontSize: "1.5em" }}>
                        info
                      </Icon>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Button>
            </Box>
          )
      )}
    </>
  );
};

export default BadgeClassTypeSelector;
