import { Box, Container, Grid, Typography } from "@material-ui/core";
import React from "react";
import AdminLayout from "../layouts/AdminLayout";
import AuthService from "../services/AuthService";
import ErrorsAlert from "../components/Common/ErrorsAlert";
import usePromise from "../hooks/usePromise";
import { useParams } from "react-router-dom";
import ResetPasswordRequestForm from "../components/ResetPassword/ResetPasswordRequestForm";
import ResetPasswordRequestSuccess from "../components/ResetPassword/ResetPasswordRequestSuccess";
import ResetPasswordForm from "../components/ResetPassword/ResetPasswordForm";
import ResetPasswordSuccess from "../components/ResetPassword/ResetPasswordSuccess";
import { FormattedMessage } from "react-intl";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const ResetPassword = () => {
  const params = useParams();
  const [
    requestData,
    requestErrors,
    requestLoading,
    setRequestPromise,
  ] = usePromise();
  const [data, errors, loading, setPromise] = usePromise();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleResetPasswordRequestFormSubmit = async (formData) => {
    const token = executeRecaptcha ? await executeRecaptcha() : "";
    AuthService.setGoogleRecaptchaToken(token);

    setRequestPromise(AuthService.resetPasswordRequest(formData["email"]));
  };

  const handleResetPasswordFormSubmit = async (formData) => {
    const token = executeRecaptcha ? await executeRecaptcha() : "";
    AuthService.setGoogleRecaptchaToken(token);

    setPromise(
      AuthService.resetPassword(params.resetHash, formData["password"])
    );
  };

  if (params.resetHash) {
    return (
      <AdminLayout>
        <Box my={6} display="flex" flexDirection="row" justifyContent="center">
          <Container maxWidth="sm">
            <Grid container direction="row" spacing={4}>
              <Grid item xs={12}>
                <ErrorsAlert errors={errors} />
              </Grid>
              <Grid item sm={12}>
                {data.changed ? (
                  <ResetPasswordSuccess />
                ) : (
                  <ResetPasswordForm
                    handleFormSubmit={handleResetPasswordFormSubmit}
                    loading={loading}
                  />
                )}
              </Grid>
            </Grid>
          </Container>
        </Box>
      </AdminLayout>
    );
  }

  return (
    <AdminLayout>
      <Box my={2}>
        <Typography variant="h5">
          <FormattedMessage
            id="form.forgot-password-link"
            description="Forgot password link"
            defaultMessage="Forgot password?"
          />
        </Typography>
      </Box>
      <Box my={2} display="flex" flexDirection="row" justifyContent="center">
        <Container maxWidth="sm">
          <Grid container direction="row" spacing={4}>
            <Grid item xs={12}>
              <ErrorsAlert errors={requestErrors} />
            </Grid>
            <Grid item xs={12}>
              {requestData.email ? (
                <ResetPasswordRequestSuccess email={requestData.email} />
              ) : (
                <ResetPasswordRequestForm
                  handleFormSubmit={handleResetPasswordRequestFormSubmit}
                  loading={requestLoading}
                />
              )}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </AdminLayout>
  );
};

export default ResetPassword;
