import {
  Box,
  Button,
  Divider,
  Icon,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import React, { useContext, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { RevokeBadgeContext } from "../../context/RevokeBadgeProvider";
import BadgeDownloadButton from "../Badge/BadgeDownloadButton";
import BadgePrintButton from "../Badge/BadgePrintButton";
import BadgeResendButton from "../Badge/BadgeResendButton";
import MoreOptionsMenu from "../Common/MoreOptionsMenu";

const BatchAssertions = (props) => {
  const intl = useIntl();
  const {
    handleRevokeButtonClick,
    revocatedBadges,
    revocationData,
  } = useContext(RevokeBadgeContext);

  useEffect(() => {
    if (revocatedBadges) {
      props.assertions.map((badgeRow) => {
        if (revocatedBadges.includes(badgeRow.uuid)) {
          badgeRow.revoked = revocationData.revoked;
          badgeRow.revocationReason = revocationData.revocationReason;
        }
        return badgeRow;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [revocatedBadges, revocationData]);

  const columns = [
    {
      field: "externalId",
      headerName: intl.formatMessage({
        id: "earners-grid.id-field-name",
        defaultMessage: "ID",
        description: "ID column header name in recipients grid",
      }),
      width: 150,
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        return <>{params.row.earner.externalId}</>;
      },
    },
    {
      field: "email",
      headerName: intl.formatMessage({
        id: "earners-grid.email-field-name",
        defaultMessage: "Email",
        description: "Email column header name in recipients grid",
      }),
      flex: true,
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        return <>{params.row.earner.email}</>;
      },
    },
    {
      field: "name",
      headerName: intl.formatMessage({
        id: "earners-grid.name-field-name",
        defaultMessage: "Name",
        description: "Name column header name in recipients grid",
      }),
      flex: true,
      disableColumnMenu: true,
      filterable: false,
      sortable: false,

      renderCell: (params) => {
        return <>{params.row.earner.name}</>;
      },
    },
    {
      field: "score",
      headerName: "Score",
      width: 150,
      renderCell: (params) => {
        return <>{params.row.score}</>;
      },
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
      align: "right",
    },
    {
      field: "actions",
      headerName: " ",
      width: 300,
      renderCell: (params) => {
        if (params.row.status !== "processed") {
          return <></>;
        }

        return (
          <>
            {params.row.revocationReason ? (
              <Box mt={2} mr={1}>
                <Tooltip title={params.row.revocationReason} aria-label="info">
                  <Icon color="action">info_sign</Icon>
                </Tooltip>
              </Box>
            ) : (
              ""
            )}

            {params.row.revoked ? (
              <Box mr={2}>
                <Typography variant="body2" color="error">
                  <FormattedMessage
                    defaultMessage="Revoked"
                    id="badge.revoked-label"
                    description="Badge revoked text"
                  />
                </Typography>
              </Box>
            ) : (
              ""
            )}

            {!params.row.revoked && !params.row.errors && (
              <Box mr={2}>
                <Button
                  variant="outlined"
                  onClick={() => handleRevokeButtonClick(params.row)}
                  data-test="revoke"
                  size="small"
                  startIcon={<Icon>block</Icon>}
                >
                  <FormattedMessage
                    defaultMessage="Revoke"
                    id="badge.revoke-button"
                    description="Badge revoke button text"
                  />
                </Button>
              </Box>
            )}

            {params.row.errors && (
              <Box mr={2}>
                <Typography
                  variant="body2"
                  color="error"
                  display="inline"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Icon>error_outline</Icon>
                  &nbsp;
                  <FormattedMessage
                    defaultMessage="Error"
                    id="badge-status.status-error"
                  />
                </Typography>
              </Box>
            )}

            {!params.row.errors && (
              <MoreOptionsMenu>
                {params.row.pdf && (
                  <Box>
                    <BadgePrintButton
                      uuid={params.row.uuid}
                      startIcon={<Icon>print</Icon>}
                      fullWidth={true}
                      style={{ padding: "0.5em 1em" }}
                    />
                    <Divider style={{ width: "100%" }} />
                  </Box>
                )}
                <Box>
                  <BadgeDownloadButton
                    display="grid"
                    justify="left"
                    uuid={params.row.uuid}
                    startIcon={<Icon>download</Icon>}
                    fullWidth={true}
                    style={{ padding: "0.5em 1em" }}
                  />
                  <Divider style={{ width: "100%" }} />
                </Box>
                <Box>
                  <BadgeResendButton
                    uuid={params.row.uuid}
                    fullWidth={true}
                    style={{ padding: "0.5em 1em" }}
                  />
                  <Divider style={{ width: "100%" }} />
                </Box>
              </MoreOptionsMenu>
            )}
          </>
        );
      },
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
      align: "right",
    },
  ];

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <DataGrid
        columns={columns}
        rows={props.assertions}
        loading={false}
        pagination={props.isTesting ? false : true}
        hideFooterPagination={props.isTesting ? true : false}
        rowCount={props.assertions.length}
        disableSelectionOnClick={true}
        disableColumnSelector={true}
        disableColumnReorder={true}
        autoHeight={true}
        columnBuffer={columns.length}
      ></DataGrid>
    </div>
  );
};

export default BatchAssertions;
