import { Box, Container, Grid, Typography } from "@material-ui/core";
import MuiLink from "@material-ui/core/Link/Link";
import React, { useState, useContext } from "react";
import AdminLayout from "../layouts/AdminLayout";
import { UserContext } from "../context/UserProvider";
import AuthService from "../services/AuthService";
import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import LoadingButton from "../components/Common/LoadingButton";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import history from "../utils/history";
import ErrorsAlert from "../components/Common/ErrorsAlert";
import useSnackbar from "../hooks/useSnackbar";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import GoogleRecaptchaInfo from "../components/Misc/GoogleRecaptchaInfo";

const Login = () => {
  const intl = useIntl();
  const { setUser, referer } = useContext(UserContext);
  const [showSnackbar] = useSnackbar();

  const [formData, setFormData] = useState({ email: "", password: "" });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleInputChange = (event) => {
    event.preventDefault();
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleFormSubmit = async () => {
    setLoading(true);

    const token = executeRecaptcha ? await executeRecaptcha() : "";
    AuthService.setGoogleRecaptchaToken(token);

    AuthService.login(formData.email, formData.password)
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setUser(data.user);
          setError(false);
          showSnackbar(
            intl.formatMessage({
              id: "login.success-alert",
              defaultMessage: "Entering the matrix...",
            })
          );

          if (referer) {
            history.push(referer);
          } else {
            history.push("/");
          }
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError(err.message);
      });
  };

  const validationMessages = {
    required: intl.formatMessage({
      id: "validation.field-required",
      defaultMessage: "This field is required",
      description: "Label shown on required form field when is required",
    }),
    isEmail: intl.formatMessage({
      id: "validation.field-email",
      defaultMessage: "Email is not valid",
      description: "Label shown on required form field when email is not valid",
    }),
  };

  const inputs = {
    email: {
      label: intl.formatMessage({
        id: "login.email-input",
        defaultMessage: "Email",
        description: "Email input label",
      }),
      placeholder: intl.formatMessage({
        id: "login.email-placeholder",
        defaultMessage: "Please enter your email",
        description: "Email input placeholder",
      }),
    },
    password: {
      label: intl.formatMessage({
        id: "login.password-input",
        defaultMessage: "Password",
        description: "Password input label",
      }),
      placeholder: intl.formatMessage({
        id: "login.password-placeholder",
        defaultMessage: "Please enter your password",
        description: "Password input placeholder",
      }),
    },
  };

  return (
    <AdminLayout>
      <Box my={2}>
        <Typography variant="h5">
          <FormattedMessage
            id="header.login"
            description="Log In button"
            defaultMessage="Log In"
          />
        </Typography>
      </Box>
      <ValidatorForm onSubmit={handleFormSubmit}>
        <Box my={2} display="flex" flexDirection="row" justifyContent="center">
          <Box>
            <Grid container direction="row" spacing={4}>
              <Grid item xs={12}>
                <ErrorsAlert errors={error ? [error] : false} />
              </Grid>
              <Grid item xs={12}>
                <Container maxWidth="sm">
                  <Box>
                    <TextValidator
                      onChange={handleInputChange}
                      name="email"
                      label={inputs.email.label}
                      value={formData["email"]}
                      placeholder={inputs.email.placeholder}
                      fullWidth
                      validators={["required", "isEmail"]}
                      errorMessages={[
                        validationMessages.required,
                        validationMessages.isEmail,
                      ]}
                      variant="outlined"
                    />
                  </Box>
                  <Box mt={2}>
                    <TextValidator
                      onChange={handleInputChange}
                      name="password"
                      type="password"
                      value={formData["password"]}
                      label={inputs.password.label}
                      placeholder={inputs.password.placeholder}
                      fullWidth
                      validators={["required"]}
                      errorMessages={[validationMessages.required]}
                      variant="outlined"
                    />
                  </Box>
                  <GoogleRecaptchaInfo />
                </Container>
              </Grid>
              <Grid item xs={12}>
                <Box justifyContent="center" display="flex" alignItems="center">
                  <LoadingButton
                    loading={loading ? "true" : undefined}
                    size="medium"
                    color="primary"
                    variant="contained"
                    type="submit"
                  >
                    <FormattedMessage
                      id="login.login-button"
                      description="Log In button"
                      defaultMessage="Log In"
                    />
                  </LoadingButton>
                </Box>
                <Box
                  mt={2}
                  justifyContent="center"
                  display="flex"
                  alignItems="center"
                >
                  <MuiLink
                    to="/reset-password"
                    color="secondary"
                    component={Link}
                  >
                    <FormattedMessage
                      id="login.forgot-password-link"
                      description="Forgot password link"
                      defaultMessage="Forgot password?"
                    />
                  </MuiLink>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </ValidatorForm>
    </AdminLayout>
  );
};

export default Login;
