import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, useParams } from "react-router-dom";
import BatchAssertions from "../components/Batch/BatchAssertions";
import BatchBadgeClass from "../components/Batch/BatchBadgeClass";
import BatchQuotation from "../components/Batch/BatchQuotation";
import BatchStatus from "../components/Batch/BatchStatus";
import ErrorsAlert from "../components/Common/ErrorsAlert";
import LoadingButton from "../components/Common/LoadingButton";
import { ResendConfirmProvider } from "../context/ResendConfirmProvider";
import { RevokeBadgeProvider } from "../context/RevokeBadgeProvider";
import usePromise from "../hooks/usePromise";
import AdminLayout from "../layouts/AdminLayout";
import BatchesService from "../services/BatchesService";

const BATCH_STATUS_REFRESH_MSEC = parseInt(
  process.env.REACT_APP_BATCH_STATUS_REFRESH_MSEC
);

const BatchSummary = () => {
  const params = useParams();

  const [batch, errors, loading, setPromise] = usePromise();
  const [awardData, awardErrors, awarding, setAwardPromise] = usePromise();
  const intl = useIntl();

  BatchesService.setDefaultErrorMessage(
    intl.formatMessage({
      id: "errors.unexpected-error",
      defaultMessage: "An unexpected error ocurred.",
    })
  );

  useEffect(() => {
    setPromise(BatchesService.getBatch(params.batchId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.batchId]);

  useEffect(() => {
    if (
      batch &&
      batch.status !== "processed" &&
      batch.status !== "pending" &&
      batch.status !== "processed_with_errors"
    ) {
      setTimeout(
        () => setPromise(BatchesService.getBatch(batch.uuid)),
        BATCH_STATUS_REFRESH_MSEC
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batch]);

  const handleAward = () => {
    setAwardPromise(BatchesService.awardBatch(batch.uuid)).then(function () {
      setPromise(BatchesService.getBatch(batch.uuid));
    });
  };

  return (
    <AdminLayout>
      <Box p={2}>
        <Breadcrumbs separator=">" aria-label="breadcrumb">
          <Link style={{ color: "inherit" }} to="/">
            <FormattedMessage
              id="routes.protected.dashboard"
              defaultMessage="Dashboard"
              description="Dashboard menu breadcrumbs title"
            />
          </Link>
          <Link style={{ color: "inherit" }} to="/history">
            <FormattedMessage
              id="routes.protected.history"
              defaultMessage="History"
              description="History menu link"
            />
          </Link>
          <FormattedMessage
            id="batch-summary.breadcrumb"
            defaultMessage="Batch #{batchId}"
            description="Batch breadcrumbs title"
            values={{ batchId: batch.number ?? "..." }}
          />
        </Breadcrumbs>
      </Box>
      {loading && (
        <Box display="grid" justifyItems="center">
          <CircularProgress />
        </Box>
      )}
      <Box mb={2}>
        <ErrorsAlert errors={errors} />
        <ErrorsAlert errors={awardErrors} />
      </Box>
      {batch && (
        <>
          <Paper>
            <Box p={2}>
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                <BatchStatus batch={batch} />
              </Box>
              <Box mt={2}>
                <Typography variant="h6">
                  <FormattedMessage
                    id="send-badges-wizard.badge-title"
                    defaultMessage="Badge"
                    description="Badge title"
                  />
                </Typography>
              </Box>
              <Paper variant="outlined">
                <Grid container>
                  <Grid item xs>
                    <BatchBadgeClass badgeClass={batch.badgeClass} />
                  </Grid>
                  <Grid item xs>
                    <BatchQuotation
                      batchStatus={batch.status}
                      batchUuid={batch.uuid}
                    />
                  </Grid>
                </Grid>
              </Paper>
              <Box mt={2}>
                <Typography variant="h6">
                  <FormattedMessage
                    id="batch-summary.earners-title"
                    defaultMessage="Recipients"
                    description="Recipients list title"
                  />{" "}
                  ({batch.badges.length})
                </Typography>
              </Box>
              <Box>
                <Paper variant="outlined">
                  <ResendConfirmProvider>
                    <RevokeBadgeProvider>
                      <BatchAssertions assertions={batch.badges} />
                    </RevokeBadgeProvider>
                  </ResendConfirmProvider>
                </Paper>
              </Box>
            </Box>
          </Paper>
          <Box mt={2} display="flex" justifyContent="space-between">
            <Button component={Link} to="/history">
              <FormattedMessage
                id="batch-summary.return-to-history-button"
                defaultMessage="Return to history"
                description="Return to history button text"
              />
            </Button>
            {(batch.status === "pending" ||
              batch.status === "processed_with_errors") && (
              <LoadingButton
                variant="contained"
                color="primary"
                onClick={handleAward}
                loading={awarding}
                disabled={awarding}
              >
                <Typography noWrap variant="button">
                  {batch.status === "processed_with_errors" ? (
                    <FormattedMessage
                      id="send-badges-wizard.retry-with-errors-button"
                      defaultMessage="Retry"
                    />
                  ) : (
                    <FormattedMessage
                      id="send-badges-wizard.award-button"
                      defaultMessage="Award"
                      description="Award button in wizard"
                    />
                  )}
                </Typography>
              </LoadingButton>
            )}
          </Box>
        </>
      )}
    </AdminLayout>
  );
};

export default BatchSummary;
