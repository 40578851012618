import { Link, Typography } from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";

const GoogleRecaptchaInfo = () => {
  return (
    <Typography
      variant="caption"
      style={{ fontSize: "0.7em", color: "#AEAEAE" }}
    >
      <FormattedMessage
        id="recaptcha-info"
        defaultMessage="This site is protected by reCAPTCHA and the Google {privacy} and {terms} apply."
        values={{
          privacy: (
            <Link
              href="https://policies.google.com/privacy"
              target="_blank"
              rel="noreferrer"
              style={{ color: "#AEAEAE" }}
            >
              <FormattedMessage
                id="recaptcha-privacy"
                defaultMessage="Privacy Policy"
              />
            </Link>
          ),
          terms: (
            <Link
              href="https://policies.google.com/terms"
              target="_blank"
              rel="noreferrer"
              style={{ color: "#AEAEAE" }}
            >
              <FormattedMessage
                id="recaptcha-terms"
                defaultMessage="Terms of Service"
              />
            </Link>
          ),
        }}
      />
    </Typography>
  );
};

export default GoogleRecaptchaInfo;
