import {
  Box,
  Breadcrumbs,
  Grid,
  Paper,
  Tab,
  Typography,
} from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, useParams } from "react-router-dom";
import BatchList from "../components/Batch/BatchList";
import BadgeStatusSelect from "../components/Badge/BadgeStatusSelect";
import BadgeList from "../components/Badge/BadgeList";
import AdminLayout from "../layouts/AdminLayout";
import EarnerOneSelectInput from "../components/Earner/EarnerOneSelectInput";
import { RevokeBadgeProvider } from "../context/RevokeBadgeProvider";
import history from "../utils/history";
import { ResendConfirmProvider } from "../context/ResendConfirmProvider";

const Batches = () => {
  const params = useParams();
  const intl = useIntl();
  const [value, setValue] = useState(
    params.tabNumber ? params.tabNumber : "batches"
  );
  const [search, setSearch] = useState("");
  const [statuses, setStatuses] = useState(["pending", "processed", "error"]);
  const [earner, setEarner] = useState(false);
  const pageNumber = params.pageNumber ? parseInt(params.pageNumber, 10) : 1;

  const handleChange = (event, newValue) => {
    history.push("/history/" + newValue);
    setValue(newValue);
  };

  return (
    <AdminLayout>
      <Box p={2}>
        <Breadcrumbs separator=">" aria-label="breadcrumb">
          <Link style={{ color: "inherit" }} to="/">
            <FormattedMessage
              id="routes.protected.dashboard"
              defaultMessage="Dashboard"
              description="Dashboard menu breadcrumbs title"
            />
          </Link>
          <Typography color="textPrimary">
            <FormattedMessage
              id="routes.protected.history"
              defaultMessage="History"
              description="History menu link"
            />
          </Typography>
        </Breadcrumbs>
      </Box>
      <Paper p={1} variant="outlined">
        <ResendConfirmProvider>
          <RevokeBadgeProvider>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="history tabs"
                  indicatorColor="primary"
                >
                  <Tab
                    label={intl.formatMessage({
                      id: "history.batches-tab-title",
                      defaultMessage: "Batches",
                    })}
                    value="batches"
                  />
                  <Tab
                    label={intl.formatMessage({
                      id: "history.badges-tab-title",
                      defaultMessage: "Badges",
                    })}
                    value="badges"
                  />
                </TabList>
              </Box>
              <TabPanel value="batches">
                <BatchList />
              </TabPanel>
              <TabPanel value="badges">
                <Box p={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={10}>
                      <EarnerOneSelectInput
                        setSearch={setSearch}
                        params={params}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <BadgeStatusSelect
                        setStatuses={setStatuses}
                        statuses={statuses}
                        params={params}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box p={2}>
                  <BadgeList
                    pageNumber={pageNumber}
                    limit={5}
                    search={search}
                    earner={earner}
                    statuses={statuses}
                  />
                </Box>
              </TabPanel>
            </TabContext>
          </RevokeBadgeProvider>
        </ResendConfirmProvider>
      </Paper>
    </AdminLayout>
  );
};

export default Batches;
