import {
  Avatar,
  Button,
  ClickAwayListener,
  Grow,
  Icon,
  Paper,
  Popper,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";

const AvatarMenu = (props) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handlePopperLogout = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Button
        ref={anchorRef}
        size="small"
        aria-haspopup="true"
        aria-controls={open ? "menu-list-grow" : undefined}
        onClick={() => {
          handleToggle(true);
        }}
      >
        <Avatar>
          {props.avatar ? props.avatar : <Icon>account_circle</Icon>}
        </Avatar>
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-end"
        role={undefined}
        disablePortal
      >
        <Grow>
          <ClickAwayListener onClickAway={handlePopperLogout}>
            <Paper elevation={1}>{props.children}</Paper>
          </ClickAwayListener>
        </Grow>
      </Popper>
    </>
  );
};

export default AvatarMenu;
