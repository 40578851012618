import {
  Box,
  FormControl,
  InputLabel,
  Select,
  TextField,
} from "@material-ui/core";
import { mdiBorderColor } from "@mdi/js";
import React from "react";
import SvgEditorColorPicker from "./SvgEditorColorPicker";

const SvgEditorStrokeSelector = (props) => {
  const strokeTypes = [
    {
      value: "none",
      icon: "―――――――",
    },
    {
      value: "2,2",
      icon: "···························",
    },
    {
      value: "5,5",
      icon: "˗˗˗˗˗˗˗˗˗˗˗˗˗˗˗˗˗˗˗˗˗˗",
    },
    {
      value: "5,2,2,2",
      icon: "―·―·―·―·―·―",
    },
    {
      value: "5,2,2,2,2,2",
      icon: "―··―··―··―··―··",
    },
  ];

  return (
    <Box display="flex" flexDirection="row">
      <Box>
        <SvgEditorColorPicker
          color={props.strokeColor}
          onColorChange={props.onStrokeColorChange}
          icon={mdiBorderColor}
        />
      </Box>
      <Box ml={1}>
        <FormControl size="small" variant="outlined">
          <InputLabel htmlFor="outlined-age-native-simple">Style</InputLabel>
          <Select
            native
            value={props.strokeType || ""}
            onChange={props.onStrokeTypeChange}
            label="Style"
            inputProps={{
              name: "age",
              id: "outlined-age-native-simple",
            }}
          >
            {strokeTypes.map((t) => (
              <option value={t.value} aria-label={t.value} key={t.value}>
                {t.icon}
              </option>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box ml={1}>
        <TextField
          variant="outlined"
          id="standard-number"
          label="Width"
          type="number"
          size="small"
          InputProps={{
            inputProps: { min: 0, max: 10 },
            onChange: props.onStrokeWidthChange,
          }}
          InputLabelProps={{
            shrink: true,
          }}
          value={props.strokeWidth}
        />
      </Box>
    </Box>
  );
};

export default SvgEditorStrokeSelector;
