import { Box, CircularProgress, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import usePromise from "../../hooks/usePromise";
import BatchesService from "../../services/BatchesService";
import FormattedCurrency from "../Account/FormattedCurrency";

const BatchQuotation = (props) => {
  const [quotation, setQuotation] = useState(props.quotation);
  const [data, errors, loading, setPromise] = usePromise();
  const intl = useIntl();

  BatchesService.setDefaultErrorMessage(
    intl.formatMessage({
      id: "errors.unexpected-error",
      defaultMessage: "An unexpected error ocurred.",
    })
  );
  BatchesService.setErrorMessages({
    "You have not balance": intl.formatMessage({
      id: "errors.not-affordable",
      defaultMessage: "You have not balance",
    }),
  });

  useEffect(() => {
    if (props.batchUuid && !props.quotation) {
      props.setAffordable && props.setAffordable(null);
      setPromise(BatchesService.getQuotation(props.batchUuid));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.quotation, props.batchUuid]);

  useEffect(() => {
    if (data) {
      data.batch && setQuotation(data.batch.quotation);
      props.setAffordable && props.setAffordable(data.affordable);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (errors) {
    return <Box>Error</Box>;
  }

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{ height: "100%" }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!quotation) {
    return <Box>Not found</Box>;
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-end"
      justifyContent="space-between"
      p={2}
      style={{ height: "100%" }}
    >
      <Box>
        {BatchesService.calculateTotal(quotation.items.edges).map((total) => (
          <Typography variant="h5" key={total.currency.ticker}>
            <FormattedCurrency
              value={total.amount}
              currency={total.currency}
              variant="h5"
            />
          </Typography>
        ))}
      </Box>
      <Box mt={2} flexGrow={1}>
        {quotation.items.edges.map((quotationItem) => (
          <Box key={quotationItem.node._id}>
            <Typography variant="caption">
              {quotationItem.node.quantity} x {quotationItem.node.description}
              &nbsp;
              <FormattedCurrency
                value={quotationItem.node.amount}
                currency={quotationItem.node.currency}
                variant="caption"
              />
            </Typography>
          </Box>
        ))}
      </Box>
      {props.batchStatus !== "processed" && (
        <Box mt={1}>
          <Typography variant="caption">
            Valid until{" "}
            {intl.formatDate(quotation.expiration, {
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            })}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default BatchQuotation;
