import {
  Box,
  Grid,
  Icon,
  InputAdornment,
  MenuItem,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  SelectValidator,
  ValidatorForm,
} from "react-material-ui-form-validator";
import TextValidator from "react-material-ui-form-validator/lib/TextValidator";
import IssuerFormImageField from "./IssuerFormImageField";
import LoadingButton from "../Common/LoadingButton";
import CountrySelect from "../Common/CountrySelect";

const IssuerForm = ({
  handleFormSubmit,
  loading,
  issuer,
  buttonText,
  disableIdentityInputs,
  ...rest
}) => {
  const intl = useIntl();
  const countryInputRef = useRef(null);
  const [hasChanges, setHasChanges] = useState(false);
  const [formValues, setFormValues] = useState({
    name: issuer?.name ?? "",
    description: issuer?.description ?? "",
    email: issuer?.email ?? "",
    url: issuer?.url ?? "",
    phone: issuer?.phone ?? "",
    image: issuer?.image ?? "",
    locale: issuer?.locale ?? "es",
    country: issuer?.country ?? undefined,
  });

  const languages = [
    {
      value: "es",
      label: intl.formatMessage({
        id: "issuer-form.language-es",
        defaultMessage: "Spanish",
      }),
    },
    {
      value: "en",
      label: intl.formatMessage({
        id: "issuer-form.language-en",
        defaultMessage: "English",
      }),
    },
  ];

  ValidatorForm.addValidationRule("countryRequire", (value) => {
    return formValues && formValues.country ? true : false;
  });

  const validationMessages = {
    required: intl.formatMessage({
      id: "validation.field-required",
      defaultMessage: "This field is required",
      description: "Label shown on required form field when is required",
    }),
    countryRequire: intl.formatMessage({
      id: "validation.field-required",
      defaultMessage: "This field is required",
      description: "Label shown on required form field when is required",
    }),
    isEmail: intl.formatMessage({
      id: "validation.field-email",
      defaultMessage: "Email is not valid",
      description: "Label shown on required form field when email is not valid",
    }),
    phone: intl.formatMessage({
      id: "validation.field-e164-phone",
      defaultMessage: "You must use the E.164 format.",
      description: "Label shown on phone form field when is required",
    }),
    url: intl.formatMessage({
      id: "validation.field-url",
      defaultMessage: "Enter a valid URL.",
      description: "Label shown on url form field when is required",
    }),
  };

  const handleFormInputChange = (event) => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
    setHasChanges(true);
  };

  const handleFormCountryChange = (value) => {
    setFormValues({ ...formValues, country: value });
    setHasChanges(true);
  };

  return (
    <ValidatorForm
      onSubmit={() => handleFormSubmit(formValues)}
      data-testid="issuerForm"
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Box>
            <TextValidator
              label={intl.formatMessage({
                id: "issuer-form.name-label",
                defaultMessage: "Issuer Name",
              })}
              placeholder={intl.formatMessage({
                id: "issuer-form.name-placeholder",
                defaultMessage: "High Tech School",
              })}
              variant="outlined"
              validators={["required"]}
              errorMessages={[validationMessages.required]}
              fullWidth
              name="name"
              value={formValues.name}
              onChange={handleFormInputChange}
              inputProps={{
                maxLength: 127,
                "data-testid": "inputName",
              }}
            />
          </Box>
          <Box mt={2}>
            <TextValidator
              label={intl.formatMessage({
                id: "issuer-form.description-label",
                defaultMessage: "Description",
              })}
              placeholder={intl.formatMessage({
                id: "issuer-form.description-placeholder",
                defaultMessage: "Teaching advanced technologies since 2005.",
              })}
              variant="outlined"
              fullWidth
              multiline
              rows={3}
              name="description"
              value={formValues.description}
              onChange={handleFormInputChange}
              inputProps={{
                maxLength: 1023,
                "data-testid": "inputDescription",
              }}
            />
          </Box>
          <Box mt={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box>
                  <TextValidator
                    label={intl.formatMessage({
                      id: "issuer-form.email-label",
                      defaultMessage: "Email",
                    })}
                    placeholder={intl.formatMessage({
                      id: "issuer-form.email-placeholder",
                      defaultMessage: "info@ht-school.edu",
                    })}
                    variant="outlined"
                    fullWidth
                    name="email"
                    value={formValues.email}
                    onChange={handleFormInputChange}
                    inputProps={{
                      maxLength: 63,
                      "data-testid": "inputEmail",
                    }}
                    validators={["required", "isEmail"]}
                    errorMessages={[
                      validationMessages.required,
                      validationMessages.isEmail,
                    ]}
                    disabled={disableIdentityInputs}
                    InputProps={{
                      endAdornment: disableIdentityInputs ? (
                        <InputAdornment position="end">
                          <Tooltip
                            title={intl.formatMessage({
                              id: "issuer-form.validation-field-tootlip",
                              defaultMessage:
                                "Modify this value in Identity section.",
                            })}
                          >
                            <Icon>info_outlined</Icon>
                          </Tooltip>
                        </InputAdornment>
                      ) : (
                        <></>
                      ),
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box>
                  <TextValidator
                    label={intl.formatMessage({
                      id: "issuer-form.url-label",
                      defaultMessage: "Website",
                    })}
                    placeholder={intl.formatMessage({
                      id: "issuer-form.url-placeholder",
                      defaultMessage: "https://www.ht-school.edu",
                    })}
                    variant="outlined"
                    fullWidth
                    name="url"
                    value={formValues.url}
                    onChange={handleFormInputChange}
                    inputProps={{
                      maxLength: 255,
                      "data-testid": "inputURL",
                    }}
                    validators={[
                      "required",
                      `matchRegexp:^https?\\:\\/\\/.+\\..+$`,
                    ]}
                    errorMessages={[
                      validationMessages.required,
                      validationMessages.url,
                    ]}
                    disabled={disableIdentityInputs}
                    InputProps={{
                      endAdornment: disableIdentityInputs ? (
                        <InputAdornment position="end">
                          <Tooltip
                            title={intl.formatMessage({
                              id: "issuer-form.validation-field-tootlip",
                              defaultMessage:
                                "Modify this value in Identity section.",
                            })}
                          >
                            <Icon>info_outlined</Icon>
                          </Tooltip>
                        </InputAdornment>
                      ) : (
                        <></>
                      ),
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box>
                  <TextValidator
                    label={intl.formatMessage({
                      id: "issuer-form.phone-label",
                      defaultMessage: "Phone Number",
                    })}
                    placeholder={intl.formatMessage({
                      id: "issuer-form.phone-placeholder",
                      defaultMessage: "+19341558417",
                    })}
                    variant="outlined"
                    fullWidth
                    name="phone"
                    value={formValues.phone}
                    onChange={handleFormInputChange}
                    validators={[`matchRegexp:^\\+[0-9]\\d{1,13}$`]}
                    errorMessages={[validationMessages.phone]}
                    inputProps={{
                      "data-testid": "inputPhone",
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box>
                  <SelectValidator
                    label={intl.formatMessage({
                      id: "issuer-form.language-label",
                      defaultMessage: "Main Language",
                    })}
                    variant="outlined"
                    fullWidth
                    name="locale"
                    value={formValues.locale}
                    onChange={handleFormInputChange}
                    validators={["required"]}
                    errorMessages={[validationMessages.required]}
                    inputProps={{
                      "data-testid": "inputLocale",
                    }}
                  >
                    {languages.map((language) => (
                      <MenuItem value={language.value} key={language.value}>
                        {language.label}
                      </MenuItem>
                    ))}
                  </SelectValidator>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box>
                  <CountrySelect
                    name="country"
                    value={formValues.country}
                    onChange={handleFormCountryChange}
                    inputRef={countryInputRef}
                    validators={["countryRequire"]}
                    errorMessages={[validationMessages.countryRequire]}
                    inputProps={{
                      "data-testid": "inputCountry",
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box display="flex" flexDirection="column" style={{ height: "100%" }}>
            <Box flexGrow={1}>
              <IssuerFormImageField
                onChange={handleFormInputChange}
                value={issuer?.image ? { uuid: issuer.image } : null}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} container justify="center">
          <Box my={2}>
            <LoadingButton
              loading={loading ? "true" : undefined}
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              disabled={!hasChanges}
            >
              <Typography noWrap variant="button">
                {buttonText ?? (
                  <FormattedMessage
                    id="header.create-issuer"
                    description="Create Issuer button"
                    defaultMessage="Create Issuer"
                  />
                )}
              </Typography>
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
    </ValidatorForm>
  );
};

export default IssuerForm;
