import { FormattedMessage } from "react-intl";

const errors = {
  AuthConnectionError: (
    <FormattedMessage
      id="errors.auth-connection-error"
      defaultMessage="An error ocurred, please try again."
      description="Message shown in login page when a fatal error occurs."
    />
  ),
  AuthBadCredentials: (
    <FormattedMessage
      id="errors.auth-bad-credentials"
      defaultMessage="Email or password incorrect."
      description="Message shown in login page when user puts bad credentials. "
    />
  ),
};

export { errors };
