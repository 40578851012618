import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import ResendConfirmDialog from "../components/Common/ResendConfirmDialog";
import usePromise from "../hooks/usePromise";
import useSnackbar from "../hooks/useSnackbar";
import BadgesService from "../services/BadgesService";
import BatchesService from "../services/BatchesService";

const ResendConfirmContext = React.createContext();

const ResendConfirmProvider = (props) => {
  const [showDialog, setShowDialog] = useState(false);
  const [mode, setMode] = useState("badge");
  const [uuid, setUuid] = useState(null);
  const [data, errors, loading, resendPromise] = usePromise();
  const [showSnackbar] = useSnackbar();
  const intl = useIntl();

  const handleResendClick = () => {
    setShowDialog(true);
  };

  const handleClose = () => {
    setShowDialog(false);
  };

  const handleResend = () => {
    if (mode === "badge") {
      resendPromise(BadgesService.resendBadge(uuid));
    }
    if (mode === "batch") {
      resendPromise(BatchesService.resendBatch(uuid));
    }
  };

  useEffect(() => {
    if (data.uuid) {
      showSnackbar(
        intl.formatMessage({
          id: "email-sent-message",
          defaultMessage: "Sent!",
        }),
        "success",
        1000
      );
      setShowDialog(false);
    }

    if (errors) {
      showSnackbar(errors[0], "error", 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, errors]);

  return (
    <ResendConfirmContext.Provider
      value={{ handleResendClick, setUuid, setMode }}
    >
      {props.children}
      <ResendConfirmDialog
        handleClose={handleClose}
        handleResend={handleResend}
        open={showDialog}
        mode={mode}
        resending={loading}
      />
    </ResendConfirmContext.Provider>
  );
};

export { ResendConfirmProvider, ResendConfirmContext };
