import React from "react";
import { ValidatorComponent } from "react-material-ui-form-validator";
import { Box, Checkbox, Icon } from "@material-ui/core";

class CheckboxValidator extends ValidatorComponent {
  renderValidatorComponent() {
    const {
      errorMessages,
      validators,
      requiredError,
      value,
      ...rest
    } = this.props;
    const { isValid } = this.state;

    return (
      <Box>
        <Checkbox
          {...rest}
          ref={(r) => {
            this.input = r;
          }}
          icon={
            <Icon color={isValid ? "action" : "error"}>
              check_box_outline_blank
            </Icon>
          }
        />
      </Box>
    );
  }
}

export default CheckboxValidator;
