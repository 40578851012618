import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import usePromise from "../../hooks/usePromise";
import EarnersService from "../../services/EarnersService";
import ErrorsAlert from "../Common/ErrorsAlert";
import EarnerSearchInput from "./EarnerSearchInput";

const EarnersSelectionDialog = (props) => {
  const [data, errors, loading, setPromise] = usePromise();
  const limit = props.limit ? parseInt(props.limit, 10) : 5;
  const [rows, setRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [selectionModel, setSelectionModel] = useState(null);
  const [search, setSearch] = useState("");
  const [tags, setTags] = useState([]);
  const intl = useIntl();

  EarnersService.setDefaultErrorMessage(
    intl.formatMessage({
      id: "errors.unexpected-error",
      defaultMessage: "An unexpected error ocurred.",
    })
  );

  const handlePageChange = (params) => {
    setPromise(EarnersService.getEarners(limit, params.page + 1, search, tags));
  };

  const handleEarnersSelection = (event) => {
    const selectedEarners = rows.filter(
      (earner) => selectionModel.indexOf(earner.uuid) !== -1
    );
    props.handleEarnersSelection(event, selectedEarners);
    setSelectionModel([]);
  };

  useEffect(() => {
    setPromise(EarnersService.getEarners(limit, 1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data) {
      setRows(data.collection);
      setRowCount(data.paginationInfo.totalCount);
    }
  }, [data]);

  useEffect(() => {
    setPromise(EarnersService.getEarners(limit, 1, search, tags));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags, search]);

  const columns = [
    {
      field: "externalId",
      headerName: intl.formatMessage({
        id: "earners-grid.id-field-name",
        defaultMessage: "ID",
        description: "ID column header name in recipients grid",
      }),
      width: 150,
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
    },
    {
      field: "email",
      headerName: intl.formatMessage({
        id: "earners-grid.email-field-name",
        defaultMessage: "Email",
        description: "Email column header name in recipients grid",
      }),
      flex: true,
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
    },
    {
      field: "name",
      headerName: intl.formatMessage({
        id: "earners-grid.name-field-name",
        defaultMessage: "Name",
        description: "Name column header name in recipients grid",
      }),
      flex: true,
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
    },
  ];

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      fullWidth={true}
      maxWidth="lg"
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        <Box>
          <FormattedMessage
            id="select-earners-dialog.dialog-title"
            defaultMessage="Select Recipients"
            description="Select Recipients dialog title"
          />
        </Box>
        <Box mt={2}>
          <EarnerSearchInput
            setSearch={setSearch}
            setTags={setTags}
            params={{ pageNumber: 1 }}
          />
        </Box>
      </DialogTitle>
      <DialogContent>
        <>
          <Box>
            <ErrorsAlert errors={errors} />
          </Box>
          <Box style={{ height: "100%", width: "100%" }}>
            <DataGrid
              error={errors ? errors[0] : null}
              columns={columns}
              rows={rows}
              loading={loading}
              paginationMode="server"
              onPageChange={handlePageChange}
              pageSize={limit}
              rowCount={rowCount}
              disableSelectionOnClick={true}
              disableColumnSelector={true}
              disableColumnReorder={true}
              autoHeight={true}
              checkboxSelection={true}
              getRowId={(row) => {
                return row.uuid;
              }}
              onSelectionModelChange={(newSelection) => {
                setSelectionModel(newSelection.selectionModel);
              }}
              selectionModel={selectionModel}
            ></DataGrid>
          </Box>
        </>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="default">
          <FormattedMessage
            id="select-earners-dialog.cancel-button"
            defaultMessage="Cancel"
            description="Cancel button text"
          />
        </Button>
        <Button
          onClick={handleEarnersSelection}
          variant="contained"
          color="primary"
        >
          <FormattedMessage
            id="select-earners-dialog.select-button"
            defaultMessage="Select"
            description="Select button text"
          />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EarnersSelectionDialog;
