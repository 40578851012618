import { Box, Chip, Icon, Paper, Typography } from "@material-ui/core";
import React from "react";
import { useIntl } from "react-intl";
import IssuerIdentityStatus from "./IssuerIdentityStatus";

const IssuerIdentityContainer = ({
  type,
  status,
  comingSoon,
  children,
  ...rest
}) => {
  const intl = useIntl();

  const getIdentityTitle = (type) => {
    switch (type) {
      case "email":
        return intl.formatMessage({
          id: "identity-email.title",
          defaultMessage: "Email",
        });
      case "domain":
        return intl.formatMessage({
          id: "identity-domain.title",
          defaultMessage: "Domain",
        });
      case "documentation":
        return intl.formatMessage({
          id: "identity-documentation.title",
          defaultMessage: "Official Documentation",
        });
      default:
        return intl.formatMessage({
          id: "identity-default.title",
          defaultMessage: "Identity",
        });
    }
  };

  const getIdentityIcon = (type) => {
    switch (type) {
      case "email":
        return <Icon fontSize="small">mail_outline</Icon>;
      case "domain":
        return <Icon fontSize="small">domain</Icon>;
      case "documentation":
        return <Icon fontSize="small">account_balance_outlined</Icon>;
      default:
        return <Icon fontSize="small">fingerprint</Icon>;
    }
  };

  const getBorderColor = (status) => {
    switch (status) {
      case "validating":
        return "#C9C900";
      case "validated":
        return "#009900";
      default:
        return "#BABABA";
    }
  };

  return (
    <Paper
      variant="outlined"
      {...rest}
      style={{ padding: "0.5em", borderColor: getBorderColor(status) }}
    >
      <Box mb={1} display="flex" flexDirection="row" alignItems="center">
        <Box flexShrink={1} m={1} display="flex" alignItems="center">
          {getIdentityIcon(type)}
        </Box>
        <Box flexGrow={1} ml={0.2} display="flex" alignItems="center">
          <Typography variant="body1">{getIdentityTitle(type)}</Typography>
        </Box>
        <Box flexShrink={1} m={1}>
          {comingSoon ? (
            <Chip
              size="small"
              label="Coming Soon!"
              color="default"
              component="div"
            />
          ) : (
            <IssuerIdentityStatus status={status} />
          )}
        </Box>
      </Box>
      <Box m={1}>{children}</Box>
    </Paper>
  );
};

export default IssuerIdentityContainer;
